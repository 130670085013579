import React, { Component } from 'react';
// import { PortletInfo } from "../common/portletInfo";
import Modal from 'react-bootstrap/Modal';
import { isMobile } from 'react-device-detect';
// import Overlay from "react-bootstrap/Overlay";
import Overlay from 'react-bootstrap/Overlay';
import Popover from 'react-bootstrap/Popover';
import config from '../config.json';
// import { toast } from "react-toastify";
import { getEnvironment } from '../services/EnvironmentVariable';

class MyApprovals extends Component {
  state = {
    displayInfo: false,
    displaySnowInfo: false,
    displayIIQInfo: false,
    displayAribaInfo: false,
    displayOTInfo: false,
    displayConfirm: false,
    confirmTitle: '',
    whichAction: '',
    actionIdx: 0,
    approvalTop: '0px',
    target: null,
    snowTarget: null,
    iiqTarget: null,
    aribaTarget: null,
    otTarget: null,
  };

  goToServiceNow = () => {
    window.open('https://nwproduction.service-now.com/navpage.do');
  };
  goToConcur = () => {
    window.open('https://www.concursolutions.com/home.asp');
  };

  goToAriba = () => {
    const environ = getEnvironment();
    if (environ !== 'DEFAULT') {
      window.open('https://s1.ariba.com/gb/?realm=nationwide-child-T&locale=en_US');
    } else {
      window.open('https://s1.ariba.com/gb/?realm=nationwide-child&locale=en_US');
    }
  };

  goToIIQ = () => {
    const environ = getEnvironment();
    if (environ !== 'DEFAULT') {
      window.open('https://iiqdev.nwie.net/identityiq/home.jsf');
    } else {
      window.open('https://iiq.nwie.net/identityiq/home.jsf');
    }
  };
  showInformation = event => {
    const { displayInfo } = this.state;
    const target = event.target;
    this.setState({ displayInfo: !displayInfo, target });
  };
  showSnowInformation = event => {
    const { displaySnowInfo } = this.state;
    const snowTarget = event.target;
    this.setState({ displaySnowInfo: !displaySnowInfo, snowTarget });
  };
  showIIQInformation = event => {
    const { displayIIQInfo } = this.state;
    const iiqTarget = event.target;
    this.setState({ displayIIQInfo: !displayIIQInfo, iiqTarget });
  };

  showAribaInformation = event => {
    const { displayAribaInfo } = this.state;
    const aribaTarget = event.target;
    this.setState({ displayAribaInfo: !displayAribaInfo, aribaTarget });
  };
  showOTInformation = event => {
    const { displayOTInfo } = this.state;
    const otTarget = event.target;
    this.setState({ displayOTInfo: !displayOTInfo, otTarget });
  };
  handleRefresh = () => {
    // console.log("myApprovals.handleRefresh");
    this.props.retrieveApprovals();
  };

  handleLoadMore = (event, approvalCount) => {
    this.props.handleChangeMaxApprovals(approvalCount);
  };

  confirmAction = () => {
    const { whichAction, actionIdx } = this.state;
    // console.log("confirmAction", whichAction);
    const displayConfirm = false;
    this.props.handleRemoveApproval(actionIdx);
    this.setState({ displayConfirm });
    // setTimeout(this.moveToTop, 100);
  };

  cancelAction = () => {
    const { whichAction } = this.state;
    // console.log("cancelAction", whichAction);
    const displayConfirm = false;
    this.setState({ displayConfirm });
    // setTimeout(this.moveToTop, 100);
  };

  review = item => {
    // const types = ["Change Request", "Service Request", "Expense Request"];
    const { approvals } = this.props;
    if (item.url) {
      const t = window.open(item.url, '_blank');
    }
  };

  reject = index => {
    const { approvals } = this.props;
    const approvalEntries = approvals.entries;
    const approvalTitle = approvalEntries[index].title;
    const confirmTitle = 'Are you sure you want to reject this request? ';
    const actionIdx = index;
    const displayConfirm = true;
    const whichAction = 'reject';
    const parent = document.getElementById('approvalsScrollBox');
    let rect = parent.getBoundingClientRect();
    const t = parent.scrollTop + rect.top + 'px';
    this.setState({
      approvalTitle,
      confirmTitle,
      actionIdx,
      displayConfirm,
      whichAction,
      approvalTop: t,
    });
  };
  approve = index => {
    const { approvals } = this.props;
    const approvalEntries = approvals.entries;
    // console.log("myApprovals displayApprovals", approvals, index);
    const approvalTitle = approvalEntries[index].title;
    const confirmTitle = 'Are you sure you want to approve this request?';
    const actionIdx = index;
    const displayConfirm = true;
    const whichAction = 'approve';
    const parent = document.getElementById('approvalsScrollBox');
    let rect = parent.getBoundingClientRect();

    const t = parent.scrollTop + rect.top + 'px';
    this.setState({
      approvalTitle,
      confirmTitle,
      actionIdx,
      displayConfirm,
      whichAction,
      approvalTop: t,
    });
  };

  moveToTop = () => {
    const { actionIdx } = this.state;
    const targetIdx = actionIdx === 0 ? 1 : actionIdx - 1;
    try {
      const myElement = document.getElementById('approvalItemDisplay_' + targetIdx);
      const topPos = myElement.offsetTop;
      document.getElementById('approvalsScrollBox').scrollTop = topPos;
    } catch (e) {}
  };

  renderServiceNow = displayApprovals => {
    const { displaySnowInfo, snowTarget } = this.state;
    let informationMessge = `Currently only catalog requests, change approvals and TOP requests are supported from ServiceNow.`;
    let snowApprovals = [];
    let s = 0;
    if (displayApprovals && displayApprovals.length > 0) {
      for (let i = 0; i < displayApprovals.length; i++) {
        if (displayApprovals[i].actionType === 'ServiceNow') {
          snowApprovals[s++] = {
            actionType: 'ServiceNow',
            id: displayApprovals[i].id,
            title: displayApprovals[i].title,
            url: displayApprovals[i].url,
          };
        }
      }
      if (snowApprovals.length > 0) {
        return (
          <div style={{ width: '100%', borderBottom: '1px solid rgb(188, 189, 188)' }}>
            <div
              style={{
                color: '#1C57A5',
                fontSize: '16px',
                fontWeight: 'bold',
                paddingTop: '15px',
              }}
            >
              ServiceNow
              <button
                onClick={e => this.showSnowInformation(e)}
                className="infoLink"
                id="approvalsPopoverButton2"
              >
                {displaySnowInfo ? 'X' : '?'}
              </button>
              <Overlay show={displaySnowInfo} target={snowTarget} placement="bottom">
                <Popover id="approvalsPopOver2">
                  <Popover.Title as="div">ServiceNow</Popover.Title>
                  <Popover.Content>{informationMessge}</Popover.Content>
                </Popover>
              </Overlay>
            </div>
            {snowApprovals.map((item, index) => (
              <div
                className="approval"
                key={'approvalItem_' + index}
                id={'approvalItemDisplay_' + index}
                tabIndex="-1"
                style={
                  index > 0
                    ? {
                        borderTop: 'dashed gray 1px',
                        paddingTop: '10px',
                        paddingBottom: '10px',
                      }
                    : { paddingTop: '10px', paddingBottom: '10px' }
                }
              >
                {/* <div className="approvalType">{item.actionType}</div> */}
                <div className="approvalTitle snowTitle">
                  {item.title}
                  <div>
                    <div style={{ width: '100%' }}>
                      <button
                        className="approvalButtons"
                        onClick={() => this.review(item)}
                        style={{ textAlign: 'left' }}
                      >
                        Review to Approve or Reject
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        );
      } else {
        return <React.Fragment></React.Fragment>;
      }
    } else {
      return <React.Fragment></React.Fragment>;
    }
  };

  renderIIQ = displayApprovals => {
    console.log('myApprovals.renderIIQ start', displayApprovals, displayApprovals.length);
    const { displayIIQInfo, iiqTarget } = this.state;
    let informationMessge = `Currently only access reviews and access requests to applications are supported from IIQ. IIQ requires VPN for access.`;
    let iiqApprovals = [];
    let s = 0;
    if (displayApprovals && displayApprovals.length > 0) {
      for (let i = 0; i < displayApprovals.length; i++) {
        console.log(
          'myApprovals.renderIIQ displayApprovals[' + i + '].actionType',
          displayApprovals[i].actionType,
        );
        if (displayApprovals[i].actionType === 'IIQ') {
          iiqApprovals[s++] = {
            actionType: 'IIQ',
            id: displayApprovals[i].id,
            title: displayApprovals[i].title,
            url: displayApprovals[i].url,
          };
        }
      }
      if (iiqApprovals.length > 0) {
        console.log('myApprovals.renderIIQ iiqApprovals', iiqApprovals);
        return (
          <div style={{ width: '100%', borderBottom: '1px solid rgb(188, 189, 188)' }}>
            <div
              style={{
                color: '#1C57A5',
                fontSize: '16px',
                fontWeight: 'bold',
                paddingTop: '15px',
              }}
            >
              IIQ
              <button
                onClick={e => this.showIIQInformation(e)}
                className="infoLink"
                id="approvalsPopoverButton2"
              >
                {displayIIQInfo ? 'X' : '?'}
              </button>
              <Overlay show={displayIIQInfo} target={iiqTarget} placement="bottom">
                <Popover id="approvalsPopOver2">
                  <Popover.Title as="div">IIQ</Popover.Title>
                  <Popover.Content>{informationMessge}</Popover.Content>
                </Popover>
              </Overlay>
            </div>
            {iiqApprovals.map((item, index) => (
              <div
                className="approval"
                key={'approvalItem_' + index}
                id={'approvalItemDisplay_' + index}
                tabIndex="-1"
                style={
                  index > 0
                    ? {
                        borderTop: 'dashed gray 1px',
                        paddingTop: '10px',
                        paddingBottom: '10px',
                      }
                    : { paddingTop: '10px', paddingBottom: '10px' }
                }
              >
                {/* <div className="approvalType">{item.actionType}</div> */}
                <div className="approvalTitle iiqTitle">
                  {item.title}
                  <div>
                    <div style={{ width: '100%' }}>
                      <button
                        className="approvalButtons"
                        onClick={() => this.review(item)}
                        style={{ textAlign: 'left' }}
                      >
                        Review to Approve or Reject
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        );
      } else {
        console.log('myApprovals.renderIIQ No IIQ Approvals FOund');
        return <React.Fragment></React.Fragment>;
      }
    } else {
      console.log('myApprovals.renderIIQ No APprovals found');
      return <React.Fragment></React.Fragment>;
    }
  };

  renderAriba = displayApprovals => {
    const { displayAribaInfo, aribaTarget } = this.state;
    let informationMessge = `Currently only contract approvals, invoice approvals, and requisition approvals are supported from Ariba.`;
    let aribaApprovals = [];
    let s = 0;
    if (displayApprovals && displayApprovals.length > 0) {
      for (let i = 0; i < displayApprovals.length; i++) {
        if (displayApprovals[i].actionType === 'Ariba') {
          aribaApprovals[s++] = {
            actionType: 'Ariba',
            id: displayApprovals[i].id,
            title: displayApprovals[i].title,
            url: displayApprovals[i].url,
          };
        }
      }
      if (aribaApprovals.length > 0) {
        return (
          <div style={{ width: '100%', borderBottom: '1px solid rgb(188, 189, 188)' }}>
            <div
              style={{
                color: '#1C57A5',
                fontSize: '16px',
                fontWeight: 'bold',
                paddingTop: '15px',
              }}
            >
              Ariba
              <button
                onClick={e => this.showAribaInformation(e)}
                className="infoLink"
                id="approvalsPopoverButton2"
              >
                {displayAribaInfo ? 'X' : '?'}
              </button>
              <Overlay show={displayAribaInfo} target={aribaTarget} placement="bottom">
                <Popover id="approvalsPopOver2">
                  <Popover.Title as="div">Ariba</Popover.Title>
                  <Popover.Content>{informationMessge}</Popover.Content>
                </Popover>
              </Overlay>
            </div>
            {aribaApprovals.map((item, index) => (
              <div
                className="approval"
                key={'approvalItem_' + index}
                id={'approvalItemDisplay_' + index}
                tabIndex="-1"
                style={
                  index > 0
                    ? {
                        borderTop: 'dashed gray 1px',
                        paddingTop: '10px',
                        paddingBottom: '10px',
                      }
                    : { paddingTop: '10px', paddingBottom: '10px' }
                }
              >
                {/* <div className="approvalType">{item.actionType}</div> */}
                <div className="approvalTitle aribaTitle">
                  {item.title}
                  <div>
                    <div style={{ width: '100%' }}>
                      <button
                        className="approvalButtons"
                        onClick={() => this.review(item)}
                        style={{ textAlign: 'left' }}
                      >
                        Review to Approve or Reject
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        );
      } else {
        return <React.Fragment></React.Fragment>;
      }
    } else {
      return <React.Fragment></React.Fragment>;
    }
  };

  renderOT = displayApprovals => {
    const { displayOTInfo, otTarget } = this.state;
    let informationMessge = `Currently time entry approvals are only updated once a week in LeaderConnect. For a more up to date view, go to Workday.`;
    let otApprovals = [];
    let s = 0;
    if (displayApprovals && displayApprovals.length > 0) {
      for (let i = 0; i < displayApprovals.length; i++) {
        if (displayApprovals[i].actionType === 'OT') {
          otApprovals[s++] = {
            actionType: 'OT',
            id: displayApprovals[i].id,
            title: displayApprovals[i].title,
            url: displayApprovals[i].url,
          };
        }
      }
      if (otApprovals.length > 0) {
        return (
          <div style={{ width: '100%', borderBottom: '1px solid rgb(188, 189, 188)' }}>
            <div
              style={{
                color: '#1C57A5',
                fontSize: '16px',
                fontWeight: 'bold',
                paddingTop: '15px',
              }}
            >
              Time Entry
              <button
                onClick={e => this.showOTInformation(e)}
                className="infoLink"
                id="approvalsPopoverButton2"
              >
                {displayOTInfo ? 'X' : '?'}
              </button>
              <Overlay show={displayOTInfo} target={otTarget} placement="bottom">
                <Popover id="approvalsPopOver2">
                  <Popover.Title as="div">Overtime</Popover.Title>
                  <Popover.Content>{informationMessge}</Popover.Content>
                </Popover>
              </Overlay>
            </div>
            {otApprovals.map((item, index) => (
              <div
                className="approval"
                key={'approvalItem_' + index}
                id={'approvalItemDisplay_' + index}
                tabIndex="-1"
                style={
                  index > 0
                    ? {
                        borderTop: 'dashed gray 1px',
                        paddingTop: '10px',
                        paddingBottom: '10px',
                      }
                    : { paddingTop: '10px', paddingBottom: '10px' }
                }
              >
                {/* <div className="approvalType">{item.actionType}</div> */}
                <div className="approvalTitle otTitle">
                  {item.title}
                  <div style={{ fontSize: '14px', color: '#4d4f53', fontWeight: 'normal' }}>
                    1 or more people have time ready for approval for this week.
                  </div>
                  <div>
                    <div style={{ width: '100%' }}>
                      <button
                        className="approvalButtons"
                        onClick={() => this.review(item)}
                        style={{ textAlign: 'left' }}
                      >
                        Review or Take Action
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        );
      } else {
        return <React.Fragment></React.Fragment>;
      }
    } else {
      return <React.Fragment></React.Fragment>;
    }
  };
  // Returns true if all the approval endpoints error
  checkErrors() {
    const {
      snowApprovals,
      aribaApprovals,
      iiqApprovals,
      otApprovals,
      aribaContractApprovals,
    } = this.props;
    // Falsify variables by default
    let snowError = false,
      aribaError = false,
      iiqError = false,
      otError = false,
      aribaContractError = false;
    if (snowApprovals.error != null) {
      snowError = true;
    }
    if (aribaApprovals.error != null) {
      aribaError = true;
    }
    if (iiqApprovals.error != null) {
      iiqError = true;
    }
    if (otApprovals.error != null) {
      otError = true;
    }
    if (aribaContractApprovals.error != null) {
      aribaContractError = true;
    }

    let errorAll =
      snowError && aribaError && iiqError && otError && aribaContractError ? true : false;
    let errorSome =
      snowError || aribaError || iiqError || otError || aribaContractError ? true : false;
    return { all: errorAll, some: errorSome };
  }

  combineData() {
    const {
      snowApprovals,
      aribaApprovals,
      iiqApprovals,
      otApprovals,
      aribaContractApprovals,
    } = this.props;
    let output = [];
    if (snowApprovals.entries)
      for (let i = 0; i < snowApprovals.entries.length; i++) {
        output.push(snowApprovals.entries[i]);
      }
    if (aribaApprovals.entries)
      for (let i = 0; i < aribaApprovals.entries.length; i++) {
        output.push(aribaApprovals.entries[i]);
      }
    if (iiqApprovals.entries)
      for (let i = 0; i < iiqApprovals.entries.length; i++) {
        output.push(iiqApprovals.entries[i]);
      }
    if (otApprovals.entries)
      for (let i = 0; i < otApprovals.entries.length; i++) {
        output.push(otApprovals.entries[i]);
      }
    if (aribaContractApprovals.entries)
      for (let i = 0; i < aribaContractApprovals.entries.length; i++) {
        output.push(aribaContractApprovals.entries[i]);
      }
    return output;
  }

  render() {
    const {
      approvalMax,
      fetchOTApprovalData,
      fetchIIQApprovalData,
      fetchAribaApprovalData,
      fetchSnowApprovalData,
      snowApprovals,
      aribaApprovals,
      iiqApprovals,
      otApprovals,
      aribaContractApprovals,
      fetchAribaContractApprovalData,
    } = this.props;

    //Populate data
    if (snowApprovals === null) {
      fetchSnowApprovalData();
    }

    if (aribaApprovals === null) {
      fetchAribaApprovalData();
    }

    if (iiqApprovals === null) {
      fetchIIQApprovalData();
    }

    if (otApprovals === null) {
      fetchOTApprovalData();
    }
    if (aribaContractApprovals === null) {
      fetchAribaContractApprovalData();
    }

    let allLoaded =
      snowApprovals && aribaApprovals && iiqApprovals && otApprovals && aribaContractApprovals
        ? true
        : false;
    let errorAll = false;
    let someErrors = false;
    if (allLoaded) {
      let errors = this.checkErrors();
      errorAll = errors.all;
      someErrors = errors.some;
    }
    // End populate data

    let informationMessge = `Currently approvals are supported from ServiceNow (catalog requests and change approvals), IIQ (access reviews) and Ariba (contract approvals and requisition approvals). Please note that VPN is required to access IIQ.`;
    const { displayInfo, target } = this.state;
    // console.log("myApprovals.render approvals", approvals);
    if (allLoaded) {
      //The approvals object exists and all approval API's have completed
      if (errorAll) {
        //The approvals object has an error
        return (
          <div className="actionsContainer">
            <div className="actionsContent" style={{ height: '513px' }}>
              <h2 className="tile-header">
                <div style={{ height: '39px' }}>
                  My Approvals &amp; Actions{' '}
                  <div
                    className="noScrollingActions"
                    style={{
                      color: '#bcbdbc',
                    }}
                  ></div>
                  <div className="portletError">
                    <div className="portletErrorAlert">!</div>
                    <div
                      style={{
                        fontFamily: 'nw-primary',
                        fontSize: '20px',
                        lineHeight: '24px',
                        color: '#4D4F53',
                      }}
                    >
                      Oops, this is unexpected.
                    </div>
                    <div
                      style={{
                        fontFamily: 'nw-primary',
                        fontSize: '12px',
                        lineHeight: '15px',
                        color: '#4D4F53',
                      }}
                    >
                      Error code: 500
                      <br />
                      An error has occurred and we're working to fix the issue.
                      <br />
                      If the issue persists please{' '}
                      <a
                        style={{
                          color: '#4D4F53',
                          textDecoration: 'underline',
                        }}
                        href={config.contactUsUrl}
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        contact us.
                      </a>
                    </div>
                    <button
                      className="tile-apierror-refresh"
                      style={{
                        display: 'inline-block',
                        border: 'none',
                        backgroundColor: 'transparent',
                      }}
                      // onMouseDown={() => this.getFow(index)}
                      onClick={this.handleRefresh}
                      title="Refresh"
                    >
                      Refresh
                    </button>
                  </div>
                </div>
              </h2>
            </div>
          </div>
        );
      }
      const approvalEntries = this.combineData();
      console.log('Approvals Entries combination: ', approvalEntries);
      const approvalCount = approvalEntries.length;

      let errorMsg = '';
      if (snowApprovals.error) {
        errorMsg = 'ServiceNow Error: ' + snowApprovals.error;
      }

      if (aribaApprovals.error) {
        if (errorMsg !== '') {
          errorMsg += '\n';
        }
        errorMsg += 'Ariba Invoices Error: ' + aribaApprovals.error;
      }
      if (aribaApprovals.error) {
        if (errorMsg !== '') {
          errorMsg += '\n';
        }
        errorMsg += 'Ariba Requisitions Error: ' + aribaApprovals.error;
      }
      if (aribaContractApprovals.error) {
        if (errorMsg !== '') {
          errorMsg += '\n';
        }
        errorMsg += 'Ariba Contracts Error: ' + aribaContractApprovals.error;
      }

      if (iiqApprovals.error) {
        // console.log("myApprovals THERE IS AN IIQ ERROR");
        if (errorMsg !== '') {
          errorMsg += '\n';
        }
        errorMsg += 'IIQ Error: ' + iiqApprovals.error;
      }
      if (otApprovals.error) {
        // console.log("myApprovals THERE IS AN IIQ ERROR");
        if (errorMsg !== '') {
          errorMsg += '\n';
        }
        errorMsg += 'Overtime Error: ' + otApprovals.error;
      }

      const sliceAt = isMobile ? approvalMax : approvalCount;
      // console.log("MyApprovals.render ", sliceAt, approvalMax, approvalCount);

      const displayApprovals = approvalEntries ? approvalEntries.slice(0, sliceAt) : null;
      // console.log("MyApprovals.render displayApprovals", displayApprovals);
      return (
        <div className={isMobile ? 'mobileActionsContainer' : 'actionsContainer'}>
          <div className="actionsContent">
            <h2 className="tile-header">
              <div style={{ minHeight: '39px' }}>
                <div style={{ display: 'inline-block', width: '90%' }}>
                  My Approvals &amp; Actions ({approvalCount}){' '}
                </div>
                <div
                  style={{
                    display: 'inline-block',
                    width: '10%',
                    minWidth: '20px',
                    verticalAlign: 'top',
                  }}
                >
                  {' '}
                  <button
                    className="approval-refresh btn btn-link"
                    onClick={this.handleRefresh}
                    style={{ padding: '0' }}
                    title="Refresh"
                  >
                    <img
                      style={{
                        height: '14px',
                        width: '14px',
                      }}
                      alt="refresh approvals and actions tile"
                      src="/refresh.svg"
                    />
                  </button>
                </div>
              </div>
              <div
                id="approvalsScrollBox"
                className={isMobile ? 'noScrollingActions' : 'scrollingActions'}
              >
                <Modal
                  show={this.state.displayConfirm}
                  onHide={this.cancelAction}
                  backdrop="static"
                  centered
                >
                  <Modal.Header closeButton>
                    <Modal.Title>{this.state.confirmTitle}</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>{this.state.approvalTitle}</Modal.Body>
                  <Modal.Footer>
                    <button
                      id="confirmApprovalsAction"
                      className="btn btn-primary"
                      onClick={e => this.confirmAction(e)}
                    >
                      Yes
                    </button>
                    <button
                      className="btn btn-primary"
                      onClick={this.cancelAction}
                      style={{ marginLeft: '10px' }}
                    >
                      No
                    </button>
                  </Modal.Footer>
                </Modal>

                {approvalCount === 0 ? (
                  <div
                    style={{
                      fontSize: '16px',
                      color: '#4D4F53',
                      fontWeight: 'bold',
                      textAlign: 'center',
                    }}
                  >
                    {someErrors ? (
                      <div style={{ width: '225px' }} title={errorMsg}>
                        <span
                          style={{
                            display: 'inline-block',
                            marginLeft: '0px',
                            borderRadius: '50%',
                            fontSize: '12px',
                            height: '17px',
                            width: '17px',
                            textAlign: 'center',
                            cursor: 'pointer',
                            zIndex: '1',
                          }}
                          className="portletErrorAlert"
                        >
                          !
                        </span>
                        <span
                          style={{
                            fontFamily: 'nw-primary',
                            fontSize: '12px',
                            lineHeight: '15px',
                            color: '#D32426',
                            display: 'inline-block',
                            paddingLeft: '20px',
                            paddingRight: '10px',
                            zIndex: '1',
                          }}
                        >
                          An error occured.{' '}
                        </span>
                        <button
                          style={{
                            display: 'inline-block',
                            border: 'none',
                            backgroundColor: 'transparent',
                          }}
                          className="tile-apierror-refresh"
                          // onMouseDown={() => this.getFow(index)}
                          onClick={this.handleRefresh}
                          title="Refresh"
                        >
                          Refresh
                        </button>
                      </div>
                    ) : (
                      <React.Fragment></React.Fragment>
                    )}
                    <img
                      src="/Confetti-animation.gif"
                      alt="No new approvals"
                      style={
                        someErrors && !allLoaded
                          ? { width: '208px', display: 'none' }
                          : { width: '245px' }
                      }
                    ></img>
                    <br />
                    No new approvals or actions
                  </div>
                ) : (
                  <div>
                    <div className="approval">
                      {/* <div className="approvalTypeLabel">ACTION</div>
                      <div className="approvalTitleLabel">NEWEST</div> */}
                    </div>
                    {someErrors ? (
                      <div style={{ width: '225px' }} title={errorMsg}>
                        <span
                          style={{
                            display: 'inline-block',
                            marginLeft: '0px',
                            borderRadius: '50%',
                            fontSize: '12px',
                            height: '17px',
                            width: '17px',
                            textAlign: 'center',
                            cursor: 'pointer',
                            zIndex: '1',
                          }}
                          className="portletErrorAlert"
                        >
                          !
                        </span>
                        <span
                          style={{
                            fontFamily: 'nw-primary',
                            fontSize: '12px',
                            lineHeight: '15px',
                            color: '#D32426',
                            display: 'inline-block',
                            paddingLeft: '20px',
                            paddingRight: '10px',
                            zIndex: '1',
                          }}
                        >
                          An error occured.
                        </span>
                        <button
                          style={{
                            display: 'inline-block',
                            border: 'none',
                            backgroundColor: 'transparent',
                          }}
                          className="tile-apierror-refresh"
                          onClick={this.handleRefresh}
                          title="Refresh"
                        >
                          Refresh
                        </button>
                      </div>
                    ) : (
                      <React.Fragment></React.Fragment>
                    )}
                    {this.renderServiceNow(displayApprovals)}
                    {this.renderIIQ(displayApprovals)}
                    {this.renderAriba(displayApprovals)}
                    {this.renderOT(displayApprovals)}
                    {this.displayLoading(allLoaded)}

                    <div
                      style={
                        approvalCount === approvalMax || !isMobile
                          ? { display: 'none' }
                          : {
                              display: 'block',
                              paddingTop: '10px',
                              paddingBottom: '10px',
                            }
                      }
                    >
                      <button
                        className="btn btn-primary "
                        onClick={e => this.handleLoadMore(e, approvalCount)}
                        style={{ marginLeft: '112px' }}
                      >
                        Load More
                      </button>
                    </div>
                  </div>
                )}
              </div>

              <div className="blueLinkContainer">
                <button
                  className="tileButton serviceNowButton"
                  onClick={() => this.goToServiceNow()}
                >
                  ServiceNow
                </button>
                {/* <div className="tile-rectangle serviceNowButton">
                  <img
                    className="tile-externallink"
                    alt="external-link"
                    src="/external-link.svg"
                  />
                  <a
                    className="tile-recttitle"
                    href="https://nwproduction.service-now.com/navpage.do"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    ServiceNow
                  </a>
                </div> */}
                <button className="aribaButton tileButton " onClick={() => this.goToAriba()}>
                  Ariba
                </button>
                <button className="iiqButton tileButton " onClick={() => this.goToIIQ()}>
                  IIQ
                </button>
              </div>
            </h2>
          </div>
        </div>
      );
    } else {
      //approvals object not loaded or not finished loading
      return (
        <div className="actionsContainer">
          <div className="actionsContent" style={{ height: '513px' }}>
            <h2 className="tile-header">
              <div style={{ height: '39px' }}>
                My Approvals &amp; Actions (0)
                <div className="scrollingActions">
                  <div
                    className="progress-spinner "
                    style={{ left: '50%', top: '50%', paddingTop: '15px' }}
                  />
                </div>
              </div>
            </h2>
          </div>
        </div>
      );
    }
  }

  displayLoading(allLoaded) {
    if (!allLoaded) {
      return (
        <div className="noScrollingActions">
          <div className="progress-spinner" style={{ left: '40%', marginTop: '25px' }} />
        </div>
      );
    }
  }
}

export default MyApprovals;
