import React from "react";

const Spinner = ({ fullPage }) => {

  return fullPage && fullPage === "Y" ? (
      <div
      id="pageCover"
      style={{
        position: "fixed",
        top: "0%",
        left: "0%",
        height: "100%",
        width: "100%",
        zIndex: "100",
        backgroundColor: "white",
        opacity: "0.5",
        overflow: "hidden"
      }}
    >
      <Spinner />
    </div>
  ) : (
    <div
    className="progress-spinner position-fixed"
    style={{ left: "50%", top: "50%" }}
    />
    ) 
  }

  export default Spinner;
