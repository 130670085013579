import React from "react";
import _ from "lodash";
import PropTypes from "prop-types";

const Pagination = ({ itemsCount, pageSize, currentPage, onPageChange }) => {
  const pagesCount = Math.ceil(itemsCount / pageSize);
  if (pagesCount === 1) return null;
  const pages = _.range(1, pagesCount + 1);

  return (
    <nav aria-label="Page navigation" className="container">
      <ul className="pagination">
        {pagesCount > 1 ? (
          <li
            className={currentPage === 1 ? "page-item disabled" : "page-item"}
          >
            <button
              className="page-link"
              aria-label="First Page"
              onClick={() => onPageChange(pages[0])}
            >
              First
            </button>
          </li>
        ) : (
          <li></li>
        )}
        {pages.map(page => (
          <li
            className={page === currentPage ? "page-item active" : "page-item"}
            key={page}
          >
            <button
              className="page-link"
              onClick={() => onPageChange(page)}
              aria-label="Go To Page {page}"
            >
              {page}
            </button>
          </li>
        ))}
        {pagesCount > 1 ? (
          <li
            className={
              currentPage === pagesCount ? "page-item disabled" : "page-item"
            }
          >
            <button
              className="page-link"
              aria-label="Last Page"
              onClick={() => onPageChange(pages[pagesCount - 1])}
            >
              Last
            </button>
          </li>
        ) : (
          <li></li>
        )}
      </ul>
    </nav>
  );
};

Pagination.propTypes = {
  itemsCount: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,
  currentPage: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired
};

export default Pagination;
