import config from '../config.json';
import testers from '../testers.json';
import { getRandomString } from './AuthService';
import { getEnvironment } from './EnvironmentVariable';
import { isMobile } from 'react-device-detect';

export const getOTApprovals = async () => {
  const environ = getEnvironment();
  let otURL = 'https://www.myworkday.com/nationwide/d/task/2997$11361.htmld';
  let otAPI = config.otAPI;
  let otKey = config.otKey;
  if (environ !== 'DEFAULT') {
    //We are not in production environment
    otURL = 'https://impl.workday.com/nationwide2/d/task/2997$11361.htmld';
    if (isMobile) {
      //However we are testing on a mobile device so use the Mobile api
      otAPI = config.Mobile_otAPI;
      otKey = config.test_otKey;
    } else {
      //We are not testing on a mobile device, so we will use the test API
      otAPI = config.test_otAPI;
      otKey = config.test_otKey;
    }
  }
  // console.log("iiqService.getIIQApprovals iiqAPI", iiqAPI);
  const userInfo = JSON.parse(sessionStorage.getItem('userinfo'));
  if (!userInfo || !userInfo.shortName) {
    return { error: 'No user information found.' };
  }
  let shortName = userInfo.shortName
    ? userInfo.shortName.toLowerCase()
    : userInfo.username.toLowerCase();

  const testUsers = testers.users;
  for (let x = 0; x < testUsers.length; x++) {
    const tester = testUsers[x];
    if (tester.shortName.toLowerCase() === shortName.toLowerCase()) {
      //User shortname matches to a tester
      if (environ === 'DEFAULT') {
        //This is the production environment
        if (tester.prodAllowed) {
          //This tester is allowed to mimic in production
          shortName = tester.mimicManager.toLowerCase();
        }
      } else {
        //This is not a production environment
        shortName = tester.mimicManager.toLowerCase();
      }
      break;
    }
  }

  const message = getRandomString(25);
  const token = sessionStorage.getItem('access_token');
  let headers = new Headers();
  headers.append('Content-Type', 'application/json');
  headers.append('Authorization', 'Bearer ' + token);
  headers.append('X-NW-Message-ID', message);
  headers.set('client_id', otKey);

  const body = {
    shortName: shortName,
  };
  let data = null;
  try {
    // console.log("iiqService.getIIQApprovals url", url);
    const response = await fetch(otAPI, {
      method: 'post',
      headers: headers,
      body: JSON.stringify(body),
    });
    if (response.ok) {
      //Successful response from the API
      try {
        data = await response.json();
        // console.log("iiqService.getIIQApprovals data", data);
      } catch (e) {
        //Response status was OK, but we got an error getting the json data
        console.error('overtimeService.getOTApprovals error', e);
        return { error: 'error with the Overtime json' };
      }
    } else {
      //API did not respond with OK
      console.error('overtimeService.getOTApprovals  error calling API', response.statusText);
      return {
        error: 'error received  when trying to retrieve Overtime',
      };
    }
  } catch (E) {
    //There was an error calling the API
    console.error('overtimeService.getOTApprovals  error', E);
    return { error: 'error received  when trying to fetch Overtime approvals' };
  }

  if (data && data.userMessage) {
    //There is an error message in the data that was returned
    console.error('overtimeService.getOTApprovals error', data.userMessage);
    return { error: 'Overtime approvals error:  ' + data.userMessage };
  }
  let approvals = [];

  if (data && data.timeWeek) {
    const timeWeek = data.timeWeek;
    if (timeWeek.length > 0) {
      //data was retrieved and has approval entries
      for (let x = 0; x < timeWeek.length; x++) {
        console.log('overtimeService.getOTApprovals x ', x, timeWeek[x]);
        //loop through the approvals
        const entry = timeWeek[x];
        const iLen = String(entry).length;

        const lastDate = String(entry).substring(iLen, iLen - 10);
        const dtLastDate = new Date(lastDate);
        const month = dtLastDate.getMonth();
        const day = dtLastDate.getDate();
        const year = dtLastDate.getFullYear();

        const monthNames = [
          'January',
          'February',
          'March',
          'April',
          'May',
          'June',
          'July',
          'August',
          'September',
          'October',
          'November',
          'December',
        ];
        const message = 'Week of ' + monthNames[month] + ' ' + day + ', ' + year;
        //Sample data  "03/29/2021 - 04/04/2021",
        if (entry !== 'No Certs Found') {
          //Instead of an empty array, the API responds with "No Certs Found".   Only add
          //entries that are not equal to that text
          approvals[x] = {
            id: 'ot_' + x,
            actionType: 'OT',
            title: message,
            url: otURL,
          };
        }
      }
    }
  }

  const error = null;
  return {
    entries: approvals,
    error,
  };
};
