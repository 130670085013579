import { isMobile } from 'react-device-detect';
import config from '../config.json';
import testers from '../testers.json';
import { getRandomString } from './AuthService';
import { getEnvironment } from './EnvironmentVariable';

export const getArribaProcurementAuth = async () => {
  const environ = getEnvironment();

  let aribaAuthAPI = config.aribaProcureAuthApi;
  let aribaKey = config.aribaKey;

  if (environ !== 'DEFAULT') {
    //We are not in production environment
    if (isMobile) {
      //However we are testing on a mobile device so use the Mobile api
      aribaAuthAPI = config.Mobile_aribaProcureAuthApi;
      aribaKey = config.test_aribaKey;
    } else {
      //We are not testing on a mobile device, so we will use the test API
      aribaAuthAPI = config.test_aribaProcureAuthApi;
      aribaKey = config.test_aribaKey;
    }
  }
  const userInfo = JSON.parse(sessionStorage.getItem('userinfo'));
  if (!userInfo || !userInfo.shortName) {
    return { error: 'No user information found.' };
  }
  let shortName = userInfo.shortName
    ? userInfo.shortName.toUpperCase()
    : userInfo.username.toUpperCase();

  const testUsers = testers.users;
  for (let x = 0; x < testUsers.length; x++) {
    const tester = testUsers[x];
    if (tester.shortName.toUpperCase() === shortName.toUpperCase()) {
      //User shortname matches to a tester
      if (environ === 'DEFAULT') {
        //This is the production environment
        if (tester.prodAllowed) {
          //This tester is allowed to mimic in production
          shortName = tester.mimicManager.toUpperCase();
        }
      } else {
        //This is not a production environment
        shortName = tester.mimicManager.toUpperCase();
      }
      break;
    }
  }
  let aribaProcreAccessToken = '';
  const message = getRandomString(25);
  const token = sessionStorage.getItem('access_token');

  // sessionStorage.removeItem("aribaProcreAccessToken");

  let headers = new Headers();
  headers.append('Content-Type', 'application/x-www-form-urlencoded');
  headers.append('Authorization', 'Bearer ' + token);
  headers.append('X-NW-Message-ID', message);
  headers.set('client_id', aribaKey);
  const body = encodeKeyValuePair('grant_type', 'openapi_2lo');
  // console.log(
  //   "aribaProcurementService.getArribaProcurementAuth aribaAuthAPI",
  //   aribaAuthAPI
  // );
  let data = null;
  try {
    const response = await fetch(aribaAuthAPI, {
      method: 'post',
      headers: headers,
      body: body,
    });
    if (response.ok) {
      try {
        data = await response.json();
        // console.log(
        //   "aribaProcurementService.getArribaProcurementAuth data",
        //   data
        // );
      } catch (e) {
        //Response status was OK, but we got an error getting the json data
        console.error('aribaProcurementService.getArribaProcurementAuth error', e);
        return 'error with the Ariba Procurement authorization json';
      }
    } else {
      console.error(
        'aribaProcurementService.getArribaProcurementAuth  error calling API',
        response.statusText,
      );
      return 'error recieved when trying to retrieve Ariba Procurement Authorization';
    }
  } catch (E) {
    //There was an error calling the API
    console.error('aribaProcurementService.getAribaProcurementApprovals  error', E);
    return 'error recieved when trying to fetch Ariba Procurement Authorization';
  }

  if (data && data.userMessage) {
    //There is an error message in the data that was returned
    console.error('aribaProcurementService.getAribaProcurementApprovals  error', data.userMessage);
    return 'Ariba Procurement authorization error:  ' + data.userMessage;
  }
  //console.log('Ariba Auth response', data);
  if (!data.access_token) {
    console.error('aribaProcurementService.getArribaProcurementAuth  error no data.access_token');
    return 'Ariba Procurement Authorization error no data.access_token';
  }
  aribaProcreAccessToken = data.access_token;
  sessionStorage.setItem('aribaProcreAccessToken', aribaProcreAccessToken);
  return aribaProcreAccessToken;
};

export const getAribaProcurementApprovals = async documentType => {
  const environ = getEnvironment();
  const message = getRandomString(25);

  let aribaApprovalAPI = config.arribaProcurement;
  let aribaKey = config.aribaKey;

  if (environ !== 'DEFAULT') {
    //We are not in production environment
    if (isMobile) {
      //However we are testing on a mobile device so use the Mobile api
      aribaApprovalAPI = config.Mobile_arribaProcurement;
      aribaKey = config.test_aribaKey;
    } else {
      //We are not testing on a mobile device, so we will use the test API
      aribaApprovalAPI = config.test_arribaProcurement;
      aribaKey = config.test_aribaKey;
    }
  }
  const userInfo = JSON.parse(sessionStorage.getItem('userinfo'));
  if (!userInfo || !userInfo.shortName) {
    return { error: 'No user information found.' };
  }
  let shortName = userInfo.shortName
    ? userInfo.shortName.toUpperCase()
    : userInfo.username.toUpperCase();

  const testUsers = testers.users;
  for (let x = 0; x < testUsers.length; x++) {
    const tester = testUsers[x];
    if (tester.shortName.toUpperCase() === shortName.toUpperCase()) {
      //User shortname matches to a tester
      if (environ === 'DEFAULT') {
        //This is the production environment
        if (tester.prodAllowed) {
          //This tester is allowed to mimic in production
          shortName = tester.mimicManager.toUpperCase();
        }
      } else {
        //This is not a production environment
        shortName = tester.mimicManager.toUpperCase();
      }
      break;
    }
  }

  //DEBUG START
  // shortName = "SANGEV1";
  //DEBUG END

  const aribaProcreAccessToken = await getArribaProcurementAuth();
  let approvalHeaders = new Headers();
  approvalHeaders.append('Content-Type', 'application/x-www-form-urlencoded');
  approvalHeaders.append('Authorization', 'Bearer ' + aribaProcreAccessToken);
  approvalHeaders.append('X-NW-Message-ID', message);
  approvalHeaders.set('client_id', aribaKey);

  let approvalData = null;

  let params = '';
  if (environ !== 'DEFAULT') {
    params =
      '?realm=nationwide-child-T&documentType=' +
      documentType +
      '&passwordAdapter=PasswordAdapter1&user=' +
      encodeURIComponent(shortName.toLowerCase() + '@nationwide.com');
    // params =
    //   "?realm=nationwide-child-T&documentType=" +
    //   documentType +
    //   "&passwordAdapter=PasswordAdapter1&user=" +
    //   encodeURIComponent("sangev1@nationwide.com");
  } else {
    params =
      '?realm=nationwide-child&documentType=' +
      documentType +
      '&passwordAdapter=PasswordAdapter1&user=' +
      encodeURIComponent(shortName.toLowerCase() + '@nationwide.com');
  }

  try {
    const response = await fetch(aribaApprovalAPI + params, {
      method: 'get',
      headers: approvalHeaders,
    });
    if (response.ok) {
      try {
        approvalData = await response.json();
      } catch (e) {
        //Response status was OK, but we got an error getting the json data
        console.error('aribaProcurementService.getAribaApprovals  error', e);
        return {
          error: 'There was a problem with the Ariba ' + documentType + ' JSON data.',
        };
      }
    } else {
      return {
        error: 'There was a problem retrieving the ARIBA ' + documentType + ' data.',
      };
    }
  } catch (E) {
    //There was an error calling the API
    console.error('aribaProcurementService.getAribaProcurementApprovals  error', E);
    return {
      error: 'There was a problem retrieving the ARIBA ' + documentType + ' data.',
    };
  }

  if (approvalData && approvalData.userMessage) {
    //There is an error message in the data that was returned
    return { error: approvalData.userMessage };
  }
  // console.log("aribaProcurementService.getAribaProcurementApprovals  approvalData", approvalData);
  let approvals = [];
  // const actionType =
  //   documentType.toLowerCase() === "invoices"
  //     ? "Invoice Approval"
  //     : "Requisition Approval";
  if (approvalData && approvalData.length > 0) {
    //data was retrieved and has approval entries
    for (let x = 0; x < approvalData.length; x++) {
      //loop through the approvals
      const entry = approvalData[x];
      // console.log("aribaProcurementService.getAribaProcurementApprovals  entry", x, entry);
      let url = entry.fullURL;
      if (url.indexOf('https://') < 0) {
        // console.log("serviceNow.getApprovals 1 url", url);
        url = url.substring(7);
        // console.log("serviceNow.getApprovals 2 url", url);
        url = 'https://' + url;
        // console.log("serviceNow.getApprovals 3 url", url);
      }
      approvals[x] = {
        id: entry.uniqueName,
        actionType: 'Ariba',
        title: entry.description,
        url: url,
      };
    }
  }
  // console.log("aribaProcurementService.getAribaProcurementApprovals  approvals", approvals);

  // //DEBUG START
  // approvals[0] = {
  //   id: 'ariba0',
  //   actionType: 'Ariba',
  //   title: 'Look a fake Ariba Procurement',
  //   url: 'https:www.google.com',
  // };
  // //DEBUG END
  const error = null;
  return {
    entries: approvals,
    error,
  };
};

function encodeKeyValuePair(key, value) {
  return `${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
}
