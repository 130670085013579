import config from '../config.json';
import { getRandomString } from '../services/AuthService';
import { getEnvironment } from './EnvironmentVariable';
import { isMobile } from 'react-device-detect';

export const getAnnouncements = async () => {
  const environ = getEnvironment();
  //THIS IS ONLY FOR PRE-PROD -- START
  let portalApi = config.portalApi;
  // let portalApi = config.test_portalApi;

  let portalKey = config.client_id;
  if (environ !== 'DEFAULT') {
    // console.log("portalsService.getPortals environ", environ);
    if (isMobile) {
      portalApi = config.Mobile_test_portalApi;
      portalKey = config.test_client_id;
    } else {
      portalApi = config.test_portalApi;
      portalKey = config.test_client_id;
    }
  }
  let headers = new Headers();
  headers.append('Content-Type', 'application/json');
  headers.append('Access-Control-Allow-Origin', '*');
  const token = sessionStorage.getItem('access_token');
  headers.append('Authorization', 'Bearer ' + token);
  headers.append('client_id', portalKey);
  const message = getRandomString(25);
  headers.append('X-NW-Message-ID', message);

  try {
    const response = await fetch(portalApi + 'announcement/current?lcflag=Y', {
      method: 'get',
      headers: headers,
    });

    const jsondata = await response.json();
    // console.log("announcementService.getAnnouncements jsondata", jsondata);
    return jsondata;
  } catch (e) {
    console.error('announcementService.getAnnouncements Exception', e);
    return null;
  }
};

function encodeKeyValuePair(key, value) {
  return `${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
}

function searchBody(params) {
  let out = '';
  for (let [key, value] of params) {
    if (out !== '') {
      out += '&';
    }
    out += encodeKeyValuePair(key, value);
  }
  if (out !== '') {
    out += '&';
  }
  out += encodeKeyValuePair('lcflag', 'Y');
  return out;
}

export const search = async params => {
  const environ = getEnvironment();
  //THIS IS ONLY FOR PRE-PROD -- START
  let portalApi = config.portalApi;
  // let portalApi = config.test_portalApi;

  let portalKey = config.client_id;
  if (environ !== 'DEFAULT') {
    // console.log("portalsService.getPortals environ", environ);
    if (isMobile) {
      portalApi = config.Mobile_test_portalApi;
    } else {
      portalApi = config.test_portalApi;
      portalKey = config.test_client_id;
    }
  }
  let headers = new Headers();
  const body = searchBody(params);
  headers.append('Content-Type', 'application/x-www-form-urlencoded');
  headers.append('Access-Control-Allow-Origin', '*');
  const token = sessionStorage.getItem('access_token');
  headers.append('Authorization', 'Bearer ' + token);
  headers.append('client_id', portalKey);
  const message = getRandomString(25);
  headers.append('X-NW-Message-ID', message);
  try {
    const response = await fetch(portalApi + 'announcement/search', {
      method: 'post',
      headers: headers,
      body: body,
    });
    const jsondata = await response.json();
    const result = {
      totalCount: response.headers.get('x-total-count'),
      data: jsondata.data,
      messages: jsondata.messages,
    };
    return result;
  } catch (e) {
    const result = {
      totalCount: 0,
      data: null,
      messages: [
        {
          developerMessage: 'Error while calling announcementService.search: ' + e,
          severity: 'error',
          userMessage: 'There was an error',
          messageId: '',
        },
      ],
    };
    return result;
  }
};

export const getAnnouncement = async id => {
  const environ = getEnvironment();
  //THIS IS ONLY FOR PRE-PROD -- START
  let portalApi = config.portalApi;
  // let portalApi = config.test_portalApi;

  let portalKey = config.client_id;
  if (environ !== 'DEFAULT') {
    // console.log("portalsService.getPortals environ", environ);
    if (isMobile) {
      portalApi = config.Mobile_test_portalApi;
    } else {
      portalApi = config.test_portalApi;
      portalKey = config.test_client_id;
    }
  }
  let headers = new Headers();
  headers.append('Content-Type', 'application/x-www-form-urlencoded');
  headers.append('Access-Control-Allow-Origin', '*');
  const token = sessionStorage.getItem('access_token');
  headers.append('Authorization', 'Bearer ' + token);
  headers.append('client_id', portalKey);
  const message = getRandomString(25);
  headers.append('X-NW-Message-ID', message);
  const response = await fetch(portalApi + 'announcement/' + id, {
    method: 'get',
    headers: headers,
  });
  const jsondata = await response.json();
  return jsondata;
};

export const addAnnouncement = async (
  file,
  startDate,
  endDate,
  userid,
  link,
  priority,
  button1Link,
  button1Text,
  button2Link,
  button2Text,
  headline,
  summaryText,
) => {
  const environ = getEnvironment();
  //THIS IS ONLY FOR PRE-PROD -- START
  let portalApi = config.portalApi;
  // let portalApi = config.test_portalApi;

  let portalKey = config.client_id;
  if (environ !== 'DEFAULT') {
    // console.log("portalsService.getPortals environ", environ);
    if (isMobile) {
      portalApi = config.Mobile_test_portalApi;
    } else {
      portalApi = config.test_portalApi;
      portalKey = config.test_client_id;
    }
  }
  const userInfo = JSON.parse(sessionStorage.getItem('userinfo'));

  let formData = new FormData();
  formData.append('file', file);
  formData.append('startDate', startDate);
  formData.append('endDate', endDate);
  formData.append('userid', userInfo.shortName);
  formData.append('link', link);
  formData.append('priority', priority);
  formData.append('button1Link', button1Link);
  formData.append('button1Text', button1Text);
  formData.append('button2Link', button2Link);
  formData.append('button2Text', button2Text);
  formData.append('headline', headline);
  formData.append('summaryText', summaryText);
  formData.append('lcflag', 'Y');
  let headers = new Headers();
  headers.append('Access-Control-Allow-Origin', '*');
  const token = sessionStorage.getItem('access_token');
  headers.append('Authorization', 'Bearer ' + token);
  headers.append('client_id', portalKey);
  const message = getRandomString(25);
  headers.append('X-NW-Message-ID', message);
  // headers.append("Content-Type", "multipart/form-data");
  const url = portalApi + 'announcement/';

  // const url = "http://localhost:8080/hr_portal_api/announcement/";
  try {
    const response = await fetch(url, {
      method: 'post',
      headers: headers,
      body: formData,
    });
    // console.log("announcementService.addAnnouncement response", response);

    const jsondata = await response.json();
    return jsondata;
  } catch (e) {
    console.error('announcementService.addAnnouncement Error', e);
    return {
      messages: [
        {
          developerMessage: 'Error while calling announcementService.add: ' + e,
          severity: 'error',
          userMessage: 'There was an error',
          messageId: '',
        },
      ],
    };
  }
};

export const deleteAnnouncement = async id => {
  const environ = getEnvironment();
  //THIS IS ONLY FOR PRE-PROD -- START
  let portalApi = config.portalApi;
  // let portalApi = config.test_portalApi;

  let portalKey = config.client_id;
  if (environ !== 'DEFAULT') {
    // console.log("portalsService.getPortals environ", environ);
    if (isMobile) {
      portalApi = config.Mobile_test_portalApi;
    } else {
      portalApi = config.test_portalApi;
      portalKey = config.test_client_id;
    }
  }
  let headers = new Headers();
  headers.append('Content-Type', 'multipart/form-data');
  headers.append('Access-Control-Allow-Origin', '*');
  const token = sessionStorage.getItem('access_token');
  headers.append('Authorization', 'Bearer ' + token);
  headers.append('client_id', portalKey);
  const message = getRandomString(25);
  headers.append('X-NW-Message-ID', message);
  const response = await fetch(portalApi + 'announcement/' + id, {
    method: 'delete',
    headers: headers,
  });
  return response;
};

export const updateAnnouncement = async (startDate, endDate, announcement) => {
  const environ = getEnvironment();
  //THIS IS ONLY FOR PRE-PROD -- START
  let portalApi = config.portalApi;
  // let portalApi = config.test_portalApi;

  let portalKey = config.client_id;
  if (environ !== 'DEFAULT') {
    // console.log("portalsService.getPortals environ", environ);
    if (isMobile) {
      portalApi = config.Mobile_test_portalApi;
    } else {
      portalApi = config.test_portalApi;
      portalKey = config.test_client_id;
    }
  }
  let headers = new Headers();
  headers.append('Content-Type', 'application/json');
  headers.append('Access-Control-Allow-Origin', '*');
  const token = sessionStorage.getItem('access_token');
  headers.append('Authorization', 'Bearer ' + token);
  headers.append('client_id', portalKey);
  const message = getRandomString(25);

  // console.log(
  //   "announcementService.updateAnnouncement announcement",
  //   announcement
  // );

  const updatedAnnouncement = {
    id: announcement.id,
    startOnDate: startDate,
    endOnDate: endDate,
    approvedBy: announcement.approvedBy,
    link: announcement.link,
    priority: announcement.priority,
    button1Link: announcement.button1Link,
    button1Text: announcement.button1Text,
    button2Link: announcement.button2Link,
    button2Text: announcement.button2Text,
    title: announcement.title,
    approvedDate: announcement.approvedDate,
    deletedBy: announcement.deletedBy,
    deletedDate: announcement.deletedDate,
    contentType: announcement.contentType,
    headline: announcement.headline,
    summaryText: announcement.summaryText,
  };
  //,leaderConnectMessage: "Y"
  // console.log("updatedAnnouncement", updatedAnnouncement);
  headers.append('X-NW-Message-ID', message);
  try {
    const response = await fetch(portalApi + 'announcement/', {
      method: 'PUT',
      headers: headers,
      body: JSON.stringify(updatedAnnouncement),
    });
    // console.log("announcementService.updateAnnouncement response", response);
    const jsondata = await response.json();
    // console.log("announcementService.updateAnnouncement jsondata", jsondata);
    return jsondata;
  } catch (e) {
    console.error('announcementService.updateAnnouncement Error', e);
    return {
      messages: [
        {
          developerMessage: 'Error while calling announcementService.update: ' + e,
          severity: 'error',
          userMessage: 'There was an error',
          messageId: '',
        },
      ],
    };
  }
};
