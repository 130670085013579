import React, { Component } from "react";
import {
    addAnnouncement,
    deleteAnnouncement,
    updateAnnouncement
} from "../../services/announcementService";
// import config from "../../config.json";
import dateFormat from "dateformat";
import DatePicker from "react-datepicker";
import { isMobile } from "react-device-detect";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Spinner from "../../common/spinner";

class AnnouncementEdit extends Component {
  state = {
    file: null,
    hasChange: "N",
    saving: false,
    deleting: false,
    fileValid: true,
    error: false,
    errorMessage: "",
    changed: false,
    startDateError: false,
    endDateError: false,
    altTextError: false,
    link1Error: false,
    link1ErrorFormat: false,
    link2Error: false,
    link2ErrorFormat: false,
    startDateAfterEndDateError: false,
    startDatePastError: false,
    msg: ""
  };

  goToList() {
    const { hasChange } = this.state;
    if (hasChange === "Y") {
      this.props.showAnnounceList(0, "Y");
    } else {
      this.props.showAnnounceList(0, "N");
    }
    this.setState({
      startDateError: false,
      endDateError: false,
      altTextError: false,
      link1Error: false,
      link2Error: false,
      link1ErrorFormat: false,
      link2ErrorFormat: false,
      startDateAfterEndDateError: false
    });
    // this.setState({ hasChange: "N" });
  }

  handleDelete = async e => {
    if (isMobile) {
      toast.info("DELETES will not be processed while under Mobile Testing.");
      return false;
    }
    this.showHideDelete(false);
    const { user, workingAnnouncement } = this.props;
    const result = await deleteAnnouncement(
      workingAnnouncement.id,
      user.userId
    );

    if (result.status !== 204) {
      const message = await result.json();
      const displayMessage =
        "There was an error while deleting this announcement.  (" +
        message.userMessage +
        " " +
        message.messageId +
        ")";
      toast.error(displayMessage);
    } else {
      this.props.showAnnounceList(0, "Y");
    }
  };

  convertDateToTimeStamp = aDate => {
    let m = "" + (aDate.getMonth() + 1);
    if (aDate.getMonth() + 1 < 10) {
      m = "0" + m;
    }
    let d = "" + aDate.getDate();
    if (aDate.getDate() < 10) {
      d = "0" + d;
    }
    let y = aDate.getFullYear();
    return y + "-" + m + "-" + d + "T00:00:00";
  };

  scrollToError = id => {
    var elmnt = document.getElementById(id);
    elmnt.scrollIntoView();
  };

  handleSubmit = async e => {
    e.preventDefault();
    this.setState({ saving: true, fileValid: true });
    // console.log("announcementEdit.handleSubmit Saving announcement");
    const { file } = this.state;
    const { user, workingAnnouncement } = this.props;
    let startDateError = false;
    let endDateError = false;
    let altTextError = false;
    let headlineError = false;
    let summaryTextError = false;
    let link1Error = false;
    let link2Error = false;
    let link1ErrorFormat = false;
    let link2ErrorFormat = false;
    let startDateAfterEndDateError = false;
    let startDatePastError = false;
    let error = false;
    let errorMessage = "";
    // this.sendData(file, announce.startDate, announce.endDate, announce.userId);
    this.setState({
      startDateError,
      endDateError,
      altTextError,
      headlineError,
      summaryTextError,
      link1Error,
      link2Error,
      link1ErrorFormat,
      link2ErrorFormat,
      startDateAfterEndDateError,
      startDatePastError
    });

    if (workingAnnouncement.id === 0) {
      if (!file) {
        this.setState({ saving: false, fileValid: false });
        toast.error("Unable to save this announcement.");
        this.scrollToError("file");
        return false;
      }
      let headline = workingAnnouncement.headline.trim();
      if (headline === "") {
        headlineError = true;
        this.setState({ headlineError: true, saving: false });
        toast.error("Unable to save this announcement.");
        this.scrollToError("headline");
        return false;
      }

      let summaryText = workingAnnouncement.summaryText.trim();
      if (summaryText === "") {
        summaryTextError = true;
        this.setState({ summaryTextError: true, saving: false });
        toast.error("Unable to save this announcement.");
        this.scrollToError("summaryText");
        return false;
      }
      let startDate = "";
      try {
        startDate = new Date(
          workingAnnouncement.startOnDate
        ).toLocaleDateString();
      } catch (e1) {
        startDateError = true;
        this.setState({ startDateError, saving: false });
        toast.error("Unable to save this announcement.");
        this.scrollToError("startDate");
        return false;
      }
      let endDate = "";
      try {
        endDate = new Date(workingAnnouncement.endOnDate).toLocaleDateString();
      } catch (e1) {
        endDateError = true;
        this.setState({ endDateError, saving: false });
        toast.error("Unable to save this announcement.");
        this.scrollToError("endDate");
        return false;
      }
      const stDt = new Date(workingAnnouncement.startOnDate);
      const now = new Date();
      const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
      if (stDt < today) {
        startDatePastError = true;
        this.setState({ startDatePastError, saving: false });
        toast.error("Unable to save this announcement.");
        this.scrollToError("startDate");
        return false;
      }
      const edDt = new Date(workingAnnouncement.endOnDate);
      if (stDt >= edDt) {
        startDateAfterEndDateError = true;
        this.setState({ startDateAfterEndDateError, saving: false });
        toast.error("Unable to save this announcement.");
        this.scrollToError("endDate");
        return false;
      }

      let altText = workingAnnouncement.link.trim();
      if (altText === "") {
        altTextError = true;
        this.setState({ altTextError: true, saving: false });
        toast.error("Unable to save this announcement.");
        this.scrollToError("link");
        return false;
      }

      let button1Link = workingAnnouncement.button1Link.trim();
      let button1Text = workingAnnouncement.button1Text.trim();
      // console.log("button1Link", button1Link, "button1Text", button1Text);
      if (
        button1Link &&
        button1Link !== "" &&
        !button1Link.startsWith("http://") &&
        !button1Link.startsWith("https://")
      ) {
        link1ErrorFormat = true;
        this.setState({ link1ErrorFormat: true, saving: false });
        toast.error("Unable to save this announcement.");
        this.scrollToError("button1Link");
        return false;
      }
      if (button1Link !== "" && button1Text === "") {
        link1Error = true;
        this.setState({ link1Error: true, saving: false });
        toast.error("Unable to save this announcement.");
        this.scrollToError("button1Link");
        return false;
      }
      if (button1Text !== "" && button1Link === "") {
        link1Error = true;
        this.setState({ link1Error: true, saving: false });
        toast.error("Unable to save this announcement.");
        this.scrollToError("button1Text");
        return false;
      }

      let button2Link = workingAnnouncement.button2Link.trim();
      let button2Text = workingAnnouncement.button2Text.trim();
      // console.log("button2Link", button2Link, "button2Text", button2Text);
      if (
        button2Link &&
        button2Link !== "" &&
        !button2Link.startsWith("http://") &&
        !button2Link.startsWith("https://")
      ) {
        link2ErrorFormat = true;
        this.setState({ link2ErrorFormat: true, saving: false });
        toast.error("Unable to save this announcement.");
        this.scrollToError("button2Link");
        return false;
      }
      if (button2Link !== "" && button2Text === "") {
        link2Error = true;
        this.setState({ link2Error: true, saving: false });
        toast.error("Unable to save this announcement.");
        this.scrollToError("button2Link");
        return false;
      }
      if (button2Text !== "" && button2Link === "") {
        link2Error = true;
        this.setState({ link2Error: true, saving: false });
        toast.error("Unable to save this announcement.");
        this.scrollToError("button2Text");
        return false;
      }
      if (isMobile) {
        toast.info("SAVES will not be processed while under Mobile Testing.");
        return false;
      }
      const result = await addAnnouncement(
        file,
        startDate,
        endDate,
        user.userId,
        workingAnnouncement.link,
        workingAnnouncement.priority,
        workingAnnouncement.button1Link,
        workingAnnouncement.button1Text,
        workingAnnouncement.button2Link,
        workingAnnouncement.button2Text,
        workingAnnouncement.headline,
        workingAnnouncement.summaryText
      );
      const messages = result.messages;
      const data = result.data;

      if (messages && messages.length > 0) {
        for (let i = 0; i < messages.length; i++) {
          const currentMessage = messages[i];
          const displayMessage =
            "There was an error while saving this announcement.  (" +
            currentMessage.userMessage +
            " " +
            currentMessage.messageId +
            ")";
          toast.error(displayMessage);
          errorMessage += displayMessage + "<hr/>";
        }
        error = true;
      } else {
        this.props.onAnnounceIdUpdate(data.id);
      }
    } else {
      let headline = workingAnnouncement.headline.trim();
      if (headline === "") {
        headlineError = true;
        this.setState({ headlineError: true, saving: false });
        toast.error("Unable to save this announcement.");
        this.scrollToError("headline");
        return false;
      }

      let summaryText = workingAnnouncement.summaryText.trim();
      if (summaryText === "") {
        summaryTextError = true;
        this.setState({ summaryTextError: true, saving: false });
        toast.error("Unable to save this announcement.");
        this.scrollToError("summaryText");
        return false;
      }
      let startDate = "";
      // const today = new Date();
      try {
        const sDate = new Date(workingAnnouncement.startOnDate);
        startDate = this.convertDateToTimeStamp(sDate);
      } catch (e1) {
        startDateError = true;
        this.setState({ startDateError, saving: false });
        toast.error("Unable to save this announcement.");
        this.scrollToError("startDate");
        return false;
      }
      let endDate = "";
      try {
        const eDate = new Date(workingAnnouncement.endOnDate);
        endDate = this.convertDateToTimeStamp(eDate);
      } catch (e1) {
        endDateError = true;
        this.setState({ endDateError, saving: false });
        toast.error("Unable to save this announcement.");
        this.scrollToError("endDate");
        return false;
      }

      const stDt = new Date(workingAnnouncement.startOnDate);
      const edDt = new Date(workingAnnouncement.endOnDate);

      if (stDt >= edDt) {
        startDateAfterEndDateError = true;
        this.setState({ startDateAfterEndDateError, saving: false });
        toast.error("Unable to save this announcement.");
        this.scrollToError("startDate");
        return false;
      }

      let altText = workingAnnouncement.link.trim();
      if (altText === "") {
        altTextError = true;
        this.setState({ altTextError: true, saving: false });
        toast.error("Unable to save this announcement.");
        this.scrollToError("link");
        return false;
      }

      let button1Link = workingAnnouncement.button1Link.trim();
      let button1Text = workingAnnouncement.button1Text.trim();
      // console.log("button1Link", button1Link, "button1Text", button1Text);
      if (
        button1Link &&
        button1Link !== "" &&
        !button1Link.startsWith("http://") &&
        !button1Link.startsWith("https://")
      ) {
        link1ErrorFormat = true;
        this.setState({ link1ErrorFormat: true, saving: false });
        toast.error("Unable to save this announcement.");
        this.scrollToError("button1Link");
        return false;
      }
      if (button1Link !== "" && button1Text === "") {
        link1Error = true;
        this.setState({ link1Error: true, saving: false });
        toast.error("Unable to save this announcement.");
        this.scrollToError("button1Link");
        return false;
      }
      if (button1Text !== "" && button1Link === "") {
        link1Error = true;
        this.setState({ link1Error: true, saving: false });
        toast.error("Unable to save this announcement.");
        this.scrollToError("button1Text");
        return false;
      }

      let button2Link = workingAnnouncement.button2Link.trim();
      let button2Text = workingAnnouncement.button2Text.trim();
      // console.log("button2Link", button2Link, "button2Text", button2Text);
      if (
        button2Link &&
        button2Link !== "" &&
        !button2Link.startsWith("http://") &&
        !button2Link.startsWith("https://")
      ) {
        link2ErrorFormat = true;
        this.setState({ link2ErrorFormat: true, saving: false });
        this.scrollToError("button2Link");
        return false;
      }
      if (button2Link !== "" && button2Text === "") {
        link2Error = true;
        this.setState({ link2Error: true, saving: false });
        toast.error("Unable to save this announcement.");
        this.scrollToError("button2Link");
        return false;
      }
      if (button2Text !== "" && button2Link === "") {
        link2Error = true;
        this.setState({ link2Error: true, saving: false });
        toast.error("Unable to save this announcement.");
        this.scrollToError("button2Text");
        return false;
      }
      if (isMobile) {
        toast.info("SAVES will not be processed while under Mobile Testing.");
        return false;
      }
      const result = await updateAnnouncement(
        startDate,
        endDate,
        workingAnnouncement,
        user.userId
      );
      if (result.developerMessage) {
        const displayMessage =
          "There was an error while updating this announcement.  (" +
          result.userMessage +
          " " +
          result.messageId +
          ")";
        error = true;
        errorMessage += displayMessage + "<hr/>";
        toast.error(displayMessage);
      } else {
        toast.info("Announcement saved");
      }

      // console.log("announcementEdit.handleSubmit result", result);
    }
    this.setState({ hasChange: "Y", changed: false });
    this.setState({ saving: false, error, errorMessage });
  };

  handleFileChange = e => {
    const file = e.target.files[0];
    this.setState({ file });
  };

  renderImage() {
    const { workingAnnouncement } = this.props;
    if (workingAnnouncement) {
      const url = "/displayAnnouncement/" + workingAnnouncement.id;
      const base64Image =
        "data:" +
        workingAnnouncement.contentType +
        ";base64," +
        workingAnnouncement.img;
      return (
        <div
          className="announcementDefaultHeight"
          style={{ overflow: "auto", width: "100%" }}
        >
          <a
            href={url}
            target="_blank"
            rel="noopener noreferrer"
            title="Click to view full size image in a new window."
          >
            <img
              className="announcementDefaultHeight"
              src={base64Image}
              alt={workingAnnouncement.title}
            />
          </a>
        </div>
      );
    }
  }

  showHideDelete(deleting) {
    this.setState({ deleting });
  }
  renderSaving() {
    const { saving } = this.state;
    if (saving) {
      return <Spinner fullPage="Y" />;
    }
  }

  renderInvalidFile() {
    const { fileValid } = this.state;
    if (!fileValid) {
      const msg = "You must select a file before saving.";
      // toast.error(msg);
      return (
        <div className="alert alert-danger media nw-field-error">
          <svg
            height="32"
            version="1.1"
            viewBox="0 0 32 32"
            width="32"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M15.973 0.818c-8.293 0-15.031 6.729-15.031 15.022s6.729 15.031 15.031 15.031 15.031-6.729 15.031-15.022-6.738-15.031-15.031-15.031zM15.973 26.373c-1.6 0-2.898-1.298-2.898-2.898s1.298-2.898 2.898-2.898 2.898 1.298 2.898 2.898c0 1.6-1.298 2.898-2.898 2.898zM18.969 7.671l-1.609 10.756h-2.747l-1.644-10.756v-2.364h6.009v2.364h-0.009z"
              fill="#6d6e71"
            ></path>
          </svg>
          <div className="media-body">{msg}</div>
        </div>
      );
    }
  }

  renderInvalidStartOnDate() {
    const { startDateError } = this.state;
    if (startDateError) {
      const msg = "Please select or enter a valid start on date (mm/dd/yyyy).";
      // toast.error(msg);
      return (
        <div className="alert alert-danger media nw-field-error">
          <svg
            height="32"
            version="1.1"
            viewBox="0 0 32 32"
            width="32"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M15.973 0.818c-8.293 0-15.031 6.729-15.031 15.022s6.729 15.031 15.031 15.031 15.031-6.729 15.031-15.022-6.738-15.031-15.031-15.031zM15.973 26.373c-1.6 0-2.898-1.298-2.898-2.898s1.298-2.898 2.898-2.898 2.898 1.298 2.898 2.898c0 1.6-1.298 2.898-2.898 2.898zM18.969 7.671l-1.609 10.756h-2.747l-1.644-10.756v-2.364h6.009v2.364h-0.009z"
              fill="#6d6e71"
            ></path>
          </svg>
          <div className="media-body">{msg}</div>
        </div>
      );
    }
  }
  renderStartDateInThePastError() {
    const { startDatePastError } = this.state;
    if (startDatePastError) {
      const msg =
        "You have entered a date in the past, please correct the date and resubmit.";
      // toast.error(msg);
      return (
        <div className="alert alert-danger media nw-field-error">
          <svg
            height="32"
            version="1.1"
            viewBox="0 0 32 32"
            width="32"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M15.973 0.818c-8.293 0-15.031 6.729-15.031 15.022s6.729 15.031 15.031 15.031 15.031-6.729 15.031-15.022-6.738-15.031-15.031-15.031zM15.973 26.373c-1.6 0-2.898-1.298-2.898-2.898s1.298-2.898 2.898-2.898 2.898 1.298 2.898 2.898c0 1.6-1.298 2.898-2.898 2.898zM18.969 7.671l-1.609 10.756h-2.747l-1.644-10.756v-2.364h6.009v2.364h-0.009z"
              fill="#6d6e71"
            ></path>
          </svg>
          <div className="media-body">{msg}</div>
        </div>
      );
    }
  }
  renderStartDateAfterEndDateError() {
    const { startDateAfterEndDateError } = this.state;
    if (startDateAfterEndDateError) {
      const msg = "Your Start Date must be earlier than your End Date.";
      // toast.error(msg);
      return (
        <div className="alert alert-danger media nw-field-error">
          <svg
            height="32"
            version="1.1"
            viewBox="0 0 32 32"
            width="32"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M15.973 0.818c-8.293 0-15.031 6.729-15.031 15.022s6.729 15.031 15.031 15.031 15.031-6.729 15.031-15.022-6.738-15.031-15.031-15.031zM15.973 26.373c-1.6 0-2.898-1.298-2.898-2.898s1.298-2.898 2.898-2.898 2.898 1.298 2.898 2.898c0 1.6-1.298 2.898-2.898 2.898zM18.969 7.671l-1.609 10.756h-2.747l-1.644-10.756v-2.364h6.009v2.364h-0.009z"
              fill="#6d6e71"
            ></path>
          </svg>
          <div className="media-body">{msg}</div>
        </div>
      );
    }
  }

  renderInvalidEndOnDate() {
    const { endDateError } = this.state;
    if (endDateError) {
      const msg = "Please select or enter a valid end on date (mm/dd/yyyy).";
      // toast.error(msg);
      return (
        <div className="alert alert-danger media nw-field-error">
          <svg
            height="32"
            version="1.1"
            viewBox="0 0 32 32"
            width="32"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M15.973 0.818c-8.293 0-15.031 6.729-15.031 15.022s6.729 15.031 15.031 15.031 15.031-6.729 15.031-15.022-6.738-15.031-15.031-15.031zM15.973 26.373c-1.6 0-2.898-1.298-2.898-2.898s1.298-2.898 2.898-2.898 2.898 1.298 2.898 2.898c0 1.6-1.298 2.898-2.898 2.898zM18.969 7.671l-1.609 10.756h-2.747l-1.644-10.756v-2.364h6.009v2.364h-0.009z"
              fill="#6d6e71"
            ></path>
          </svg>
          <div className="media-body">{msg}</div>
        </div>
      );
    }
  }

  renderInvalidHeadline() {
    const { headlineError } = this.state;
    if (headlineError) {
      const msg = "Please enter a Headline.";
      // toast.error(msg);
      return (
        <div className="alert alert-danger media nw-field-error">
          <svg
            height="32"
            version="1.1"
            viewBox="0 0 32 32"
            width="32"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M15.973 0.818c-8.293 0-15.031 6.729-15.031 15.022s6.729 15.031 15.031 15.031 15.031-6.729 15.031-15.022-6.738-15.031-15.031-15.031zM15.973 26.373c-1.6 0-2.898-1.298-2.898-2.898s1.298-2.898 2.898-2.898 2.898 1.298 2.898 2.898c0 1.6-1.298 2.898-2.898 2.898zM18.969 7.671l-1.609 10.756h-2.747l-1.644-10.756v-2.364h6.009v2.364h-0.009z"
              fill="#6d6e71"
            ></path>
          </svg>
          <div className="media-body">{msg}</div>
        </div>
      );
    }
  }
  renderInvalidSummaryText() {
    const { summaryTextError } = this.state;
    if (summaryTextError) {
      const msg = "Please enter Summary Text.";
      // toast.error(msg);
      return (
        <div className="alert alert-danger media nw-field-error">
          <svg
            height="32"
            version="1.1"
            viewBox="0 0 32 32"
            width="32"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M15.973 0.818c-8.293 0-15.031 6.729-15.031 15.022s6.729 15.031 15.031 15.031 15.031-6.729 15.031-15.022-6.738-15.031-15.031-15.031zM15.973 26.373c-1.6 0-2.898-1.298-2.898-2.898s1.298-2.898 2.898-2.898 2.898 1.298 2.898 2.898c0 1.6-1.298 2.898-2.898 2.898zM18.969 7.671l-1.609 10.756h-2.747l-1.644-10.756v-2.364h6.009v2.364h-0.009z"
              fill="#6d6e71"
            ></path>
          </svg>
          <div className="media-body">{msg}</div>
        </div>
      );
    }
  }

  renderInvalidAltText() {
    const { altTextError } = this.state;
    if (altTextError) {
      const msg = `Please enter alt text. This text provides alternative information
      for an image if a user is unable to view it (due to a slow
      connection, an error in the src attribute, use of a screen reader,
      etc.).`;
      // toast.error(msg);
      return (
        <div className="alert alert-danger media nw-field-error">
          <svg
            height="32"
            version="1.1"
            viewBox="0 0 32 32"
            width="32"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M15.973 0.818c-8.293 0-15.031 6.729-15.031 15.022s6.729 15.031 15.031 15.031 15.031-6.729 15.031-15.022-6.738-15.031-15.031-15.031zM15.973 26.373c-1.6 0-2.898-1.298-2.898-2.898s1.298-2.898 2.898-2.898 2.898 1.298 2.898 2.898c0 1.6-1.298 2.898-2.898 2.898zM18.969 7.671l-1.609 10.756h-2.747l-1.644-10.756v-2.364h6.009v2.364h-0.009z"
              fill="#6d6e71"
            ></path>
          </svg>
          <div className="media-body">{msg}</div>
        </div>
      );
    }
  }

  renderInvalidLink1Text() {
    const { link1Error, link1ErrorFormat } = this.state;
    if (link1ErrorFormat) {
      const msg = `A valid link must start with http:// or https://`;
      // toast.error(msg);
      return (
        <div className="alert alert-danger media nw-field-error">
          <svg
            height="32"
            version="1.1"
            viewBox="0 0 32 32"
            width="32"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M15.973 0.818c-8.293 0-15.031 6.729-15.031 15.022s6.729 15.031 15.031 15.031 15.031-6.729 15.031-15.022-6.738-15.031-15.031-15.031zM15.973 26.373c-1.6 0-2.898-1.298-2.898-2.898s1.298-2.898 2.898-2.898 2.898 1.298 2.898 2.898c0 1.6-1.298 2.898-2.898 2.898zM18.969 7.671l-1.609 10.756h-2.747l-1.644-10.756v-2.364h6.009v2.364h-0.009z"
              fill="#6d6e71"
            ></path>
          </svg>
          <div className="media-body">{msg}</div>
        </div>
      );
    }
    if (link1Error) {
      const msg = `Button text is a required field when adding a link to the banner`;
      // toast.error(msg);
      return (
        <div className="alert alert-danger media nw-field-error">
          <svg
            height="32"
            version="1.1"
            viewBox="0 0 32 32"
            width="32"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M15.973 0.818c-8.293 0-15.031 6.729-15.031 15.022s6.729 15.031 15.031 15.031 15.031-6.729 15.031-15.022-6.738-15.031-15.031-15.031zM15.973 26.373c-1.6 0-2.898-1.298-2.898-2.898s1.298-2.898 2.898-2.898 2.898 1.298 2.898 2.898c0 1.6-1.298 2.898-2.898 2.898zM18.969 7.671l-1.609 10.756h-2.747l-1.644-10.756v-2.364h6.009v2.364h-0.009z"
              fill="#6d6e71"
            ></path>
          </svg>
          <div className="media-body">{msg}</div>
        </div>
      );
    }
  }

  renderInvalidLink2Text() {
    const { link2Error, link2ErrorFormat } = this.state;
    if (link2ErrorFormat) {
      const msg = `A valid link must start with http:// or https://`;
      // toast.error(msg);
      return (
        <div className="alert alert-danger media nw-field-error">
          <svg
            height="32"
            version="1.1"
            viewBox="0 0 32 32"
            width="32"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M15.973 0.818c-8.293 0-15.031 6.729-15.031 15.022s6.729 15.031 15.031 15.031 15.031-6.729 15.031-15.022-6.738-15.031-15.031-15.031zM15.973 26.373c-1.6 0-2.898-1.298-2.898-2.898s1.298-2.898 2.898-2.898 2.898 1.298 2.898 2.898c0 1.6-1.298 2.898-2.898 2.898zM18.969 7.671l-1.609 10.756h-2.747l-1.644-10.756v-2.364h6.009v2.364h-0.009z"
              fill="#6d6e71"
            ></path>
          </svg>
          <div className="media-body">{msg}</div>
        </div>
      );
    }
    if (link2Error) {
      const msg = `Button text is a required field when adding a link to the banner`;
      // toast.error(msg);
      return (
        <div className="alert alert-danger media nw-field-error">
          <svg
            height="32"
            version="1.1"
            viewBox="0 0 32 32"
            width="32"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M15.973 0.818c-8.293 0-15.031 6.729-15.031 15.022s6.729 15.031 15.031 15.031 15.031-6.729 15.031-15.022-6.738-15.031-15.031-15.031zM15.973 26.373c-1.6 0-2.898-1.298-2.898-2.898s1.298-2.898 2.898-2.898 2.898 1.298 2.898 2.898c0 1.6-1.298 2.898-2.898 2.898zM18.969 7.671l-1.609 10.756h-2.747l-1.644-10.756v-2.364h6.009v2.364h-0.009z"
              fill="#6d6e71"
            ></path>
          </svg>
          <div className="media-body">{msg}</div>
        </div>
      );
    }
  }
  link;

  renderDelete() {
    const { deleting } = this.state;
    if (deleting) {
      return (
        <React.Fragment>
          <div id="pageCover" className="pageCover">
            <div id="dateEntryContainer" className="dateSelectContainer">
              <div className="modalTitleBar" id="editHeaderDateEntryContainer">
                Confirm Delete
                <button
                  className="close"
                  aria-label="Close"
                  id="closeDateEntryContainer"
                  onClick={() => this.showHideDelete(false)}
                  type="button"
                >
                  x
                </button>
              </div>
              <div id="editPane" className="modalEditPane">
                <div className="col-xs-12 form-group row ml-5 mr-5">
                  Are you sure you want to delete this Announcement?
                </div>
                <div
                  className="col-xs-12 form-group row"
                  style={{ position: "relative", bottom: "0px" }}
                >
                  <button
                    className="btn btn-primary demoLink float-right ml-5"
                    id="confirmDelete"
                    type="button"
                    title="Click to confirm deletion of this Announcement"
                    onClick={() => this.handleDelete()}
                  >
                    Yes
                  </button>
                  <button
                    className="btn btn-primary demoLink float-right ml-5"
                    id="doNotDelete"
                    title="Exit without deleting this Announcement"
                    type="button"
                    onClick={() => this.showHideDelete(false)}
                  >
                    No
                  </button>
                </div>
              </div>
            </div>
          </div>
        </React.Fragment>
      );
    }
  }

  handleLinkChange = e => {
    const workingAnnouncement = { ...this.props.workingAnnouncement };
    workingAnnouncement.link = e.currentTarget.value;
    workingAnnouncement.updatedBy = this.props.user.userId;
    this.props.onWorkingAnnouncementChange(workingAnnouncement);
  };
  handleLink1Change = e => {
    const workingAnnouncement = { ...this.props.workingAnnouncement };
    workingAnnouncement.button1Link = e.currentTarget.value;
    workingAnnouncement.updatedBy = this.props.user.userId;
    this.props.onWorkingAnnouncementChange(workingAnnouncement);
  };
  handleText1Change = e => {
    const workingAnnouncement = { ...this.props.workingAnnouncement };
    workingAnnouncement.button1Text = e.currentTarget.value;
    workingAnnouncement.updatedBy = this.props.user.userId;
    this.props.onWorkingAnnouncementChange(workingAnnouncement);
    // const btn1Characters = 25 - workingAnnouncement.button1Text.length;
    // this.setState({ btn1Characters });
  };
  handleLink2Change = e => {
    const workingAnnouncement = { ...this.props.workingAnnouncement };
    workingAnnouncement.button2Link = e.currentTarget.value;
    workingAnnouncement.updatedBy = this.props.user.userId;
    this.props.onWorkingAnnouncementChange(workingAnnouncement);
  };
  handleText2Change = e => {
    const workingAnnouncement = { ...this.props.workingAnnouncement };
    workingAnnouncement.button2Text = e.currentTarget.value;
    workingAnnouncement.updatedBy = this.props.user.userId;
    this.props.onWorkingAnnouncementChange(workingAnnouncement);
    // const btn2Characters = 25 - workingAnnouncement.button2Text.length;
    // this.setState({ btn2Characters });
  };
  handlePriorityChange = e => {
    const workingAnnouncement = { ...this.props.workingAnnouncement };
    workingAnnouncement.priority = e.currentTarget.value;
    workingAnnouncement.updatedBy = this.props.user.userId;
    this.props.onWorkingAnnouncementChange(workingAnnouncement);
  };
  handleStartDateChange = e => {
    const workingAnnouncement = { ...this.props.workingAnnouncement };
    try {
      workingAnnouncement.startOnDate = e.toISOString();
      workingAnnouncement.updatedBy = this.props.user.userId;
      this.props.onWorkingAnnouncementChange(workingAnnouncement);
    } catch (e1) {
      toast.error(
        "The entered value (" +
          e +
          ") is not valid for a start on date.  Reverting to previous value."
      );
    }
  };

  handleEndDateChange = e => {
    const workingAnnouncement = { ...this.props.workingAnnouncement };
    try {
      workingAnnouncement.endOnDate = e.toISOString();
      workingAnnouncement.updatedBy = this.props.user.userId;
      this.props.onWorkingAnnouncementChange(workingAnnouncement);
    } catch (e1) {
      toast.error(
        "The entered value (" +
          e +
          ") is not valid for a end on date.  Reverting to previous value."
      );
      console.error(
        "announcementEdit.handleEndDateChange End On Date Error",
        e1
      );
    }
  };

  handleApprovedChange = e => {
    const workingAnnouncement = { ...this.props.workingAnnouncement };
    if (e.target.value === "Y") {
      workingAnnouncement.approvedDate = new Date().toISOString();
      workingAnnouncement.approvedBy = this.props.user.userId;
      workingAnnouncement.updatedBy = this.props.user.userId;
      this.props.onWorkingAnnouncementChange(workingAnnouncement);
    }
    this.setState({ changed: true });
  };

  handleHeadlineChange = e => {
    const workingAnnouncement = { ...this.props.workingAnnouncement };
    workingAnnouncement.headline = e.currentTarget.value;
    workingAnnouncement.updatedBy = this.props.user.userId;
    this.props.onWorkingAnnouncementChange(workingAnnouncement);
  };

  handleSummaryTextChange = e => {
    const workingAnnouncement = { ...this.props.workingAnnouncement };
    workingAnnouncement.summaryText = e.currentTarget.value;
    workingAnnouncement.updatedBy = this.props.user.userId;
    this.props.onWorkingAnnouncementChange(workingAnnouncement);
  };
  renderApprovedBy() {
    const { workingAnnouncement, user } = this.props;
    const { changed } = this.state;
    if (workingAnnouncement.id === 0) {
      return <div>N/A</div>;
    }
    if (!changed && workingAnnouncement.approvedBy !== "") {
      return (
        <div>
          {workingAnnouncement.approvedBy} on{" "}
          {dateFormat(workingAnnouncement.approvedDate, "mm/dd/yyyy HH:MM")}
        </div>
      );
    }
    if (!changed && workingAnnouncement.createdBy === user.userId) {
      return (
        <div>
          Approval is still required. You can not approve an announcement that
          was created by you.
        </div>
      );
    }
    return (
      <fieldset className="form-group">
        <div className="form-check">
          <label className="form-check-label" htmlFor="approvedY">
            <input
              type="radio"
              className="form-check-input"
              name="approved"
              id="approvedY"
              value="Y"
              onChange={this.handleApprovedChange}
              checked={workingAnnouncement.approvedBy !== "" ? true : false}
            />
            Yes
          </label>
        </div>
        <div className="form-check">
          <label className="form-check-label" htmlFor="approvedN">
            <input
              type="radio"
              className="form-check-input"
              name="approved"
              id="approvedN"
              value="N"
              onChange={this.handleApprovedChange}
              checked={workingAnnouncement.approvedBy === "" ? true : false}
            />
            No
          </label>
        </div>
      </fieldset>
    );
  }

  renderForm() {
    const { workingAnnouncement } = this.props;
    const { file } = this.state;
    if (workingAnnouncement) {
      const startDate = new Date(workingAnnouncement.startOnDate);
      const endDate = new Date(workingAnnouncement.endOnDate);
      // const approvedBy = workingAnnouncement.approvedBy;

      // const btn1Character = workingAnnouncement.button1Text
      //   ? 25 - workingAnnouncement.button1Text.length
      //   : 25;
      // const btn2Character = workingAnnouncement.button2Text
      //   ? 25 - workingAnnouncement.button2Text.length
      //   : 25;
      // this.setState({ btn1Characters, btn2Characters });

      return (
        <div className="container">
          <form onSubmit={this.handleSubmit}>
            <hr></hr>
            <div className="announcementFormHeading">
              Rotating Banner Information:
            </div>
            <div>
              <label className="announcementLabel" htmlFor="file">
                Background Image: (JPG or PNG)
              </label>
            </div>
            <div className="input-group mb-3">
              {workingAnnouncement.id === 0 ? (
                <React.Fragment>
                  <div className="custom-file">
                    <input
                      type="file"
                      className="custom-file-input "
                      id="file"
                      name="file"
                      accept=".jpg,.png,.jpeg"
                      onChange={this.handleFileChange}
                    />
                    <label
                      className="custom-file-label bolt-textfield-wc--input bolt-textfield-wc--input__style-scope bolt-textfield"
                      htmlFor="inputGroupFile01"
                      style={{
                        color: "#4D4F53",
                        fontSize: "14px",
                        fontWeight: "normal"
                      }}
                    >
                      {file ? file.name : "Choose file"}
                    </label>
                  </div>
                </React.Fragment>
              ) : (
                this.renderImage()
              )}
            </div>
            {this.renderInvalidFile()}

            <div
            // className="col-xs-12 form-group row"
            >
              <label className="announcementLabel" htmlFor="headline">
                Headline:
              </label>
              <input
                id="headline"
                name="headline"
                type="text"
                className="bolt-textfield-wc--input bolt-textfield-wc--input__style-scope bolt-textfield"
                value={workingAnnouncement.headline}
                onChange={this.handleHeadlineChange}
                // disabled={approvedBy === "" ? "" : "disabled"}
                maxLength="40"
              />
              <span className="announcementCounter">
                Characters remaining: {40 - workingAnnouncement.headline.length}
              </span>
              {/* <div className="col-sm-2">
                <label htmlFor="headline">Headline:</label>
              </div>
              <div className="col-sm-10">
                <input
                  id="headline"
                  name="headline"
                  type="text"
                  className="bolt-textfield-wc--input bolt-textfield-wc--input__style-scope bolt-textfield"
                  value={workingAnnouncement.headline}
                  onChange={this.handleHeadlineChange}
                  // disabled={approvedBy === "" ? "" : "disabled"}
                  maxLength="26"
                />
                <br />
                <span>
                  {26 - workingAnnouncement.headline.length} characters left
                </span>
              </div> */}
            </div>
            {this.renderInvalidHeadline()}
            <div
            // className="col-xs-12 form-group row"
            >
              <label className="announcementLabel" htmlFor="summaryText">
                Summary Text:
              </label>
              <input
                id="summaryText"
                name="summaryText"
                type="text"
                className="bolt-textfield-wc--input bolt-textfield-wc--input__style-scope bolt-textfield"
                value={workingAnnouncement.summaryText}
                onChange={this.handleSummaryTextChange}
                // disabled={approvedBy === "" ? "" : "disabled"}
                maxLength="200"
              />
              <br />
              <span className="announcementCounter">
                Characters remaining:{" "}
                {200 - workingAnnouncement.summaryText.length}
              </span>
              {/* <div className="col-sm-2">
                <label htmlFor="summaryText">Summary Text:</label>
                
              </div> */}
              {/* <div className="col-sm-10">
                <input
                  id="summaryText"
                  name="summaryText"
                  type="text"
                  className="bolt-textfield-wc--input bolt-textfield-wc--input__style-scope bolt-textfield"
                  value={workingAnnouncement.summaryText}
                  onChange={this.handleSummaryTextChange}
                  // disabled={approvedBy === "" ? "" : "disabled"}
                  maxLength="110"
                />
                <br />
                <span>
                  {110 - workingAnnouncement.summaryText.length} characters left
                </span>
              </div> */}
            </div>
            {this.renderInvalidSummaryText()}
            <div
            // className="col-xs-12 form-group row"
            >
              <label className="announcementLabel" htmlFor="link">
                Alt Text:
              </label>
              <input
                id="link"
                name="link"
                type="text"
                className="bolt-textfield-wc--input bolt-textfield-wc--input__style-scope bolt-textfield"
                value={workingAnnouncement.link}
                onChange={this.handleLinkChange}
                // disabled={approvedBy === "" ? "" : "disabled"}
                maxLength="500"
              />
              <br />
              <span className="announcementCounter">
                Characters remaining: {500 - workingAnnouncement.link.length}
              </span>
              {/* <div className="col-sm-2">
                <label htmlFor="link">Alt Text:</label>
              </div>
              <div className="col-sm-10">
                <input
                  id="link"
                  name="link"
                  type="text"
                  className="bolt-textfield-wc--input bolt-textfield-wc--input__style-scope bolt-textfield"
                  value={workingAnnouncement.link}
                  onChange={this.handleLinkChange}
                  // disabled={approvedBy === "" ? "" : "disabled"}
                  maxLength="500"
                />
              </div> */}
            </div>
            {this.renderInvalidAltText()}
            <div
              className="col-xs-12 form-group row"
              style={{ marginTop: "50px" }}
            >
              <div className="col-sm-2">
                <label
                  htmlFor="button1Link"
                  className="nw-fieldlabel-optional announcementLabel"
                  title="Enter a valid URL. "
                >
                  Link 1:
                </label>
              </div>
              <div className="col-sm-4">
                <input
                  id="button1Link"
                  name="button1Link"
                  type="text"
                  className="bolt-textfield-wc--input bolt-textfield-wc--input__style-scope bolt-textfield"
                  value={workingAnnouncement.button1Link}
                  onChange={this.handleLink1Change}
                  maxLength="500"
                  // disabled={approvedBy === "" ? "" : "disabled"}
                />
              </div>
              <div className="col-sm-2">
                <label
                  htmlFor="button1Text"
                  className="nw-fieldlabel announcementLabel"
                  title="Enter text to display on the button "
                >
                  Button Text:
                </label>
              </div>
              <div className="col-sm-4">
                <input
                  id="button1Text"
                  name="button1Text"
                  type="text"
                  className="bolt-textfield-wc--input bolt-textfield-wc--input__style-scope bolt-textfield"
                  value={workingAnnouncement.button1Text}
                  onChange={this.handleText1Change}
                  // disabled={approvedBy === "" ? "" : "disabled"}
                  maxLength="20"
                />
                <br />
                <span className="announcementCounter">
                  Characters remaining:{" "}
                  {20 - workingAnnouncement.button1Text.length}
                </span>
              </div>
            </div>
            {this.renderInvalidLink1Text()}
            <div className="col-xs-12 form-group row">
              <div className="col-sm-2">
                <label
                  htmlFor="button2Link"
                  className="nw-fieldlabel-optional announcementLabel"
                  title="Enter a valid URL. "
                >
                  Link 2:
                </label>
              </div>
              <div className="col-sm-4">
                <input
                  id="button2Link"
                  name="button2Link"
                  type="text"
                  className="bolt-textfield-wc--input bolt-textfield-wc--input__style-scope bolt-textfield"
                  value={workingAnnouncement.button2Link}
                  onChange={this.handleLink2Change}
                  maxLength="500"
                  // disabled={approvedBy === "" ? "" : "disabled"}
                />
              </div>
              <div className="col-sm-2">
                <label
                  htmlFor="button2Text"
                  className="nw-fieldlabel announcementLabel"
                  title="Enter text to display on the button "
                >
                  Button Text:
                </label>
              </div>
              <div className="col-sm-4">
                <input
                  id="button2Text"
                  name="button2Text"
                  type="text"
                  className="bolt-textfield-wc--input bolt-textfield-wc--input__style-scope bolt-textfield"
                  value={workingAnnouncement.button2Text}
                  onChange={this.handleText2Change}
                  maxLength="20"
                  // disabled={approvedBy === "" ? "" : "disabled"}
                />
                <br />
                <span className="announcementCounter">
                  Characters remaining:{" "}
                  {20 - workingAnnouncement.button2Text.length}
                </span>
              </div>
            </div>
            {this.renderInvalidLink2Text()}
            <hr></hr>
            <div className="announcementFormHeading">Publishing Schedule</div>
            <div className="col-xs-12 form-group row">
              <div className="col-sm-2">
                <label className="announcementLabel" htmlFor="startDate">
                  Start On Date:
                </label>
              </div>
              <div className="col-sm-10">
                <DatePicker
                  id="startDate"
                  name="startDate"
                  fixedHeight
                  selected={startDate}
                  onChange={this.handleStartDateChange}
                  showYearDropdown
                  yearDropdownItemNumber={5}
                  showMonthDropdown
                  // disabled={approvedBy === "" ? "" : "disabled"}
                  className="bolt-textfield-wc--input bolt-textfield-wc--input__style-scope bolt-textfield"
                />
              </div>
            </div>
            {this.renderInvalidStartOnDate()}
            {this.renderStartDateInThePastError()}
            {this.renderStartDateAfterEndDateError()}
            <div className="col-xs-12 form-group row">
              <div className="col-sm-2">
                <label className="announcementLabel" htmlFor="endDate">
                  End On Date:
                </label>
              </div>
              <div className="col-sm-10">
                <DatePicker
                  id="endDate"
                  name="endDate"
                  fixedHeight
                  selected={endDate}
                  onChange={this.handleEndDateChange}
                  showYearDropdown
                  yearDropdownItemNumber={5}
                  showMonthDropdown
                  // disabled={approvedBy === "" ? "" : "disabled"}
                  className="bolt-textfield-wc--input bolt-textfield-wc--input__style-scope bolt-textfield"
                />
              </div>
            </div>
            {this.renderInvalidEndOnDate()}

            <div className="col-xs-12 form-group row">
              <div className="col-sm-2">
                <label
                  htmlFor="priority"
                  className="nw-fieldlabel announcementLabel"
                  title="Select the priority of "
                >
                  Requested Priority:
                </label>
              </div>
              <div className="col-sm-4">
                <select
                  id="priority"
                  name="priority"
                  className="bolt-textfield-wc--input bolt-textfield-wc--input__style-scope bolt-textfield"
                  onChange={this.handlePriorityChange}
                  value={workingAnnouncement.priority}
                  // disabled={approvedBy === "" ? "" : "disabled"}
                >
                  <option value="1">Position One</option>
                  <option value="2">Position Two</option>
                  <option value="3">Position Three</option>
                  <option value="4">Position Four</option>
                </select>
              </div>
            </div>

            {/* <div className="col-xs-12 form-group row">
              <div className="col-sm-2">
                <label htmlFor="approved">Approved:</label>
              </div>
              <div className="col-sm-10">{this.renderApprovedBy()}</div>
            </div> */}
            <div className="col-xs-12 form-group row">
              <div className="col-sm-2">
                <label className="nw-fieldlabel announcementLabel">
                  Status:
                </label>
              </div>
              <div className="col-sm-10">
                {workingAnnouncement.announcementType}
              </div>
            </div>
            <div className="col-xs-12 form-group">
              {/* {!changed ? (
                <button
                  className="btn btn-primary demoLink"
                  type="submit"
                  disabled="disabled"
                >
                  Save
                </button>
              ) : (
                <button
                  className="btn btn-primary demoLink"
                  type="submit"
                  aria-label="Save this Announcement"
                >
                  Save
                </button>
              )} */}
              <button
                className="btn btn-primary demoLink"
                type="submit"
                aria-label="Save this Announcement"
              >
                Save
              </button>
              <button
                className="btn btn-secondary demoLink ml-5"
                type="button"
                onClick={() => this.showHideDelete(true)}
                aria-label="Delete this Announcement"
              >
                Delete
              </button>
            </div>
          </form>
        </div>
      );
    }
  }

  render() {
    const { isAdmin } = this.props;
    if (!isAdmin) {
      return <h2>You do not have permission to edit Announcements</h2>;
    }

    if (isAdmin) {
      return (
        <div className="announcements" style={{ height: "auto" }}>
          <div className="container">
            <div className="row">
              <div className="announcementEditContainer col">
                <div className="pageHeader">
                  {" "}
                  <button className="link" onClick={() => this.goToList()}>
                    Announcements Banners
                  </button>{" "}
                  > Edit
                </div>
                <div className="instructions">
                  <div
                    style={
                      isMobile
                        ? {
                            display: "block",
                            color: "red",
                            fontWeight: "bold",
                            fontSize: "24px",
                            lineHeight: "30px"
                          }
                        : { display: "none" }
                    }
                  >
                    WE ARE CURRENTLY CONNECTED TO THE PRODUCTION DATABASE FOR
                    MOBILE TESTING. DO NOT SAVE ANY ANNOUNCEMENTS AT THIS TIME
                  </div>
                  <p>
                    Use these form fields to fill out the necessary elements to
                    post a new rotating banner to the top of the associate HR
                    dashboard. For questions, reach out to
                    <a href="mailto:helphrdashboard@nationwide.com">
                      helphrdashboard@nationwide.com
                    </a>
                  </p>
                  <ol>
                    <li>
                      Download the image template to have your designer create a
                      graphic that works with the correct spacing and contrast
                      guidelines.
                      <br />
                      <a href="/HRConnect_Rotating-Banner-Template.pptx">
                        Image Template (PPT)
                      </a>
                    </li>
                    <li>
                      Export and upload a JPG or PNG that follows the template
                      standards at 463 x 234 px.
                    </li>
                    <li>Enter a Headline and summary text for the banner.</li>

                    <li>
                      Select the start and end dates for the banner announcement
                    </li>
                    <li>
                      Optional: Add link(s) for the banner. For each link, add
                      the text that will show up on the button (e.g. "Get
                      Started"). Check to make sure the link(s) are accurate.
                    </li>
                    <li>Click Save to submit the banner request.</li>
                  </ol>
                </div>
              </div>
            </div>
          </div>

          {this.renderForm()}
          {this.renderSaving()}
          {this.renderDelete()}
        </div>
      );
    }
    return <div>You are not authorized to use this page.</div>;
  }
}

export default AnnouncementEdit;
