import React, { Component } from "react";

class NotAuthorized extends Component {
  state = {};

  render() {
    return <div>You do not have access to this application</div>;
  }
}

export default NotAuthorized;
