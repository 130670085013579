import config from '../config.json';
import { getRandomString } from '../services/AuthService';
import { getEnvironment } from './EnvironmentVariable';
import { isMobile } from 'react-device-detect';

export const addSearchHistory = async (searchString, resultCount, state) => {
  const environ = getEnvironment();
  let portalApi = config.portalApi;
  let portalKey = config.client_id;
  if (environ !== 'DEFAULT') {
    // console.log("searchHIstoryService.addSearchHistory environ", environ);
    portalApi = config.test_portalApi;
    portalKey = config.test_client_id;
  }
  // let formData = new FormData();
  // formData.append("searchString", searchString);
  // formData.append("resultCount", resultCount);
  // formData.append("state", state);

  var formBody = [];
  formBody.push(encodeURIComponent('searchString') + '=' + encodeURIComponent(searchString));
  formBody.push(encodeURIComponent('resultCount') + '=' + encodeURIComponent(resultCount));
  formBody.push(encodeURIComponent('state') + '=' + encodeURIComponent(state));
  formBody.push(encodeURIComponent('source') + '=' + encodeURIComponent('LeaderConnect'));
  formBody = formBody.join('&');

  let headers = new Headers();
  headers.append('Access-Control-Allow-Origin', '*');
  const token = sessionStorage.getItem('access_token');
  headers.append('Authorization', 'Bearer ' + token);
  headers.append('client_id', portalKey);
  const message = getRandomString(25);
  headers.append('X-NW-Message-ID', message);
  headers.append('Content-Type', 'application/x-www-form-urlencoded');
  const response = await fetch(portalApi + 'searchhistory/', {
    method: 'post',
    headers: headers,
    body: formBody,
  });
  const jsondata = await response.json();
  return jsondata;
};

export const addSearchResultActivity = async (searchHistoryId, activity, description) => {
  const environ = getEnvironment();
  let portalApi = config.portalApi;
  let portalKey = config.client_id;
  if (environ !== 'DEFAULT') {
    // console.log(
    //   "searchHIstoryService.addSearchResultActivity environ",
    //   environ
    // );
    if (isMobile) {
      portalApi = config.Mobile_test_portalApi;
    } else {
      portalApi = config.test_portalApi;
      portalKey = config.test_client_id;
    }
  }
  // let formData = new FormData();
  // formData.append("searchHistoryId", searchHistoryId);
  // formData.append("activity", activity);
  // formData.append("description", description);

  var formBody = [];
  formBody.push(encodeURIComponent('searchHistoryId') + '=' + encodeURIComponent(searchHistoryId));
  formBody.push(encodeURIComponent('activity') + '=' + encodeURIComponent(activity));
  formBody.push(encodeURIComponent('description') + '=' + encodeURIComponent(description));
  formBody.push(encodeURIComponent('source') + '=' + encodeURIComponent('LeaderConnect'));
  formBody = formBody.join('&');

  let headers = new Headers();
  headers.append('Access-Control-Allow-Origin', '*');
  const token = sessionStorage.getItem('access_token');
  headers.append('Authorization', 'Bearer ' + token);
  headers.append('client_id', portalKey);
  const message = getRandomString(25);
  headers.append('X-NW-Message-ID', message);
  headers.append('Content-Type', 'application/x-www-form-urlencoded');
  const response = await fetch(portalApi + 'searchhistory/activity', {
    method: 'post',
    headers: headers,
    body: formBody,
  });
  const jsondata = await response.json();
  return jsondata;
};
