import React, { Component } from 'react';
import SearchBar from './searchBar';
// import Logo from "../nw-logo-name.png";
import MenuBar from './menubar';

class Header extends Component {
  goToHrConnect() {
    // console.log("Header goToHrConnect");
    // window.location = "https://hrconnect.nationwide.com";
    window.location.href = 'https://hrconnect.nationwide.com';
  }

  render() {
    const {
      user,
      userPhoto,
      onSearchQueryUpdate,
      onSearchQueryUpdateCallup,
      searchQuery,
      showDefault,
      showMessage,
      openLinkInNewWindow,
      callUpSNData,
      menuExpanded,
      changeMenuExpanded,
      isSepm,
      checkSepm
    } = this.props;

    return (
      <React.Fragment>
        <div className="topStrip topColor blockHideWhenMobile">
          <div className="container">
            <div className="row">
              <div className="col" style={{ textAlign: 'right' }}>
                <button
                  id="btn_gotohrconnect"
                  onClick={() => this.goToHrConnect()}
                  className="btn hrConnectButton"
                >
                  <span style={{ color: '#c7d0dc', fontSize: '15px' }}>Switch to </span>
                  <span style={{ color: 'white' }}>HR</span>
                  <span style={{ color: '#c7d0dc' }}>Connect</span>
                </button>
              </div>
              <div className="col col-lg-3 welcomediv">
                {user ? user.firstName : ''} {user ? user.lastName : ''}
                <img
                  className="userPhoto"
                  alt={userPhoto ? 'Associate Image' : 'no-image'}
                  src={userPhoto ? 'data:image/jpeg;base64,' + userPhoto : '/no-image.svg'}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="header">
          <div className="container">
            <div className="row" style={{ whiteSpace: 'nowrap' }}>
              <div className="xcol-2 hideWhenMobile">
                <div className="nwlogo" title="Nationwide Logo"></div>
                <h1  
                  id="leaderconnect_home"
                  className="hrlogo"
                  onClick={showDefault}
                  style={{ cursor: 'pointer' }}
                  title="My LeaderConnect Dashboard"
                ></h1 >
                <div
                  className="showInlineWhenMobile"
                  style={{
                    paddingTop: '28px',
                    paddingRight: '28px',
                    float: 'right',
                  }}
                >
                  <button
                    onClick={changeMenuExpanded}
                    className={
                      menuExpanded ? 'btn btn-link menuDisplayed' : 'btn btn-link menuClosed'
                    }
                  ></button>
                </div>
              </div>

              <div className="hideWhenMobile xcol">
                <MenuBar
                  //handleJobChangeChecklistDisplay ={this.props.handleJobChangeChecklistDisplay}
                  menuExpanded={menuExpanded}
                  showMessage={showMessage}
                  openLinkInNewWindow={openLinkInNewWindow}
                  showDefault={showDefault}
                  changeMenuExpanded={changeMenuExpanded}
                  loadChecklistContent={this.props.loadChecklistContent}
                  isSepm ={isSepm}
                  checkSepm ={checkSepm}
                />
              </div>
              <div className="searchFieldContainer hideWhenMobile xcol-2">
                <SearchBar
                  searchQuery={searchQuery}
                  onSearchQueryUpdate={searchQuery => onSearchQueryUpdate(searchQuery)}
                  onSearchQueryUpdateCallup={searchQuery => onSearchQueryUpdateCallup(searchQuery)}
                  callUpSNData={callUpSNData}
                />
              </div>
              <div className="showWhenMobile" style={{ width: '100%', paddingLeft: '10px' }}>
                <div
                  className="nwlogo"
                  title="Nationwide Logo"
                  style={menuExpanded ? { display: 'none' } : { display: 'inline-block' }}
                ></div>
                <div
                  className="hrlogo"
                  onClick={showDefault}
                  style={
                    menuExpanded
                      ? { display: 'none' }
                      : { display: 'inline-block', cursor: 'pointer' }
                  }
                  title="My Hr Dashboard"
                ></div>
                <div
                  className="showInlineWhenMobile"
                  style={{
                    paddingTop: '28px',
                    paddingRight: '7px',
                    float: 'right',
                  }}
                >
                  <button
                    id="mobileMenuHideShowButton"
                    onClick={changeMenuExpanded}
                    className={
                      menuExpanded ? 'btn btn-link menuDisplayed' : 'btn btn-link menuClosed'
                    }
                  ></button>
                </div>
              </div>
              <div className="showWhenMobile" style={{ marginLeft: '10px', width: '100%' }}>
                <div
                  className="searchFieldContainer"
                  id="searchDiv"
                  style={menuExpanded ? { display: 'block', width: '100%' } : { display: 'none' }}
                >
                  <SearchBar
                    searchQuery={searchQuery}
                    onSearchQueryUpdate={searchQuery => onSearchQueryUpdate(searchQuery)}
                    onSearchQueryUpdateCallup={searchQuery =>
                      onSearchQueryUpdateCallup(searchQuery)
                    }
                    callUpSNData={callUpSNData}
                  />
                </div>
              </div>
              <div className="showWhenMobile" style={{ width: '100%' }}>
                <MenuBar
                  menuExpanded={menuExpanded}
                  showMessage={showMessage}
                  openLinkInNewWindow={openLinkInNewWindow}
                  showDefault={showDefault}
                  changeMenuExpanded={changeMenuExpanded}
                  loadChecklistContent={this.props.loadChecklistContent}
                  isSepm ={isSepm}
                />
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Header;
