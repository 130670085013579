export const getHolidays = DAYS => {
  const holidays = [];
  const numberOfDays = DAYS;
  for (var d = 0; d < numberOfDays; d++) {
    //Check the next numberOfDays for holidays
    const checkDate = new Date();
    const dt = checkDate.getDate() + d;
    checkDate.setDate(dt);
    const year = checkDate.getFullYear();
    const month = checkDate.getMonth() + 1;
    const day = checkDate.getDate();
    const strMonth = month < 10 ? '0' + month : '' + month;
    const strDay = day < 10 ? '0' + day : '' + day;
    const strDate = year + '-' + strMonth.substring(0, 2) + '-' + strDay.substring(0, 2);
    const h = Holiday(checkDate, strDate);
    // console.log("holidayService.getHolidays", checkDate, h);
    if (h !== null) {
      //A holiday was returned so add it to the hoidays array
      holidays.push(h);
    }
  }
  return holidays;
};

const FullDate = someDate => {
  const monthName = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];
  try {
    const displayDate =
      monthName[someDate.getMonth()] + ' ' + someDate.getDate() + ', ' + someDate.getFullYear();
    return displayDate;
  } catch (e) {
    return someDate;
  }
};
const Holiday = (someDate, strDate) => {
  const theMonth = someDate.getMonth();

  switch (theMonth) {
    case 0:
      //January
      //New Year’s Day
      //  Jan 1st.  If it falls on a weekend, then the following Monday
      //New years day and MLK (3rd monday in january)

      if (someDate.getDate() === 1) {
        //New Years Day
        //It is January 1st--always a holiday
        const displayDate = FullDate(someDate);
        return {
          date: strDate,
          displayDate,
          type: 'Holiday',
          name: "New Year's Day",
        };
      }
      if (someDate.getDate() === 2) {
        //it is January 2nd
        if (someDate.getDay() === 1) {
          //the 2nd is a Monday, so New Years Day was on a Sunday
          const displayDate = FullDate(someDate);
          return {
            date: strDate,
            displayDate,
            type: 'Holiday',
            name: 'Nationwide Holiday',
          };
        }
      }
      if (someDate.getDate() === 3) {
        //it is January 3rd
        if (someDate.getDay() === 1) {
          //The 3rd is a Monday, so New Years ay was on a Saturday
          const displayDate = FullDate(someDate);
          return {
            date: strDate,
            displayDate,
            type: 'Holiday',
            name: 'Nationwide Holiday',
          };
        }
      }
      //MLK (3rd monday in january)
      let janTestDate = new Date();
      janTestDate.setDate(1);
      janTestDate.setMonth(0);
      janTestDate.setFullYear(someDate.getFullYear());
      let mondayCounter = 0;
      let mlkDay = null;
      for (let x = 0; x < 30; x++) {
        janTestDate.setDate(1 + x);
        if (janTestDate.getDay() === 1) {
          //It is a Monday
          mondayCounter++;
          if (mondayCounter === 3) {
            //It is the third Monday
            mlkDay = janTestDate;
            break;
          }
        }
      }
      if (mlkDay && mlkDay.getDate() === someDate.getDate()) {
        //someDate is MLK day
        const displayDate = FullDate(someDate);
        return {
          date: strDate,
          displayDate,
          type: 'Holiday',
          name: 'MLK Day',
        };
      }
      return null;
    case 4:
      //Memorial day (last monday in may)
      if (someDate.getDate() > 23) {
        //Memorial day will always be after the 23rd
        let mayTestDate = new Date();
        //Set the test date to the first day in june
        mayTestDate.setDate(1);
        mayTestDate.setMonth(5);
        mayTestDate.setFullYear(someDate.getFullYear());

        //subtract a day until we get to a monday
        for (let x = 0; x < 14; x++) {
          const d = mayTestDate.getDate() - 1;
          mayTestDate.setDate(d);
          //   console.log("holidayService  mayTestDate", mayTestDate);
          if (mayTestDate.getDay() === 1) {
            //last monday in may
            // console.log("holidayService  FOUND LAST MONDAY IN MAY", mayTestDate);
            if (mayTestDate.getDate() === someDate.getDate()) {
              const displayDate = FullDate(someDate);
              return {
                date: strDate,
                displayDate,
                type: 'Holiday',
                name: 'Memorial Day',
              };
            }
            break;
          }
        }
      }

      return null;
    case 6:
      //July

      const independanceDay = new Date();
      independanceDay.setDate(4);
      independanceDay.setMonth(6);
      independanceDay.setFullYear(someDate.getFullYear());
      const independanceWeekDay = independanceDay.getDay();
      if (someDate.getDate() === 4) {
        //someDate is July 4th so it is a holiday
        const displayDate = FullDate(someDate);
        return {
          date: strDate,
          displayDate,
          type: 'Holiday',
          name: 'Fourth of July',
        };
      }
      if (someDate.getDate() === 3) {
        //it is July 3rd
        const displayDate = FullDate(someDate);
        switch (independanceWeekDay) {
          case 2:
            //4th is Tuesday then we get Monday (3rd) too

            return {
              date: strDate,
              displayDate,
              type: 'Holiday',
              name: 'Nationwide Holiday',
            };
          case 6:
            //4th is a Saturday then we get the Friday(3rd) too
            return {
              date: strDate,
              displayDate,
              type: 'Holiday',
              name: 'Nationwide Holiday',
            };
          default:
            return null;
        }
      }
      if (someDate.getDate() === 5) {
        //It is July 5th
        const displayDate = FullDate(someDate);
        switch (independanceWeekDay) {
          case 0:
            ///4th is sunday then we get Monday (5th)

            return {
              date: strDate,
              displayDate,
              type: 'Holiday',
              name: 'Nationwide Holiday',
            };
          case 4:
            //4th is Thursday then we get Friday (5th) too
            return {
              date: strDate,
              displayDate,
              type: 'Holiday',
              name: 'Nationwide Holiday',
            };
          default:
            return null;
        }
      }

      return null;

    case 8:
      //September
      //First Monday is Labor Day
      let sepTestDate = new Date();
      //Set the test date to the first day in june
      sepTestDate.setDate(1);
      sepTestDate.setMonth(8);
      sepTestDate.setFullYear(someDate.getFullYear());
      for (let x = 0; x < 7; x++) {
        sepTestDate.setDate(1 + x);
        if (sepTestDate.getDay() === 1) {
          //found the first MOnday
          if (sepTestDate.getDate() === someDate.getDate()) {
            const displayDate = FullDate(someDate);
            return {
              date: strDate,
              displayDate,
              type: 'Holiday',
              name: 'Labor Day',
            };
          }
          break;
        }
      }
      return null;
    case 10:
      //Thanksgiving (4th thursday of november)
      let novTestDate = new Date();
      //Set the test date to the first day in june
      novTestDate.setDate(1);
      novTestDate.setMonth(10);
      novTestDate.setFullYear(someDate.getFullYear());
      let thursdayCounter = 0;
      for (let x = 0; x < 30; x++) {
        novTestDate.setDate(1 + x);
        if (novTestDate.getDay() === 4) {
          //found a Thursday
          thursdayCounter++;
          if (thursdayCounter === 4) {
            //this is the 4th thursday
            if (novTestDate.getDate() === someDate.getDate()) {
              const displayDate = FullDate(someDate);
              return {
                date: strDate,
                displayDate,
                type: 'Holiday',
                name: 'Thanksgiving',
              };
            }
            if (novTestDate.getDate() + 1 === someDate.getDate()) {
              const displayDate = FullDate(someDate);
              return {
                date: strDate,
                displayDate,
                type: 'Holiday',
                name: 'Nationwide Holiday',
              };
            }

            return null;
          }
        }
      }
      return null;
    case 11:
      //December
      if (someDate.getDate() === 25) {
        const displayDate = FullDate(someDate);
        return {
          date: strDate,
          displayDate,
          type: 'Holiday',
          name: 'Christmas Day',
        };
      }
      let xMasDay = new Date();
      //Set the test date to the first day in june
      xMasDay.setDate(25);
      xMasDay.setMonth(11);
      xMasDay.setFullYear(someDate.getFullYear());
      const xMasWeekDay = xMasDay.getDay();
      if (someDate.getDate() === 23) {
        //It is December 23rd
        const displayDate = FullDate(someDate);
        switch (xMasWeekDay) {
          case 0:
            //The 25th is Sunday, then we get off Previous Friday (23rd)
            return {
              date: strDate,
              displayDate,
              type: 'Holiday',
              name: 'Nationwide Holiday',
            };
          case 6:
            // the 25th is Saturday then we get off Thursday (23rd)
            return {
              date: strDate,
              displayDate,
              type: 'Holiday',
              name: 'Nationwide Holiday',
            };
          default:
            return null;
        }
      }
      if (someDate.getDate() === 24) {
        //It is December 24th
        const displayDate = FullDate(someDate);
        switch (xMasWeekDay) {
          case 2:
            //the 25th is Tuesday, then we get off Monday (24th) too

            return {
              date: strDate,
              displayDate,
              type: 'Holiday',
              name: 'Nationwide Holiday',
            };
          case 3:
            //the 25th is Wednesday then we get off Tuesday(24th) too.
            return {
              date: strDate,
              displayDate,
              type: 'Holiday',
              name: 'Nationwide Holiday',
            };
          case 5:
            //the 25ths is Friday then we get off Thursday (24th) too
            return {
              date: strDate,
              displayDate,
              type: 'Holiday',
              name: 'Nationwide Holiday',
            };
          case 6:
            //the 25th is Saturday then we get off Thursday (23rd) and Friday (24th) too
            return {
              date: strDate,
              displayDate,
              type: 'Holiday',
              name: 'Nationwide Holiday',
            };
          default:
            return null;
        }
      }
      if (someDate.getDate() === 26) {
        //It is December 26th
        const displayDate = FullDate(someDate);
        switch (xMasWeekDay) {
          case 0:
            //the 25th is Sunday, then we get off Previous Friday (23rd) and Monday (26th)
            return {
              date: strDate,
              displayDate,
              type: 'Holiday',
              name: 'Nationwide Holiday',
            };
          case 1:
            //the 25th is Monday, then we get off Tuesday (26th) too.
            return {
              date: strDate,
              displayDate,
              type: 'Holiday',
              name: 'Nationwide Holiday',
            };
          case 4:
            //the 25th is Thursday then we get off Friday (26th) too

            return {
              date: strDate,
              displayDate,
              type: 'Holiday',
              name: 'Nationwide Holiday',
            };
          default:
            return null;
        }
      }
      return null;
    default:
      return null;
  }
};
