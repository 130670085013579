import config from '../config.json';
import testers from '../testers.json';
import { getRandomString } from '../services/AuthService';
import { getEnvironment } from './EnvironmentVariable';
import { isMobile } from 'react-device-detect';

export const getApprovals = async () => {
  const environ = getEnvironment();

  let snowApprovalAPI = config.snowApprovalAPI;
  let snowApprovalKey = config.snowApprovalKey;

  if (environ !== 'DEFAULT') {
    //We are not in production environment
    if (isMobile) {
      //However we are testing on a mobile device so use the Mobile api
      snowApprovalAPI = config.Mobile_test_snowApprovalAPI;
      snowApprovalKey = config.test_snowApprovalKey;
    } else {
      //We are not testing on a mobile device, so we will use the test API
      snowApprovalAPI = config.test_snowApprovalAPI;
      snowApprovalKey = config.test_snowApprovalKey;
    }
  }
  const userInfo = JSON.parse(sessionStorage.getItem('userinfo'));
  if (!userInfo || !userInfo.shortName) {
    return { snowError: 'No user information found.' };
  }
  let shortName = userInfo.shortName
    ? userInfo.shortName.toLowerCase()
    : userInfo.username.toLowerCase();

  const testUsers = testers.users;
  for (let x = 0; x < testUsers.length; x++) {
    const tester = testUsers[x];
    if (tester.shortName.toUpperCase() === shortName.toUpperCase()) {
      //User shortname matches to a tester
      if (environ === 'DEFAULT') {
        //This is the production environment
        if (tester.prodAllowed) {
          //This tester is allowed to mimic in production
          shortName = tester.mimicManager.toLowerCase();
        }
      } else {
        //This is not a production environment
        shortName = tester.mimicManager.toLowerCase();
      }
      break;
    }
  }
  //*********************************************DEBUG  START*/
  // snowApprovalKey = 'DF:LKSDJ:FL';
  //*********************************************DEBUG  STOP*/
  const message = getRandomString(25);
  const token = sessionStorage.getItem('access_token');
  let headers = new Headers();
  headers.append('Content-Type', 'application/json');
  headers.append('Authorization', 'Bearer ' + token);
  headers.append('X-NW-Message-ID', message);
  headers.set('client_id', snowApprovalKey);
  const body = {
    shortName: shortName,
  };
  let data = null;
  try {
    const response = await fetch(snowApprovalAPI, {
      method: 'post',
      headers: headers,
      body: JSON.stringify(body),
    });
    if (response.ok) {
      try {
        data = await response.json();
        // console.log("calendarService.getcalendar  data", data);
      } catch (e) {
        //Response status was OK, but we got an error getting the json data
        console.error('approvalsService.getApprovals  error', e);
        return { snowError: 'There was a problem with the JSON data.' };
      }
    } else {
      console.error('approvalsService.getApprovals  error', response);
      return { snowError: 'There was a problem retrieving the data' };
    }
  } catch (E) {
    //There was an error calling the API
    console.error('approvalsService.getApprovals  error', E);
    return {
      snowError: 'There was a problem retrieving the SNOW approvals data.',
    };
  }

  let approvals = [];

  if (data && data.userMessage) {
    //There is an error message in the data that was returned
    return { snowError: data.userMessage };
  }

  if (data && data.approval && data.approval.length > 0) {
    //data was retrieved and has approval entries
    for (let x = 0; x < data.approval.length; x++) {
      //loop through the approvals
      const entry = data.approval[x];
      let url = entry.approvalUrl;
      // console.log(
      //   "serviceNow.getApprovals",
      //   url,
      //   url.indexOf("https://"),
      //   url.substring(7)
      // );
      if (url.indexOf('https://') < 0) {
        //The link does not start with https
        url = url.replace('http:', 'https:');
        // console.log("serviceNow.getApprovals 1 url", url);
        // url = url.substring(7);
        // console.log("serviceNow.getApprovals 2 url", url);
        // url = "https://" + url;
        // console.log("serviceNow.getApprovals 3 url", url);
      }
      approvals[x] = {
        id: entry.id,
        actionType: 'ServiceNow',
        title: entry.docid,
        url: url,
      };
    }
  }
  // console.log('Tops', approvals);
  // //DEBUG START
  // approvals[0] = {
  //   id: 'sn_1',
  //   actionType: 'ServiceNow',
  //   title: 'Look a fake ServiceNow Approval',
  //   url: 'https:www.google.com',
  // };
  // approvals[1] = {
  //   id: 'sn_2',
  //   actionType: 'ServiceNow',
  //   title: 'Look a second fake ServiceNow Approval',
  //   url: 'https:www.google.com',
  // };
  // //DEBUG END

  const error = null;
  return {
    entries: approvals,
    snowError: error,
    error,
  };
};
