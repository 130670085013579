import config from '../config.json';
import testers from '../testers.json';
import { getRandomString } from './AuthService';
import { getEnvironment } from './EnvironmentVariable';
import { isMobile } from 'react-device-detect';

export const getIIQApprovals = async () => {
  const environ = getEnvironment();

  let iiqAPI = config.iiqAPI;
  let iiqKey = config.iiqKey;
  let iiqURL = 'https://iiq.nwie.net/identityiq/home.jsf';
  if (environ !== 'DEFAULT') {
    //We are not in production environment
    iiqURL = 'https://iiqdev.nwie.net/identityiq/home.jsf';
    if (isMobile) {
      //However we are testing on a mobile device so use the Mobile api
      iiqAPI = config.Mobile_iiqAPI;
      iiqKey = config.test_iiqKey;
    } else {
      //We are not testing on a mobile device, so we will use the test API
      iiqAPI = config.test_iiqAPI;
      iiqKey = config.test_iiqKey;
    }
  }
  // console.log("iiqService.getIIQApprovals iiqAPI", iiqAPI);
  const userInfo = JSON.parse(sessionStorage.getItem('userinfo'));
  if (!userInfo || !userInfo.shortName) {
    return { error: 'No user information found.' };
  }
  let shortName = userInfo.shortName
    ? userInfo.shortName.toLowerCase()
    : userInfo.username.toLowerCase();

  const testUsers = testers.users;
  for (let x = 0; x < testUsers.length; x++) {
    const tester = testUsers[x];
    if (tester.shortName.toLowerCase() === shortName.toLowerCase()) {
      //User shortname matches to a tester
      if (environ === 'DEFAULT') {
        //This is the production environment
        if (tester.prodAllowed) {
          //This tester is allowed to mimic in production
          shortName = tester.mimicManager.toLowerCase();
        }
      } else {
        //This is not a production environment
        shortName = tester.mimicManager.toLowerCase();
      }
      break;
    }
  }
  // return [];
  const message = getRandomString(25);
  const token = sessionStorage.getItem('access_token');
  let headers = new Headers();
  // headers.append("Content-Type", "application/x-www-form-urlencoded");
  headers.append('Authorization', 'Bearer ' + token);
  headers.append('X-NW-Message-ID', message);
  headers.set('client_id', iiqKey);

  let data = null;
  try {
    const url = iiqAPI + shortName + '/certifications';
    // console.log("iiqService.getIIQApprovals url", url);
    const response = await fetch(url, {
      method: 'get',
      headers: headers,
    });
    if (response.ok) {
      //Successful response from the API
      try {
        data = await response.json();
        console.log('iiqService.getIIQApprovals data', data);
      } catch (e) {
        //Response status was OK, but we got an error getting the json data
        console.error('iiqService.getIIQApprovals error', e);
        return { error: 'error with the IIQ json' };
      }
    } else {
      //API did not respond with OK
      console.error('iiqService.getIIQApprovals  error calling API', response.statusText);
      return {
        error: 'error recieved when trying to retrieve IIQ certifications',
      };
    }
  } catch (E) {
    //There was an error calling the API
    console.error('iiqService.getIIQApprovals  error', E);
    return { error: 'error recieved when trying to fetch IIQ certifications' };
  }

  if (data && data.userMessage) {
    //There is an error message in the data that was returned
    console.error('iiqService.getIIQApprovals error', data.userMessage);
    return { error: 'IIQ Certifications error:  ' + data.userMessage };
  }
  let approvals = [];
  // const actionType = "IIQ";
  if (data && data.length > 0) {
    //data was retrieved and has approval entries
    for (let x = 0; x < data.length; x++) {
      //loop through the approvals
      const entry = data[x];
      if (entry !== 'No Certs Found') {
        //Instead of an empty array, the API responds with "No Certs Found".   Only add
        //entries that are not equal to that text
        approvals[x] = {
          id: 'iiq_' + x,
          actionType: 'IIQ',
          title: entry,
          url: iiqURL,
        };
      }
    }
  }
  // //DEBUG START
  // approvals[0] = {
  //   id: 'iiq_1',
  //   actionType: 'IIQ',
  //   title: 'Look a fake IIQ Approval',
  //   url: 'https:www.google.com',
  // };
  // approvals[1] = {
  //   id: 'iiq_2',
  //   actionType: 'IIQ',
  //   title: 'Look a second fake IIQ Approval',
  //   url: 'https:www.google.com',
  // };
  // //DEBUG END
  // console.log("aribaProcurementService.getAribaProcurementApprovals  approvals", approvals);
  const error = null;
  return {
    entries: approvals,
    error,
  };
};

export const getIIQWorkItems = async () => {
  const environ = getEnvironment();

  let iiqAPI = config.iiqAPI;
  let iiqKey = config.iiqKey;
  let iiqURL = 'https://iiq.nwie.net/identityiq/home.jsf';
  if (environ !== 'DEFAULT') {
    //We are not in production environment
    iiqURL = 'https://iiqdev.nwie.net/identityiq/home.jsf';
    if (isMobile) {
      //However we are testing on a mobile device so use the Mobile api
      iiqAPI = config.Mobile_iiqAPI;
      iiqKey = config.test_iiqKey;
    } else {
      //We are not testing on a mobile device, so we will use the test API
      iiqAPI = config.test_iiqAPI;
      iiqKey = config.test_iiqKey;
    }
  }
  // console.log("iiqService.getIIQApprovals iiqAPI", iiqAPI);
  const userInfo = JSON.parse(sessionStorage.getItem('userinfo'));
  if (!userInfo || !userInfo.shortName) {
    return { error: 'No user information found.' };
  }
  let shortName = userInfo.shortName
    ? userInfo.shortName.toLowerCase()
    : userInfo.username.toLowerCase();

  const testUsers = testers.users;
  for (let x = 0; x < testUsers.length; x++) {
    const tester = testUsers[x];
    if (tester.shortName.toLowerCase() === shortName.toLowerCase()) {
      //User shortname matches to a tester
      if (environ === 'DEFAULT') {
        //This is the production environment
        if (tester.prodAllowed) {
          //This tester is allowed to mimic in production
          shortName = tester.mimicManager.toLowerCase();
        }
      } else {
        //This is not a production environment
        shortName = tester.mimicManager.toLowerCase();
      }
      break;
    }
  }

  const message = getRandomString(25);
  const token = sessionStorage.getItem('access_token');
  let headers = new Headers();
  // headers.append("Content-Type", "application/x-www-form-urlencoded");
  headers.append('Authorization', 'Bearer ' + token);
  headers.append('X-NW-Message-ID', message);
  headers.set('client_id', iiqKey);

  let data = null;
  try {
    const url = iiqAPI + shortName + '/workitems';
    // console.log("iiqService.getIIQApprovals url", url);
    const response = await fetch(url, {
      method: 'get',
      headers: headers,
    });
    if (response.ok) {
      //Successful response from the API
      try {
        data = await response.json();
        // console.log("iiqService.getIIQApprovals data", data);
      } catch (e) {
        //Response status was OK, but we got an error getting the json data
        console.error('iiqService.getIIQApprovals error', e);
        return { error: 'error with the IIQ json' };
      }
    } else {
      //API did not respond with OK
      console.error('iiqService.getIIQApprovals  error calling API', response.statusText);
      return {
        error: 'error recieved when trying to retrieve IIQ WorkItems',
      };
    }
  } catch (E) {
    //There was an error calling the API
    console.error('iiqService.getIIQApprovals  error', E);
    return { error: 'error recieved when trying to fetch IIQ WorkItems' };
  }

  if (data && data.userMessage) {
    //There is an error message in the data that was returned
    console.error('iiqService.getIIQApprovals error', data.userMessage);
    return { error: 'IIQ WorkItems error:  ' + data.userMessage };
  }
  let workItems = [];
  // const actionType = "IIQ";
  if (data && data.length > 0) {
    //data was retrieved and has approval entries
    for (let x = 0; x < data.length; x++) {
      //loop through the approvals
      const entry = data[x];
      if (entry !== 'No WorkItems Found') {
        //Instead of an empty array, the API responds with "No Certs Found".   Only add
        //entries that are not equal to that text
        workItems[x] = {
          id: 'iiq_' + x,
          actionType: 'IIQ',
          title: entry,
          url: iiqURL,
        };
      }
    }
  }
  const error = null;
  return {
    entries: workItems,
    error,
  };
};
