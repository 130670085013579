import React, { Component } from 'react';
import links from '../links.json';
import { getEnvironment } from '../services/EnvironmentVariable';

class LinksBar extends Component {
  openConcur = () => {
    window.open(links.concur);
  };

  openAriba = () => {
    window.open(links.ariba);
  };

  openWorkday = () => {
    window.open(links.workday);
  };

  openEgencia = () => {
    window.open(links.egencia);
  };

  openBravo = () => {
    window.open(links.bravo);
  };

  openMyTech = () => {
    window.open(links.myTech_Link);
  };

  openIIQ = () => {
    const environ = getEnvironment();
    if (environ !== 'DEFAULT') {
      window.open(links.test_iiq);
    } else {
      window.open(links.iiq);
    }
  };

  render() {
    return (
      <div className="linksbar">
        <div className="container">
          <div className="row">
            <div className="col">
              <div className="linksmargin">
                {/* The LeaderConnect belt order should be as follows: Workday, Bravo, IIQ, Ariba, Report Expenses, Book Travel */}
                <div
                  id="links_workday"
                  tabIndex="0"
                  className="linkButton workdayLinkImage"
                  title={'Open Workday! - ' + links.workday_text}
                  onClick={this.openWorkday}
                >
                  <div className="workdayOverlay">{links.workday_text}</div>
                </div>
                <div
                  id="links_bravo"
                  tabIndex="0"
                  className="linkButton bravoLinkImage"
                  title={'Open Bravo! - ' + links.bravo_text}
                  onClick={this.openBravo}
                >
                  <div className="bravoOverlay">{links.bravo_text}</div>
                </div>
                <div
                  id="links_iiq"
                  tabIndex="0"
                  className="linkButton iiqLinkImage"
                  title={'Open IIQ! - ' + links.iiq_text}
                  onClick={this.openIIQ}
                >
                  <div className="iiqOverlay">{links.iiq_text}</div>
                </div>
                <div
                  id="links_ariba"
                  tabIndex="0"
                  className="linkButton aribaLinkImage"
                  title={'Open Ariba! - ' + links.ariba_text}
                  onClick={this.openAriba}
                >
                  <div className="aribaOverlay">{links.ariba_text}</div>
                </div>

                <div
                  id="links_concur"
                  tabIndex="0"
                  className="linkButton concurLinkImage"
                  title={'Open Concur! - ' + links.concur_text}
                  onClick={this.openConcur}
                >
                  <div className="concurOverlay">{links.concur_text}</div>
                </div>

                <div
                  id="links_egencia"
                  tabIndex="0"
                  className="linkButton egenciaLinkImage"
                  title={'Open Concur! - ' + links.egencia_text}
                  onClick={this.openEgencia}
                >
                  <div className="egenciaOverlay">{links.egencia_text}</div>
                </div>
                <div
                  id="links_MyTech"
                  tabIndex="0"
                  className="linkButton mytechLinkImage"
                  title={'Open MyTech! - ' + links.myTech_Text}
                  onClick={this.openMyTech}
                >
                  <div className="mytechOverlay">{links.myTech_Text}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default LinksBar;
