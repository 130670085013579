import config from '../config.json';
import { getRandomString } from '../services/AuthService';
import testers from '../testers.json';
import { getEnvironment } from './EnvironmentVariable';

export const getBravoData = async () => {
  const environ = getEnvironment();
  let portalAuthApi = config.bravoAuthAPI;
  let portalKey = config.bravoKey;
  let portalApi = config.bravoDataAPI;
  let sentBravoAPI = config.sentBravoAPI;

  if (environ !== 'DEFAULT') {
    portalAuthApi = config.test_bravoAuthAPI;
    portalKey = config.test_bravoKey;
    portalApi = config.test_bravoDataAPI;
    sentBravoAPI = config.test_sentBravoAPI;
  }
  const userInfo = JSON.parse(sessionStorage.getItem('userinfo'));
  if (!userInfo || !userInfo.shortName) {
    return { error: 'No user information found.' };
  }
  let shortName = userInfo.shortName
    ? userInfo.shortName.toUpperCase()
    : userInfo.username.toUpperCase();

  const testUsers = testers.users;
  for (let x = 0; x < testUsers.length; x++) {
    const tester = testUsers[x];
    if (tester.shortName.toUpperCase() === shortName.toUpperCase()) {
      //User shortname matches to a tester
      if (environ === 'DEFAULT') {
        //This is the production environment
        if (tester.prodAllowed) {
          //This tester is allowed to mimic in production
          shortName = tester.mimicManager.toUpperCase();
        }
      } else {
        //This is not a production environment
        shortName = tester.mimicManager.toUpperCase();
      }
      break;
    }
  }
  // sessionStorage.removeItem('access_token');
  const message = getRandomString(25);
  const token = sessionStorage.getItem('access_token');

  let headers = new Headers();
  headers.append('Content-Type', 'application/x-www-form-urlencoded');
  headers.append('Authorization', 'Bearer ' + token);
  headers.append('X-NW-Message-ID', message);
  headers.set('client_id', portalKey);

  const body = encodeKeyValuePair('grant_type', 'client_credentials');
  let data = null;
  try {
    const response = await fetch(portalAuthApi, {
      method: 'post',
      headers: headers,
      body: body,
    });
    if (response.ok) {
      try {
        data = await response.json();
        //console.log('Bravo Auth token ', data);
      } catch (e) {
        console.log('TeamRecognitionService.Bravo error', e);
        return { error: 'There was a problem with the JSON data.' };
      }
    } else {
      console.error('TeamRecognitionService.Bravo  error calling API', response.statusText);
      return {
        error: 'There was a problem retrieving the Bravo data.',
      };
    }
  } catch (E) {
    console.log('TeamRecognitionService.Bravo error', E);
    return {
      error: 'There was a problem retrieving the Bravo data.',
    };
  }

  if (data && data.userMessage) {
    //There is an error message in the data that was returned
    console.error('TeamRecognitionService.Bravo  error', data.userMessage);
    return { error: data.userMessage };
  }
 // console.log('Bravo response', data);
  if (!data.access_token) {
    console.error('TeamRecognitionService.Bravo  error no data.access_token');
    return { error: data.userMessage };
  }

  const bravoAccessToken = data.access_token;
  sessionStorage.setItem('bravoAccessToken', bravoAccessToken);

  // console.log('Bravo bearer token', token);
  let BravoHeaders = new Headers();
  BravoHeaders.append('Content-Type', 'application/json');
  BravoHeaders.append('Authorization', 'Bearer ' + token);
  BravoHeaders.append('X-NW-Message-ID', message);
  BravoHeaders.set('client_id', portalKey);

  let bravoData = null,
    totalCount = 0,
    pageNum = 0,
    bravos = [],
    managerBravos = [],
    nextPage = true;
  while (nextPage) {
    const body2 = {
      shortName: encodeURIComponent(shortName),
      token: encodeURIComponent(bravoAccessToken),
      page: encodeURIComponent(pageNum),
    };
    pageNum++;
    try {
      const response = await fetch(portalApi, {
        method: 'post',
        headers: BravoHeaders,
        body: JSON.stringify(body2),
      });
      if (response.ok) {
        try {
          bravoData = await response.json();
        } catch (e) {
          //Response status was OK, but we got an error getting the json data
          console.error('TeamRecognitionService.Bravo error', e);
          return { error: 'There was a problem with the JSON data.' };
        }
      } else {
        return {
          error: 'There was a problem retrieving the Bravo data.',
        };
      }
    } catch (E) {
      //There was an error calling the API
      console.error('TeamRecognitionService.Bravo  error', E);
      return {
        error: 'There was a problem retrieving the Bravo data.',
      };
    }

    if (bravoData && bravoData.userMessage) {
      //There is an error message in the data that was returned
      return { error: bravoData.userMessage };
    }

    // let managerBravos = [];
    // let managerBravoCount = 0;
    try {
      if (bravoData && bravoData.data.events.length > 0) {
        //data was retrieved and has approval entries
        for (let x = 0; x < bravoData.data.events.length; x++) {
          //loop through the Bravo!

          if (bravoData.data.events[x].programName == 'Bravo!') {
            let shortname = bravoData.data.events[x].recipients[0].email;

            shortname = shortname.substring(0, shortname.indexOf('@')).toUpperCase();
            let date = bravoData.data.events[x].eventDate;
            let eid = bravoData.data.events[x].eventId;
            let from = bravoData.data.events[x].nominator.email;
            let name = bravoData.data.events[x].recipients[0].fullName;
            from = from.substring(0, from.indexOf('@')).toUpperCase();
            // if (from.toUpperCase() == shortName) {
            //   managerBravos[managerBravoCount] = {
            //     shortName: shortname,
            //     type: 'Bravo',
            //     Date: date,
            //     eventId: eid,
            //     From: from,
            //     name: name,
            //   };
            //   managerBravoCount++;
            // }
            bravos[x + totalCount] = {
              shortName: shortname,
              type: 'Bravo',
              Date: date,
              eventId: eid,
              From: from,
              name: name,
            };
          }
        }
        totalCount += bravoData.data.events.length;
      }
      nextPage = bravoData.data.events.length >= 100 ? true : false;
      pageNum++;
    } catch (err) {
      return { error: 'There was an error parsing BravoData' };
    }
  }
  // Start Bravos sent call
  let BravoSentHeaders = new Headers();
  BravoSentHeaders.append('Content-Type', 'application/json');
  BravoSentHeaders.append('Authorization', 'Bearer ' + token);
  BravoSentHeaders.append('X-NW-Message-ID', message);
  BravoSentHeaders.set('client_id', portalKey);

  let bravoSentData = null;

  // Calculate start date
  let today = new Date(Date.now());
  let two_weeksAgo = today - 12096e5;
  let startDate = formatDate(two_weeksAgo);

  const body3 = {
    shortName: encodeURIComponent(shortName),
    token: encodeURIComponent(bravoAccessToken),
    page: encodeURIComponent('0'),
    startDate: encodeURIComponent(startDate),
  };

  try {
    const response = await fetch(sentBravoAPI, {
      method: 'post',
      headers: BravoSentHeaders,
      body: JSON.stringify(body3),
    });
    if (response.ok) {
      try {
        bravoSentData = await response.json();
      } catch (e) {
        //Response status was OK, but we got an error getting the json data
        console.error('TeamRecognitionService.Bravo error', e);
        return { error: 'There was a problem with the JSON data.' };
      }
    } else {
      return {
        error: 'There was a problem retrieving the Bravo data.',
      };
    }
  } catch (E) {
    //There was an error calling the API
    console.error('TeamRecognitionService.Bravo  error', E);
    return {
      error: 'There was a problem retrieving the Bravo data.',
    };
  }

  if (bravoSentData && bravoSentData.userMessage) {
    //There is an error message in the data that was returned
    return { error: bravoSentData.userMessage };
  }

  if (bravoSentData && bravoSentData.data.count > 0) {
    //data was retrieved and has approval entries

    managerBravos = [];
    //console.log('Return Bravo service ', bravoSentData);
    // bravoSentData = bravoTester;
    let manNum = 0;
    for (let x = 0; x < bravoSentData.data.count; x++) {
      //loop through the Bravo!
      try {
        if (bravoSentData.data.recognitions[0].recognitionType == 'ecard') {
          if (bravoSentData.data.recognitions[0].ecard.length > 0) {
            let shortname = bravoSentData.data.recognitions[0].ecard[x].recipient.emailId;
            shortname = shortname.substring(0, shortname.indexOf('@')).toUpperCase();
            let name = bravoSentData.data.recognitions[0].ecard[x].recipient.name;
            let date = bravoSentData.data.recognitions[0].ecard[x].date;
            // console.log('Return Bravo service ', shortname, date, name);
            managerBravos[manNum++] = {
              shortName: shortname,
              type: 'Bravo',
              Date: date,
              name: name,
            };
          }
        }
      } catch (err) {
        console.log('Error on Bravo data ', err);
        return { error: 'There was a problem parsing Bravo data.' };
      }
    }
  }
  //console.log('Return Bravo service ', managerBravos, bravos);
  const error = null;
  return {
    entries: bravos,
    managerBravos: managerBravos,
    snowError: error,
    error,
  };
};

function encodeKeyValuePair(key, value) {
  return `${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
}

function formatDate(date) {
  var d = new Date(date),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = '0' + month;
  if (day.length < 2) day = '0' + day;

  return [month, day, year].join('');
}
