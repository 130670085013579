import { getRandomString } from '../services/AuthService';
import { getEnvironment } from './EnvironmentVariable';
import config from '../config.json';

// This method deletes entries given an ID
export const deleteLeaderLink = async ID => {
  const environ = getEnvironment();
  let portalApi = config.El_deleteLink + ID;
  let portalKey = config.EffectiveLd_Key;
  if (environ !== 'DEFAULT') {
    portalApi = config.test_El_deleteLink + ID;
    portalKey = config.test_EffectiveLd_Key;
  }
  const userInfo = JSON.parse(sessionStorage.getItem('userinfo'));
  const message = getRandomString(25);
  const token = sessionStorage.getItem('access_token');

  let headers = new Headers();
  // headers.append('Content-Type', 'application/json');
  headers.append('Authorization', 'Bearer ' + token);
  headers.append('X-NW-Message-ID', message);
  headers.set('client_id', portalKey);

  // const body = JSON.stringify({ shortName: userInfo.shortName });
  let data = null;
  try {
    const response = await fetch(portalApi, {
      method: 'delete',
      headers: headers,
    });
    if (response.ok) {
      try {
        const jsondata = await response.json();
        data = jsondata;
      } catch (e) {
        console.log('DeleteEffectiveLeaderLink ' + ID + ' error', e);
        return { error: e };
      }
    }
  } catch (e1) {
    console.log('DeleteEffectiveLeaderLink ' + ID + ' error', e1);
    return { error: e1 };
  }

  return 'Successful delete of ' + ID;
};

// This method updates entries given an ID
export const updateLeaderLink = async input => {
  const environ = getEnvironment();
  let portalApi = config.EL_updateLink;
  let portalKey = config.EffectiveLd_Key;
  if (environ !== 'DEFAULT') {
    portalApi = config.test_EL_updateLink;
    portalKey = config.test_EffectiveLd_Key;
  }
  const userInfo = JSON.parse(sessionStorage.getItem('userinfo'));
  const message = getRandomString(25);
  const token = sessionStorage.getItem('access_token');

  let headers = new Headers();
  headers.append('Content-Type', 'application/json');
  headers.append('Authorization', 'Bearer ' + token);
  headers.append('X-NW-Message-ID', message);
  headers.set('client_id', portalKey);

  const body = {
    id: input.id,
    category: input.category,
    display: input.display,
    url: input.url,
    startDate: input.startDate,
    endDate: input.endDate,
  };
  //console.log('Body of service', body);
  let data = null;
  try {
    const response = await fetch(portalApi, {
      method: 'put',
      headers: headers,
      body: JSON.stringify(body),
    });
    console.log('Response', response);
    if (response.ok) {
      try {
        const jsondata = await response.json();
        data = jsondata;
      } catch (e) {
        console.log('UpdateEffectiveLeaderLink ' + input.ID + ' error', e);
        return { e };
      }
    }
  } catch (e1) {
    console.log('UpdateEffectiveLeaderLink ' + input.ID + ' error', e1);
    return { error: e1 };
  }

  return 'Successful update of ' + input.ID;
};

// This method updates entries given an ID
export const addLeaderLink = async input => {
  const environ = getEnvironment();
  let portalApi = config.EL_addLink;
  let portalKey = config.EffectiveLd_Key;
  if (environ !== 'DEFAULT') {
    portalApi = config.test_EL_addLink;
    portalKey = config.test_EffectiveLd_Key;
  }
  const userInfo = JSON.parse(sessionStorage.getItem('userinfo'));
  const message = getRandomString(25);
  const token = sessionStorage.getItem('access_token');

  let headers = new Headers();
  headers.append('Content-Type', 'application/json');
  headers.append('Authorization', 'Bearer ' + token);
  headers.append('X-NW-Message-ID', message);
  headers.set('client_id', portalKey);
  let body = {};
  if (input.startDate != '' && input.endDate != '') {
    body = {
      category: input.category,
      display: input.display,
      url: input.url,
      startDate: input.startDate + 'T00:00:00',
      endDate: input.endDate + 'T00:00:00',
    };
  } else {
    body = {
      category: input.category,
      display: input.display,
      url: input.url,
    };
  }
  let data = null;
  console.log('request ', headers, body, portalApi);
  try {
    const response = await fetch(portalApi, {
      method: 'post',
      headers: headers,
      body: JSON.stringify(body),
    });
    if (response.ok) {
      try {
        const jsondata = await response.json();
        data = jsondata;
      } catch (e) {
        console.log('ADDEffectiveLeaderLink ' + input + ' error', e);
        return { error: e };
      }
    } else {
      return 'error';
    }
  } catch (e1) {
    console.log('ADDEffectiveLeaderLink ' + input + ' error', e1);
    return { error: e1 };
  }

  return 'Successful add of: ' + data;
};

// This method retrieves all links stored in the database
export const getLeaderLinks = async () => {
  const environ = getEnvironment();
  let portalApi = config.EL_getLinks;
  let portalKey = config.EffectiveLd_Key;
  if (environ !== 'DEFAULT') {
    portalApi = config.test_EL_getLinks;
    portalKey = config.test_EffectiveLd_Key;
  }
  const userInfo = JSON.parse(sessionStorage.getItem('userinfo'));
  const message = getRandomString(25);
  const token = sessionStorage.getItem('access_token');

  let headers = new Headers();
  // headers.append('Content-Type', 'application/json');
  headers.append('Authorization', 'Bearer ' + token);
  headers.append('X-NW-Message-ID', message);
  headers.set('client_id', portalKey);

  // const body = JSON.stringify({ shortName: userInfo.shortName });
  let data = null;
  try {
    const response = await fetch(portalApi, {
      method: 'get',
      headers: headers,
    });
    if (response.ok) {
      try {
        const jsondata = await response.json();
        data = jsondata;
      } catch (e) {
        console.log('getAllEffectiveLeaderLinks error', e);
        return { error: e };
      }
    }
  } catch (e1) {
    console.log('getAllEffectiveLeaderLinks error', e1);
    return { error: e1 };
  }
  let output = [];
  // Parse out table data
  data.sort(custom_sort);

  if (data) {
    let strongerTeam_title = '',
      LeaderRetention_title = '',
      Better1on1s_title = '',
      Onboard_title = '',
      OtherLeaders_title = '',
      Change_title = '',
      strongerTeam = [],
      LeaderRetention = [],
      Better1on1s = [],
      Onboard = [],
      OtherLeaders = [],
      Change = [],
      news = [],
      idList = [];

    let count_strongerTeam = 0,
      count_LeaderRetention = 0,
      count_Better1on1s = 0,
      count_Onboard = 0,
      count_OtherLeaders = 0,
      count_Change = 0,
      count_news = 0;

    for (let i = 0; i < data.length; i++) {
      // Parse titles
      if (data[i].category === 'Summary strongerTeam') {
        strongerTeam_title = { Summary: data[i].display, ID: data[i].id };
      }
      if (data[i].category === 'Summary LeaderRetention') {
        LeaderRetention_title = { Summary: data[i].display, ID: data[i].id };
      }
      if (data[i].category === 'Summary Better1on1s') {
        Better1on1s_title = { Summary: data[i].display, ID: data[i].id };
      }
      if (data[i].category === 'Summary Onboard') {
        Onboard_title = { Summary: data[i].display, ID: data[i].id };
      }
      if (data[i].category === 'Summary OtherLeaders') {
        OtherLeaders_title = { Summary: data[i].display, ID: data[i].id };
      }
      if (data[i].category === 'Summary Change') {
        Change_title = { Summary: data[i].display, ID: data[i].id };
      }
      // Parse links
      if (data[i].category === 'strongerTeam') {
        if (data[i].startDate && data[i].endDate) {
          strongerTeam[count_strongerTeam] = {
            ID: data[i].id,
            Display: data[i].display,
            Link: data[i].url,
            startDate: data[i].startDate,
            endDate: data[i].endDate,
          };
        } else {
          strongerTeam[count_strongerTeam] = {
            ID: data[i].id,
            Display: data[i].display,
            Link: data[i].url,
          };
        }
        count_strongerTeam++;
      }
      if (data[i].category === 'LeaderRetention') {
        if (data[i].startDate && data[i].endDate) {
          LeaderRetention[count_LeaderRetention] = {
            ID: data[i].id,
            Display: data[i].display,
            Link: data[i].url,
            startDate: data[i].startDate,
            endDate: data[i].endDate,
          };
        } else {
          LeaderRetention[count_LeaderRetention] = {
            ID: data[i].id,
            Display: data[i].display,
            Link: data[i].url,
          };
        }
        count_LeaderRetention++;
      }
      if (data[i].category === 'Better1on1s') {
        if (data[i].startDate && data[i].endDate) {
          Better1on1s[count_Better1on1s] = {
            ID: data[i].id,
            Display: data[i].display,
            Link: data[i].url,
            startDate: data[i].startDate,
            endDate: data[i].endDate,
          };
        } else {
          Better1on1s[count_Better1on1s] = {
            ID: data[i].id,
            Display: data[i].display,
            Link: data[i].url,
          };
        }
        count_Better1on1s++;
      }
      if (data[i].category === 'Onboard') {
        if (data[i].startDate && data[i].endDate) {
          Onboard[count_Onboard] = {
            ID: data[i].id,
            Display: data[i].display,
            Link: data[i].url,
            startDate: data[i].startDate,
            endDate: data[i].endDate,
          };
        } else {
          Onboard[count_Onboard] = { ID: data[i].id, Display: data[i].display, Link: data[i].url };
        }
        count_Onboard++;
      }
      if (data[i].category === 'OtherLeaders') {
        if (data[i].startDate && data[i].endDate) {
          OtherLeaders[count_OtherLeaders] = {
            ID: data[i].id,
            Display: data[i].display,
            Link: data[i].url,
            startDate: data[i].startDate,
            endDate: data[i].endDate,
          };
        } else {
          OtherLeaders[count_OtherLeaders] = {
            ID: data[i].id,
            Display: data[i].display,
            Link: data[i].url,
          };
        }
        count_OtherLeaders++;
      }
      if (data[i].category === 'Change') {
        if (data[i].startDate && data[i].endDate) {
          Change[count_Change] = {
            ID: data[i].id,
            Display: data[i].display,
            Link: data[i].url,
            startDate: data[i].startDate,
            endDate: data[i].endDate,
          };
        } else {
          Change[count_Change] = { ID: data[i].id, Display: data[i].display, Link: data[i].url };
        }
        count_Change++;
      }
      if (data[i].category === 'news') {
        if (data[i].startDate && data[i].endDate) {
          news[count_news] = {
            ID: data[i].id,
            Display: data[i].display,
            Link: data[i].url,
            Added: data[i].entryDate,
            startDate: data[i].startDate,
            endDate: data[i].endDate,
          };
        } else {
          news[count_news] = {
            ID: data[i].id,
            Display: data[i].display,
            Link: data[i].url,
            Added: data[i].entryDate,
          };
        }
        count_news++;
      }
      idList[i] = data[i].id;
    }
    output = {
      news,
      strongerTeam: { Summary: strongerTeam_title, Links: strongerTeam },
      LeaderRetention: { Summary: LeaderRetention_title, Links: LeaderRetention },
      Better1on1s: { Summary: Better1on1s_title, Links: Better1on1s },
      Onboard: { Summary: Onboard_title, Links: Onboard },
      Change: { Summary: Change_title, Links: Change },
      OtherLeaders: { Summary: OtherLeaders_title, Links: OtherLeaders },
      idList,
      data,
    };
  }
  console.log('Effective Leader Service ', output);
  return output;
};

// function custom_sort(a, b) {
//   return new Date(a.entryDate).getTime() - new Date(b.entryDate).getTime();
// }

function custom_sort(a, b) {
  return a.id - b.id;
}
