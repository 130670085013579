import { isEmpty } from 'lodash';
import React, { Component } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import LeaderTransitionChecklist from './components/LeaderTransitionChecklist.jsx';
import DisplayAnnouncement from './components/announcementDisplay';
import Callback from './components/callback';
import ErrorPage from './components/errorPage';
import Home from './components/home';
import NotAuthorized from './components/notAuthorized';
import config from './config.json';
import { UserIsAdmin, getAzureAuthUrl } from './services/AuthService';
import { addLeaderLink, deleteLeaderLink, getLeaderLinks, updateLeaderLink } from './services/EffectiveLeaderService';
import { getManagerTeam, getOrgChart } from './services/OrgChartService';
import { getBravoData } from './services/TeamRecognitionService';
import { isAdminUser } from './services/adminUserService';
import { getAnnouncement } from './services/announcementService';
import { getApprovals } from './services/approvalsService';
import { getAribaContractApprovals } from './services/aribaContractService';
import { getAribaProcurementApprovals } from './services/aribaProcurementService';
import { getCalendar } from './services/calendarService';
import { getAdminAssistants, getCallupData, getCallupShortName } from './services/callupService';
import { getFOW } from './services/fowService';
import { getIIQApprovals } from './services/iiqService';
import { getOTApprovals } from './services/overtimeService';
import { GetPhotos } from './services/peoplePhotoService.jsx';
import { addSearchHistory } from './services/searchHistoryService';
import { getFromServiceNow } from './services/serviceNowService';
import { getTeam } from './services/teamService';
import { getTraining } from './services/trainingService';


import 'react-toastify/dist/ReactToastify.css';
import './Announcements.css';
import './App.css';
import './EffectiveLeader.css';
import './Linksbar.css';
import './Portals.css';
import './TeamRecognition.css';
import './approvals.css';
import './calendar.css';
import './fow.css';
import './idrive.css';
import './main.css';
import './menu.css';
import './myteam.css';

// import { idText } from "typescript";

const minCharactersToSearch = 2;
const maxServiceNowResults = config.maxServiceNowResults;
// let fetchingtimeOff = false;
class App extends Component {
  state = {
    userPhoto: null,
    user: null,
    searchQuery: '',
    searchData: [],
    isAdmin: false,
    isSepm: false,
    searching: true,
    searchingCallup: true,
    intervalId: 0,
    intervalId2: 0,
    announcementId: 0,
    workingAnnouncement: null,
    unfilteredNumber: 0,
    filteredNumber: 0,
    contact: null,
    showPortletSelector: 'N',
    searchHistoryId: 0,
    callUpSNData: [],
    shortNameResults: [],
    lastNameResults: [],
    firstNameResults: [],
    preferredNameResults: [],
    preferredNameResultsA: [],
    Callup_Image: [],
    HRK_Error: false,
    People_Error: false,
    HRK_Display: true,
    People_Display: true,
    menuExpanded: false,
    team: null,
    teamMax: 5,
    approvalMax: 5,
    approvals: null,
    // Approvals performance enhancements
    snowApprovals: null,
    aribaApprovals: null,
    iiqApprovals: null,
    otApprovals: null,
    aribaContractApprovals: null,
    // End performance enhancements
    bravoData: null,
    leaderLinks: null,
    leaderLinkUpdates: null,
    approvalsLoaded: false,
    timeoffMax: 5,
    birthdayMax: 5,
    orgChart_Image: [],
    holidayMax: 5,
    anniversaryMax: 5,
    calendarRange: '2',
    searchDataAll: [],
    restrictKnowledgeData: 10,
    orderApprovals: [],
    AdminNameData: [],
    orgChartData: null,
    Org_people: null,
    WorkGroupData: null,
    displayChecklist: false
  };

  componentDidMount() {
    let intervalId = setInterval(this.checkAdmin, 100);
    this.setState({ intervalId });
    this.fowFetched = false;
    this.fetchingTeam = false;
    this.fetchingFOW = false;
    this.fetchingTeamPhotos = false;
    this.teamPhotosRetrieved = false;
    this.fetchingIdrive = false;
    this.fowRetrieved = false;
    this.fetchingUserPhoto = false;
    this.fetchingTraining = false;
    this.fetchingCalendar = false;
    this.fetchingBravos = false;
    this.fetchingLeaderLinks = false;
    this.addingEffLdLinks = false;
    this.updatingLdLinks = false;
    this.deletingLdLinks = false;
    this.fetchingSnowApprovals = false;
    this.fetchingAribaApprovals = false;
    this.fetchingIIQApprovals = false;
    this.fetchingOTApprovals = false;
    this.fetchingAribaContractApprovals = false;
    this.fetchingadminName = false;
    this.fetchingOrgChart = false;
    this.fetchingWorkGroup = false;
    //this.checkSepm = true;
  }

  handleRemoveApproval = idx => {
    //Not actually doing anything yet
    // console.log("App.handleRemoveApproval ", idx);
    let { approvals } = this.state;
    const approvalEntries = approvals.entries;
    approvalEntries.splice(idx, 1);
    approvals.entries = approvalEntries;
    this.setState({ approvals });
  };

  handleChangeMenuExpanded = () => {
    const { menuExpanded } = this.state;
     console.log("App.handleChangeMenuExpanded menuExpanded", menuExpanded);
    if (menuExpanded) {
      //The menu is currently expanded
      document.getElementById('mobileMenuHideShowButton').style.transform = 'none';
    } else {
      //The menu is currently contracted
      document.getElementById('mobileMenuHideShowButton').style.transform = 'rotate(0.5turn)';
    }

    this.setState({ menuExpanded: !menuExpanded });
  };

  handleUserUpdate(user) {
    this.setState({ user });
    this.fetchingAdmin = false;
    let intervalId = setInterval(this.checkAdmin, 100);
    this.setState({ intervalId });
  }

  handleAnnouncementIdChange(announcementId) {
    this.setState({ announcementId });
    this.retrieveWorkingAnnouncement(announcementId);
  }

  handleWorkingAnnouncementChange = workingAnnouncement => {
    this.setState({ workingAnnouncement });
  };

  handleChangeMaxTeam = teamMax => {
    this.setState({ teamMax });
  };

  handleChangeMaxApprovals = approvalMax => {
    this.setState({ approvalMax });
  };

  handleChangeMaxTimeOff = timeoffMax => {
    this.setState({ timeoffMax });
  };
  handleChangeMaxBirthdays = birthdayMax => {
    this.setState({ birthdayMax });
  };
  handleChangeMaxHolidays = holidayMax => {
    this.setState({ holidayMax });
  };
  handleChangeMaxAnniversary = anniversaryMax => {
    this.setState({ anniversaryMax });
  };
  handleChangeCalendarRange = async calendarRange => {
    this.setState({ calendarData: null, calendarRange });
    await this.fetchCalendarData(calendarRange);
  };

  retrieveWorkingAnnouncement = async announcementId => {
    if (announcementId > 0) {
      //AnnouncementId is greater than zero so get the announcment
      const workingAnnouncement = await getAnnouncement(announcementId);
      this.setState({ workingAnnouncement });
    } else {
      //The announcment is not greater than zero so set the workingAnnouncement to an empty object
      const workingAnnouncement = {
        id: 0,
        startOnDate: new Date().toISOString(),
        endOnDate: new Date().toISOString(),
        link: '',
        img: null,
        approvedBy: '',
        priority: '1',
        button1Text: '',
        button2Text: '',
        button1Link: '',
        button2Link: '',
        headline: '',
        summaryText: '',
      };
      this.setState({ workingAnnouncement });
    }
  };

  retrieveEffectiveLeaderData = async refreshing => {
    //console.log('Refreshing EffectiveLeader', refreshing);
    if (refreshing) {
      this.fetchingLeaderLinks = false;
    }
    if (!this.fetchingLeaderLinks) {
      this.fetchEffectiveLeaderData();
    }
  };

  fetchEffectiveLeaderData = async () => {
    //console.log('retrieveEffectiveLeaderData', this.fetchingLeaderLinks);
    this.fetchingLeaderLinks = true;
    const dataRetrieved = await getLeaderLinks();

    let data = null;
    if (dataRetrieved.userMessage) {
      if (dataRetrieved.userMessage) {
        console.log(
          'APP - retrieveEffectiveLeaderData -- effectiveLeader data contains a user message',
        );
        data = { error: dataRetrieved.userMessage };
        toast.error('Unable to retrieve effective leader' + dataRetrieved.userMessage);
      }
    } else {
      console.log('APP - retrieveEffectiveLeaderData -- get effective leader links', data);
      data = dataRetrieved;
    }
    console.log('Fetch data retreived', data);
    this.setState({ leaderLinks: data, leaderLinksLoaded: true });
  };

  rankLoadTimes(type) {
    const { orderApprovals } = this.state;
    let temp = orderApprovals;
    if (!temp.includes(type)) {
      temp.push(type);
    }
    this.setState({ orderApprovals: temp });
  }

  // Get all SNOW approvals
  retrieveSnowApprovalData = async refreshing => {
    //console.log('Refreshing SnowData', refreshing);
    if (refreshing) {
      this.fetchingSnowApprovals = false;
    }
    if (!this.fetchingSnowApprovals) {
      this.fetchSnowApprovalData();
    }
  };

  fetchSnowApprovalData = async () => {
    console.log('retrieveSnowApprovalData', this.fetchingSnowApprovals);
    this.fetchingSnowApprovals = true;
    const dataRetrieved = await getApprovals();

    let data = null;
    if (dataRetrieved.userMessage) {
      if (dataRetrieved.userMessage) {
        console.log('APP - retrieveSnowApprovalData -- SnowApproval data contains a user message');
        data = { error: dataRetrieved.userMessage };
        toast.error('Unable to retrieve Snow Approvals' + dataRetrieved.userMessage);
      }
    } else {
      console.log('APP - retrieveSnowApprovalData -- get SNOW approvals', data);
      data = dataRetrieved;
    }
    console.log('Fetch data retreived', data);
    this.setState({ snowApprovals: data });
    this.rankLoadTimes('SNOW');
  };
  // End SNOW
  // Get all Ariba Approvals
  retrieveAribaApprovalData = async refreshing => {
    //console.log('Refreshing AribaData', refreshing);
    if (refreshing) {
      this.fetchingAribaApprovals = false;
    }
    if (!this.fetchingAribaApprovals) {
      this.fetchAribaApprovalData();
    }
  };

  fetchAribaApprovalData = async () => {
    console.log('retrieveAribaApprovalData', this.fetchingAribaApprovals);
    this.fetchingAribaApprovals = true;
    const dataRetrieved = await getAribaProcurementApprovals('requisitions');

    let data = null;
    if (dataRetrieved.userMessage) {
      if (dataRetrieved.userMessage) {
        console.log(
          'APP - retrieveAribaApprovalData -- AribaApproval data contains a user message',
        );
        data = { error: dataRetrieved.userMessage };
        toast.error('Unable to retrieve Ariba Approvals' + dataRetrieved.userMessage);
      }
    } else {
      console.log('APP - retrieveAribaApprovalData -- get Ariba approvals', data);
      data = dataRetrieved;
    }
    console.log('Fetch data retreived', data);
    this.setState({ aribaApprovals: data });
    this.rankLoadTimes('Ariba');
  };

  retrieveAribaContractApprovalData = async refreshing => {
    //console.log('Refreshing AribaContractData', refreshing);
    if (refreshing) {
      this.fetchingAribaContractApprovalData = false;
    }
    if (!this.fetchingAribaContractApprovalData) {
      this.fetchAribaContractApprovalData();
    }
  };

  fetchAribaContractApprovalData = async () => {
    //console.log('retrieveAribaContractApprovalData', this.fetchingAribaContractApprovalData);
    this.fetchingAribaContractApprovalData = true;
    const dataRetrieved = await getAribaContractApprovals();

    let data = null;
    if (dataRetrieved.userMessage) {
      if (dataRetrieved.userMessage) {
        console.log(
          'APP - retrieveAribaContractApprovalData -- AribaContractApproval data contains a user message',
        );
        data = { error: dataRetrieved.userMessage };
        toast.error('Unable to retrieve Ariba contract Approvals' + dataRetrieved.userMessage);
      }
    } else {
      console.log('APP - retrieveAribaContractApprovalData -- get Ariba contract approvals', data);
      data = dataRetrieved;
    }
    console.log('Fetch data retreived', data);
    this.setState({ aribaContractApprovals: data });
    this.rankLoadTimes('Ariba');
  };
  // End Ariba
  // Get all IIQ Approvals
  retrieveIIQApprovalData = async refreshing => {
    //console.log('Refreshing IIQData', refreshing);
    if (refreshing) {
      this.fetchingIIQApprovals = false;
    }
    if (!this.fetchingIIQApprovals) {
      this.fetchIIQApprovalData();
    }
  };

  fetchIIQApprovalData = async () => {
    console.log('retrieveIIQApprovalData', this.fetchingIIQApprovals);
    this.fetchingIIQApprovals = true;
    const dataRetrieved = await getIIQApprovals();

    let data = null;
    if (dataRetrieved.userMessage) {
      if (dataRetrieved.userMessage) {
        console.log('APP - retrieveIIQApprovalData -- IIQApproval data contains a user message');
        data = { error: dataRetrieved.userMessage };
        toast.error('Unable to retrieve IIQ Approvals' + dataRetrieved.userMessage);
      }
    } else {
      console.log('APP - retrieveIIQApprovalData -- get IIQ approvals', data);
      data = dataRetrieved;
    }
    console.log('Fetch data retreived', data);
    this.setState({ iiqApprovals: data });
    this.rankLoadTimes('IIQ');
  };
  // End IIQ
  // Get all OT Approvals from Workday
  retrieveOTApprovalData = async refreshing => {
    console.log('Refreshing OTData', refreshing);
    if (refreshing) {
      this.fetchingOTApprovals = false;
    }
    if (!this.fetchingOTApprovals) {
      this.fetchOTApprovalData();
    }
  };

  fetchOTApprovalData = async () => {
    console.log('retrieveOTApprovalData', this.fetchingOTApprovals);
    this.fetchingOTApprovals = true;
    const dataRetrieved = await getOTApprovals();

    let data = null;
    if (dataRetrieved.userMessage) {
      if (dataRetrieved.userMessage) {
        console.log('APP - retrieveOTApprovalData -- OTApproval data contains a user message');
        data = { error: dataRetrieved.userMessage };
        toast.error('Unable to retrieve OT Approvals' + dataRetrieved.userMessage);
      }
    } else {
      console.log('APP - retrieveOTApprovalData -- get OT approvals', data);
      data = dataRetrieved;
    }
    console.log('Fetch data retreived', data);
    this.setState({ otApprovals: data });
    this.rankLoadTimes('OT');
  };
  // End OT Approvals

  checkAdmin = () => {
    const idToken = sessionStorage.getItem('access_token');
    // const idToken = localStorage.getItem("idToken");
    if (idToken) {
      //The idToken exists in the Session Storage
      clearInterval(this.state.intervalId);
      this.getAdmin();
      this.checkSepm();
    }
  };

  getAdmin = async () => {
    if (!this.fetchingAdmin) {
      //Not currently fetching the user
      this.fetchingAdmin = true;
      const contact = await isAdminUser();
      if (contact.errorMessage) {
        //The contact object contains an error Message
        console.error(contact.errorMessage.userMessage);
        let attempts = localStorage.getItem('loginAttempt');
        let tryAgain = true;
        if (attempts) {
          //loginAttempt exists in localStorage
          if (parseInt(attempts) > 0) {
            //loginAttempt is greater than zero
            toast('contact.errorMessage.userMessage');
            tryAgain = false;
          }
        }
        if (tryAgain) {
          //tryAgain is true so go to Azure login again
          localStorage.setItem('loginAttempt', '1');
          sessionStorage.clear();
          const url = getAzureAuthUrl();
          console.log('home.componentDidMount azure url', url);
          window.location = url;
        }
      } else {
        //The contact object did not have an error message
        localStorage.setItem('loginAttempt', '0');
        // console.log("App.getAdmin returned this", contact);
        sessionStorage.setItem('contact', JSON.stringify(contact));
        // if (contact && contact.isPortalAdmin === "Y") {
        //   this.setState({ isAdmin: true, contact });
        // } else {
        //   this.setState({ isAdmin: false, contact });
        // }
        const isAdmin = UserIsAdmin();
        this.setState({ isAdmin, contact });
        // let intervalId2 = setInterval(this.checkTeam, 100);
        // this.setState({ intervalId2 });
        setTimeout(this.checkTeam, 100);
        setTimeout(this.checkApprovals, 100);
        setTimeout(this.checkCalendar, 100);
        this.fetchingAdmin = false;
        this.getUserPhoto();
      }
    }
  };

  getUserPhoto = async () => {
    if (!this.fetchingUserPhoto) {
      //Not currently retrieving User Photo
      const { contact } = this.state;
      if (contact) {
        //contact information has been populated
        this.fetchingUserPhoto = true;
        if (!this.state.userPhoto) {
          //userPhoto has not been populated
          const data = [{ emplid: contact.emplid }];
          let Callup_Image = [];
          Callup_Image = await GetPhotos(data);
          if (Callup_Image && Callup_Image.length > 0) {
            const userPhoto = Callup_Image[0].Image;
            this.setState({ userPhoto });
          }
        }
      } else {
        //contact data not populated yet, try again in 1 second
        setTimeout(this.getUserPhoto, 1000);
      }
    }
  };

  deleteEffLdLink = async ID => {
    let response = await deleteLeaderLink(ID);
    if (response.userMessage) {
      console.log('Error deleting entry for Effective Leader Database', ID);
    } else {
      console.log('Successful Delete of entry', ID);
    }
  };

  addEffLdLink = async (category, display, url, startDate, endDate) => {
    let input = { category, display, url, startDate, endDate };
    let response = await addLeaderLink(input);
    if (response.userMessage) {
      console.log('Error adding entry to Effective Leader Database', response);
    } else {
      console.log('Successful add of entry', response);
    }
  };

  checkTeam = () => {
    const idToken = sessionStorage.getItem('access_token');
    if (idToken) {
      //The access_token exists in sessionStorage so retreive teh team data
      // clearInterval(this.state.intervalId2);
      this.retrieveTeam();
    } else {
      //The access_token is not in sessionStorage
      setTimeout(this.checkTeam, 100);
    }
  };

  refreshTeam = () => {
    this.fetchingTeam = false;
    this.retrieveTeam();
  };

  retrieveTeam = async () => {
    // clearInterval(this.state.intervalId2);
    if (!this.fetchingTeam) {
      //Not currently fetching Team data
      this.fetchingTeam = true;
      const team = await getTeam();
      if (team) {
        //team data was retrieved
        if (team.error) {
          //The team object has an error
          this.setState({ team });
        } else {
          //no error retrieving team
          if (team.members) {
            try {
              for (let member of team.members) {
                member.display = false;
                member.userPrincipalName = member.shortName + '@nationwide.com';
              }
              // for (let i = 0; i < team.members.length; i++) {
              //   team.members[i].display = false;
              //   team.members[i].userPrincipalName = team.members[i].shortName + '@nationwide.com';
              // }
            } catch (e) {
              console.error('App.retrieveTeam There was a problem iterating the team members');
            }

            this.setState({ team });
            this.fetchingFOW = false;
            //start fetching FOW data for the team
            this.retrieveFOWData(true);
            //start fetching Training data for the team
            this.retrieveTrainingData(true);
            //start fetching Calendar data
            // this.retrieveCalendarData(true);
            this.teamPhotosRetrieved = false;
            //start fetching the photo's of the team members
            await this.retrievePhotos();
          }
        }
      }
    }
  };

  checkApprovals = () => {
    const idToken = sessionStorage.getItem('access_token');
    if (idToken) {
      //idToken exist so retrieve the Approvals
      this.retrieveApprovals();
    } else {
      setTimeout(this.checkApprovals, 100);
    }
  };

  retrieveApprovals = async () => {
    this.setState({
      snowApprovals: null,
      aribaApprovals: null,
      iiqApprovals: null,
      otApprovals: null,
      aribaContractApprovals: null,
    });
    // const approvals = await getApprovals();
    this.fetchAribaApprovalData();
    this.fetchSnowApprovalData();
    this.fetchIIQApprovalData();
    this.fetchOTApprovalData();
    this.fetchAribaContractApprovalData();
    console.log('App.retrieveApprovals start approvals');
  };

  retrieveBravos = async refreshing => {
    console.log('refreshing BravoData', refreshing);
    if (refreshing) {
      this.fetchingBravos = false;
    } else {
      if (!this.fetchingBravos) {
        this.fetchBravoData();
      }
    }
  };

  retrieveAribaApprovals = async () => {
    // console.log("App.retrieveAribaApprovals start");
    const { approvals } = this.state;
    const aribaApprovals = await getAribaContractApprovals();
    console.log('App.retrieveAribaApprovals approvals', approvals);
    console.log('App.retrieveAribaApprovals aribaApprovals', aribaApprovals);
    let result = { entries: null, snowError: approvals.snowError, error: approvals.error };

    if (approvals) {
      //approvals object exists
      // console.log("App.retrieveAribaApprovals approvals object exists");
      result = approvals;
    }
    if (aribaApprovals) {
      //ariba approvals exists
      // console.log("App.retrieveAribaApprovals ariba approvals object exists");
      if (approvals.entries && approvals.entries.length > 0) {
        //approvals has entries array
        // console.log("App.retrieveAribaApprovals approvals has entries array");
        if (aribaApprovals.entries && aribaApprovals.entries.length > 0) {
          //ariba approvals has entries array
          // console.log(
          //   "App.retrieveAribaApprovals ariba approvals has entries array ",
          //   result.entries,
          //   aribaApprovals.entries
          // );
          const entries = result.entries.concat(aribaApprovals.entries);
          // console.log(
          //   "App.retrieveAribaApprovals result.entries concat",
          //   entries
          // );
          result.entries = entries;
        }
      } else {
        //approvals did not have any entries
        // console.log(
        //   "App.retrieveAribaApprovals approvals did not have any entries "
        // );
        result.entries = aribaApprovals.entries;
      }
      if (aribaApprovals.error) {
        //The aribaApprovals object has an error
        approvals.aribaRequisitionError = aribaApprovals.error;
      }
    }
    if (aribaApprovals.error && approvals.snowError) {
      //Both approval sources have an error, so set the error message for the object
      approvals.error = 'Unable to retrieve approvals from ServiceNow or Ariba';
    } else {
      //At least one of the approval sources is error free
      approvals.error = null;
    }
    // console.log("App.retrieveAribaApprovals result", result);
    this.setState({ approvals: result });
    await this.retrieveAribaProcurementApprovals();
  };

  retrieveIIQApprovals = async () => {
    const { approvals } = this.state;
    const iiqApprovals = await getIIQApprovals();
    // console.log("App.retrieveIIQApprovals iiqApprovals", iiqApprovals);
    let result = {
      entries: null,
      snowError: approvals.snowError,
      error: approvals.error,
      aribaInvoiceError: approvals.aribaInvoiceError,
      aribaProcurementError: approvals.aribaProcurementError,
      aribaRequisitionError: approvals.aribaRequisitionError,
      iiqError: null,
      otError: null,
    };
    if (approvals) {
      //approvals object exists, so set it to the current results
      result = approvals;
    }
    if (iiqApprovals) {
      //IIQ approvals exists
      if (approvals.entries && approvals.entries.length > 0) {
        //approvals has entries array
        if (iiqApprovals.entries && iiqApprovals.entries.length > 0) {
          //IIQ has entries array so add them to the current results entries
          const entries = result.entries.concat(iiqApprovals.entries);
          result.entries = entries;
        }
      } else {
        //approvals did not have any entries so set the current results entries to the IIQ entries
        result.entries = iiqApprovals.entries;
      }
      if (iiqApprovals.error) {
        //The iiqApprovals object has an error
        result.iiqError = iiqApprovals.error;
      }
    }
    if (
      result.iiqError &&
      result.aribaInvoiceError &&
      result.aribaRequisitionError &&
      result.snowError
    ) {
      //All approval sources have an error, so set the error message for the object
      result.error = 'Unable to retrieve approvals from ServiceNow, Ariba, and IIQ';
    } else {
      //At least one of the approval sources is error free
      result.error = null;
    }
    this.setState({ approvals: result });
    await this.retrieveOTApprovals();
  };

  retrieveOTApprovals = async () => {
    const { approvals } = this.state;
    const otApprovals = await getOTApprovals();
    console.log('App.retrieveOTApprovals otApprovals', otApprovals);
    let result = {
      entries: null,
      snowError: approvals.snowError,
      error: approvals.error,
      aribaInvoiceError: approvals.aribaInvoiceError,
      aribaProcurementError: approvals.aribaProcurementError,
      aribaRequisitionError: approvals.aribaRequisitionError,
      iiqError: approvals.iiqError,
      otError: null,
    };
    if (approvals) {
      //approvals object exists, so set it to the current results
      result = approvals;
    }
    if (otApprovals) {
      //IIQ approvals exists
      if (approvals.entries && approvals.entries.length > 0) {
        //approvals has entries array
        if (otApprovals.entries && otApprovals.entries.length > 0) {
          //IIQ has entries array so add them to the current results entries
          const entries = result.entries.concat(otApprovals.entries);
          result.entries = entries;
        }
      } else {
        //approvals did not have any entries so set the current results entries to the IIQ entries
        result.entries = otApprovals.entries;
      }
      if (otApprovals.error) {
        //The iiqApprovals object has an error
        result.otError = otApprovals.error;
      }
    }
    if (
      result.iiqError &&
      // result.aribaInvoiceError &&
      result.aribaProcurementError &&
      result.aribaRequisitionError &&
      result.snowError &&
      result.otError
    ) {
      //All approval sources have an error, so set the error message for the object
      console.log(
        'App.retrieveIIQApprovals All approval sources have an error, so set the error message for the object',
      );
      result.error = 'Unable to retrieve approvals from ServiceNow, Ariba, IIQ and Overtime';
    } else {
      //At least one of the approval sources is error free
      console.log('App.retrieveIIQApprovals   at least one approval source is error free', result);
      result.error = null;
    }
    this.setState({ approvals: result, approvalsLoaded: true });
  };
  retrieveAribaProcurementApprovals = async () => {
    // console.log("App.retrieveAribaApprovals start");
    const { approvals } = this.state;

    /*DIGITAL-6433 (LDCPROJ: LEADER CONNECT - APPROVALS AND ACTIONS - 
      REMOVE/UPDATE API CALLS AND ERROR MESSAGING FOR ARIBA 'INVOICES')
      START HERE*/
    // const aribaInvoiceApprovals = await getAribaProcurementApprovals(
    //   "invoices"
    // );
    /*DIGITAL-6433 (LDCPROJ: LEADER CONNECT - APPROVALS AND ACTIONS - 
      REMOVE/UPDATE API CALLS AND ERROR MESSAGING FOR ARIBA 'INVOICES')
      STOP HERE*/
    const aribalRequisitionApprovals = await getAribaProcurementApprovals('requisitions');

    let result = {
      entries: null,
      snowError: approvals.snowError,
      error: approvals.error,
      aribaInvoiceError: approvals.aribaInvoiceError,

      aribaRequisitionError: approvals.aribaRequisitionError,
      iiqError: approvals.iiqError,
    };

    if (approvals) {
      //approvals object exists, so set it to the current results
      result = approvals;
    }
    /*DIGITAL-6433 (LDCPROJ: LEADER CONNECT - APPROVALS AND ACTIONS - 
      REMOVE/UPDATE API CALLS AND ERROR MESSAGING FOR ARIBA 'INVOICES')
      START HERE*/
    // if (aribaInvoiceApprovals) {
    //   //ariba invoice approvals exists
    //   if (approvals.entries && approvals.entries.length > 0) {
    //     //approvals has entries array
    //     if (
    //       aribaInvoiceApprovals.entries &&
    //       aribaInvoiceApprovals.entries.length > 0
    //     ) {
    //       //ariba invoices has entries array so add them to the current results entries
    //       const entries = result.entries.concat(aribaInvoiceApprovals.entries);
    //       result.entries = entries;
    //     }
    //   } else {
    //     //approvals did not have any entriesk so set the current results entries to the invoices entries
    //     result.entries = aribaInvoiceApprovals.entries;
    //   }
    //   if (aribaInvoiceApprovals.error) {
    //     //The aribaInvoiceApprovals object has an error
    //     result.aribaInvoiceError = aribaInvoiceApprovals.error;
    //   }
    // }
    /*DIGITAL-6433 (LDCPROJ: LEADER CONNECT - APPROVALS AND ACTIONS - 
      REMOVE/UPDATE API CALLS AND ERROR MESSAGING FOR ARIBA 'INVOICES')
      STOP HERE*/
    if (aribalRequisitionApprovals) {
      //ariba Requisition approvals exists
      if (approvals.entries && approvals.entries.length > 0) {
        //approvals has entries array
        if (aribalRequisitionApprovals.entries && aribalRequisitionApprovals.entries.length > 0) {
          //ariba Requisition approvals has entries array so add them the the current result entries
          const entries = result.entries.concat(aribalRequisitionApprovals.entries);
          result.entries = entries;
        }
      } else {
        //approvals did not have any entries
        /*DIGITAL-6433 (LDCPROJ: LEADER CONNECT - APPROVALS AND ACTIONS - 
      REMOVE/UPDATE API CALLS AND ERROR MESSAGING FOR ARIBA 'INVOICES')
      START HERE*/
        // if (
        //   aribaInvoiceApprovals &&
        //   aribaInvoiceApprovals.entries &&
        //   aribaInvoiceApprovals.entries.length > 0
        // ) {
        //   //Appoval Invoices had entries so add the Requisition Approvals to the current results
        //   const entries = result.entries.concat(
        //     aribalRequisitionApprovals.entries
        //   );
        //   result.entries = entries;
        // } else {
        //   //Approval Invoices did no have entries so set the Requisition Approvals to the current results
        //   result.entries = aribalRequisitionApprovals.entries;
        // }
        result.entries = aribalRequisitionApprovals.entries;
        /*DIGITAL-6433 (LDCPROJ: LEADER CONNECT - APPROVALS AND ACTIONS - 
      REMOVE/UPDATE API CALLS AND ERROR MESSAGING FOR ARIBA 'INVOICES')
      STOP HERE*/
      }
      if (aribalRequisitionApprovals.error) {
        //The aribalRequisitionApprovals object has an error
        result.aribaProcurementError = aribalRequisitionApprovals.error;
      }
    }
    result.snowError = approvals.snowError;

    // console.log("App.retrieveAribaApprovals result", result);
    this.setState({ approvals: result });
    await this.retrieveIIQApprovals();
  };

  retrievePhotos = async () => {
    if (!this.fetchingTeamPhotos && !this.teamPhotosRetrieved) {
      //Not already fetching photo's and Photos do not exist
      this.fetchingTeamPhotos = true;
      // let teamImages = [];
      const { team } = this.state;
      //TEST
      for (let row = 0; row < team.members.length; row++) {
        const member = team.members[row];
        const data = [{ emplid: member.emplid }];
        let Callup_Image = [];
        Callup_Image = await GetPhotos(data);
        if (Callup_Image && Callup_Image.length > 0) {
          //The Callup_image object exists and has data
          team.members[row].image = Callup_Image[0].Image;
          if (Callup_Image[0].userPrincipalName) {
            //A userPrincipalName was returned so set its value
            team.members[row].userPrincipalName = Callup_Image[0].userPrincipalName;
          }
          if (!team.members[row].workPhone && Callup_Image[0].businessPhone) {
            //Workphone did not exist, but businessPhone was retreived for this user
            team.members[row].workPhone = Callup_Image[0].businessPhone;
          }
        }
        //console.log("this is the callup Image ", Callup_Image)
        this.setState({ team });
      }

      //This code was used previously.   The team would not update until all of
      //the photo's have been either retrieved -- or failed.
      // teamImages = await GetPhotos(this.state.team.members);
      // for (let i = 0; i < teamImages.length; i++) {
      //   team.members[i].image = teamImages[i].Image;
      //   if (teamImages[i].userPrincipalName) {
      //     //A userPrincipalName was returned so set its value
      //     team.members[i].userPrincipalName = teamImages[i].userPrincipalName;
      //   }
      // }
      this.teamPhotosRetrieved = true;
      // this.setState({ team });
    }
  };

  // checkKbValue = item => {
  //   return item.kb_knowledge_base.value === "ff2a6c39dbe1bf84c2937b27689619d6";
  // };

  APS_PrefNameSearch = async (normalResults, body) => {
    // console.log("normal results", body);
    let APSresponse = '';
    APSresponse = [];
    let outArray = [];
    let map = new Map();
    // Map normal results
    if (normalResults.data) {
      for (let i = 0; i < normalResults.data.contacts.length; i++) {
        let id = normalResults.data.contacts[i].emplid;
        map.set(id, normalResults.data.contacts[i]);
      }
      APSresponse = await getCallupData(body);
      if (APSresponse.data) {
        // Map preferred name results
        for (let i = 0; i < APSresponse.data.contacts.length; i++) {
          let id = APSresponse.data.contacts[i].emplid;
          map.set(id, APSresponse.data.contacts[i]);
        }
      }

      let i = 0;
      for (let value of map.values()) {
        outArray[i] = value;
        i++;
      }
    }

    return outArray;
  };
  

  handleCallupAdvancedSearch = async (short_name, body_post, body_post2) => {
    // console.log("App.handleCallupAdvancedSearch APS short_name", short_name);
    // console.log("App.handleCallupAdvancedSearch APS body_post", body_post);
    let callUpSNData = [];
    let Callup_Image = [];
    let AdminNameData = [];
    let APSresponse = '';
    let shortNameResults = '';
    let searchQuery = '';
    searchQuery = body_post.lastName
      ? '<i>Last Name: </i><b>' + body_post.lastName + '</b>'
      : searchQuery;
    searchQuery = body_post.firstName
      ? searchQuery + '<i> First Name: </i><b>' + body_post.firstName + '</b>'
      : searchQuery;
    searchQuery = body_post.workPhone
      ? searchQuery + '<i> Phone: </i><b>' + body_post.workPhone + '</b>'
      : searchQuery;
    searchQuery = body_post.mailDrop
      ? searchQuery + '<i> Mail Code: </i><b>' + body_post.mailDrop + '</b>'
      : searchQuery;
    searchQuery = short_name
      ? searchQuery + '<i> Shortname: </i><b>' + short_name + '</b>'
      : searchQuery;
    searchQuery = body_post.locID
      ? searchQuery + '<i> locID: </i><b>' + body_post.locID + '</b>'
      : searchQuery;
    searchQuery = body_post.disbCode
      ? searchQuery + '<i> disbCode: </i><b>' + body_post.disbCode + '</b>'
      : searchQuery;
    this.setState({
      People_Display: true,
      searching: false,
      searchQuery,
      searchData: [],
      HRK_Display: false,
    });
    let searchingCallup = true;
    this.setState(
      {
        totalCount: 0,
        data: [],
        searchingCallup,
        callUpSNData: [],
        Callup_Image: [],
      },
      () =>
        console.log(
          'App.handleCallupAdvancedSearch APS Image_Array in App pre',
          this.state.Callup_Image,
        ),
    );

    if (body_post && !isEmpty(body_post)) {
      //Search box is not empty
      APSresponse = await getCallupData(body_post);
      let combined = await this.APS_PrefNameSearch(APSresponse, body_post2);
      if (APSresponse) {
        const APSLength = combined ? combined.length : 0;
        if (APSLength > 0) {
          if (APSresponse.userMessage) {
            console.error(
              'App.handleCallupAdvancedSearch GOT A MESSAGE FROM CALLUP API INSTEAD OF A VALID RESPONSE',
            );
            this.setState({ People_Error: true });
            toast.error(
              'Unable to retrieve a People response to your search. Error Message: ' +
                APSresponse.userMessage,
            );
            return false;
          }
          // console.log(
          //   "App.handleCallupAdvancedSearch people Lastname search:",
          //   APSLength
          // );
          this.setState({
            APSResults: combined,
          });
        } else {
          this.setState({ APSResults: [] });
        }
      } else {
        this.setState({ APSResults: [], People_Error: true });
        toast.error(
          'Unable to retrieve a People response to your search.  Please try again later.',
        );
      }
    }

    if (short_name && short_name !== '') {
      shortNameResults = await getCallupShortName(short_name);
      // console.log(
      //   "App.handleCallupAdvancedSearch shortNameResults",
      //   shortNameResults
      // );
      if (shortNameResults) {
        const shortNameLength = shortNameResults.contacts.length
          ? shortNameResults.contacts.length
          : 0;
        if (shortNameLength > 0) {
          if (shortNameResults.userMessage) {
            // console.log(
            //   "App.handleCallupAdvancedSearch GOT A MESSAGE FROM CALLUP API INSTEAD OF A VALID RESPONSE ",
            //   shortNameResults.userMessage
            // );
            this.setState({ People_Error: true });
            return false;
          }
          // console.log(
          //   "App.handleCallupAdvancedSearch people shortname search:",
          //   shortNameLength
          // );
          this.setState({ shortNameResults: shortNameResults.contacts });
        } else {
          this.setState({ shortNameResults: [] });
        }
      } else {
        this.setState({ shortNameResults: [], People_Error: true });
      }
    }

    // console.log(
    //   "App.handleCallupAdvancedSearch APS body_post in App",
    //   this.state.APSResults
    // );

    // console.log(
    //   "App.handleCallupAdvancedSearch APS shortNameResults in App",
    //   this.state.shortNameResults
    // );

    if (short_name && short_name !== '') {
      if (body_post && !isEmpty(body_post)) {
        callUpSNData = this.state.APSResults.filter(elem =>
          this.state.shortNameResults.find(({ emplid }) => elem.emplid === emplid),
        );

        this.setState({ callUpSNData });
      } else {
        this.setState({ callUpSNData: this.state.shortNameResults });
      }
    } else {
      if (body_post && !isEmpty(body_post)) {
        this.setState({ callUpSNData: this.state.APSResults });
      } else {
        this.setState({ callUpSNData: [] });
      }
    }

    // console.log(
    //   "App.handleCallupAdvancedSearch APS callUpSNData in App",
    //   this.state.callUpSNData
    // );
    this.setState({ searchingCallup: false });

    Callup_Image = await GetPhotos(this.state.callUpSNData.slice(0, 10));
    this.setState({ Callup_Image });
    // console.log(
    //   "App.handleCallupAdvancedSearch APS Image_Array in App",
    //   this.state.Callup_Image
    // );
    AdminNameData = await getAdminAssistants(this.state.callUpSNData.slice(0, 10));
    this.setState({ AdminNameData });
    console.log(
      'App.handleCallupAdvancedSearch APS AdminNameData in App',
      this.state.AdminNameData,
    );

    let Imagenewload = [];
    let newAdminNameData = [];
    Imagenewload = await GetPhotos(this.state.callUpSNData.slice(10, 20));
    Callup_Image = Callup_Image.concat(Imagenewload);
    this.setState({ Callup_Image });

    newAdminNameData = await getAdminAssistants(this.state.callUpSNData.slice(10, 20));
    AdminNameData = AdminNameData.concat(newAdminNameData);
    this.setState({ AdminNameData });
    console.log(
      'App.handleCallupAdvancedSearch APS AdminNameData in App next set',
      this.state.AdminNameData,
    );
    // console.log(
    //   "App.handleCallupAdvancedSearch APS Image_Array in App next set",
    //   this.state.Callup_Image
    // );
  };

  handleManagerHyperlink = async short_name => {
    // console.log("App.handleManagerHyperlink short_name", short_name);
    let Callup_Image = [];
    let shortNameResults = '';
    this.setState({
      People_Display: true,
      searching: false,
      searchQuery: short_name,
      searchData: [],
      HRK_Display: false,
    });
    let searchingCallup = true;
    this.setState({
      totalCount: 0,
      data: [],
      searchingCallup,
      callUpSNData: [],
      Callup_Image: [],
    });

    if (short_name && short_name !== '') {
      //short_name exists and is not empty
      shortNameResults = await getCallupShortName(short_name);
      // console.log(
      //   "App.handleManagerHyperlink shortNameResults",
      //   shortNameResults
      // );
      if (shortNameResults) {
        //Short name search returned results
        const shortNameLength = shortNameResults.contacts.length
          ? shortNameResults.contacts.length
          : 0;
        if (shortNameLength > 0) {
          //Short name search results contains at least 1 contact object
          if (shortNameResults.userMessage) {
            //Short name search results contains an error message
            // console.log(
            //   "App.handleManagerHyperlink GOT A MESSAGE FROM CALLUP API INSTEAD OF A VALID RESPONSE ",
            //   shortNameResults.userMessage
            // );
            this.setState({ People_Error: true });
            return false;
          }
          // console.log(
          //   "App.handleManagerHyperlink  shortname search:",
          //   shortNameLength
          // );
          this.setState({ shortNameResults: shortNameResults.contacts });
        } else {
          this.setState({ shortNameResults: [] });
        }
      } else {
        //Short name search results does not contain any contact objects
        this.setState({ shortNameResults: [], People_Error: true });
      }
    } else {
      //short_name does not exists or is empty
      this.setState({ shortNameResults: [] });
    }

    this.setState({ callUpSNData: this.state.shortNameResults });

    // console.log(
    //   "App.handleManagerHyperlink  callUpSNData in App",
    //   this.state.callUpSNData
    // );
    this.setState({ searchingCallup: false });

    Callup_Image = await GetPhotos(this.state.callUpSNData);
    this.setState({ Callup_Image });
    // console.log(
    //   "App.handleManagerHyperlink  Image_Array in App",
    //   this.state.Callup_Image
    // );

    let AdminNameData = await getAdminAssistants(this.state.callUpSNData);
    this.setState({ AdminNameData });
    console.log(
      'App.handleCallupAdvancedSearch APS AdminNameData in App',
      this.state.AdminNameData,
    );
  };

  handleSearchQueryCallup = async query => {
    //Search in Callup
    let callUpSNData = [];
    let Callup_Image = [];
    let AdminNameData = [];
    let shortNameResults = '';
    if (query.includes('in <b>HR Knowledge</b>')) {
      //query contains message to search in HR Knowledge, so no need to search Call Up
      const searchQuery = query.replace(' in <b>HR Knowledge</b>', '');
      this.setState({
        searchingCallup: false,
        searchQuery,
        callUpSNData: [],
        Callup_Image: [],
        People_Display: false,
      });
    } else {
      //query does not contain message to search in HR Knowledge
      this.setState({ People_Display: true });
      const searchQuery = query.replace(' in <b>People</b>', '');
      if (searchQuery) {
        //query is not empty
        const searchText = searchQuery.trim();
        // console.log("App.handleSearchQueryCallup searchText", searchText);
        // console.log("App.handleSearchQueryCallup searchText query", query);

        let searchingCallup = true;
        this.setState({
          searchText,
          totalCount: 0,
          data: [],
          searchingCallup,
          callUpSNData: [],
          Callup_Image: [],
        });

        var body_post = {};
        let lastname_pre = '';
        let firstname_pre = '';
        let preferredname_pre = '';
        let filteredLNResults = [];
        let filteredFNResults = [];
        let filteredPNResults = [];
        let filteredPNResultsA = [];
        let comma = searchText.search(',');
        let space = searchQuery.search(' ');
        let percentile = searchText.search('%');
        let LastNameresponse = '';
        let FirstNameresponse = '';
        let PreferredNameresponse = '';
        let PreferredNameresponseA = '';
        if (comma === -1 && space === -1) {
          //the Trimmed query does not contains comma and the untrimmed query does not contain a space
          //So this is essentially a single word search
          if (percentile === -1) {
            //The trimmed query does not contain a % (wildcard)
            //Search for shortname
            shortNameResults = await getCallupShortName(searchText);
            // console.log(
            //   "App.handleSearchQueryCallup shortNameResults",
            //   shortNameResults
            // );
            if (shortNameResults) {
              //results returned from a shortname search
              const shortNameLength = shortNameResults.contacts.length
                ? shortNameResults.contacts.length
                : 0;
              if (shortNameLength > 0) {
                //The short name results contain at least 1 contact
                if (shortNameResults.userMessage) {
                  //The short name results contained an error messasge
                  // console.log(
                  //   "App.handleSearchQueryCallup GOT A MESSAGE FROM CALLUP API INSTEAD OF A VALID RESPONSE ",
                  //   shortNameResults.userMessage
                  // );
                  return false;
                }
                // console.log(
                //   "App.handleSearchQueryCallup people shortname search:",
                //   shortNameLength
                // );
                this.setState({ shortNameResults: shortNameResults.contacts });
              } else {
                //The short name response contains a contact object
                this.setState({ shortNameResults: [] });
              }
            } else {
              //No results returned from a shortname search
              this.setState({ shortNameResults: [] });
            }
          } else {
            //The trimmed query contains a % (wildcard)
            this.setState({ shortNameResults: [] });
          }
          lastname_pre = searchText;
          firstname_pre = searchText;
          preferredname_pre = searchText;
          //Search for Last name
          body_post['lastName'] = lastname_pre;
          // console.log(
          //   "App.handleSearchQueryCallup BodyPost Last name Call",
          //   body_post
          // );
          LastNameresponse = await getCallupData(body_post);
          if (LastNameresponse) {
            //Result were returned from a last name search
            const LastNameLength = LastNameresponse.data.contacts
              ? LastNameresponse.data.contacts.length
              : 0;
            if (LastNameLength > 0) {
              //the last name response contains a contact object
              if (LastNameresponse.userMessage) {
                //The last name response contains an error message
                console.error(
                  'App.handleSearchQueryCallup GOT A MESSAGE FROM CALLUP API INSTEAD OF A VALID RESPONSE',
                );
                this.setState({ People_Error: true });
                toast.error(
                  'Unable to retrieve a People response to your search. Error Message: ' +
                    LastNameresponse.userMessage,
                );
                return false;
              }
              // console.log(
              //   "App.handleSearchQueryCallup people Lastname search:",
              //   LastNameLength
              // );
              this.setState({
                lastNameResults: LastNameresponse.data.contacts,
              });
            } else {
              //the last name response did not contain a contact object
              this.setState({ lastNameResults: [] });
            }
          } else {
            //No Results were returned from a last name search
            this.setState({ lastNameResults: [], People_Error: true });
            toast.error(
              'Unable to retrieve a People response to your search.  Please try again later.',
            );
          }
          // Firstname Search
          body_post = {};
          body_post['firstName'] = firstname_pre;
          // console.log(
          //   "App.handleSearchQueryCallup BodyPost first name Call",
          //   body_post
          // );
          FirstNameresponse = await getCallupData(body_post);
          if (FirstNameresponse) {
            //Search by first name returned a result
            const FirstNameLength = FirstNameresponse.data.contacts
              ? FirstNameresponse.data.contacts.length
              : 0;
            if (FirstNameLength > 0) {
              //The first name search results contains a contact object
              if (FirstNameresponse.userMessage) {
                //the first name search result contains an error message
                console.error(
                  'App.handleSearchQueryCallup GOT A MESSAGE FROM CALLUP API INSTEAD OF A VALID RESPONSE ',
                  FirstNameresponse.userMessage,
                );
                return false;
              }
              // console.log(
              //   "App.handleSearchQueryCallup people Firstname search:",
              //   FirstNameLength
              // );
              this.setState({
                firstNameResults: FirstNameresponse.data.contacts,
              });
            } else {
              //The first name search results does not contain a contact object
              this.setState({ firstNameResults: [] });
            }
          } else {
            //Search by first name did not return a result
            this.setState({ firstNameResults: [] });
          }

          // PreferredName Search
          body_post = {};
          body_post['preferredFirstName'] = preferredname_pre;
          // console.log(
          //   "App.handleSearchQueryCallup BodyPost Preferred first name Call",
          //   body_post
          // );
          PreferredNameresponse = await getCallupData(body_post);
          if (PreferredNameresponse) {
            //Search by Preferred name returned results
            const PreferredNameLength = PreferredNameresponse.data.contacts
              ? PreferredNameresponse.data.contacts.length
              : 0;
            if (PreferredNameLength > 0) {
              //Preferred name search results contains a contact object
              if (PreferredNameresponse.userMessage) {
                //Search by Preferred name results contains an error message
                console.error(
                  'App.handleSearchQueryCallup GOT A MESSAGE FROM CALLUP API INSTEAD OF A VALID RESPONSE ',
                  PreferredNameresponse.userMessage,
                );
                return false;
              }
              // console.log(
              //   "App.handleSearchQueryCallup people Preferred Firstname search:",
              //   PreferredNameLength
              // );
              this.setState({
                preferredNameResults: PreferredNameresponse.data.contacts,
              });
            } else {
              //Preferred name search results does not contain a contact object
              this.setState({ preferredNameResults: [] });
            }
          } else {
            //Preferred name search did not return results
            this.setState({ preferredNameResults: [] });
          }

          callUpSNData = this.state.shortNameResults;
          // console.log(
          //   "App.handleSearchQueryCallup callUpSNData - Added SN results",
          //   callUpSNData
          // );

          //Filter out mplid from last name results that already exist in the overall callup results
          filteredLNResults = this.state.lastNameResults.filter(
            item => !callUpSNData.find(({ emplid }) => item.emplid === emplid),
          );

          //Add filtered Last Name Results to the overall callup results
          callUpSNData = callUpSNData.concat(filteredLNResults);
          // console.log(
          //   "App.handleSearchQueryCallup callUpSNData - Added LN results",
          //   callUpSNData
          // );

          //Filter out mplid from first name results that already exist in the overall callup results
          filteredFNResults = this.state.firstNameResults.filter(
            item => !callUpSNData.find(({ emplid }) => item.emplid === emplid),
          );

          //Add filtered First Name Results to the overall callup results
          callUpSNData = callUpSNData.concat(filteredFNResults);
          // console.log(
          //   "App.handleSearchQueryCallup callUpSNData - Added FN results",
          //   callUpSNData
          // );

          //Filter out mplid from preferred name results that already exist in the overall callup results
          filteredPNResults = this.state.preferredNameResults.filter(
            item => !callUpSNData.find(({ emplid }) => item.emplid === emplid),
          );

          //Add filtered preferred Name Results to the overall callup results
          callUpSNData = callUpSNData.concat(filteredPNResults);
          // console.log(
          //   "App.handleSearchQueryCallup callUpSNData - Added PN results",
          //   callUpSNData
          // );

          this.setState({ callUpSNData });

          // console.log(
          //   "App.handleSearchQueryCallup callUpSNData",
          //   this.state.callUpSNData
          // );
          this.setState({ searchingCallup: false, searchQuery });
        } else {
          //the Trimmed query  contains a comma or the untrimmed query contains a space
          // Don't search for shortname if there is a comma or space
          // Search as Last name & First Name
          if (comma === -1) {
            //Trimmed query does not contain a comma
            if (space === -1) {
              //the untrimmed query does not contain a space
              // do nothing - percentile exists
            } else {
              //the untrimmed query contains a space
              //First Name comes after last name in the search
              lastname_pre = searchQuery.slice(0, space).trim();
              firstname_pre = searchQuery.slice(space + 1, searchQuery.length).trim();
              preferredname_pre = searchQuery.slice(space + 1, searchQuery.length).trim();
            }
          } else {
            //Trimmed query contains a comma
            //First Name comes after last name in the search
            lastname_pre = searchText.slice(0, comma).trim();
            firstname_pre = searchText.slice(comma + 1, searchText.length).trim();
            preferredname_pre = searchText.slice(comma + 1, searchText.length).trim();
          }
          //Last name and first name
          if (lastname_pre) {
            //There is text before the first comma or the first space
            body_post['lastName'] = lastname_pre;
          }
          if (firstname_pre) {
            //There is text after the first comma or the first space
            body_post['firstName'] = firstname_pre;
          }

          // console.log("App.handleSearchQueryCallup BodyPost", body_post);
          const LastNameresponse = await getCallupData(body_post);
          if (LastNameresponse) {
            //A response was received from the name search
            const LastNameLength = LastNameresponse.data.contacts
              ? LastNameresponse.data.contacts.length
              : 0;
            if (LastNameLength > 0) {
              //There is at least one contact object in the search results
              if (LastNameresponse.userMessage) {
                //There waa an error message in the search results
                console.error(
                  'App.handleSearchQueryCallup GOT A MESSAGE FROM CALLUP API INSTEAD OF A VALID RESPONSE',
                );
                this.setState({ People_Error: true });
                toast.error(
                  'Unable to retrieve a People response to your search. Error Message: ' +
                    LastNameresponse.userMessage,
                );
                return false;
              }
              // console.log(
              //   "App.handleSearchQueryCallup people Lastname search:",
              //   LastNameLength
              // );
              this.setState({
                lastNameResults: LastNameresponse.data.contacts,
              });
            } else {
              //There were no contacts in the search results
              this.setState({ lastNameResults: [] });
            }
          } else {
            //There was no response from the name search
            this.setState({ lastNameResults: [], People_Error: true });
            toast.error(
              'Unable to retrieve a People response to your search.  Please try again later.',
            );
          }

          //Last name and Pref first name
          body_post = {};
          if (lastname_pre) {
            //There is text before the first comma
            body_post['lastName'] = lastname_pre;
          }
          if (preferredname_pre) {
            //There is text after the first comma
            body_post['preferredFirstName'] = preferredname_pre;
          }

          // console.log("App.handleSearchQueryCallup BodyPost", body_post);
          const PreferredNameresponse = await getCallupData(body_post);
          if (PreferredNameresponse) {
            //A response was received for the name search
            const PreferredNameLength = PreferredNameresponse.data.contacts
              ? PreferredNameresponse.data.contacts.length
              : 0;
            if (PreferredNameLength > 0) {
              //The response contains at least one contact object
              if (PreferredNameresponse.userMessage) {
                //The response contains an error message
                console.error(
                  'App.handleSearchQueryCallup GOT A MESSAGE FROM CALLUP API INSTEAD OF A VALID RESPONSE',
                );
                toast.error(
                  'Unable to retrieve a People response to your search. Error Message: ' +
                    PreferredNameresponse.userMessage,
                );
                return false;
              }
              // console.log(
              //   "App.handleSearchQueryCallup people Prefname search:",
              //   PreferredNameLength
              // );
              this.setState({
                preferredNameResults: PreferredNameresponse.data.contacts,
              });
            } else {
              //The search results did not contain any contact objects
              this.setState({ preferredNameResults: [] });
            }
          } else {
            //There was no response to the search
            this.setState({ preferredNameResults: [] });
            toast.error(
              'Unable to retrieve a People response to your search.  Please try again later.',
            );
          }

          firstname_pre = '';
          lastname_pre = '';
          preferredname_pre = '';
          body_post = {};
          // Search as First name & Last Name
          if (comma === -1) {
            //Trimmed query does not contain a comma
            if (space === -1) {
              //untrimmed query does not contain a space
              // do nothing - percentile exists
            } else {
              //untrimmed query contins a space
              //First Name comes before last name in the search
              firstname_pre = searchQuery.slice(0, space).trim();
              preferredname_pre = searchQuery.slice(0, space).trim();
              lastname_pre = searchQuery.slice(space + 1, searchQuery.length).trim();
            }
          } else {
            //Trimmed query contains a comma
            //First Name comes before last name in the search
            firstname_pre = searchText.slice(0, comma).trim();
            preferredname_pre = searchText.slice(0, comma).trim();
            lastname_pre = searchText.slice(comma + 1, searchText.length).trim();
          }
          //First Name & Last name
          if (lastname_pre) {
            //There is text after the first space
            body_post['lastName'] = lastname_pre;
          }
          if (firstname_pre) {
            //There is text before the first space
            body_post['firstName'] = firstname_pre;
          }

          // console.log("App.handleSearchQueryCallup BodyPost", body_post);
          FirstNameresponse = await getCallupData(body_post);
          if (FirstNameresponse) {
            //Received a response from the name search
            const FirstNameLength = FirstNameresponse.data.contacts
              ? FirstNameresponse.data.contacts.length
              : 0;
            if (FirstNameLength > 0) {
              //The name search response contains at least 1 contact object
              if (FirstNameresponse.userMessage) {
                //The name search response contains an error message
                console.error(
                  'App.handleSearchQueryCallup GOT A MESSAGE FROM CALLUP API INSTEAD OF A VALID RESPONSE ',
                  FirstNameresponse.userMessage,
                );
                return false;
              }
              // console.log(
              //   "App.handleSearchQueryCallup people Firstname search:",
              //   FirstNameLength
              // );
              this.setState({
                firstNameResults: FirstNameresponse.data.contacts,
              });
            } else {
              //The name search response did not contain any contact objects
              this.setState({ firstNameResults: [] });
            }
          } else {
            //There was no response to the search
            this.setState({ firstNameResults: [] });
          }

          //Pref Name & Last name
          body_post = {};
          if (lastname_pre) {
            //There was text after the first space
            body_post['lastName'] = lastname_pre;
          }
          if (preferredname_pre) {
            //There was text before the first space
            body_post['preferredFirstName'] = preferredname_pre;
          }

          // console.log("App.handleSearchQueryCallup BodyPost", body_post);
          PreferredNameresponseA = await getCallupData(body_post);
          if (PreferredNameresponseA) {
            //There was a response to the name search
            const PreferredNameLengthA = PreferredNameresponseA.data.contacts
              ? PreferredNameresponseA.data.contacts.length
              : 0;
            if (PreferredNameLengthA > 0) {
              //The search results contain at least one contact object
              if (PreferredNameresponseA.userMessage) {
                //The search results contain an error message
                console.error(
                  'App.handleSearchQueryCallup GOT A MESSAGE FROM CALLUP API INSTEAD OF A VALID RESPONSE ',
                  PreferredNameresponseA.userMessage,
                );
                return false;
              }
              // console.log(
              //   "App.handleSearchQueryCallup people PREFname search:",
              //   PreferredNameLengthA
              // );
              this.setState({
                preferredNameResultsA: PreferredNameresponseA.data.contacts,
              });
            } else {
              //The search results do not contain any contact objects
              this.setState({ preferredNameResultsA: [] });
            }
          } else {
            //There was no response to the search
            this.setState({ preferredNameResultsA: [] });
          }

          callUpSNData = this.state.lastNameResults;
          //Filter out emplid from last name results that already exist in the overall callup results
          filteredFNResults = this.state.firstNameResults.filter(
            item => !callUpSNData.find(({ emplid }) => item.emplid === emplid),
          );

          //Add filtered last name search results to the overall callup results
          callUpSNData = callUpSNData.concat(filteredFNResults);

          //Filter out emplid from preferred name results that already exist in the overall callup results
          filteredPNResults = this.state.preferredNameResults.filter(
            item => !callUpSNData.find(({ emplid }) => item.emplid === emplid),
          );
          //Add filtered preferred name search results to the overall callup results
          callUpSNData = callUpSNData.concat(filteredPNResults);

          //Filter out emplid from preferred name results that already exist in the overall callup results
          filteredPNResultsA = this.state.preferredNameResultsA.filter(
            item => !callUpSNData.find(({ emplid }) => item.emplid === emplid),
          );
          //Add filtered preferred name search results to the overall callup results
          callUpSNData = callUpSNData.concat(filteredPNResultsA);

          this.setState({ callUpSNData });

          // console.log(
          //   "App.handleSearchQueryCallup callUpSNData",
          //   this.state.callUpSNData
          // );
          this.setState({ searchingCallup: false, searchQuery });
        }

        Callup_Image = await GetPhotos(this.state.callUpSNData.slice(0, 10));
        this.setState({ Callup_Image });
        // console.log(
        //   "App.handleSearchQueryCallup Image_Array in App",
        //   this.state.Callup_Image
        // );
        let Imagenewload = [];
        Imagenewload = await GetPhotos(this.state.callUpSNData.slice(10, 20));
        Callup_Image = Callup_Image.concat(Imagenewload);
        this.setState({ Callup_Image });
        // console.log(
        //   "App.handleSearchQueryCallup APS Image_Array in App next set",
        //   this.state.Callup_Image
        // );

        AdminNameData = await getAdminAssistants(this.state.callUpSNData.slice(0, 10));
        this.setState({ AdminNameData });
        console.log(
          'App.handleSearchQueryCallup AdminNameData_Array in App',
          this.state.AdminNameData,
        );
        let newAdminNameData = [];
        newAdminNameData = await getAdminAssistants(this.state.callUpSNData.slice(10, 20));
        AdminNameData = AdminNameData.concat(newAdminNameData);
        this.setState({ AdminNameData });
        console.log(
          'App.handleSearchQueryCallup AdminNameData_Array in App next set',
          this.state.AdminNameData,
        );
      } else {
        //query is empty
        this.setState({ callUpSNData: [], searchQuery, Callup_Image: [], AdminNameData: [] });
      }
      // this.setState({ searchQuery });
    }
  };

  handleSearchQueryUpdate = async query => {
    //Search ServiceNow Knowledge Base
    // console.log("App.handleSearchQueryUpdate start", query);
    if (query.includes('in <b>People</b>')) {
      //query contains message to search in People
      const searchQuery = query.replace(' in <b>People</b>', '');
      this.setState({
        searching: false,
        searchQuery,
        searchData: [],
        HRK_Display: false,
        menuExpanded: false,
        searchDataAll: [],
        restrictKnowledgeData: 10,
      });
    } else {
      //query odes not contains message to search in People
      this.setState({ HRK_Display: true, menuExpanded: false });
      const searchQuery = query.replace(' in <b>HR Knowledge</b>', '');
      if (searchQuery) {
        //there is text to search
        const searchText = searchQuery.trim();
        // console.log("App.handleSearchQueryUpdate searchText", searchText);
        let searching = true;

        this.setState({ searchText, totalCount: 0, data: [], searching });
        if (searchText.length > 0 && searchText.length < 2) {
          //Less than 3 characters are in the search text
          toast.error(
            `At least ${minCharactersToSearch} characters must be entered to search ServiceNow`,
          );
        }
        const resp = await getFromServiceNow(searchText);
        // console.log("App.handleSearchQueryUpdate resp", resp);
        if (!resp) {
          //ServiceNow search did not return any results
          this.setState({ searching: false, HRK_Error: true, searchQuery });
          toast.error(
            'Unable to retrieve a HR Knowledge response to your search.  Please try again later.',
          );
          this.addToSearchHistory(searchText, -1);
          return false;
        }
        if (resp.userMessage) {
          //ServiceNow search returned an error message
          console.error(
            'App.handleSearchQueryUpdate HRK - GOT A MESSAGE FROM SERVICENOW API INSTEAD OF A VALID RESPONSE ',
            resp.userMessage,
          );
          toast.error(
            'Unable to retrieve a HR Knowledge response to your search. Error Message: ' +
              resp.userMessage,
          );
          this.setState({ searching: false, HRK_Error: true, searchQuery });
          this.addToSearchHistory(searchText, -1);
          return false;
        }
        const result = resp.result;
        const unfilteredData = result ? result.articles : null;
        // console.log(
        //   "App.handleSearchQueryUpdate HRK - App>handleSearchQueryUpdate unfilteredData",
        //   unfilteredData
        // );
        if (unfilteredData) {
          //ServiceNow Search results exist
          const unfilteredNumber = unfilteredData.length;
          // const filteredData = await unfilteredData.filter(this.checkKbValue);
          const filteredData = result.articles;
          const filteredNumber = filteredData.length;
          const searchData = filteredData.slice(0, 10);
          const searchDataAll = filteredData;
          const totalCount = searchData ? searchData.length : 0;
          searching = false;
          this.setState({
            totalCount,
            searchData,
            searching,
            unfilteredNumber,
            filteredNumber,
            searchDataAll,
            restrictKnowledgeData: 10,
          });
          this.addToSearchHistory(searchText, filteredData.length);
        } else {
          //ServiceNOw search did not return any results
          this.setState({
            totalCount: 0,
            searchData: [],
            searching: true,
            unfilteredNumber: 0,
            filteredNumber: 0,
          });
          this.addToSearchHistory(searchText, 0);
        }
      }
      this.setState({ searchQuery });
    }
  };

  /*getUsersJob = async () =>{
    if (!this.fetchingUserJobTitle) {
      //Not currently retrieving job info
      const { contact } = this.state;
      if (contact) {
        //contact information has been populated
        this.fetchingUserJobTitle = true;
        if (!this.state.userJobTitle) {
          //userJobTitle has not been populated
          const data = [{ emplid: contact.emplid }];
          let Callup_Image = [];
          Callup_Image = await GetJobTitle(data);
          if (Callup_Image && Callup_Image.length > 0) {
            const userJobTitle = Callup_Image[0].jobTitle;
            this.setState({ userJobTitle });
          }
        }
      } else {
        //contact data not populated yet, try again in 1 second
        setTimeout(this.getUserPhoto, 1000);
      }
    }
  };
  };*/

  loadMoreKnowledge = () => {
    const { restrictKnowledgeData, searchDataAll } = this.state;
    let tempCount = restrictKnowledgeData + 10;
    let data = searchDataAll.slice(0, tempCount);
    this.setState({ restrictKnowledgeData: tempCount, searchData: data });
  };

  reSearchKnowledge() {
    this.setState({ restrictKnowledgeData: 10 });
  };

  addToSearchHistory = async (searchString, resultCount) => {
    const contactState = this.state.contact ? this.state.contact.state : '';
    const result = await addSearchHistory(searchString, resultCount, contactState);
    if (!result.userMessage) {
      //Add Search History was successful
      // console.log("APP.appToSearchHistory  result", result);
      const searchHistoryId = result.data ? result.data.id : 0;
      this.setState({ searchHistoryId });
    }
  };

  fetchBravoData = async () => {
    //console.log('retrieveBravoData', this.fetchingBravos);

    if (!this.fetchingBravos) {
      console.log('retrieveBravoData', typeof this.fetchingBravos);
      this.fetchingBravos = true;
      const bravosRetrieved = await getBravoData();
      if (bravosRetrieved) {
        let bravoData = [];
        if (bravosRetrieved.error) {
          console.log(
            'APP retrieveBravoData -- Bravo data contains user message',
            bravosRetrieved.error,
          );
          toast.error(
            'Unable to retrieve Bravo data. Error message: ' + bravosRetrieved.userMessage,
          );
          bravoData = bravosRetrieved;
        } else {
          console.log('APP - retrieveBravoData -- get Bravo data from OCTanner');
          bravoData = bravosRetrieved;
        }
        this.setState({ bravoData, bravosLoaded: true });
      }
    }
  };

  fetchFowData = async () => {
    // console.log("App.fetchFowData fetchingFOW");
    if (!this.fetchingFOW) {
      //Not already fetching the FOW data
      this.fetchingFOW = true;
      const fowData = await getFOW();
      // console.log("App.fetchFowData fowData", fowData);
      if (fowData) {
        //FOW is retrieved
        if (fowData.error) {
          //The FOW data contains an error message
          console.error('App.fetchFowData Error getting FOW data ', fowData.error);
          toast.error('Unable to retrieve Future of Work.  Error message: ' + fowData.error);
          const { team } = this.state;
          const teamSize = team.members.length;
          for (let member of team.members) {
            member.fow = {
              shortName: member.shortName,
              fowYear: fowData.fowYear,
              error: fowData.error,
            };
          }
          // for (let i = 0; i < teamSize; i++) {
          //   team.members[i].fow = {
          //     shortName: team.members[i].shortName,
          //     fowYear: fowData.fowYear,
          //     error: fowData.error,
          //   };
          // }
          team.teamOverall.overalFow = 0;
          this.setState({ team });
        } else {
          //The FOW data did not have an error message
          const { team } = this.state;
          const teamSize = team.members.length;

          let overalFow = 0;
          for (let member of team.members) {
            for (let fowEntry of fowData) {
              if (fowEntry.shortName === member.shortName) {
                //This FOW Data entry has the same short name as this team member
                member.fow = fowEntry;
                overalFow += fowEntry.overall;
                break;
              }
            }
          }
          // for (let i = 0; i < teamSize; i++) {
          //   for (let x = 0; x < fowData.length; x++) {
          //     if (fowData[x].shortName === team.members[i].shortName) {
          //       //This FOW Data entry has the same short name as this team member
          //       team.members[i].fow = fowData[x];
          //       overalFow += fowData[x].overall;
          //       break;
          //     }
          //   }
          // }
          if (teamSize > 0) {
            //teamSize is greater than zero, so divide the overalFow by it
            overalFow = overalFow / teamSize;
          }
          team.teamOverall.overalFow = overalFow;
          this.setState({ team });
        }
      } else {
        //FOW data not retrieved
      }
    }
  };
  retrieveFOWData = refreshing => {
    //getFOW
    // console.log("App.retrieveFOWData refreshing", refreshing);
    const { team } = this.state;

    if (team) {
      //team populated
      // console.log("App.retrieveFOWData team populated ");
      if (refreshing) {
        //This is a forced refresh, so reset the fetchingFOW flag
        this.fetchingFOW = false;
      }
      if (!this.fetchingFOW) {
        //We are not currently fetchingFOW data so call the API to get it
        // console.log("App.retrieveFOWData call fetchFowData");
        this.fetchFowData();
      }
    }
  };

  fetchTrainingData = async () => {
    // console.log("App.fetchTrainingData fetchingTraining");
    if (!this.fetchingTraining) {
      //Not already fetching the Training data
      this.fetchingTraining = true;
      const trainingData = await getTraining();

      if (trainingData) {
        //Training is retrieved
        if (trainingData.error) {
          //The Training data contains an error message
          console.error('App.fetchTrainingData Error getting Training data ', trainingData.error);
          toast.error(
            'Unable to retrieve Training information.  Error message: ' + trainingData.error,
          );
        } else {
          //The Training data did not have an error message
          const { team } = this.state;
          const teamSize = team.members.length;
          let teamLearning = [];
          let teamLearningIdx = 0;
          // console.log("DEBUG TEAM", team);
          // console.log("DEBUG trainingData", trainingData);
          for (let i = 0; i < teamSize; i++) {
            //Loop through the team
            for (let x = 0; x < trainingData.length; x++) {
              //Loop throught the training Data
              if (
                trainingData[x].shortName.toUpperCase() === team.members[i].shortName.toUpperCase()
              ) {
                //This Training Data entry has the same short name as this team member
                team.members[i].learning = trainingData[x].learning;
                const currentTraining = trainingData[x].learning;
                for (let c = 0; c < currentTraining.length; c++) {
                  //Loop through this training data and crate a new TeamLearning object
                  teamLearning[teamLearningIdx++] = {
                    associateName: team.members[i].associateName,
                    ...currentTraining[c],
                  };
                }
                break;
              }
            }
          }
          //Sort the teamLearning array by title and then associate Name
          teamLearning.sort(function sortByNameDate(a, b) {
            return a.title === b.title
              ? a.associateName > b.associateName
                ? 1
                : -1
              : a.title > b.title
              ? 1
              : -1;
          });
          //Create the training array
          let training = [];
          let currentTitle = '';
          let titleNumber = 0;
          // console.log("DEBUG teamLearning", teamLearning.length, teamLearning);
          for (let i = 0; i < teamLearning.length; i++) {
            //Loop through the teamLeanring array
            if (i === 0) {
              //This is the first loop, set the Current title
              currentTitle = teamLearning[i].title;
              training[titleNumber] = {
                complete: 0,
                incomplete: 0,
                completedBy: '',
                notCompletedBy: '',
                title: teamLearning[i].title,
                assignedDate: '1900-01-01',
              };
            } else {
              //This is not the first loop
              if (teamLearning[i].title !== currentTitle) {
                //The title of this learning is not the same as the previous learning title
                titleNumber++;
                training[titleNumber] = {
                  complete: 0,
                  incomplete: 0,
                  completedBy: '',
                  notCompletedBy: '',
                  title: teamLearning[i].title,
                  assignedDate: '1900-01-01',
                };
                currentTitle = teamLearning[i].title;
              }
            }
            training[titleNumber].title = currentTitle;
            training[titleNumber].assignedDate = teamLearning[i].assignedDate;
            if (teamLearning[i].completed === 'Complete') {
              training[titleNumber].complete++;
              training[titleNumber].completedBy +=
                training[titleNumber].completedBy !== '' ? ',\n' : '';
              training[titleNumber].completedBy += teamLearning[i].associateName;
            } else {
              training[titleNumber].incomplete++;
              training[titleNumber].notCompletedBy +=
                training[titleNumber].notCompletedBy !== '' ? ',\n' : '';
              training[titleNumber].notCompletedBy += teamLearning[i].associateName;
            }
          }
          const teamOverall = team.teamOverall;
          //Sort the training by the assignedDate in descending order
          training.sort((a, b) => (a.assignedDate > b.assignedDate ? -1 : 1));

          // console.log("App.fetchTrainingData training", training);

          //Requirement to limit to the last three is being removed
          // teamOverall.training = training.slice(0, 3);
          teamOverall.training = training;

          team.teamOverall = teamOverall;
          // console.log("App.fetchTrainingData teamOverall", teamOverall);
          this.setState({ team });
        }
      } else {
        //Training data not retrieved
      }
    }
  };
  retrieveTrainingData = refreshing => {
    // console.log("App.retrieveTrainingData refreshing", refreshing);
    const { team } = this.state;

    if (team) {
      //team populated
      // console.log("App.retrieveTrainingData team populated ");
      if (refreshing) {
        this.fetchingTraining = false;
      }
      if (!this.fetchingTraining) {
        // console.log("App.retrieveTrainingData call fetchTrainingData");
        this.fetchTrainingData();
      }
    }
  };

  fetchCalendarData = async (calendarRange = '2') => {
    // console.log("App.fetchCalendarData fetching Calendar");
    if (!this.fetchingCalendar) {
      //Not already fetching the Calendar data
      this.fetchingCalendar = true;
      const calendarData = await getCalendar(calendarRange);

      // console.log("App.fetchCalendarData calendarData", calendarData);

      if (calendarData) {
        //Calendar is retrieved
        if (calendarData.userMessage) {
          //The Calendar data contains an error message
          console.error(
            'App.fetchCalendarData Error getting Calendar data ',
            calendarData.userMessage,
          );
          toast.error(
            'Unable to retrieve Calendar information.  Error message: ' + calendarData.userMessage,
          );
        }
        this.setState({ calendarData });
      }
      this.fetchingCalendar = false;
    }
  };

  checkCalendar = () => {
    const idToken = sessionStorage.getItem('access_token');
    if (idToken) {
      //idToken exist so retrieve the Approvals
      this.retrieveCalendarData(true);
    } else {
      setTimeout(this.checkCalendar, 100);
    }
  };

  retrieveCalendarData = refreshing => {
    if (refreshing) {
      //This is a data refresh, so reset the fetchingCalendar variable
      this.fetchingCalendar = false;
    }
    if (!this.fetchingCalendar) {
      //We are not already fetching the Calendar data
      this.fetchCalendarData();
    }
  };

  changeTeamDisplay = memberIdx => {
    const { team } = this.state;
    if (team && team.members[memberIdx]) {
      //Found the team member so change it's display property
      const display = !team.members[memberIdx].display;
      // console.log("App.changeTeamDisplay display", display);
      team.members[memberIdx].display = display;
      this.setState({ team });
    }
  };

  changeWholeTeamDisplay = display => {
    const { team } = this.state;
    if (team && team.members) {
      for (let i = 0; i < team.members.length; i++) {
        team.members[i].display = display;
      }
      this.setState({ team });
    }
  };

  updateLeaderLinks = async input => {
    const { leaderLinks } = this.state;
    let response = null;
    response = await updateLeaderLink(input);
    if (response.userMessage) {
      console.log('Error updating ' + input.id);
    } else {
      console.log('ID updated' + input.id);
    }
  };

  deleteLeaderLinks = (id, category, display, url) => {
    const { leaderLinks } = this.state;
    let current = leaderLinks;

    if (category === 'news') {
      for (let i = 0; i < current.news.length; i++) {
        if (current.news[i].ID === id) {
          console.log('EffectiveLeader - Delete News entry from state - APP.jsx', current.news[i]);
          current.news.splice(i, 1);
          this.setState({ leaderLinks: current });
          break;
        }
      }
    }
    if (category === 'strongerTeam') {
      for (let i = 0; i < current.strongerTeam.Links.length; i++) {
        if (current.strongerTeam.Links[i].ID === id) {
          console.log(
            'EffectiveLeader - Delete StrongerTeam entry from state - APP.jsx',
            current.strongerTeam.Links[i],
          );
          current.strongerTeam.Links.splice(i, 1);
          console.log('Current State ', current);
          this.setState({ leaderLinks: current });
          break;
        }
      }
    }
    if (category === 'LeaderRetention') {
      for (let i = 0; i < current.LeaderRetention.Links.length; i++) {
        if (current.LeaderRetention.Links[i].ID === id) {
          console.log(
            'EffectiveLeader - Delete LeaderRetention entry from state - APP.jsx',
            current.LeaderRetention.Links[i],
          );
          current.LeaderRetention.Links.splice(i, 1);
          console.log('Current State ', current);
          this.setState({ leaderLinks: current });
          break;
        }
      }
    }
    if (category === 'Better1on1s') {
      for (let i = 0; i < current.Better1on1s.Links.length; i++) {
        if (current.Better1on1s.Links[i].ID === id) {
          console.log(
            'EffectiveLeader - Delete Better1on1s entry from state - APP.jsx',
            current.Better1on1s.Links[i],
          );
          current.Better1on1s.Links.splice(i, 1);
          console.log('Current State ', current);
          this.setState({ leaderLinks: current });
          break;
        }
      }
    }
    if (category === 'Onboard') {
      for (let i = 0; i < current.Onboard.Links.length; i++) {
        if (current.Onboard.Links[i].ID === id) {
          console.log(
            'EffectiveLeader - Delete Onboard entry from state - APP.jsx',
            current.Onboard.Links[i],
          );
          current.Onboard.Links.splice(i, 1);
          console.log('Current State ', current);
          this.setState({ leaderLinks: current });
          break;
        }
      }
    }
    if (category === 'OtherLeaders') {
      for (let i = 0; i < current.OtherLeaders.Links.length; i++) {
        if (current.OtherLeaders.Links[i].ID === id) {
          console.log(
            'EffectiveLeader - Delete OtherLeaders entry from state - APP.jsx',
            current.OtherLeaders.Links[i],
          );
          current.OtherLeaders.Links.splice(i, 1);
          console.log('Current State ', current);
          this.setState({ leaderLinks: current });
          break;
        }
      }
    }

    if (category === 'Change') {
      for (let i = 0; i < current.Change.Links.length; i++) {
        console.log('delete change', current.Change.Links[i].ID, id);
        if (current.Change.Links[i].ID === id) {
          console.log(
            'EffectiveLeader - Delete Change entry from state - APP.jsx',
            current.Change.Links[i],
          );
          current.Change.Links.splice(i, 1);
          console.log('Current State ', current);
          this.setState({ leaderLinks: current });
          break;
        }
      }
    }
  };

  retrieveOrgChartData = async refreshing => {
    console.log('refreshing OrgChart', refreshing);
    //getWHOAMI
    if (refreshing) {
      this.fetchingOrgChart = false;
    }
    if (!this.fetchingOrgChart) {
      this.fetchOrgChartData();
    }
  };

  fetchOrgChartData = async Person => {
    console.log('retrieveOrgChartData fetchingorgChart', this.fetchingOrgChart);
    this.setState({ orgChartData: null, Org_people: null });
    this.fetchingOrgChart = true;
    const orgChartRetrieved = await getOrgChart(Person);
    if (orgChartRetrieved) {
      let orgChartData = [];
      if (orgChartRetrieved.error) {
        if (orgChartRetrieved.error) {
          console.log('APP - retrieveOrgChartData -- OrgChart Data contains an error');
          orgChartRetrieved = { error: orgChartRetrieved.error };
          toast.error(
            'Unable to retrieve OrgChart data.  Error message: ' + orgChartRetrieved.error,
          );
        }
      } else {
        console.log('APP - retrieveOrgChartData -- get OrgChart Data from callup');
        orgChartData = orgChartRetrieved;
      }
      let people = [];
      let subs = [];

      this.setState({ orgChartData });
      for (let i = 0; i < orgChartData.length; i++) {
        people[i] = orgChartData[i].TeamMate;
      }
      console.log('People for photo', people);
      // Get admin names
      let AdminNameData = this.state.AdminNameData;
      let AdminNameDataNew = await getAdminAssistants(people);
      Array.prototype.push.apply(AdminNameData, AdminNameDataNew);
      this.setState({ AdminNameData });
      // Get photos
      let orgChart_Image = [];
      let orgChart_Image2 = [];
      // Load first 7 photos then do all the rest
      orgChart_Image = await GetPhotos(people.slice(0, 7));
      this.setState({ orgChart_Image });
      orgChart_Image2 = await GetPhotos(people.slice(7));
      Array.prototype.push.apply(orgChart_Image, orgChart_Image2);
      this.setState({ orgChart_Image });
    }
  };

  retrieveWorkGroupData = async refreshing => {
    console.log('refreshing WorkGroup', refreshing);
    //getWHOAMI
    if (refreshing) {
      this.fetchingWorkGroup = false;
    }
    if (!this.fetchingWorkGroup) {
      this.fetchWorkGroupData();
    }
  };

  fetchWorkGroupData = async managerSN => {
    console.log('retrieveWorkGroupData fetchingWorkGroupData', this.fetchingWorkGroup);
    this.setState({ WorkGroupData: null });
    this.fetchingWorkGroup = true;
    const WorkGroupRetrieved = await getManagerTeam(managerSN);
    if (WorkGroupRetrieved) {
      let WorkGroupData = [];
      if (WorkGroupRetrieved.error) {
        if (WorkGroupRetrieved.error) {
          console.log('APP - retrieveWorkGroupData -- WorkGroup Data contains an error');
          WorkGroupRetrieved = { error: WorkGroupRetrieved.error };
          toast.error(
            'Unable to retrieve WorkGroup data.  Error message: ' + WorkGroupRetrieved.error,
          );
        }
      } else {
        console.log('APP - retrieveWorkGroupData -- get WorkGroup Data from callup');
        WorkGroupData = WorkGroupRetrieved.contacts;
      }
      let people = [];

      this.setState({ WorkGroupData });
      for (let i = 0; i < WorkGroupData.length; i++) {
        people[i] = WorkGroupData[i];
      }

      console.log('People for photo', people);
      let orgChart_Image = [];

      let orgChart_Image2 = [];
      orgChart_Image = await GetPhotos(people.slice(0, 7));
      this.setState({ orgChart_Image });
      orgChart_Image2 = await GetPhotos(people.slice(7));
      Array.prototype.push.apply(orgChart_Image, orgChart_Image2);
      this.setState({ orgChart_Image });
      let AdminNameData = this.state.AdminNameData;
      let AdminNameDataNew = await getAdminAssistants(people);
      Array.prototype.push.apply(AdminNameData, AdminNameDataNew);
      this.setState({ AdminNameData });
    }
  };

  retrieveAdminName = async refreshing => {
    console.log('refreshing AdminName', refreshing);

    if (refreshing) {
      this.fetchingAdminName = false;
    }

    if (!this.fetchingAdminName) {
      this.fetchAdminNameData();
    }
  };

  fetchAdminNameData = async (entry, shortname) => {
    console.log('retrieveAdminNAme fetchingAdminNameData', this.fetchingAdminNameData);

    this.fetchingAdminNameData = true;
    const { AdminNameData } = this.state;
    let current = AdminNameData;

    const AdminNameRetrieved = await getCallupShortName(shortname);
    console.log('getcallupdata for admin', AdminNameRetrieved);

    if (AdminNameRetrieved) {
      if (AdminNameRetrieved.userMessage) {
        console.log('APP - retrieveAdminName --', AdminNameRetrieved.userMessage);

        toast.error(
          'Unable to retrieve Future of Word.  Error message: ' + AdminNameRetrieved.userMessage,
        );
      } else {
        console.log('APP - retrieveAdminName');
      }
    }
    console.log('Progression of admin', AdminNameRetrieved);
    current.push({ entry, AdminNameRetrieved });
    this.setState({ AdminNameData: current });
  };
  checkSepm = async () =>  {//for if contact and isSepmChecked = false then run if statement and change is checked to true  then next time it will fail to execute 
    console.log("checkSepm was called ")
    const contact = JSON.parse(sessionStorage.getItem('contact'));
    //const idToken = sessionStorage.getItem('access_token');
    if (contact){
      const data = JSON.parse('{"shortName":"'+ contact.shortName + '"}');

        let SEPMresponse = [];
        SEPMresponse = await getCallupData(data);
        if (SEPMresponse) {
          if(SEPMresponse.data.contacts[0].jobTitle == "Manager, Software Engineering Product Manager" || SEPMresponse.data.contacts[0].jobTitle == "Director, Software Engineering Product Manager" 
          || SEPMresponse.data.contacts[0].jobTitle == "AVP, App Owner" || SEPMresponse.data.contacts[0].jobTitle == "AVP, Application Owner") 
            {
              console.log("this is the sepm is true ", " ", SEPMresponse.data.contacts[0].jobTitle, " ", data)
             // console.log("check sepm has been called and should be true ")
              this.setState({isSepm: true});
            }else{
              console.log("this is the user is not a sepm area ", " ", SEPMresponse.data.contacts[0].jobTitle)
              //const isSepm = true;
              this.setState({isSepm: false });
            //  console.log("check sepm has been called and should be false ")

              //isSepm.setState(true)
            }

       }else{
        //console.log("this is an error for no SEPMresponse data")
       }
    }
    //console.log("This is the isSepm value ", isSepm)
  };

  closeChecklist = () => {
    const { isSepm } = this.state;
    //console.log("closechecklist displaychecklist value: " + displayChecklist);
    this.setState({displayChecklist: false});
    document.body.style.overflow = "auto";
    //this.loadChecklistContent();
    window.setTimeout(function () {
      document.getElementById("dropdown-item-button").focus();
    },0)
  }
  
  loadChecklistContent = () => {
    //console.log("loadChecklistContent Called");
    const { displayChecklist } = this.state;
    //console.log("displayChecklist value: " + displayChecklist);
    const show = !displayChecklist;
    if (show) {
      document.body.scrollTop = 0; // For Safari
      document.documentElement.scrollTop = 0;
      document.body.style.overflow = "hidden";
      window.setTimeout(function () {
        document.getElementById("checklistTitle").focus();
      }, 0)
      //below sets items that should not be tabbed to -1 when checklist is active 
      try {
        document.getElementById("dropdown-item-button").tabIndex = "-1";
      } catch (e) {}
      try {
        document.getElementById("dropdown-item-button2").tabIndex = "-1";
      } catch (e) {}
      try {
        document.getElementById("dropdown-item-button3").tabIndex = "-1";
      } catch (e) {}
      try {
        document.getElementById("dropdown-item-button4").tabIndex = "-1";
      } catch (e) {}
      try {
        document.getElementById("links_bravo").tabIndex = "-1";
      } catch (e) {}
      try {
        document.getElementById("links_iiq").tabIndex = "-1";
      } catch (e) {}
      try {
        document.getElementById("links_ariba").tabIndex = "-1";
      } catch (e) {}
      try {
        document.getElementById("links_workday").tabIndex = "-1";
      } catch (e) {}
      try {
        document.getElementById("links_concur").tabIndex = "-1";
      } catch (e) {}
      try {
        document.getElementById("links_egencia").tabIndex = "-1";
      } catch (e) {}
      try {
        document.getElementById("links_MyTech").tabIndex = "-1";
      } catch (e) {}
      const tags = document.getElementsByTagName("a");
      for (let i = 0, max = tags.length; i < max; i++) {
        const classList = tags[i].className.split(" ");
        let skip = false;
        for (let c = 0; c < classList.length; c++) {
          if (classList[c] === "checkListLinks") {
            skip = true;
            break;
          }
        }
        if (!skip) {
          tags[i].tabIndex = "-1";
        }
      }
      const droptags = document.getElementsByTagName("Dropdown.Menu");
      for (let i = 0, max = droptags.length; i < max; i++) {
        // console.log(inputtags[i]);
        droptags[i].tabIndex = "-1";
      }
      const inputtags = document.getElementsByTagName("input");
      for (let i = 0, max = inputtags.length; i < max; i++) {
        // console.log(inputtags[i]);
        inputtags[i].tabIndex = "-1";
      }
      const btnTags = document.getElementsByTagName("button");
      for (let i = 0, max = btnTags.length; i < max; i++) {
        // console.log("handleNewHireDisplay", btnTags[i]);
        const classList = btnTags[i].className.split(" ");
        let skip = false;
        for (let c = 0; c < classList.length; c++) {
          if (classList[c] === "Check" || "btn checklistCloseButton") {
            skip = true;
            break;
          }
        }
        if (!skip) {
          btnTags[i].tabIndex = "-1";
        }
      }
    } else {//setting things back to default 
      document.body.style.overflow = "auto";
      try {
        document.getElementById("dropdown-item-button").tabIndex = "0";//naybe try .hidden
      } catch (e) {}
      try {
        document.getElementById("dropdown-item-button2").tabIndex = "0";
      } catch (e) {}
      try {
        document.getElementById("dropdown-item-button3").tabIndex = "0";
      } catch (e) {}
      try {
        document.getElementById("dropdown-item-button4").tabIndex = "0";
      } catch (e) {}
      try {
        document.getElementById("links_bravo").tabIndex = "0";
      } catch (e) {}
      try {
        document.getElementById("links_iiq").tabIndex = "0";
      } catch (e) {}
      try {
        document.getElementById("links_ariba").tabIndex = "0";
      } catch (e) {}
      try {
        document.getElementById("links_workday").tabIndex = "0";
      } catch (e) {}
      try {
        document.getElementById("links_concur").tabIndex = "0";
      } catch (e) {}
      try {
        document.getElementById("links_egencia").tabIndex = "0";
      } catch (e) {}
      try {
        document.getElementById("links_MyTech").tabIndex = "0";
      } catch (e) {}
      const tags = document.getElementsByTagName("a");
      for (let i = 0, max = tags.length; i < max; i++) {
        const classList = tags[i].className.split(" ");
        let skip = false;
        for (let c = 0; c < classList.length; c++) {
          if (classList[c] === "checkListLinks") {
            skip = true;
            console.log("value of skip when closing ", skip)
            break;
          }
        }
        if (!skip) {
          tags[i].tabIndex = "0";
        }
      }
      const droptags = document.getElementsByTagName("Dropdown.Menu");
      for (let i = 0, max = droptags.length; i < max; i++) {
        // console.log(inputtags[i]);
        droptags[i].tabIndex = "0";
      }
      const inputtags = document.getElementsByTagName("input");
      for (let i = 0, max = inputtags.length; i < max; i++) {
        // console.log(inputtags[i]);
        inputtags[i].tabIndex = "0";
      }
      const btnTags = document.getElementsByTagName("button");
      for (let i = 0, max = btnTags.length; i < max; i++) {
        // console.log("handleNewHireDisplay", btnTags[i]);
        const classList = btnTags[i].className.split(" ");
        let skip = false;
        for (let c = 0; c < classList.length; c++) {
          if (classList[c] === "Check" || "btn checklistCloseButton") {
            skip = true;
            break;
          }
        }
        if (!skip) {
          btnTags[i].tabIndex = "0";
        }
      }
    }
      this.setState({ displayChecklist: show });   
      document.getElementById("checklistTitle").focus();        
      console.log("displayChecklist value after show is called: " + this.state.displayChecklist);
      //set a this .setState to work to remove the menu 
      this.setState({ menuExpanded: false });
      this.setState({ changeMenuExpanded: false});
     // console.log("this is the tab index for dropdown at end of function ", document.getElementById("dropdown-item-button").tabIndex)
    
    }


  render() {
    const {
      user,
      userPhoto,
      searchQuery,
      short_name,
      body_post,
      searchData,
      isAdmin,
      isSepm,
      searching,
      searchingCallup,
      announcementId,
      workingAnnouncement,
      unfilteredNumber,
      filteredNumber,
      searchHistoryId,
      callUpSNData,
      Callup_Image,
      HRK_Error,
      People_Error,
      HRK_Display,
      People_Display,
      menuExpanded,
      team,
      teamMax,
      approvalMax,
      approvals,
      approvalsLoaded,
      calendarData,
      timeoffMax,
      birthdayMax,
      holidayMax,
      anniversaryMax,
      calendarRange,
      bravoData,
      bravosLoaded,
      leaderLinks,
      leaderLinksLoaded,
      restrictKnowledgeData,
      snowApprovals,
      aribaApprovals,
      iiqApprovals,
      otApprovals,
      aribaContractApprovals,
      AdminNameData,
      orgChartData,
      orgChart_Image,
      WorkGroupData,
      displayChecklist
    } = this.state;
    return (
      <React.Fragment>
        <BrowserRouter>
          <Switch>
            <Route path="/not-authorized" component={NotAuthorized} />
            <Route path="/errorpage" component={ErrorPage} />
            <Route path="/oidc-client-callback" component={Callback} />
            <Route path="/auth-callback" component={Callback} />
            <Route path="/displayAnnouncement/:id" component={DisplayAnnouncement} />
            <Route path="/leaderTransitionChecklist" component={LeaderTransitionChecklist}/>
            <Route
              path="/AdvancedPeopleSearch"
              render={props => (
                <Home
                  aribaContractApprovals={aribaContractApprovals}
                  fetchAribaContractApprovalData={this.retrieveAribaContractApprovalData}
                  snowApprovals={snowApprovals}
                  aribaApprovals={aribaApprovals}
                  iiqApprovals={iiqApprovals}
                  otApprovals={otApprovals}
                  fetchSnowApprovalData={this.retrieveSnowApprovalData}
                  fetchAribaApprovalData={this.retrieveAribaApprovalData}
                  fetchIIQApprovalData={this.retrieveIIQApprovalData}
                  fetchOTApprovalData={this.retrieveOTApprovalData}
                  advancedPplUrl={true}
                  WorkGroupData={WorkGroupData}
                  fetchWorkGroupData={this.fetchWorkGroupData}
                  orgChart_Image={orgChart_Image}
                  orgChartData={orgChartData}
                  fetchOrgChartData={this.fetchOrgChartData}
                  fetchAdminNameData={this.fetchAdminNameData}
                  AdminNameData={AdminNameData}
                  reSearchKnowledge={this.reSearchKnowledge}
                  restrictKnowledgeData={restrictKnowledgeData}
                  loadMoreKnowledge={this.loadMoreKnowledge}
                  updateLeaderLinks={this.updateLeaderLinks}
                  addEffLdLink={this.addEffLdLink}
                  deleteLeaderLinks={this.deleteLeaderLinks}
                  leaderLinks={leaderLinks}
                  leaderLinksLoaded={leaderLinksLoaded}
                  retrieveEffectiveLeaderData={this.retrieveEffectiveLeaderData}
                  deleteEffLdLink={this.deleteEffLdLink}
                  retrieveBravos={this.retrieveBravos}
                  bravoData={bravoData}
                  bravosLoaded={bravosLoaded}
                  calendarData={calendarData}
                  retrieveCalendarData={refreshing => this.retrieveCalendarData(refreshing)}
                  approvals={approvals}
                  approvalsLoaded={approvalsLoaded}
                  approvalMax={approvalMax}
                  handleChangeMaxApprovals={teamMax => this.handleChangeMaxApprovals(teamMax)}
                  handleRemoveApproval={idx => this.handleRemoveApproval(idx)}
                  team={team}
                  teamMax={teamMax}
                  handleChangeMaxTeam={teamMax => this.handleChangeMaxTeam(teamMax)}
                  timeoffMax={timeoffMax}
                  handleChangeMaxTimeOff={timeoffMax => this.handleChangeMaxTimeOff(timeoffMax)}
                  calendarRange={calendarRange}
                  handleChangeCalendarRange={calendarRange =>
                    this.handleChangeCalendarRange(calendarRange)
                  }
                  birthdayMax={birthdayMax}
                  handleChangeMaxBirthdays={birthdayMax =>
                    this.handleChangeMaxBirthdays(birthdayMax)
                  }
                  holidayMax={holidayMax}
                  handleChangeMaxHolidays={holidayMax => this.handleChangeMaxHolidays(holidayMax)}
                  anniversaryMax={anniversaryMax}
                  handleChangeMaxAnniversary={anniversaryMax =>
                    this.handleChangeMaxAnniversary(anniversaryMax)
                  }
                  menuExpanded={menuExpanded}
                  changeMenuExpanded={this.handleChangeMenuExpanded}
                  user={user}
                  userPhoto={userPhoto}
                  searchQuery={searchQuery}
                  short_name={short_name}
                  body_post={body_post}
                  searchData={searchData}
                  unfilteredNumber={unfilteredNumber}
                  filteredNumber={filteredNumber}
                  isAdmin={isAdmin}
                  searching={searching}
                  searchingCallup={searchingCallup}
                  onUserUpate={user => this.handleUserUpdate(user)}
                  workingAnnouncement={workingAnnouncement}
                  announcementId={announcementId}
                  loadChecklistContent ={this.loadChecklistContent}
                  closeChecklist = {this.loadChecklistContent}
                  isSepm = {isSepm}
                  checkSepm = {this.checkSepm}
                  displayChecklist ={displayChecklist}
                  onAnnouncementIdUpdate={announcmentId =>
                    this.handleAnnouncementIdChange(announcmentId)
                  }
                  onSearchQueryUpdate={searchQuery => this.handleSearchQueryUpdate(searchQuery)}
                  onSearchQueryUpdateCallup={searchQuery =>
                    this.handleSearchQueryCallup(searchQuery)
                  }
                  handleManagerHyperlink={searchQuery => this.handleManagerHyperlink(searchQuery)}
                  onSearchQueryAPS={(short_name, body_post, body_post2) =>
                    this.handleCallupAdvancedSearch(short_name, body_post, body_post2)
                  }
                  callUpSNData={callUpSNData}
                  Callup_Image={Callup_Image}
                  HRK_Error={HRK_Error}
                  People_Error={People_Error}
                  HRK_Display={HRK_Display}
                  People_Display={People_Display}
                  onWorkingAnnouncementChange={workingAnnouncement =>
                    this.handleWorkingAnnouncementChange(workingAnnouncement)
                  }
                  searchHistoryId={searchHistoryId}
                  retrieveFOWData={refreshing => this.retrieveFOWData(refreshing)}
                  changeTeamDisplay={memberIdx => this.changeTeamDisplay(memberIdx)}
                  changeWholeTeamDisplay={display => this.changeWholeTeamDisplay(display)}
                  retrieveTeam={() => this.refreshTeam()}
                  retrieveApprovals={() => this.retrieveApprovals()}
                  {...props}
                />
              )}
            />
            <Route
              path="/"
              render={props => (
                <Home
                  aribaContractApprovals={aribaContractApprovals}
                  fetchAribaContractApprovalData={this.retrieveAribaContractApprovalData}
                  snowApprovals={snowApprovals}
                  aribaApprovals={aribaApprovals}
                  iiqApprovals={iiqApprovals}
                  otApprovals={otApprovals}
                  fetchSnowApprovalData={this.retrieveSnowApprovalData}
                  fetchAribaApprovalData={this.retrieveAribaApprovalData}
                  fetchIIQApprovalData={this.retrieveIIQApprovalData}
                  fetchOTApprovalData={this.retrieveOTApprovalData}
                  advancedPplUrl={false}
                  WorkGroupData={WorkGroupData}
                  fetchWorkGroupData={this.fetchWorkGroupData}
                  orgChart_Image={orgChart_Image}
                  orgChartData={orgChartData}
                  fetchOrgChartData={this.fetchOrgChartData}
                  fetchAdminNameData={this.fetchAdminNameData}
                  AdminNameData={AdminNameData}
                  reSearchKnowledge={this.reSearchKnowledge}
                  restrictKnowledgeData={restrictKnowledgeData}
                  loadMoreKnowledge={this.loadMoreKnowledge}
                  updateLeaderLinks={this.updateLeaderLinks}
                  addEffLdLink={this.addEffLdLink}
                  deleteLeaderLinks={this.deleteLeaderLinks}
                  leaderLinks={leaderLinks}
                  leaderLinksLoaded={leaderLinksLoaded}
                  retrieveEffectiveLeaderData={this.retrieveEffectiveLeaderData}
                  deleteEffLdLink={this.deleteEffLdLink}
                  retrieveBravos={this.retrieveBravos}
                  bravoData={bravoData}
                  bravosLoaded={bravosLoaded}
                  calendarData={calendarData}
                  retrieveCalendarData={refreshing => this.retrieveCalendarData(refreshing)}
                  approvals={approvals}
                  approvalsLoaded={approvalsLoaded}
                  approvalMax={approvalMax}
                  handleChangeMaxApprovals={teamMax => this.handleChangeMaxApprovals(teamMax)}
                  handleRemoveApproval={idx => this.handleRemoveApproval(idx)}
                  team={team}
                  teamMax={teamMax}
                  handleChangeMaxTeam={teamMax => this.handleChangeMaxTeam(teamMax)}
                  timeoffMax={timeoffMax}
                  handleChangeMaxTimeOff={timeoffMax => this.handleChangeMaxTimeOff(timeoffMax)}
                  calendarRange={calendarRange}
                  handleChangeCalendarRange={calendarRange =>
                    this.handleChangeCalendarRange(calendarRange)
                  }
                  birthdayMax={birthdayMax}
                  handleChangeMaxBirthdays={birthdayMax =>
                    this.handleChangeMaxBirthdays(birthdayMax)
                  }
                  holidayMax={holidayMax}
                  handleChangeMaxHolidays={holidayMax => this.handleChangeMaxHolidays(holidayMax)}
                  anniversaryMax={anniversaryMax}
                  handleChangeMaxAnniversary={anniversaryMax =>
                    this.handleChangeMaxAnniversary(anniversaryMax)
                  }
                  menuExpanded={menuExpanded}
                  changeMenuExpanded={this.handleChangeMenuExpanded}
                  user={user}
                  userPhoto={userPhoto}
                  searchQuery={searchQuery}
                  short_name={short_name}
                  body_post={body_post}
                  searchData={searchData}
                  unfilteredNumber={unfilteredNumber}
                  filteredNumber={filteredNumber}
                  isAdmin={isAdmin}
                  isSepm = {isSepm}
                  checkSepm = {this.checkSepm}// will keep running if called normally need to limit it to one call {this.checkSepm()}// eventully leads to 504 badgateway 
                  searching={searching}
                  searchingCallup={searchingCallup}
                  onUserUpate={user => this.handleUserUpdate(user)}
                  workingAnnouncement={workingAnnouncement}
                  announcementId={announcementId}
                  loadChecklistContent ={this.loadChecklistContent}
                  closeChecklist={this.closeChecklist}
                  displayChecklist ={displayChecklist}
                  onAnnouncementIdUpdate={announcmentId =>
                    this.handleAnnouncementIdChange(announcmentId)
                  }
                  onSearchQueryUpdate={searchQuery => this.handleSearchQueryUpdate(searchQuery)}
                  onSearchQueryUpdateCallup={searchQuery =>
                    this.handleSearchQueryCallup(searchQuery)
                  }
                  handleManagerHyperlink={searchQuery => this.handleManagerHyperlink(searchQuery)}
                  onSearchQueryAPS={(short_name, body_post, body_post2) =>
                    this.handleCallupAdvancedSearch(short_name, body_post, body_post2)
                  }
                  callUpSNData={callUpSNData}
                  Callup_Image={Callup_Image}
                  HRK_Error={HRK_Error}
                  People_Error={People_Error}
                  HRK_Display={HRK_Display}
                  People_Display={People_Display}
                  onWorkingAnnouncementChange={workingAnnouncement =>
                    this.handleWorkingAnnouncementChange(workingAnnouncement)
                  }
                  searchHistoryId={searchHistoryId}
                  retrieveFOWData={refreshing => this.retrieveFOWData(refreshing)}
                  changeTeamDisplay={memberIdx => this.changeTeamDisplay(memberIdx)}
                  changeWholeTeamDisplay={display => this.changeWholeTeamDisplay(display)}
                  retrieveTeam={() => this.refreshTeam()}
                  retrieveApprovals={this.retrieveApprovals}
                  {...props}
                />
              )}
            />
          </Switch>
        </BrowserRouter>
        <ToastContainer />
      </React.Fragment>
    );
  }
}
export default App;
