import axios from 'axios';
import config from '../config.json';
import { getRandomString } from './AuthService';
import { getEnvironment } from './EnvironmentVariable';

export const getCallupShortName = async searchText => {
  const message = getRandomString(25);
  const environ = getEnvironment();
  let callupAPI = config.callupAPI;
  let callupKey = config.callupKey;
  if (environ !== 'DEFAULT') {
    // if (isMobile) {
    //   callupAPI = config.Mobile_test_callupAPI;
    // } else {
    // console.log("callupService.getCallupShortName environ", environ);
    callupAPI = config.test_callupAPI;
    callupKey = config.test_callupKey;
    // }
  }

  const body = {
    shortName: encodeURIComponent(searchText),
  };

  let headers = new Headers();
  headers.append('Content-Type', 'application/json');
  headers.append('X-NW-Message-ID', message);

  const token = sessionStorage.getItem('access_token');
  //const token = config.token;
  headers.append('Authorization', 'Bearer ' + token);
  headers.set('client_id', callupKey);

  const response = await fetch(callupAPI, {
    method: 'POST',
    headers: headers,
    body: JSON.stringify(body),
  });

  if (response.ok) {
    try {
      const jsondata = await response.json();
      return jsondata;
    } catch (e) {
      console.error('callupService.getCallupShortName Callup API error', e);
      return null;
    }
  }
  return null;
};

export const getCallupData = async body_post => {
  const message = getRandomString(25);
  const environ = getEnvironment();
  const token = sessionStorage.getItem('access_token');
  let callupAPI = config.callupAPI;
  let callupKey = config.callupKey;
  if (environ !== 'DEFAULT') {
    // if (isMobile) {
    // callupAPI = config.Mobile_test_callupAPI;
    // } else {
    // console.log("jobService.getCallupData environ", environ);
    callupAPI = config.test_callupAPI;
    callupKey = config.test_callupKey;
    // }
  }
  try {
    const response = await axios.post(`${callupAPI}`, JSON.stringify(body_post), {
      headers: {
        client_id: callupKey,
        'X-NW-Message-ID': message,
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
    });

    // const jsondata = await response.json();
    // console.log("BodyPost", jsondata);
    return response;
  } catch (e) {
    console.error('callupService.getCallupData Callup people API error', e);
    return null;
  }
};

export const getAdminAssistants = async admindata => {
  const message = getRandomString(25);
  const environ = getEnvironment();
  let callupAPI = config.callupAPI;
  let callupKey = config.callupKey;
  if (environ !== 'DEFAULT') {
    // if (isMobile) {
    // callupAPI = config.Mobile_test_callupAPI;
    // } else {
    // console.log('jobService.getState environ', environ);
    callupAPI = config.test_callupAPI;
    callupKey = config.test_callupKey;
    // }
  }
  let admins = [];
  // console.log("currentSN", admindata);
  for (let i = 0; i < admindata.length; i++) {
    let currentSN = admindata[i].adminID;

    if (currentSN) {
      const body = {
        shortName: encodeURIComponent(currentSN),
      };

      console.log('currentSN', currentSN);

      let headers = new Headers();
      headers.append('Content-Type', 'application/json');
      headers.append('X-NW-Message-ID', message);

      const token = sessionStorage.getItem('access_token');
      //const token = config.token;
      headers.append('Authorization', 'Bearer ' + token);
      headers.set('client_id', callupKey);

      const response = await fetch(callupAPI, {
        method: 'POST',
        headers: headers,
        body: JSON.stringify(body),
      });

      if (response.ok) {
        try {
          const jsondata = await response.json();
          // return jsondata;
          admins.push(jsondata);
        } catch (e) {
          console.log('jobService.getCallupShortName Callup API error', e);
          return null;
        }
      }
    } else {
      //admins.push({});
    }
  }
  return admins;
};
