import React, { Component } from 'react';
import Announcements from '../common/announcements';
import LinksBar from '../common/linksbar';
// import MyTeam from "../components/myTeam.jsx";
import TILES from '../common/tiles';
// import { isBrowser } from "react-device-detect";
//import { JobChangeChecklist } from './jobChange';
// import Footer from "../common/footer";

class DefaultPage extends Component {
  render() {
    const {
      team,
      teamMax,
      approvals,
      approvalsLoaded,
      approvalMax,
      calendarData,
      timeoffMax,
      birthdayMax,
      holidayMax,
      anniversaryMax,
      calendarRange,
      bravoData,
      retrieveBravos,
      bravosLoaded,
      leaderLinks,
      leaderLinksLoaded,
      retrieveEffectiveLeaderData,
      isAdmin,
      showAdminEffectiveLd,
      fetchOTApprovalData,
      fetchIIQApprovalData,
      fetchAribaApprovalData,
      fetchSnowApprovalData,
      snowApprovals,
      aribaApprovals,
      iiqApprovals,
      otApprovals,
      aribaContractApprovals,
      fetchAribaContractApprovalData,
      launchChecklistContent,
      isSepm,
      displayChecklist
    } = this.props;
    // console.log("defaultPage props", this.props);
    return (
      <div style={{ backgroundColor: '#DEE6F2' }}>
        <Announcements />
        <LinksBar team={team} />
        <TILES
          launchChecklistContent={launchChecklistContent}
          loadChecklistContent={this.props.loadChecklistContent}
          displayChecklist={displayChecklist}
          isSepm={ isSepm }
          aribaContractApprovals={aribaContractApprovals}
          fetchAribaContractApprovalData={fetchAribaContractApprovalData}
          snowApprovals={snowApprovals}
          aribaApprovals={aribaApprovals}
          iiqApprovals={iiqApprovals}
          otApprovals={otApprovals}
          fetchOTApprovalData={fetchOTApprovalData}
          fetchIIQApprovalData={fetchIIQApprovalData}
          fetchAribaApprovalData={fetchAribaApprovalData}
          fetchSnowApprovalData={fetchSnowApprovalData}
          showAdminEffectiveLd={showAdminEffectiveLd}
          isAdmin={isAdmin}
          leaderLinks={leaderLinks}
          leaderLinksLoaded={leaderLinksLoaded}
          retrieveEffectiveLeaderData={retrieveEffectiveLeaderData}
          retrieveBravos={() => this.props.retrieveBravos()}
          bravoData={bravoData}
          bravosLoaded={bravosLoaded}
          calendarData={calendarData}
          retrieveCalendarData={refreshing => this.props.retrieveCalendarData(refreshing)}
          team={team}
          teamMax={teamMax}
          approvals={approvals}
          approvalsLoaded={approvalsLoaded}
          approvalMax={approvalMax}
          handleChangeMaxTeam={teamMax => this.props.handleChangeMaxTeam(teamMax)}
          handleChangeMaxApprovals={teamMax => this.props.handleChangeMaxApprovals(teamMax)}
          retrieveFOWData={refreshing => this.props.retrieveFOWData(refreshing)}
          changeTeamDisplay={memberIdx => this.props.changeTeamDisplay(memberIdx)}
          changeWholeTeamDisplay={display => this.props.changeWholeTeamDisplay(display)}
          retrieveTeam={() => this.props.retrieveTeam()}
          retrieveApprovals={this.props.retrieveApprovals}
          handleRemoveApproval={idx => this.props.handleRemoveApproval(idx)}
          timeoffMax={timeoffMax}
          handleChangeMaxTimeOff={timeoffMax => this.props.handleChangeMaxTimeOff(timeoffMax)}
          calendarRange={calendarRange}
          handleChangeCalendarRange={calendarRange =>
            this.props.handleChangeCalendarRange(calendarRange)
          }
          birthdayMax={birthdayMax}
          handleChangeMaxBirthdays={birthdayMax => this.props.handleChangeMaxBirthdays(birthdayMax)}
          holidayMax={holidayMax}
          handleChangeMaxHolidays={holidayMax => this.props.handleChangeMaxHolidays(holidayMax)}
          anniversaryMax={anniversaryMax}
          handleChangeMaxAnniversary={anniversaryMax =>
            this.props.handleChangeMaxAnniversary(anniversaryMax)
          }
        />   
      </div>
    );
  }
}

export default DefaultPage;
