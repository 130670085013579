import React, { Component } from 'react';
import Header from '../common/header';
import AdvancedPeopleSearch from '../components/AdvancedPeopleSearch';
import AnnouncementEdit from '../components/admin/announcementEdit';
import AnnouncementList from '../components/admin/announcementList';
import Authorization from '../components/authorization';
import DefaultPage from '../components/defaultPage';
import ServiceNowSearch from '../components/serviceNowSearch';
// import AdminUserList from "../components/admin/adminUserList";
import * as microsoftTeams from '@microsoft/teams-js';
import { isBrowser } from 'react-device-detect';
import { toast } from 'react-toastify';
import Footer from '../common/footer';
import Spinner from '../common/spinner';
import { getAzureAuthUrl } from '../services/AuthService';
import { getEnvironment } from '../services/EnvironmentVariable';
import testers from '../testers.json';
import EffectiveLdAdmin from './EffectiveLdAdmin';
import LeaderTransitionChecklist from './LeaderTransitionChecklist';
import PeopleOrgChart from './PeopleOrgChart';
import PeopleWorkGroup from './PeopleWorkGroup';

class Home extends Component {
  state = {
    displayDefaultPage: 'block',
    displaySearchResults: 'none',
    displayAdvancedPeopleSearch: 'none',
    displayAnnounceList: 'none',
    displayAnnounceEdit: 'none',
    displayAdminUserList: 'none',
    forceAnnounceSearchReload: 'Y',
    forceAdminUserSearchReload: 'N',
    displayAdminEffectiveLd: 'none',
    displayOrgChart: 'none',
    displayWorkGroup: 'none',
    orgChartDisplay: '',
    workGroupDisplay: ''
  };

  componentDidMount() {
    // const contact = JSON.parse(sessionStorage.getItem("contact"));
    if (isBrowser) {
      console.log('BROWSER TYPE', 'DESKTOP');
    } else {
      console.log('BROWSER TYPE', 'MOBILE');
    }
    const userinfo = JSON.parse(sessionStorage.getItem('userinfo'));
    if (!userinfo) {
      try {
        const search = window.location.search;

        console.log('HOME componentDidMount search', search);
        const searchP = new URLSearchParams(search);

        console.log('HOME componentDidMount searchP', searchP);
        const inTeams = searchP.get('inTeamsSSO');
        console.log('HOME componentDidMount inTeams', inTeams);
        // alert('HOME componentDidMount inTeams ');
        // alert(inTeams);

        if (inTeams) {
          console.log('TEAMS!');
          console.log('HOME componentDidMount Try teams initialize');
          // alert('HOME componentDidMount Try teams initialize');
          microsoftTeams.initialize();
          var authTokenRequest = {
            successCallback: function(result) {
              console.log('HOME componentDidMount teams result', result);

              const loc = window.location.hostname;
              const url = 'https://' + loc + '/oidc-client-callback#id_token=' + result;

              // const url = getAzureAuthUrl();
              console.log('home.componentDidMount azure url', url);
              // alert(url);
              window.location = url;
            },
            failureCallback: function(error) {
              console.error('HOME componentDidMount teams error', error);
              alert('HOME componentDidMount teams error ' + error);
              // alert(error);
            },
          };
          console.log('HOME componentDidMount call microsoftTeams.authentication');
          microsoftTeams.authentication.getAuthToken(authTokenRequest);
          console.log('HOME componentDidMount callED microsoftTeams.authentication');
          // alert('HOME componentDidMount callED microsoftTeams.authentication');
          console.log('HOME componentDidMount microsoftTeams.ErrorCode', microsoftTeams.ErrorCode);

          console.log('HOME componentDidMount microsoftTeams', microsoftTeams);
        } else {
          const url = getAzureAuthUrl();
          console.log('home.componentDidMount azure url', url);
          window.location = url;
        }
      } catch (e) {
        alert('HOME componentDidMount error during ms teams initalize ' + e);
        console.error('HOME componentDidMount error during ms teams initalize ', e);
        toast.error('HOME componentDidMount error during ms teams initalize ' + JSON.stringify(e));
        const url = getAzureAuthUrl();
        console.log('home.componentDidMount azure url', url);

        window.location = url;
      }
    } else {
      const { startPage } = this.props;
      if (startPage === 'AnnounceList') {
        this.showAnnounceList();
      }
      if (startPage === 'AnnounceEdit') {
        this.showAnnounceEdit();
      }
    }
    const { advancedPplUrl } = this.props;
    if (advancedPplUrl) {
      console.log('In advanced people search mode: ', advancedPplUrl);
      this.showAdvancedSearch();
    }
  }

  notALeader = () => {
    window.location.href = 'https://hrconnect.nationwide.com';
  };

  isALeader = contact => {
    // console.log("home isALeader start");

    if (contact) {
      if (contact.managerFlag === '1' || contact.isPortalAdmin === 'Y') {
        return true;
      }
    }
    const testUsers = testers.users;
    for (let x = 0; x < testUsers.length; x++) {
      const tester = testUsers[x];
      if (tester.shortName.toUpperCase() === contact.shortName.toUpperCase()) {
        //User shortname matches to a tester
        const environ = getEnvironment();
        if (environ === 'DEFAULT') {
          //This is the production environment
          if (test.prodAllowed) {
            //This tester is allowed to mimic in production
            return true;
          }
        } else {
          //This is not a production environment
          return true;
        }
        break;
      }
    }
    return false;
  };

  showSearchResults = () => {
    const displayDefaultPage = 'none';
    const displaySearchResults = 'block';
    const displayAdvancedPeopleSearch = 'none';
    const displayAnnounceList = 'none';
    const displayAnnounceEdit = 'none';
    const displayAdminUserList = 'none';
    const displayAdminEffectiveLd = 'none';
    const displayOrgChart = 'none';
    const displayWorkGroup = 'none';
    this.setState({
      displayDefaultPage,
      displaySearchResults,
      displayAdvancedPeopleSearch,
      displayAnnounceList,
      displayAnnounceEdit,
      displayAdminUserList,
      displayAdminEffectiveLd,
      displayOrgChart,
      displayWorkGroup,
    });
  };

  showAdminEffectiveLd = () => {
    const displayDefaultPage = 'none';
    const displaySearchResults = 'none';
    const displayAdvancedPeopleSearch = 'none';
    const displayAnnounceList = 'none';
    const displayAnnounceEdit = 'none';
    const displayAdminUserList = 'none';
    const displayAdminEffectiveLd = 'block';
    const displayOrgChart = 'none';
    const displayWorkGroup = 'none';
    this.setState({
      displayDefaultPage,
      displaySearchResults,
      displayAdvancedPeopleSearch,
      displayAnnounceList,
      displayAnnounceEdit,
      displayAdminUserList,
      displayAdminEffectiveLd,
      displayOrgChart,
      displayWorkGroup,
    });
  };

  showAdvancedSearch = () => {
    const displayDefaultPage = 'none';
    const displaySearchResults = 'none';
    const displayAdvancedPeopleSearch = 'block';
    const displayAnnounceList = 'none';
    const displayAnnounceEdit = 'none';
    const displayAdminUserList = 'none';
    const displayAdminEffectiveLd = 'none';
    const displayOrgChart = 'none';
    const displayWorkGroup = 'none';
    this.setState({
      displayDefaultPage,
      displaySearchResults,
      displayAdvancedPeopleSearch,
      displayAnnounceList,
      displayAnnounceEdit,
      displayAdminUserList,
      displayAdminEffectiveLd,
      displayOrgChart,
      displayWorkGroup,
    });
  };

  showDefault = () => {
    const displayDefaultPage = 'block';
    const displaySearchResults = 'none';
    const displayAdvancedPeopleSearch = 'none';
    const displayAnnounceList = 'none';
    const displayAnnounceEdit = 'none';
    const displayAdminUserList = 'none';
    const displayAdminEffectiveLd = 'none';
    const displayOrgChart = 'none';
    const displayWorkGroup = 'none';
    this.setState({
      displayDefaultPage,
      displaySearchResults,
      displayAdvancedPeopleSearch,
      displayAnnounceList,
      displayAnnounceEdit,
      displayAdminUserList,
      displayAdminEffectiveLd,
      displayOrgChart,
      displayWorkGroup
    });
  };
  showAnnounceList = (announceId, forceAnnounceSearchReload) => {
    const displayDefaultPage = 'none';
    const displaySearchResults = 'none';
    const displayAdvancedPeopleSearch = 'none';
    const displayAnnounceList = 'block';
    const displayAnnounceEdit = 'none';
    const displayAdminUserList = 'none';
    const displayAdminEffectiveLd = 'none';
    const displayOrgChart = 'none';
    const displayWorkGroup = 'none';
    this.setState({
      displayDefaultPage,
      displaySearchResults,
      displayAdvancedPeopleSearch,
      displayAnnounceList,
      displayAnnounceEdit,
      displayAdminUserList,
      displayAdminEffectiveLd,
      announceId,
      forceAnnounceSearchReload,
      displayOrgChart,
      displayWorkGroup,
    });
  };

  showAdminUserList = forceAdminUserSearchReload => {
    const displayDefaultPage = 'none';
    const displaySearchResults = 'none';
    const displayAdvancedPeopleSearch = 'none';
    const displayAnnounceList = 'none';
    const displayAnnounceEdit = 'none';
    const displayAdminUserList = 'block';
    const displayAdminEffectiveLd = 'none';
    const displayOrgChart = 'none';
    const displayWorkGroup = 'none';
    this.setState({
      displayDefaultPage,
      displaySearchResults,
      displayAdvancedPeopleSearch,
      displayAnnounceList,
      displayAnnounceEdit,
      displayAdminUserList,
      forceAdminUserSearchReload,
      displayAdminEffectiveLd,
      displayOrgChart,
      displayWorkGroup,
    });
  };

  showMessage = msg => {
    toast.info(msg);
  };

  openLinkInNewWindow = url => {
    const newWin = window.open(url, 'blank');
    if (!newWin || newWin.closed || typeof newWin.closed == 'undefined') {
      toast.error('You appear to have a popup blocker enabled.');
    }
  };

  showAnnounceEdit = () => {
    const displayDefaultPage = 'none';
    const displaySearchResults = 'none';
    const displayAdvancedPeopleSearch = 'none';
    const displayAnnounceList = 'none';
    const displayAnnounceEdit = 'block';
    const displayAdminUserList = 'none';
    const displayAdminEffectiveLd = 'none';
    const displayOrgChart = 'none';
    const displayWorkGroup = 'none';
    this.setState({
      displayDefaultPage,
      displaySearchResults,
      displayAdvancedPeopleSearch,
      displayAnnounceList,
      displayAnnounceEdit,
      displayAdminUserList,
      displayAdminEffectiveLd,
      displayOrgChart,
      displayWorkGroup,
    });
  };

  showWorkGroup = item => {
    const displayDefaultPage = 'none';
    const displaySearchResults = 'none';
    const displayAdvancedPeopleSearch = 'none';
    const displayAnnounceList = 'none';
    const displayAnnounceEdit = 'none';
    const displayAdminUserList = 'none';
    const displayAdminEffectiveLd = 'none';
    const displayOrgChart = 'none';
    const displayWorkGroup = 'block';
    const workGroupDisplay = item;
    console.log('Showing work group for: ', workGroupDisplay.shortName);
    this.setState({
      displayDefaultPage,
      displaySearchResults,
      displayAdvancedPeopleSearch,
      displayAnnounceList,
      displayAnnounceEdit,
      displayAdminUserList,
      displayAdminEffectiveLd,
      displayOrgChart,
      displayWorkGroup,
      workGroupDisplay,
    });
  };

  showOrgChart = item => {
    const displayDefaultPage = 'none';
    const displaySearchResults = 'none';
    const displayAdvancedPeopleSearch = 'none';
    const displayAnnounceList = 'none';
    const displayAnnounceEdit = 'none';
    const displayAdminUserList = 'none';
    const displayAdminEffectiveLd = 'none';
    const displayOrgChart = 'block';
    const displayWorkGroup = 'none';
    const orgChartDisplay = item;
    console.log('Showing org chart for: ', orgChartDisplay);
    this.setState({
      displayDefaultPage,
      displaySearchResults,
      displayAdvancedPeopleSearch,
      displayAnnounceList,
      displayAnnounceEdit,
      displayAdminUserList,
      displayAdminEffectiveLd,
      displayOrgChart,
      displayWorkGroup,
      orgChartDisplay,
    });
  };

  submitSearch = searchQuery => {
    this.props.onSearchQueryUpdate(searchQuery);
    this.props.onSearchQueryUpdateCallup(searchQuery);
    this.showSearchResults();
  };

  managerSearch = searchQuery => {
    this.props.handleManagerHyperlink(searchQuery);
    this.showSearchResults();
  };

  apsSearch = (short_name, body_post, body_post2) => {
    // console.log("home apsSearch APS Submit here");
    this.props.onSearchQueryAPS(short_name, body_post, body_post2);
    this.showSearchResults();
  };

  testRefresh() {
    const url = getAzureAuthUrl();
    sessionStorage.setItem('refreshing', 'true');
    localStorage.setItem('refreshing', 'true');
    // console.log("home.testrefresh url", url);
    window.open(
      url,
      '_blank',
      'height=100,width=100,location=yes,menubar=no,resizable=no,titlebar=yes',
    );

    // console.log("home.testrefresh windows opened?", t);
  }

  renderDisplay() {
    const {
      displayDefaultPage,
      displaySearchResults,
      displayAnnounceList,
      displayAnnounceEdit,
      displayAdvancedPeopleSearch,
      forceAnnounceSearchReload,
      displayAdminEffectiveLd,
      displayOrgChart,
      orgChartDisplay,
      displayWorkGroup,
      workGroupDisplay,
    } = this.state;
    const {
      user,
      searchQuery,
      searchData,
      isAdmin,
      searching,
      searchingCallup,
      announcementId,
      workingAnnouncement,
      onWorkingAnnouncementChange,
      unfilteredNumber,
      filteredNumber,
      searchHistoryId,
      callUpSNData,
      Callup_Image,
      HRK_Error,
      People_Error,
      HRK_Display,
      People_Display,
      menuExpanded,
      team,
      teamMax,
      approvals,
      approvalsLoaded,
      approvalMax,
      calendarData,
      timeoffMax,
      birthdayMax,
      holidayMax,
      anniversaryMax,
      calendarRange,
      bravoData,
      retrieveBravos,
      bravosLoaded,
      leaderLinks,
      leaderLinksLoaded,
      retrieveEffectiveLeaderData,
      deleteLeaderLinks,
      deleteEffLdLink,
      addEffLdLink,
      updateLeaderLinks,
      restrictKnowledgeData,
      reSearchKnowledge,
      loadMoreKnowledge,
      fetchOTApprovalData,
      fetchIIQApprovalData,
      fetchAribaApprovalData,
      fetchSnowApprovalData,
      snowApprovals,
      aribaApprovals,
      iiqApprovals,
      otApprovals,
      aribaContractApprovals,
      fetchAribaContractApprovalData,
      fetchAdminNameData,
      AdminNameData,
      orgChartData,
      fetchOrgChartData,
      WorkGroupData,
      fetchWorkGroupData,
      orgChart_Image,
      displayChecklist,
      isSepm
    } = this.props;
    // console.log("home props", this.props);
    //console.log("home displayChecklist value: ", displayChecklist);
    return (
      <React.Fragment>
        <div id="defaultPageContainer" className={menuExpanded ? 'blockHideWhenMobile' : ''}>
          <div style={{ display: displayDefaultPage }}>
            <DefaultPage
              loadChecklistContent={this.props.loadChecklistContent}
              displayChecklist ={displayChecklist}
              isSepm={ isSepm }
              aribaContractApprovals={aribaContractApprovals}
              fetchAribaContractApprovalData={fetchAribaContractApprovalData}
              snowApprovals={snowApprovals}
              aribaApprovals={aribaApprovals}
              iiqApprovals={iiqApprovals}
              otApprovals={otApprovals}
              fetchOTApprovalData={fetchOTApprovalData}
              fetchIIQApprovalData={fetchIIQApprovalData}
              fetchAribaApprovalData={fetchAribaApprovalData}
              fetchSnowApprovalData={fetchSnowApprovalData}
              leaderLinks={leaderLinks}
              leaderLinksLoaded={leaderLinksLoaded}
              retrieveEffectiveLeaderData={retrieveEffectiveLeaderData}
              bravoData={bravoData}
              retrieveBravos={retrieveBravos}
              bravosLoaded={bravosLoaded}
              calendarData={calendarData}
              retrieveCalendarData={refreshing => this.props.retrieveCalendarData(refreshing)}
              searchHistoryId={searchHistoryId}
              showAnnounceList={this.showAnnounceList}
              showAdminEffectiveLd={this.showAdminEffectiveLd}
              isAdmin={isAdmin}
              team={team}
              teamMax={teamMax}
              approvals={approvals}
              approvalsLoaded={approvalsLoaded}
              approvalMax={approvalMax}
              handleChangeMaxTeam={teamMax => this.props.handleChangeMaxTeam(teamMax)}
              handleChangeMaxApprovals={teamMax => this.props.handleChangeMaxApprovals(teamMax)}
              retrieveFOWData={refreshing => this.props.retrieveFOWData(refreshing)}
              changeTeamDisplay={memberIdx => this.props.changeTeamDisplay(memberIdx)}
              changeWholeTeamDisplay={display => this.props.changeWholeTeamDisplay(display)}
              retrieveTeam={() => this.props.retrieveTeam()}
              retrieveApprovals={this.props.retrieveApprovals}
              handleRemoveApproval={idx => this.props.handleRemoveApproval(idx)}
              timeoffMax={timeoffMax}
              handleChangeMaxTimeOff={timeoffMax => this.props.handleChangeMaxTimeOff(timeoffMax)}
              calendarRange={calendarRange}
              handleChangeCalendarRange={calendarRange =>
                this.props.handleChangeCalendarRange(calendarRange)
              }
              birthdayMax={birthdayMax}
              handleChangeMaxBirthdays={birthdayMax =>
                this.props.handleChangeMaxBirthdays(birthdayMax)
              }
              holidayMax={holidayMax}
              handleChangeMaxHolidays={holidayMax => this.props.handleChangeMaxHolidays(holidayMax)}
              anniversaryMax={anniversaryMax}
              handleChangeMaxAnniversary={anniversaryMax =>
                this.props.handleChangeMaxAnniversary(anniversaryMax)
              }
            />
            {/*<LeaderTransitionChecklist />*/}
          </div>
        </div>
        
        <div style={{display: displayChecklist}}>
          <LeaderTransitionChecklist          
            displayChecklist={displayChecklist}
            closeChecklist={this.props.closeChecklist}
            isSepm = {isSepm}
          />
        </div> 

        <div style={{ display: displaySearchResults }}>
          <ServiceNowSearch
            showWorkGroup={this.showWorkGroup}
            WorkGroupData={WorkGroupData}
            fetchWorkGroupData={fetchWorkGroupData}
            orgChartData={orgChartData}
            fetchOrgChartData={fetchOrgChartData}
            showOrgChart={this.showOrgChart}
            fetchAdminNameData={fetchAdminNameData}
            AdminNameData={AdminNameData}
            reSearchKnowledge={reSearchKnowledge}
            restrictKnowledgeData={restrictKnowledgeData}
            loadMoreKnowledge={loadMoreKnowledge}
            user={user}
            searchQuery={searchQuery}
            searchData={searchData}
            searching={searching}
            searchingCallup={searchingCallup}
            unfilteredNumber={unfilteredNumber}
            filteredNumber={filteredNumber}
            searchHistoryId={searchHistoryId}
            callUpSNData={callUpSNData}
            Callup_Image={Callup_Image}
            HRK_Error={HRK_Error}
            People_Error={People_Error}
            HRK_Display={HRK_Display}
            People_Display={People_Display}
            onSearchQueryUpdate={searchQuery => this.submitSearch(searchQuery)}
            onSearchQueryUpdateCallup={searchQuery => this.submitSearch(searchQuery)}
            handleManagerHyperlink={searchQuery => this.managerSearch(searchQuery)}
            onSearchQueryAPS={(short_name, body_post, body_post2) =>
              this.apsSearch(short_name, body_post, body_post2)
            }
            showAdvancedSearch={this.showAdvancedSearch}
          />
        </div>
        <div style={{ display: displayAdvancedPeopleSearch }}>
          <AdvancedPeopleSearch
            onSearchQueryAPS={(short_name, body_post, body_post2) =>
              this.apsSearch(short_name, body_post, body_post2)
            }
          />
        </div>
        <div style={{ display: displayAnnounceList }}>
          <AnnouncementList
            displayAnnounceList={displayAnnounceList}
            user={user}
            searchQuery={searchQuery}
            searchData={searchData}
            showDefault={this.showDefault}
            loadChecklistContent={this.props.loadChecklistContent}
            isAdmin={isAdmin}
            showAnnounceEdit={this.showAnnounceEdit}
            announcementId={announcementId}
            showAnnounceList={this.showAnnounceList}
            forceAnnounceSearchReload={forceAnnounceSearchReload}
            onAnnounceIdUpdate={announcmentId => this.props.onAnnouncementIdUpdate(announcmentId)}
          />
        </div>
        <div style={{ display: displayAnnounceEdit }}>
          <AnnouncementEdit
            displayAnnounceEdit={displayAnnounceEdit}
            user={user}
            searchQuery={searchQuery}
            searchData={searchData}
            showDefault={this.showDefault}
            loadChecklistContent={this.props.loadChecklistContent}
            announcementId={announcementId}
            showAnnounceList={this.showAnnounceList}
            workingAnnouncement={workingAnnouncement}
            onWorkingAnnouncementChange={onWorkingAnnouncementChange}
            onAnnounceIdUpdate={announcmentId => this.props.onAnnouncementIdUpdate(announcmentId)}
            isAdmin={isAdmin}
          />
        </div>
        <div style={{ display: displayAdminEffectiveLd, backgroundColor: '#DEE6F2' }}>
          <EffectiveLdAdmin
            data={leaderLinks}
            loaded={leaderLinksLoaded}
            retrieveData={retrieveEffectiveLeaderData}
            isAdmin={isAdmin}
            showDefault={this.showDefault}
            loadChecklistContent={this.props.loadChecklistContent}
            deleteLeaderLinks={deleteLeaderLinks}
            deleteEffLdLink={deleteEffLdLink}
            addEffLdLink={addEffLdLink}
            updateLeaderLinks={updateLeaderLinks}
          />
        </div>
        <div style={{ display: displayOrgChart }}>
          <PeopleOrgChart
            fetchOrgChartData={fetchOrgChartData}
            onSearchQueryAPS={(short_name, body_post, body_post2) =>
              this.apsSearch(short_name, body_post, body_post2)
            }
            handleManagerHyperlink={searchQuery => this.managerSearch(searchQuery)}
            showOrgChart={this.showOrgChart}
            orgChartDisplay={orgChartDisplay}
            orgChartData={orgChartData}
            showSearchResults={this.showSearchResults}
            Callup_Image={Callup_Image}
            orgChart_Image={orgChart_Image}
            AdminNameData={AdminNameData}
            WorkGroupData={WorkGroupData}
            fetchWorkGroupData={fetchWorkGroupData}
            showWorkGroup={this.showWorkGroup}
          />
        </div>
        <div style={{ display: displayWorkGroup }}>
          <PeopleWorkGroup
            fetchOrgChartData={fetchOrgChartData}
            onSearchQueryAPS={(short_name, body_post, body_post2) =>
              this.apsSearch(short_name, body_post, body_post2)
            }
            handleManagerHyperlink={searchQuery => this.managerSearch(searchQuery)}
            orgChartData={orgChartData}
            showSearchResults={this.showSearchResults}
            Callup_Image={Callup_Image}
            orgChart_Image={orgChart_Image}
            AdminNameData={AdminNameData}
            WorkGroupData={WorkGroupData}
            workGroupDisplay={workGroupDisplay}
            fetchWorkGroupData={fetchWorkGroupData}
            showWorkGroup={this.showWorkGroup}
            showOrgChart={this.showOrgChart}
          />
        </div>
      </React.Fragment>
    );
  }

  render() {
    const { user, searchQuery, isAdmin, isSepm, menuExpanded, changeMenuExpanded, userPhoto } = this.props;
    // const userInfo = sessionStorage.getItem("userinfo");
    const contact = JSON.parse(sessionStorage.getItem('contact'));
    if (!contact) {
      return (
        <div>
          <Spinner />
        </div>
      );
    } else {
      if (!this.isALeader(contact)) {
        // setTimeout(this.notALeader, 1000);
        // return <h2>You ain't a leader! GIT OFFA MY LAWN.</h2>;
        window.location.href = '/not-authorized';
      }
      return (
        <React.Fragment>
          <div
            style={{
              display: 'block',
              // height: "150px",
              // position: "fixed",
              width: '100%',
              top: '0px',
              left: '0px',
              zIndex: '150',
            }}
          >
            <Header
              menuExpanded={menuExpanded}
              changeMenuExpanded={changeMenuExpanded}
              user={user}
              userPhoto={userPhoto}
              searchQuery={searchQuery}
              showDefault={this.showDefault}
              loadChecklistContent={this.props.loadChecklistContent}
              closeChecklist={this.props.closeChecklist}
              showAnnounceList={this.showAnnounceList}
              showAdminUserList={this.showAdminUserList}
              showMessage={this.showMessage}
              openLinkInNewWindow={this.openLinkInNewWindow}
              isAdmin={isAdmin}
              isSepm={ isSepm }
              onSearchQueryUpdate={searchQuery => this.submitSearch(searchQuery)}
              onSearchQueryUpdateCallup={searchQuery => this.submitSearch(searchQuery)}
              handleManagerHyperlink={searchQuery => this.managerSearch(searchQuery)}
              onSearchQueryAPS={(short_name, body_post, body_post2) =>
                this.apsSearch(short_name, body_post, body_post2)
              }
            />
          </div>
          <div
            style={{
              display: 'block',
              // marginTop: "150px",
              width: '100%',
            }}
          >
            {this.renderDisplay()}
            <div style={{ height: '100px', backgroundColor: '#DEE6F2' }}></div>
            <Footer showAnnounceList={this.showAnnounceList} isAdmin={isAdmin} />
          </div>
          {/* <button onClick={this.testRefresh}>refrest test</button> */}

          <Authorization onUserUpate={user => this.props.onUserUpate(user)} />
        </React.Fragment>
      );
    }
  }
}

export default Home;
