import React, { Component } from 'react';
import config from '../config.json';
import { CanViewReports } from '../services/AuthService';
import { isBrowser } from 'react-device-detect';
import { getEnvironment } from '../services/EnvironmentVariable';
import { isMobile } from 'react-device-detect';

class Footer extends Component {
  giveFeedback = () => {
    window.open(config.feedbackLink);
  };

  goToReports = () => {
    const environ = getEnvironment();
    let reportsURL = config.reportsLink;
    if (environ !== 'DEFAULT') {
      //We are not in production environment
      //
      if (isMobile) {
        //However we are testing on a mobile device so use the Mobile api
        reportsURL = config.reportsLink;
      } else {
        //We are not testing on a mobile device, so we will use the test API
        reportsURL = config.test_reportsLink;
      }
    }
    window.open(reportsURL);
  };

  render() {
    const { showAnnounceList, isAdmin } = this.props;
    const environ = getEnvironment();
    let showAdmin = isAdmin;
    if (environ === 'DEFAULT') {
      // console.log('footer.render ', environ);
      const today = new Date();
      // console.log('footer', today.getFullYear(), today.getMonth(), today.getDate());
      if (today.getFullYear() === 2021 && today.getMonth() === 4 && today.getDate() < 28)
        showAdmin = false;
    }
    const contact = JSON.parse(sessionStorage.getItem('contact'));

    const reportsAllowed = CanViewReports();
    const adminAndReports = showAdmin && reportsAllowed;
    const adminOrReports = showAdmin || reportsAllowed;
    let feedbackAllowed = false;
    if (contact) {
      feedbackAllowed = contact.managerFlag === '1' || showAdmin || reportsAllowed;
    }

    return (
      <div className="footer">
        <div className="container">
          <div className="row">
            <div className="col-2" style={{ minWidth: '110px' }}>
              <div className="hrlogo" title="My Hr Dashboard"></div>
            </div>
            <div className="col">
              {isBrowser ? (
                <div className=" hideMobile">
                  {feedbackAllowed ? (
                    <button
                      id="btn_givefeedback"
                      className="btn btn-link"
                      onClick={() => this.giveFeedback()}
                      style={{
                        fontSize: '14px',
                        color: 'white',
                        fontFamily: 'nw-primary',
                        textDecoration: 'underline',
                      }}
                    >
                      Give Feedback
                    </button>
                  ) : (
                    <div style={{ height: '40px' }}>&nbsp;</div>
                  )}

                  {adminOrReports ? <span>&nbsp;|&nbsp;</span> : <React.Fragment></React.Fragment>}
                  {showAdmin ? (
                    <button
                      id="btn_admin"
                      className="btn btn-link"
                      onClick={showAnnounceList}
                      style={{
                        fontSize: '14px',
                        color: 'white',
                        fontFamily: 'nw-primary',
                        textDecoration: 'underline',
                      }}
                    >
                      Admin
                    </button>
                  ) : (
                    <React.Fragment></React.Fragment>
                  )}
                  {adminAndReports ? <span>&nbsp;|&nbsp;</span> : <React.Fragment></React.Fragment>}
                  {reportsAllowed ? (
                    <button
                      id="btn_reports"
                      className="btn btn-link"
                      onClick={() => this.goToReports()}
                      style={{
                        fontSize: '14px',
                        color: 'white',
                        fontFamily: 'nw-primary',
                        textDecoration: 'underline',
                      }}
                    >
                      Reports
                    </button>
                  ) : (
                    <React.Fragment></React.Fragment>
                  )}
                </div>
              ) : (
                <div></div>
              )}

              <div className="footerText">
                <p>
                  &copy; Nationwide Mutual Insurance Company.
                  <br />
                  The data displayed on this page comes from a variety of sources.
                  <br />
                  Those sources remain the systems of record. Make any decisions on the original
                  sources, not from this page.
                  <br />
                  <span style={{ fontWeight: 'bold' }}>Nationwide is on your side &reg;</span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Footer;
