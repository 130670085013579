import React, { Component } from 'react';
import Footer from '../common/footer';
import LinksBar from '../common/linksbar';
class ErrorPage extends Component {
  state = {};
  goToHrConnect() {
    // console.log("Header goToHrConnect");
    // window.location = "https://hrconnect.nationwide.com";
    window.location.href = 'https://hrconnect.nationwide.com';
  }
  goToHome() {
    // console.log('GO TO ', 'https:/.' + window.location.hostname);
    const port = window.location.port;
    let url = 'https://' + window.location.hostname;
    if (port) {
      url += ':' + port;
    }
    // console.log('GO TO ', url);
    window.location.href = url;
  }
  renderHeader() {
    return (
      <React.Fragment>
        <div className="topStrip topColor blockHideWhenMobile">
          <div className="container">
            <div className="row">
              <div className="col" style={{ textAlign: 'right' }}>
                <button onClick={() => this.goToHrConnect()} className="btn hrConnectButton">
                  <span style={{ color: '#c7d0dc', fontSize: '15px' }}>Switch to </span>
                  <span style={{ color: 'white' }}>HR</span>
                  <span style={{ color: '#c7d0dc' }}>Connect</span>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="header">
          <div className="container">
            <div className="row" style={{ whiteSpace: 'nowrap' }}>
              <div className="xcol-2 hideWhenMobile">
                <div className="nwlogo" title="Nationwide Logo"></div>
                <h1
                  className="hrlogo"
                  onClick={this.goToHome}
                  style={{ cursor: 'pointer' }}
                  title="My LeaderConnect Dashboard"
                ></h1>
                <div
                  className="showInlineWhenMobile"
                  style={{
                    paddingTop: '28px',
                    paddingRight: '28px',
                    float: 'right',
                  }}
                ></div>
              </div>

              <div className="hideWhenMobile xcol"></div>
              <div className="searchFieldContainer hideWhenMobile xcol-2"></div>
              <div className="showWhenMobile" style={{ width: '100%', paddingLeft: '10px' }}>
                <div
                  className="nwlogo"
                  title="Nationwide Logo"
                  style={{ display: 'inline-block' }}
                ></div>
                <h1
                  className="hrlogo"
                  onClick={this.goToHome}
                  style={{ display: 'inline-block', cursor: 'pointer' }}
                  title="My LeaderConnect Dashboard"
                ></h1>
                <div
                  className="showInlineWhenMobile"
                  style={{
                    paddingTop: '28px',
                    paddingRight: '7px',
                    float: 'right',
                  }}
                ></div>
              </div>
              <div className="showWhenMobile" style={{ marginLeft: '10px', width: '100%' }}></div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }

  render() {
    return (
      <React.Fragment>
        {this.renderHeader()}
        <div style={{ width: '100%', textAlign: 'center', height: '291px' }}>
          <h2 style={{ paddingTop: '50px' }}>
            Sorry, the page you requested is not available at this time.{' '}
          </h2>

          <div style={{ paddingTop: '50px', fontWeight: 'bold' }}>
            We apologize for the inconvenience and are working to restore service.
          </div>
        </div>
        <div style={{ width: '100%', paddingBottom: '106px' }}>
          <LinksBar />
        </div>
        <div style={{ position: 'fixed', bottom: '0', width: '100%' }}>
          <Footer />
        </div>
      </React.Fragment>
    );
  }
}

export default ErrorPage;
