import React, { Component } from 'react';
// import { PortletInfo } from "../common/portletInfo";
import Overlay from 'react-bootstrap/Overlay';
import Popover from 'react-bootstrap/Popover';
import { isMobile } from 'react-device-detect';
import config from '../config.json';
import links from '../links.json';

class MyCalendar extends Component {
  state = {
    displayInfo: false,
    birthdayOn: false,
    holidayOn: false,
    timeOffOn: true,
  };
  getCalendar = () => {
    this.props.retrieveCalendarData(true);
  };
  goToBravo = () => {
    window.open(links.bravo);
  };
  goToWorkDayCalendar = () => {
    window.open('https://www.myworkday.com/nationwide/d/task/2998$13481.htmld');
  };
  handleRefresh = () => {
    this.props.retrieveCalendarData(true);
  };

  showInformation = event => {
    const { displayInfo } = this.state;
    const target = event.target;
    // console.log("MyCalendar.displayInfo", displayInfo);
    this.setState({ displayInfo: !displayInfo, target });
  };

  handleHolidayClick = () => {
    let holidayOn = !this.state.holidayOn;
    this.setState({ holidayOn });
  };
  handleBirthdayClick = () => {
    let birthdayOn = !this.state.birthdayOn;
    this.setState({ birthdayOn });
  };
  handleTimeOffClick = () => {
    // console.log("calendar.handleTimeOffClick");
    let timeOffOn = !this.state.timeOffOn;
    this.setState({ timeOffOn });
  };

  handleLoadMoreTimeOff = (event, timeoffMax) => {
    this.props.handleChangeMaxTimeOff(timeoffMax);
  };
  handleLoadMoreBirthday = (event, birthdayMax) => {
    this.props.handleChangeMaxBirthdays(birthdayMax);
  };
  handleLoadMoreHoliday = (event, holidayMax) => {
    this.props.handleChangeMaxHolidays(holidayMax);
  };
  handleLoadMoreAnniverary = (event, anniversaryMax) => {
    this.props.handleChangeMaxAnniversary(anniversaryMax);
  };

  handleChangeCalendarRange = event => {
    this.props.handleChangeCalendarRange(event.target.value);
  };

  renderTitle = () => {
    // return (
    //   <React.Fragment>
    //     Team Calendar and Key Dates <span style={{ fontSize: '14px' }}>(next 2 weeks)</span>
    //   </React.Fragment>
    // );

    return (
      <div className="container-fluid">
        <div className="d-flex">
          <div className="p-2">
            Team Calendar
            <br />
            and Key Dates
          </div>

          <div className="ml-auto p-2">
            <div style={{ width: '154px' }}>
              <div
                style={{
                  fontSize: '12px',
                  fontWeight: 'bold',
                  width: '100%',
                  textAlign: 'left',
                  color: '#4d4f53',
                }}
              >
                Date Range
              </div>
              <select
                id="calRange"
                className="dateRangeSelect form-select "
                onChange={this.handleChangeCalendarRange}
                disabled={this.props.calendarData ? false : true}
                value={this.props.calendarRange}
              >
                <option value="2">Next 2 weeks</option>
                <option value="4">Next 4 weeks</option>
                <option value="6">Next 6 weeks</option>
              </select>
            </div>
          </div>
        </div>
      </div>
    );
  };

  render() {
    const {
      calendarData,
      timeoffMax,
      birthdayMax,
      holidayMax,
      anniversaryMax,
      calendarRange,
    } = this.props;
    let informationMessge = `Information displayed is only day-to-day absences. For longer term leaves go to Workday.`;
    const { displayInfo, birthdayOn, timeOffOn, holidayOn, target } = this.state;
    //console.log('Test calendarData', calendarData);
    if (calendarData) {
      if (calendarData.error && calendarData.error !== '') {
        return (
          <div className="calendarContainer">
            <div className="calendarContent" style={{ height: '388px' }}>
              <h2 className="tile-header">
                <div style={{ height: '39px' }}>
                  Team Calendar and Key Dates
                  <div
                    className="noScrollingActions"
                    style={{
                      color: '#bcbdbc',
                    }}
                  ></div>
                  <div className="portletError">
                    <div className="portletErrorAlert">!</div>
                    <div
                      style={{
                        fontFamily: 'nw-primary',
                        fontSize: '20px',
                        lineHeight: '24px',
                        color: '#4D4F53',
                      }}
                    >
                      Oops, this is unexpected.
                    </div>
                    <div
                      style={{
                        fontFamily: 'nw-primary',
                        fontSize: '12px',
                        lineHeight: '15px',
                        color: '#4D4F53',
                      }}
                    >
                      Error code: 500
                      <br />
                      An error has occurred and we're working to fix the issue.
                      <br />
                      If the issue persists please{' '}
                      <a
                        style={{
                          color: '#4D4F53',
                          textDecoration: 'underline',
                        }}
                        href={config.contactUsUrl}
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        contact us.
                      </a>
                    </div>
                    <div
                      className="tile-apierror-refresh"
                      // onMouseDown={() => this.getFow(index)}
                      onClick={() => this.getCalendar()}
                      title="Refresh"
                    >
                      Refresh
                    </div>
                  </div>
                </div>
              </h2>
            </div>
          </div>
        );
      }
      let numberDisplayed = 0;
      let displayHeight = '';

      let halfHeight = '';
      if (isMobile) {
        displayHeight = '100%';
        halfHeight = '100%';
      } else {
        if (birthdayOn) {
          numberDisplayed++;
        }
        if (timeOffOn) {
          numberDisplayed++;
        }
        if (holidayOn) {
          numberDisplayed++;
        }
        switch (numberDisplayed) {
          case 1:
            displayHeight = '240px';
            halfHeight = '200px';
            break;
          case 2:
            displayHeight = '190px';
            halfHeight = '100px';
            break;
          case 3:
            displayHeight = '100px';
            halfHeight = '50px';
            break;
          default:
            displayHeight = '600px';
            halfHeight = '250px';
        }
      }

      const timeOffSize = calendarData.timeoff.length;
      const sliceTimeOffAt = isMobile ? timeoffMax : timeOffSize;
      const timeOff = calendarData.timeoff.slice(0, sliceTimeOffAt);
      const timeOffLimit = timeOffSize < timeoffMax ? timeOffSize : timeoffMax;

      const holidaySize = calendarData.holiday.length;
      const sliceHolidayAt = isMobile ? holidayMax : holidaySize;
      const holiday = calendarData.holiday.slice(0, sliceHolidayAt);
      const holidayLimit = holidaySize < holidayMax ? holidaySize : holidayMax;

      const anniversarySize = calendarData.anniversary.length;
      const sliceAnniversaryAt = isMobile ? anniversaryMax : anniversarySize;
      const anniversary = calendarData.anniversary.slice(0, sliceAnniversaryAt);
      const anniversaryLimit = anniversarySize < anniversaryMax ? anniversarySize : anniversaryMax;

      const birthdaySize = calendarData.birthday.length;
      const sliceBirthdayAt = isMobile ? birthdayMax : birthdaySize;
      const birthday = calendarData.birthday.slice(0, sliceBirthdayAt);
      const birthdayLimit = birthdaySize < birthdayMax ? birthdaySize : birthdayMax;

      // console.log("MyCalendar.render ", calendarData);
      let timeOffCount = 0;
      if (timeOff) {
        timeOffCount = timeOff.length;
      }
      let holidayCount = 0;
      if (holiday) {
        holidayCount = holiday.length;
      }
      let birthdayAndAnniversaryCount = 0;
      if (anniversary) {
        birthdayAndAnniversaryCount = anniversary.length;
      }
      if (birthday) {
        birthdayAndAnniversaryCount += birthday.length;
      }
      return (
        <div className={isMobile ? 'mobileCalendarContainer' : 'calendarContainer'}>
          <div className="calendarContent">
            <h2 className="tile-header">
              <div style={{ minHeight: '39px' }}>{this.renderTitle()}</div>
              <div
                className={isMobile ? 'noScrollingActions' : 'scrollingCalendar'}
                style={{
                  color: '#bcbdbc',
                }}
              >
                <div
                  style={{
                    width: '95%',
                    textAlign: 'left',
                  }}
                >
                  {/* <div style={{ borderTop: "#bcbdbc solid 1px", width: "100%" }}> */}
                  <div style={{ width: '100%' }}>
                    <button
                      tabIndex="0"
                      onClick={e => this.handleTimeOffClick(e)}
                      id={'TimeOff'}
                      style={{
                        //   width: "100%",
                        textAlign: 'left',
                        border: 'none',
                        backgroundColor: 'white',
                      }}
                    >
                      <div className="calendarChevronContainer">
                        <img
                          src={
                            timeOffOn ? '/icon-chevron-up-dark.svg' : '/icon-chevron-down-dark.svg'
                          }
                          alt={this.state.timeOffOn ? "Click to collapse Time off info" : "Click to show time off info"}
                        ></img>
                      </div>
                    </button>
                    <div className="calendarData">
                      Time Off {timeOffCount > 0 ? '(' + timeOffCount + ') ' : ' '}
                      <button onClick={e => this.showInformation(e)} className="infoLink">
                        {displayInfo ? 'X' : '?'}
                      </button>
                      <Overlay show={displayInfo} target={target} placement="bottom">
                        <Popover id="timeOffPopOver">
                          <Popover.Title as="div">Time Off</Popover.Title>
                          <Popover.Content>{informationMessge}</Popover.Content>
                        </Popover>
                      </Overlay>
                      {/* <OverlayTrigger
                        trigger="click"
                        id="timeOffInformation"
                        key={'bottom'}
                        placement={'bottom'}
                        onToggle={e => this.showInformation(e)}
                        overlay={
                          <Popover id="timeOffPopOver">
                            <Popover.Title as="div">Time Off</Popover.Title>
                            <Popover.Content>{informationMessge}</Popover.Content>
                          </Popover>
                        }
                      >
                        <span
                          id="showInformationMessage"
                          className="infoLink"
                          // onClick={e => this.showInformation(e)}
                        >
                          {displayInfo ? 'X' : '?'}
                        </span>
                      </OverlayTrigger> */}
                    </div>
                  </div>
                  {timeOffCount > 0 ? (
                    <div
                      className={isMobile ? 'mobileCalendarDisplay' : 'calendarDisplay'}
                      style={
                        timeOffOn
                          ? {
                              display: 'block',
                            }
                          : { display: 'none' }
                      }
                    >
                      <div className="rowold" style={{ marginLeft: '42px' }}>
                        <div className="calendarRowHead1">DATE</div>
                        <div className="calendarRowHead2">ASSOCIATE</div>
                      </div>
                      <div
                        className={isMobile ? 'noScrollCalendar' : 'scrollCalendar'}
                        style={
                          timeOffOn
                            ? {
                                display: 'block',
                                maxHeight: `${displayHeight}`,
                              }
                            : { display: 'none' }
                        }
                      >
                        {timeOff.map((item, index) => (
                          <div key={`timeOff_${index}`}>
                            {item.displayDate !== '' ? (
                              <div
                                className="rowold"
                                style={
                                  index > 0 && item.displayDate !== ''
                                    ? {
                                        marginLeft: '42px',
                                        borderTop: 'black dashed 1px',
                                        paddingTop: '6px',
                                        paddingBottom: '6px',
                                      }
                                    : { marginLeft: '42px' }
                                }
                              >
                                <div className="calendarDate">{item.displayDate}</div>
                                <div className="calendarName">
                                  {timeOff.map((subitem, index2) => (
                                    <div key={`timeOffsub_${index}_${index2}`}>
                                      {subitem.date === item.date && index2 >= index ? (
                                        <div style={{ lineHeight: '15px' }}>{subitem.name}</div>
                                      ) : (
                                        ''
                                      )}
                                    </div>
                                  ))}
                                </div>
                              </div>
                            ) : (
                              <React.Fragment></React.Fragment>
                            )}
                          </div>
                        ))}
                      </div>
                      <div style={{ paddingTop: '10px', paddingBottom: '10px' }}>
                        <button
                          className="btn btn-primary "
                          onClick={e => this.handleLoadMoreTimeOff(e, timeOffSize)}
                          style={
                            timeOffSize === timeOffLimit || !isMobile
                              ? { display: 'none' }
                              : {
                                  display: 'block',

                                  marginLeft: '112px',
                                }
                          }
                        >
                          Load More
                        </button>
                      </div>
                    </div>
                  ) : (
                    <div
                      style={
                        timeOffOn
                          ? {
                              display: 'block',
                              fontSize: '16px',
                              fontWeight: 'bold',
                              color: '#4D4F53',
                              marginLeft: '42px',
                            }
                          : { display: 'none' }
                      }
                    >
                      No Upcoming Time Off
                    </div>
                  )}

                  <div style={{ width: '100%', borderTop: '#bcbdbc solid 1px' }}>
                    <button
                      tabIndex="0"
                      onClick={e => this.handleHolidayClick(e)}
                      id={'Holiday'}
                      style={{
                        //   width: "100%",
                        textAlign: 'left',
                        border: 'none',
                        backgroundColor: 'white',
                      }}
                    >
                      <div className="calendarChevronContainer">
                        <img
                          src={
                            holidayOn ? '/icon-chevron-up-dark.svg' : '/icon-chevron-down-dark.svg'
                          }
                          alt={this.state.holidayOn ?"Click to collapse Holiday info" : "Click to expand Holiday info"}
                        ></img>
                      </div>
                    </button>
                    <div className="calendarData">
                      Nationwide Holidays {holidayCount > 0 ? '(' + holidayCount + ') ' : ' '}
                    </div>
                  </div>
                  {holidayCount > 0 ? (
                    <div
                      className={isMobile ? 'mobileCalendarDisplay' : 'calendarDisplay'}
                      style={
                        holidayOn
                          ? {
                              display: 'block',
                              // height: `${displayHeight}`
                            }
                          : { display: 'none' }
                      }
                    >
                      <div className="rowold" style={{ marginLeft: '42px' }}>
                        <div className="calendarRowHead1">DATE</div>
                        <div className="calendarRowHead2">HOLIDAY</div>
                      </div>
                      <div
                        className={isMobile ? 'noScrollCalendar' : 'scrollCalendar'}
                        style={
                          holidayOn
                            ? {
                                display: 'block',
                                maxHeight: `${displayHeight}`,
                              }
                            : { display: 'none' }
                        }
                      >
                        {holiday.map((item, index) => (
                          <div key={`holiday_${index}`}>
                            {item.displayDate !== '' ? (
                              <div
                                className="rowold"
                                style={
                                  index > 0 && item.displayDate !== ''
                                    ? {
                                        marginLeft: '42px',
                                        borderTop: 'black dashed 1px',
                                        paddingTop: '6px',
                                        paddingBottom: '6px',
                                      }
                                    : { marginLeft: '42px' }
                                }
                              >
                                <div className="calendarDate">{item.displayDate}</div>
                                <div className="calendarName">{item.name}</div>
                              </div>
                            ) : (
                              <React.Fragment></React.Fragment>
                            )}
                          </div>
                        ))}
                      </div>
                      <div style={{ paddingTop: '10px', paddingBottom: '10px' }}>
                        <button
                          className="btn btn-primary "
                          onClick={e => this.handleLoadMoreHoliday(e, holidaySize)}
                          style={
                            holidaySize === holidayLimit || !isMobile
                              ? { display: 'none' }
                              : {
                                  display: 'block',

                                  marginLeft: '112px',
                                }
                          }
                        >
                          Load More
                        </button>
                      </div>
                    </div>
                  ) : (
                    <div
                      style={
                        holidayOn
                          ? {
                              display: 'block',
                              fontSize: '16px',
                              fontWeight: 'bold',
                              color: '#4D4F53',
                              marginLeft: '42px',
                            }
                          : { display: 'none' }
                      }
                    >
                      No Upcoming Holidays
                    </div>
                  )}

                  <div style={{ width: '100%', borderTop: '#bcbdbc solid 1px' }}>
                    <button
                      tabIndex="0"
                      onClick={e => this.handleBirthdayClick(e)}
                      id={'BirthdayAndAnniversary'}
                      style={{
                        //   width: "100%",
                        textAlign: 'left',
                        border: 'none',
                        backgroundColor: 'white',
                      }}
                    >
                      <div className="calendarChevronContainer">
                        <img
                          src={
                            birthdayOn ? '/icon-chevron-up-dark.svg' : '/icon-chevron-down-dark.svg'
                          }
                          alt={this.state.birthdayOn ? "Click to collapse Birthday and Anniversaries" : "Click to expand Birthday and Anniversaries"}
                        ></img>
                      </div>
                    </button>
                    <div className="calendarData">
                      Birthdays &amp; Service Anniversaries{' '}
                      {birthdayAndAnniversaryCount > 0
                        ? '(' + birthdayAndAnniversaryCount + ') '
                        : ' '}
                    </div>
                  </div>

                  <div
                    className={isMobile ? 'mobileCalendarDisplay' : 'calendarDisplay'}
                    style={
                      birthdayOn
                        ? {
                            display: 'block',
                            // maxHeight: `${displayHeight}`
                          }
                        : { display: 'none' }
                    }
                  >
                    {/* <div
                      style={{
                        color: '#4D4F53',
                        fontSize: '14px',
                        marginLeft: '42px',
                      }}
                    >
                      Recognize your associate for a career milestone and a job well done by sending
                      them a Bravo!
                    </div> */}
                    <div className="rowold" style={{ marginLeft: '42px' }}>
                      <div className="calendarRowHead1">BIRTHDAY</div>
                      <div className="calendarRowHead2">ASSOCIATE</div>
                    </div>
                    {birthday && birthday.length > 0 ? (
                      <div
                        className={isMobile ? 'noScrollCalendar' : 'scrollCalendar'}
                        style={
                          birthdayOn
                            ? {
                                display: 'block',
                                maxHeight: `${halfHeight}`,
                              }
                            : { display: 'none' }
                        }
                      >
                        {birthday.map((item, index) => (
                          <div key={`birthday_${index}`}>
                            {item.displayDate !== '' ? (
                              <div
                                className="rowold"
                                style={
                                  index > 0 && item.displayDate !== ''
                                    ? {
                                        marginLeft: '42px',

                                        paddingTop: '6px',
                                        paddingBottom: '6px',
                                      }
                                    : { marginLeft: '42px' }
                                }
                              >
                                <div className="calendarDate">{item.displayDate}</div>
                                <div className="calendarName">{item.name}</div>
                              </div>
                            ) : (
                              <React.Fragment></React.Fragment>
                            )}
                          </div>
                        ))}
                        <div style={{ paddingTop: '10px', paddingBottom: '10px' }}>
                          <button
                            className="btn btn-primary "
                            onClick={e => this.handleLoadMoreBirthday(e, birthdaySize)}
                            style={
                              birthdaySize === birthdayLimit || !isMobile
                                ? { display: 'none' }
                                : {
                                    display: 'block',

                                    marginLeft: '112px',
                                  }
                            }
                          >
                            Load More
                          </button>
                        </div>
                      </div>
                    ) : (
                      <div
                        className={isMobile ? 'noScrollCalendar' : 'scrollCalendar'}
                        style={{
                          fontSize: '16px',
                          fontWeight: 'bold',
                          color: '#4D4F53',
                          marginLeft: '42px',
                          display: 'block',
                          maxHeight: `${halfHeight}`,
                        }}
                      >
                        No Upcoming Birthdays
                      </div>
                    )}
                    <div
                      className="rowold"
                      style={{
                        marginLeft: '42px',
                        borderTop: 'black dashed 1px',
                      }}
                    >
                      <div className="calendarRowHead1">ANNIVERSARY</div>
                      <div className="calendarRowHead2">ASSOCIATE</div>
                    </div>
                    {anniversary && anniversary.length > 0 ? (
                      <div
                        className={isMobile ? 'noScrollCalendar' : 'scrollCalendar'}
                        style={
                          birthdayOn
                            ? {
                                display: 'block',
                                maxHeight: `${halfHeight}`,
                              }
                            : { display: 'none' }
                        }
                      >
                        {anniversary.map((item, index) => (
                          <div key={`anniversary_${index}`}>
                            {item.displayDate !== '' ? (
                              <div
                                className="rowold"
                                style={
                                  index > 0 && item.displayDate !== ''
                                    ? {
                                        marginLeft: '42px',
                                        paddingTop: '6px',
                                        paddingBottom: '6px',
                                      }
                                    : { marginLeft: '42px' }
                                }
                              >
                                <div className="calendarDate">{item.displayDate}</div>
                                <div className="calendarName">
                                  {item.name}
                                  <br />
                                  {item.serviceYears}
                                </div>
                              </div>
                            ) : (
                              <React.Fragment></React.Fragment>
                            )}
                          </div>
                        ))}
                        <div style={{ paddingTop: '10px', paddingBottom: '10px' }}>
                          <button
                            className="btn btn-primary "
                            onClick={e => this.handleLoadMoreAnniverary(e, anniversarySize)}
                            style={
                              anniversarySize === anniversaryLimit || !isMobile
                                ? { display: 'none' }
                                : {
                                    display: 'block',

                                    marginLeft: '112px',
                                  }
                            }
                          >
                            Load More
                          </button>
                        </div>
                      </div>
                    ) : (
                      <div
                        className={isMobile ? 'noScrollCalendar' : 'scrollCalendar'}
                        style={{
                          fontSize: '16px',
                          fontWeight: 'bold',
                          color: '#4D4F53',
                          marginLeft: '42px',
                          display: 'block',
                          maxHeight: `${halfHeight}`,
                        }}
                      >
                        No Upcoming Anniversaries
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="calendarBravoText">
                RECOGNIZE YOUR ASSOCIATE FOR A CAREER MILESTONE AND A JOB WELL DONE BY SENDING THEM
                A BRAVO!
              </div>
              <div className="blueLinkContainer">
                <button className="tileButton bravoButton" onClick={() => this.goToBravo()}>
                  Send a Bravo!
                </button>

                <button
                  className="tileButton workdayCalendarButton"
                  onClick={() => this.goToWorkDayCalendar()}
                >
                  Workday Calendar
                </button>
              </div>
            </h2>
          </div>
        </div>
      );
    } else {
      return (
        <div className="calendarContainer" style={{ backgroundImage: 'none' }}>
          <div className="calendarContent" style={{ height: '388px' }}>
            <h2 className="tile-header">
              {this.renderTitle()}
              <div className="scrollingCalendar">
                <div
                  className="progress-spinner "
                  style={{ left: '50%', top: '50%', paddingTop: '15px', marginTop: '50px' }}
                />
              </div>
            </h2>
          </div>
        </div>
      );
    }
  }
}

export default MyCalendar;
