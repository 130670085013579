import { isMobile } from 'react-device-detect';
import config from '../config.json';
import { getRandomString } from '../services/AuthService';
import testers from '../testers.json';
import { getEnvironment } from './EnvironmentVariable';

export const getTeam = async () => {
  const realData = await getManagerTeam();
  let members = [];
  // console.log("teamService getManagerTeam realData", realData);
  let memberNumber = 0;
  if (realData) {
    //Team data was received

    if (realData.userMessage) {
      console.error('teamService getManagerTeam error from API', realData.userMessage);
      return { error: realData.userMessage, members, teamOverall: 0 };
    }

    if (realData.contacts) {
      const contacts = realData.contacts;
      // console.log(
      //   "teamService getManagerTeam contacts.length",
      //   contacts.length
      // );

      for (let x = 0; x < contacts.length; x++) {
        const contact = contacts[x];
        const emplid = contact.emplid;
        const firstChar = emplid.charAt(0);
        if (firstChar !== 'P') {
          //This is not a contractor
          const associateName = contact.prefFirstName
            ? contact.firstName + ' (' + contact.prefFirstName + ') ' + contact.lastName
            : contact.firstName + ' ' + contact.lastName;
          const position = contact.jobTitle;

          const shortName = contact.shortName;

          const cleaned = ('' + contact.workPhone).replace(/\D/g, '');
          const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);

          // console.log("teamService match", match);
          let workPhone = contact.workPhone;
          try {
            workPhone = match[2] + '-' + match[3] + '-' + match[4];
          } catch (e) {
            //error formatting.
          }
          // if (!workPhone || workPhone === "") {
          //   workPhone = "N/A";
          // }
          let learning = [];

          const member = {
            associateName,
            position,
            shortName,
            emplid,
            workPhone,
            image: null,
            fow: {
              shortname: null,
              overall: 0,
              digitalCompletion: 0,
              leadCompletion: 0,
              error: null,
            },
            idrive: null,
            learning,
          };
          // console.log("teamService getManagerTeam member", member);
          members[memberNumber++] = member;
        }
      }
      const teamOverall = {
        overalFow: 0,
        training: [],
      };

      const realTeam = { members, teamOverall };
      // console.log("teamService getManagerTeam realTeam", realTeam);
      return realTeam;
    }
  } else {
    // console.log("teamService getManagerTeam no team found due to error");
    return { error: 'No team found.' };
  }
};

export const getManagerTeam = async () => {
  // console.log("teamService.getManagerTeam started");
  const message = getRandomString(25);
  const environ = getEnvironment();
  let callupAPI = config.callupReportsAPI;
  let callupKey = config.callupKey;
  if (environ !== 'DEFAULT') {
    if (isMobile) {
      callupAPI = config.Mobile_test_callupReportsAPI;
      callupKey = config.test_callupKey;
    } else {
      // console.log("jobService.getState environ", environ);
      callupAPI = config.test_callupReportsAPI;
      callupKey = config.test_callupKey;
    }
  }
  const contact = JSON.parse(sessionStorage.getItem('contact'));
  let shortName = contact.shortName;

  const testUsers = testers.users;
  for (let x = 0; x < testUsers.length; x++) {
    const tester = testUsers[x];
    if (tester.shortName.toUpperCase() === shortName.toUpperCase()) {
      //User shortname matches to a tester
      if (environ === 'DEFAULT') {
        //This is the production environment
        if (tester.prodAllowed) {
          //This tester is allowed to mimic in production
          shortName = tester.mimicManager.toUpperCase();
        }
      } else {
        //This is not a production environment
        shortName = tester.mimicManager.toUpperCase();
      }
      break;
    }
  }

  // console.log("teamService.getManagerTeam environ", environ);
  // if (environ !== "DEFAULT") {
  //   //We are not in production environment
  //   console.log(
  //     "teamService.getManagerTeam contact.managerFlag ",
  //     contact.managerFlag
  //   );
  //   console.log(
  //     "teamService.getManagerTeam contact.isPortalAdmin",
  //     contact.isPortalAdmin
  //   );
  //   const testUsers = testers.users;
  //   for (let x = 0; x < testUsers.length; x++) {
  //     const tester = testUsers[x];
  //     console.log("teamService.getManagerTeam  testUsers[" + x + "]",  testUsers[x]);
  //     if (tester.shortName.toUpperCase() === contact.shortName.toUpperCase()) {
  //       console.log(
  //         "teamService.getManagerTeam tester.shortName.toUpperCase()",
  //         tester.shortName.toUpperCase()
  //       );
  //       console.log(
  //         "teamService.getManagerTeam tester.mimicManager.toUpperCase()",
  //         tester.mimicManager.toUpperCase()
  //       );
  //       //User shortname matches to a tester
  //       if (environ === "DEFAULT") {
  //         //This is the production environment
  //         if (test.prodAllowed) {
  //           //This tester is allowed to mimic in production
  //           userid = tester.mimicManager.toUpperCase();
  //         }
  //       } else {
  //         //This is not a production environment
  //         userid = tester.mimicManager.toUpperCase();
  //       }
  //       break;
  //     }
  //   }
  // }

  const body = {
    shortName: encodeURIComponent(shortName),
  };

  let headers = new Headers();
  headers.append('Content-Type', 'application/json');
  headers.append('X-NW-Message-ID', message);

  const token = sessionStorage.getItem('access_token');
  console.log('Token ', token);
  //const token = config.token;
  headers.append('Authorization', 'Bearer ' + token);
  headers.set('client_id', callupKey);

  const response = await fetch(callupAPI, {
    method: 'POST',
    headers: headers,
    body: JSON.stringify(body),
  });

  if (response.ok) {
    try {
      const jsondata = await response.json();
      // console.log("teamService.getManagerTeam completed");
      return jsondata;
    } catch (e) {
      console.error('teamService.getManagerTeam Callup API error', e);
      return null;
    }
  }
  return null;
};
