import React, { Component } from 'react';
import Button from 'react-bootstrap/Button';
import Dropdown from 'react-bootstrap/Dropdown';
import { isMobile } from 'react-device-detect';
import * as Scroll from 'react-scroll';
import { Element } from 'react-scroll';
import 'react-toastify/dist/ReactToastify.css';
import SearchBar from '../common/searchBar';
import '../common/searchPage.css';
import Spinner from '../common/spinner';
import config from '../config.json';
import { getEnvironment } from '../services/EnvironmentVariable';
import { GetPhotos } from '../services/peoplePhotoService.jsx';
import { addSearchResultActivity } from '../services/searchHistoryService';

const minCharactersToSearch = 2;

class ServiceNowSearch extends Component {
  state = {
    searchText: '',
    totalCount: 0,
    data: [],
    expand: 'no',
    expandemplid: [],
    expandquery: '',
    searching: '',
    searchingCallup: '',
    showHR: true,
    showPeople: true,
    searchQuery: '',
    Callup_Image: [],
    restrictPeople: 10,
  };

  getCurrentAdmins(node) {
    const { AdminNameData } = this.props;

    for (let i = 0; i < AdminNameData.length; i++) {
      try {
        if (node.adminID === AdminNameData[i].contacts[0].shortName) {
          console.log('Get admins', AdminNameData[i].contacts[0].shortName);
          return this.adminNameString(AdminNameData[i].contacts[0]);
        }
      } catch {
        return '';
      }
    }
  }

  adminNameString(person) {
    let fName = person.firstName;
    if (person.prefFirstName) {
      fName = person.prefFirstName;
    }
    let lName = person.lastName;
    if (person.prefLastName) {
      lName = person.prefLastName;
    }
    return lName + ', ' + fName;
  }

  renderAssistant(assistantShortName) {
    const assistantRender = assistantShortName;
    console.log('ServiceNowSearch.renderManager', assistantRender);
    this.props.handleManagerHyperlink(assistantRender);
  }

  foundImage(emplid) {
    // const imageArray = await GetPhotos(emplid);
    // this.setState({Image_Array: imageArray})
    if (this.state.Callup_Image.length > 0) {
      let i = this.state.Callup_Image.length;
      for (var j = 0; j < i; j++) {
        if (emplid === this.state.Callup_Image[j].Emplid) {
          if (this.state.Callup_Image[j].Image) {
            if (this.state.Callup_Image[j].Image !== '') {
              return true;
            }
          }
        }
      }
    }
    return false;
  }

  getImagefromArray(emplid) {
    if (this.state.Callup_Image.length > 0) {
      let i = this.state.Callup_Image.length;
      for (var j = 0; j < i; j++) {
        if (emplid === this.state.Callup_Image[j].Emplid) {
          let returnvar = 'data:image/jpeg;base64,' + this.state.Callup_Image[j].Image;
          if (returnvar) {
            return returnvar;
          }
        }
      }
    }
  }

  getChatLink(emplid) {
    const { Callup_Image } = this.state;
    // NOTE, I DID A SPEED COMPARISON AND THE .find METHOD WAS
    // ACTUALLY SLOWER THAT LOOPING THROUGH THE ARRAY--BUT NOT NOTICABLY
    // UNTIL THE ARRAY HELD MILLIONS OF OBJECTS.  SO I USED THE .find
    // METHOD BECUASE IT LOOKS CLEANER (IMHO)
    let obj = Callup_Image.find(o => o.Emplid === emplid);
    if (obj) {
      //Found the emplid in the callup_image data
      return obj.userPrincipalName;
    } else {
      //Did not find the emplid in the callup_image data
      const { callUpSNData } = this.props;
      obj = callUpSNData.find(o => o.emplid === emplid);
      if (obj) {
        //found the emplid in the callUpSNData
        return obj.workEmail;
      }
    }
    return '';
  }

  formatPhone(phone) {
    let phone_new = phone.replace(/[^\d]+/g, '').replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3');

    return phone_new;
  }

  formatPhoneExtenstion(item) {
    if (item.workPhoneExtension) {
      let phone_new = item.workPhoneExtension.replace(/[^\d]+/g, "");
      return ' +' + phone_new;
    } else {
      return '';
    }
  }

  async loadmore() {
    if (this.props.callUpSNData.length > this.state.restrictPeople) {
      let newphotoarray = [];
      let Callup_Image = [];
      let newload = Number(this.state.restrictPeople) + 10;
      // console.log("ServiceNowSearch.loadmore newload", newload);
      newphotoarray = this.props.callUpSNData.slice(newload, newload + 10);
      this.setState({ restrictPeople: newload });
      Callup_Image = await GetPhotos(newphotoarray);
      // console.log("ServiceNowSearch.loadmore Callup_Image", Callup_Image);
      Callup_Image = this.state.Callup_Image.concat(Callup_Image);
      this.setState({ Callup_Image });
      // console.log(
      //   "ServiceNowSearch.loadmore Callup_Image1",
      //   this.state.Callup_Image
      // );
    }
  }

  renderCount = totalCount => {
    const { searchQuery, unfilteredNumber } = this.props;
    // console.log(
    //   "ServiceNowSearch.renderCount unfilteredNumber searchQuery",
    //   searchQuery
    // );
    const searchFor = searchQuery.trim();
    // console.log("unfilteredNumber", unfilteredNumber);
    // const removed = unfilteredNumber - filteredNumber;
    if (searchFor.length < minCharactersToSearch) {
      // console.log("ServiceNowSearch.renderCount unfilteredNumber here");
      return '';
    } else {
      // return (
      //   <div className="text-center">
      //     {totalCount} Results (Note: {removed} item
      //     {removed !== 1 ? "s were" : " was"} removed from the API search
      //     results because they were not from the ASC).{" "}
      //   </div>
      // );
      return (
        <React.Fragment>
          <div
            className={isMobile ? '' : 'searchSubLine'}
            style={isMobile ? { marginLeft: '-20px', display: 'flex' } : { display: 'flex' }}
          >
            <div className="searchTitle">HR Knowledge </div>
            <div className="searchCount">
              {unfilteredNumber} {unfilteredNumber !== 1 ? 'Results' : 'Result'}
            </div>
          </div>
        </React.Fragment>
      );
    }
  };

  renderCallupCount = totalCount => {
    return (
      <React.Fragment>
        <div>
          <Element name="people">
            <div
              className={isMobile ? '' : 'searchSubLine'}
              style={isMobile ? { marginLeft: '-20px', display: 'flex' } : { display: 'flex' }}
            >
              <div className="searchTitle">People </div>
              <div className="searchCount">
                {totalCount} {totalCount !== 1 ? 'Results' : 'Result'}
              </div>
              <div
                className="apslink"
                style={isMobile ? { display: 'none' } : {}}
                onClick={this.props.showAdvancedSearch}
              >
                Advanced People Search
              </div>
            </div>
          </Element>
        </div>
      </React.Fragment>
    );
  };

  handleExpand = async (a, searchQuery, item) => {
    if (searchQuery === this.state.expandquery) {
      this.setState({ expandemplid: this.state.expandemplid.concat(item.emplid) }, () => {
        console.log(
          'ServiceNowSearch.handleExpand this.state.expandemplid',
          this.state.expandemplid,
        );
      });
    } else {
      this.setState({ expandemplid: [], expandquery: searchQuery }, () => {
        this.setState({ expandemplid: this.state.expandemplid.concat(item.emplid) }, () => {
          console.log(
            'ServiceNowSearch.handleExpand this.state.expandemplid',
            this.state.expandemplid,
          );
        });
      });
    }
  };

  handleShrink = async (a, item) => {
    let shrinkarray = [];
    shrinkarray = this.state.expandemplid;
    let index = this.state.expandemplid.indexOf(item.emplid);
    shrinkarray.splice(index, 1);
    this.setState({ expandemplid: shrinkarray }, () => {
      console.log('ServiceNowSearch.handleShrink this.state.expandemplid', this.state.expandemplid);
    });
  };

  phoneFormat = async number => {
    let formatnum = number.replace('(', '');
    return formatnum;
  };

  addActivy = async (itemNumber, description) => {
    const { searchHistoryId } = this.props;
    const result = await addSearchResultActivity(searchHistoryId, itemNumber, description);
    console.log('ServiceNowSearch.addActivy activity Result', result);
  };

  onItemSelect = item => {
    let SN_URL = config.serviceNowUrl;
    const environ = getEnvironment();
    // console.log("ServiceNowSearch.onItemSelect environ", environ);
    if (environ !== 'DEFAULT') {
      SN_URL = config.Test_serviceNowUrl;
    }

    const url = SN_URL + item.number;
    window.open(url, '_blank');
    // console.log("ServiceNowSearch.onItemSelect", item);
    this.addActivy(item.number, item.title);
  };

  renderText = html => {
    let d = document.createElement('div');
    d.innerHTML = html.replace(/(\r\n|\r|\n|<br>|<br\s*[/]?>)/g, ' ');
    //start
    const anykbb = html.indexOf('kbblock');
    // console.log("ServiceNowSearch.renderText Check for kbblock");
    if (anykbb > 0) {
      //The html contains the text kbblock, so search are remove those blocks
      // console.log("Has kbblock");
      const children = d.childNodes;
      const len = children.length;
      for (let i = len; i >= 0; i--) {
        const child = children[i];
        try {
          // console.log(i, child.nodeName);
          if (child.nodeName === 'DIV') {
            //This child is a div, so check to see if its id starts with KBBLOCK
            const id = child.id;
            // console.log("id", id);
            if (id.toUpperCase().startsWith('KBBLOCK')) {
              //Remove this child
              // console.log("Remove", child.id);
              d.removeChild(child);
            }
          }
        } catch (e) {
          // console.log("ERROR", e);
        }
      }
    }
    //end
    const text = d.textContent;
    const maxLengthServiceNowText = config.maxLengthServiceNowText;
    const textprep = text.replace(/(\r\n|\r|\n|<br>)/g, ' ');
    const words = textprep.trim().split(' ');

    let output = '';
    for (let i = 0; i < words.length; i++) {
      let word = words[i].trim();
      if (word !== '') {
        let test = output + word;
        if (test.length <= maxLengthServiceNowText) {
          output += word + ' ';
        } else {
          output += '...';
          break;
        }
      }
    }
    return output;
  };

  handleBackToTop = () => {
    document.body.scrolltop = 0;
    document.documentElement.scrollTop = 0;
  };

  handleBackToTopMobile = () => {
    document.getElementById('people').scrollIntoView(0);
  };

  handleBackToTopPeople = () => {
    Scroll.scroller.scrollTo('people', {
      duration: 500,
      smooth: true,
    });
  };

  filterAll = () => {
    this.setState({ showPeople: true, showHR: true });
    // console.log(
    //   "filter results: Show People",
    //   this.state.showPeople,
    //   " Show HR ",
    //   this.state.showHR
    // );
  };

  filterPeople = () => {
    this.setState({ showPeople: true, showHR: false });
    // console.log(
    //   "ServiceNowSearch.filterPeople filter results: Show People",
    //   this.state.showPeople,
    //   " Show HR ",
    //   this.state.showHR
    // );
  };

  filterHR = () => {
    this.setState({ showPeople: false, showHR: true });
    // console.log(
    //   "ServiceNowSearch.filterHR filter results: Show People",
    //   this.state.showPeople,
    //   " Show HR ",
    //   this.state.showHR
    // );
  };

  renderManager(managerShortName) {
    const Managerrender = managerShortName;
    // console.log("ServiceNowSearch.renderManager", Managerrender);
    this.props.handleManagerHyperlink(Managerrender);
  }

  renderBottom() {
    const { searchQuery, HRK_Display, People_Display, searchData, unfilteredNumber } = this.props;
    const { showHR, showPeople } = this.state;
    let pplOnly = !showHR && showPeople ? true : false;
    let searchBarPpl = !HRK_Display && People_Display ? true : false;
    pplOnly = pplOnly || searchBarPpl ? true : false;
    const emailSubject = encodeURIComponent(`HR Knowledge Search: ${searchQuery}`);
    const emailBody = encodeURIComponent(
      `HR Knowledge Search: ${searchQuery}\nDo not delete the above line.  Please provide additional information below regarding your question.`,
    );
    return (
      <React.Fragment>
        {unfilteredNumber > this.props.restrictKnowledgeData ? (
          <button
            className="btn btn-primary LoadResultsHR"
            onClick={() => this.props.loadMoreKnowledge()}
          >
            Load More HR Knowledge Results
          </button>
        ) : (
          <button
            className="btn btn-primary noLoadResultsHR"
            style={
              unfilteredNumber > 0 ? { pointerEvents: 'none', color: '#fff' } : { display: 'none' }
            }
          >
            No More Results to Load
          </button>
        )}
        {isMobile ? (
          <div
            className="text-left NeedHelpDiv"
            style={!pplOnly ? { marginLeft: '-20px' } : { display: 'none' }}
          >
            Didn't find what you are looking for?<br></br>Please refine your search criteria or{' '}
            <br></br> click for more help.
          </div>
        ) : (
          <div
            style={!pplOnly ? { display: 'block' } : { display: 'none' }}
            className="text-left NeedHelpDiv"
          >
            Didn't find what you are looking for? Please refine your search criteria or click for
            more help.
          </div>
        )}
        <div
          className="backToTop"
          onClick={this.handleBackToTop}
          style={isMobile || pplOnly ? { display: 'none' } : { marginTop: '50px' }}
        >
          Back to Top
        </div>

        <Button
          className="btn-primary NeedHelpButton"
          style={
            !pplOnly
              ? {
                  backgroundColor: '#1C57A5',
                  borderColor: '#1C57A5',
                }
              : { display: 'none' }
          }
          // variant="outline-primary"
          onClick={() => this.addActivy('Need More Help', 'Send email to request help')}
          href={`mailto:NWASC@nationwide.com?subject=${emailSubject}&body=${emailBody}`}
        >
          Need More Help?
        </Button>
      </React.Fragment>
    );
  }

  renderCallupBottom(totalCount) {
    // const { searchQuery } = this.props;
    return (
      <React.Fragment>
        <div
          className="backToTop"
          style={isMobile ? { display: 'none' } : {}}
          onClick={isMobile ? this.handleBackToTopMobile : this.handleBackToTopPeople}
        >
          Back to Top
        </div>
        {totalCount > this.state.restrictPeople ? (
          <button className="btn btn-primary LoadResults" onClick={() => this.loadmore()}>
            Load More People Results
          </button>
        ) : (
          <button
            className="btn btn-primary LoadResults"
            style={{ pointerEvents: 'none', color: '#fff' }}
          >
            No More Results to Load
          </button>
        )}
      </React.Fragment>
    );
  }

  resultFilter(searching, searchingCallup, hrLength, pLength) {
    if (searching || searchingCallup) {
      return <div></div>;
    } else {
      if (isMobile) {
        let togglevalue = 'All';
        let togglespace = (
          <>
            &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
            &nbsp; &nbsp;
          </>
        );
        if (this.state.showPeople === false && this.state.showHR === true) {
          togglevalue = 'HR Knowledge';
          togglespace = <>&nbsp; &nbsp; &nbsp; &nbsp;</>;
        }
        if (this.state.showPeople === true && this.state.showHR === false) {
          togglevalue = 'People';
          togglespace = <>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</>;
        }
        return (
          <div>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <div className="searchTitle" style={{ marginLeft: '30px', marginRight: '15px' }}>
                Filter by:
              </div>
              <div className="dropdown-mobilebar">
                <Dropdown
                  style={{
                    backgroundColor: 'transparent',
                    position: 'relative',
                    caretDown: { color: '#707070' },
                  }}
                >
                  {/* {togglevalue === "All" ? ( */}
                  <Dropdown.Toggle
                    variant="success"
                    id="dropdown"
                    className="dropdown-mobilesearch"
                  >
                    {togglevalue} {togglespace}
                  </Dropdown.Toggle>
                  {/* ): (<Dropdown.Toggle variant="success" id="dropdown"
            className = "dropdown-mobilesearch">
              {togglevalue}
            </Dropdown.Toggle>)} */}
                  <Dropdown.Menu align="left" style={{ fontFamily: 'nw-primary', fontSize: '' }}>
                    <Dropdown.Item onClick={() => this.filterAll()}>All</Dropdown.Item>
                    {this.props.HRK_Display === true ? (
                      <Dropdown.Item onClick={() => this.filterHR()}>HR Knowledge</Dropdown.Item>
                    ) : (
                      <span></span>
                    )}
                    {this.props.People_Display === true ? (
                      <Dropdown.Item onClick={() => this.filterPeople()}>People</Dropdown.Item>
                    ) : (
                      <span></span>
                    )}
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
            <div className="searchTitleLine" style={{ minWidth: '105%' }}></div>
          </div>
        );
      } else {
        return (
          <div style={{ minWidth: '170px' }}>
            <div className="searchTitle">Filter by:</div>
            <div
              className="searchFilterWords"
              style={
                this.state.showPeople === true && this.state.showHR === true
                  ? { backgroundColor: '#DEE6F2' }
                  : {}
              }
              onClick={() => this.filterAll()}
            >
              All
            </div>
            {this.props.HRK_Display === true ? (
              <div
                className="searchFilterWords"
                style={
                  this.state.showPeople === false && this.state.showHR === true
                    ? { backgroundColor: '#DEE6F2' }
                    : {}
                }
                onClick={() => this.filterHR()}
              >
                HR Knowledge ({hrLength})
              </div>
            ) : (
              <div></div>
            )}
            {this.props.People_Display === true ? (
              <div
                className="searchFilterWords"
                style={
                  this.state.showPeople === true && this.state.showHR === false
                    ? { backgroundColor: '#DEE6F2' }
                    : {}
                }
                onClick={() => this.filterPeople()}
              >
                People ({pLength})
              </div>
            ) : (
              <div></div>
            )}
          </div>
        );
      }
    }
  }

  renderSearch(
    searchQuery,
    searching,
    searchingCallup,
    onSearchQueryUpdate,
    onSearchQueryUpdateCallup,
    callUpSNData,
  ) {
    // var newQuery = searchQuery.replaceAll('/', '<i>');
    // newQuery = newQuery.replaceAll(';', '</i>');
    if (!searchQuery.includes('<b>')) {
      searchQuery = '<b>' + searchQuery + '</b>';
    }
    if (searching || searchingCallup) {
      return <div></div>;
    } else {
      if (isMobile) {
        // console.log("testing mobile width", window.innerWidth);
        return (
          <div className="searchbar-mobile" style={{ minWidth: '110%' }}>
            <div
              // className="showWhenMobile"
              style={{ marginLeft: '10px', maxWidth: '100%' }}
            >
              <div id="searchDiv" style={{ display: 'block', marginLeft: '35px' }}>
                <div style={{ paddingBottom: '15px' }}></div>
                <SearchBar
                  searchQuery={searchQuery}
                  onSearchQueryUpdate={searchQuery => onSearchQueryUpdate(searchQuery)}
                  onSearchQueryUpdateCallup={searchQuery => onSearchQueryUpdateCallup(searchQuery)}
                  callUpSNData={callUpSNData}
                  classname={true}
                />
              </div>
            </div>
          </div>
        );
      } else {
        return (
          <div>
            <div className="searchTitle">
              Search Results:{' '}
              <span
                className="searchTitleWords"
                id="searchOutput"
                dangerouslySetInnerHTML={{
                  __html: searchQuery,
                }}
              ></span>
            </div>
            <div className="searchTitleLine"></div>
          </div>
        );
      }
    }
  }

  renderError() {
    return (
      <React.Fragment>
        <div style={isMobile ? { display: 'flex', marginLeft: '-70px' } : { display: 'flex' }}>
          <div
            className="portletErrorAlert"
            style={
              isMobile
                ? {
                    marginLeft: '50px',
                    marginTop: '25px',
                    paddingTop: '5px',
                    paddingLeft: '12px',
                  }
                : { marginLeft: '50px', marginTop: '25px', paddingTop: '5px' }
            }
          >
            !
          </div>
          <div
            className="tile-apierrortext"
            style={{
              marginLeft: '10px',
              textAlign: 'left',
              marginTop: '25px',
              paddingTop: '5px',
            }}
          >
            Oops, this is unexpected.
          </div>
        </div>
        <div className="search-apierrortext1" style={isMobile ? { marginLeft: '24px' } : {}}>
          Error code: 500
        </div>
        {isMobile ? (
          <div className="search-apierrortext1" style={{ marginLeft: '24px' }}>
            An error has occurred and <br></br>we’re working to fix the issue.
          </div>
        ) : (
          <div className="search-apierrortext1">
            An error has occurred and we’re working to fix the issue.
          </div>
        )}
        <div className="search-apierrortext1" style={isMobile ? { marginLeft: '24px' } : {}}>
          <span>If the issue persists please </span>
          <a
            className="tile-apierrorcontactus"
            href={config.contactUsUrl}
            rel="noopener noreferrer"
            target="_blank"
          >
            contact us.
          </a>
        </div>
        <div style={{ paddingTop: '25px' }}></div>
      </React.Fragment>
    );
  }

  renderEmptyResults(searching, searchingCallup) {
    const { searchQuery, HRK_Display, People_Display, searchData, unfilteredNumber } = this.props;
    const { showHR, showPeople } = this.state;
    let ppl = People_Display || showPeople ? true : false;
    let hr_filter = showHR && !showPeople ? true : false;
    let hr_only = HRK_Display && !People_Display ? true : false;
    ppl = ppl && !hr_filter && !hr_only ? true : false;

    if (searching || searchingCallup) {
      return <Spinner />;
    }

    return (
      <div>
        <div className="search-noresults" style={isMobile ? {} : { paddingLeft: '14px' }}>
          No Results
        </div>
        {isMobile ? (
          <div className="text-left UseAps" style={{ marginBottom: '20px' }}>
            Please refine your search criteria.
          </div>
        ) : (
          <div
            className="text-left UseAps"
            style={ppl ? { marginBottom: '20px', paddingLeft: '14px' } : { display: 'none' }}
          >
            Use{' '}
            <a
              className="apslink-noResults"
              style={isMobile ? { display: 'none' } : {}}
              onClick={this.props.showAdvancedSearch}
              title="Go to Advnaced People Search page."
            >
              Advanced People Search
            </a>{' '}
            for expanded people search criteria.
          </div>
        )}
        <div className="col" style={{ textAlign: 'left' }}>
          {this.renderBottom()}
        </div>
        <div style={{ paddingTop: '75px' }}></div>
      </div>
    );
  }

  getOrgChart = item => {
    const { showOrgChart, fetchOrgChartData } = this.props;
    showOrgChart(item);
    const orgResp = fetchOrgChartData(item);
    // console.log("Org chart", orgResp);
  };

  getWorkGroup = item => {
    const { showWorkGroup, fetchWorkGroupData } = this.props;
    showWorkGroup(item);
    console.log('Org chart', item.managerShortName);
    const orgResp = fetchWorkGroupData(item.managerShortName);
  };

  renderResults(searchData, searching) {
    // if (searchQuery.length > 0 && searchQuery.length < 2) {
    //   return <div></div>;
    // }
    if (searching) {
      return <Spinner />;
    }
    if (this.props.HRK_Error) {
      return (
        <div style={{ minWidth: '780px' }}>
          {this.renderCount(0)}
          {this.renderError()}
        </div>
      );
    } else {
      if (searchData) {
        if (searchData.length === 0) {
          // if (searchQuery.trim() === "") {
          //   return <div></div>;
          // } else {
          return (
            <div style={{ minWidth: '780px' }}>
              {this.renderCount(0)}
              <div className="row">
                {/* <div className="col col-sm-1"></div> */}
                <div className="col" style={{ textAlign: 'left' }}>
                  {this.renderBottom()}
                </div>
              </div>
            </div>
          );
          // }
        } else {
          return (
            <div>
              {this.renderCount(searchData.length)}
              <div style={{ textAlign: 'left' }}>
                {searchData.map(item => (
                  <div className="row" key={item.number}>
                    {/* <div className="col col-sm-1"></div> */}
                    <div
                      className="col md-card resultArticle"
                      style={isMobile ? { marginLeft: '-20px' } : { marginLeft: '8px' }}
                      // key={item.number}
                      aria-label="Click to view this article in ServiceNow"
                      title="Click to view this article in ServiceNow"
                      onClick={() => this.onItemSelect(item)}
                    >
                      <div className="md-card-title articleTitle">{item.title}</div>
                      <div className="md-card-text articleText">
                        {this.renderText(item.snippet)}
                      </div>
                    </div>
                  </div>
                ))}
                <div className="row">
                  {/* announcementContainer */}
                  <div className="col">{this.renderBottom()}</div>
                </div>
              </div>
            </div>
          );
        }
      } else {
        return <div>{this.renderCount(0)}</div>;
      }
    }
  }
  
  buildNameString(person) {
    let dataRow = person;
    let output = "";
    
    if (
      dataRow.prefFirstName &&
      dataRow.prefFirstName.replace(" ", "") !== ""
    ) {
      output += dataRow.prefFirstName + " ";
    } else {
      output += dataRow.firstName + " ";
    }
    if (dataRow.prefLastName && dataRow.prefLastName.replace(" ", "") !== "") {
      output += dataRow.prefLastName + " ";
    } else {
      output += dataRow.lastName + " ";
    }
  
    return output;
  }

  renderCallupResults(callUpSNData, searchingCallup, searchQuery) {
    // if (searchQuery.length > 0 && searchQuery.length < 2) {
    //   return <div></div>;
    // }
    if (searchingCallup) {
      return <Spinner />;
    }

    if (this.props.People_Error) {
      return (
        <div style={{ minWidth: '900px' }}>
          {this.renderCallupCount(0)}
          {this.renderError()}
        </div>
      );
    } else {
      if (callUpSNData) {
        if (callUpSNData.length === 0) {
          // if (searchQuery.trim() === "") {
          //   return <div></div>;
          // } else {
          return (
            <div style={{ minWidth: '900px' }}>
              {this.renderCallupCount(0)}
              <div className="search-noresults">No Results</div>
              {isMobile ? (
                <div className="text-left NeedHelpDiv" style={{ marginBottom: '20px' }}>
                  Please refine your search criteria or<br></br> use Advanced People Search.
                </div>
              ) : (
                <div className="text-left NeedHelpDiv" style={{ marginBottom: '20px' }}>
                  Please refine your search criteria or use Advanced People Search.
                </div>
              )}
            </div>
          );
          // }
        } else {
          return (
            <div>
              {this.renderCallupCount(callUpSNData.length)}
              <div style={{ textAlign: 'left' }}>
                {callUpSNData.slice(0, this.state.restrictPeople).map(item => (
                  <div className="row">
                    {/* <div className="col col-sm-1"></div> */}
                    <div
                      className="col md-card-people resultArticle"
                      style={
                        isMobile ? { marginLeft: '-20px' } : { marginLeft: '8px', width: '100%' }
                      }
                      key={item}
                      // onClick={() => this.onItemSelect(item)}
                    >
                      <div style={isMobile ? {} : { display: 'flex' }}>
                        <div
                          style={
                            isMobile
                              ? { display: 'flex' }
                              : {
                                  display: 'flex',
                                  minWidth: '500px',
                                  maxWidth: '500px',
                                }
                          }
                        >
                          {this.state.expandemplid.includes(item.emplid) ? (
                            <div onClick={() => this.handleShrink(this, item)}>
                              <img
                                className="expandPeople"
                                alt={"Click to collapse people results for " +this.buildNameString(item)}
                                src="/bolt-chevron-up.svg"
                                style={
                                  isMobile
                                    ? {
                                        marginLeft: '-4px',
                                        marginRight: '-10px',
                                      }
                                    : {}
                                }
                              />
                            </div>
                          ) : (
                            <div onClick={() => this.handleExpand(this, searchQuery, item)}>
                              <img
                                className="expandPeople"
                                alt={"Click to expand people results for " + this.buildNameString(item)}
                                src="/bolt-chevron-down.svg"
                                style={
                                  isMobile
                                    ? {
                                        marginLeft: '-4px',
                                        marginRight: '-10px',
                                      }
                                    : {}
                                }
                              />
                            </div>
                          )}
                          {this.state.Callup_Image.length > 0 ? (
                            <div>
                              {this.foundImage(item.emplid) ? (
                                <img
                                  className="people-image"
                                  src={this.getImagefromArray(item.emplid)}
                                  alt="Associate image"
                                />
                              ) : (
                                <img className="people-image" alt="no-image" src="/no-image.svg" />
                              )}
                            </div>
                          ) : (
                            <img className="people-image" alt="no-image" src="/no-image.svg" />
                          )}
                          <div>
                            <div
                              className="md-card-title articleTitle"
                              style={{ paddingTop: '2px' }}
                            >
                              {this.buildNameString(item)}
                            </div>
                            <div
                              className="people-subtitle"
                              style={
                                isMobile
                                  ? { marginTop: '-8px', lineHeight: '20px' }
                                  : { paddingTop: '-10px' }
                              }
                            >
                              {item.employeeType === 'Non-Employee'
                                ? 'Non-Employee'
                                : item.jobTitle}
                            </div>
                          </div>
                        </div>
                        <div style={isMobile ? {} : { direction: 'flex' }}>
                          {item.workPhone ? (
                            <div style={isMobile ? { marginLeft: '25px' } : {}}>
                              <span className="people-phone">OFFICE PHONE NUMBER: </span>
                              <span className="people-subtitle">
                                1-{this.formatPhone(item.workPhone)}{this.formatPhoneExtenstion(item)}
                              </span>
                              <div style={{ display: 'flex' }}>
                                <a className="people-email" href={`mailto:${item.workEmail}`}>
                                  <img
                                    style={{
                                      marginLeft: '25px',
                                      marginTop: '-6px',
                                    }}
                                    alt="email"
                                    src="/email.svg"
                                  />
                                  <span
                                    style={{
                                      paddingLeft: '4px',
                                      position: 'absolute',
                                      marginTop: '-2px',
                                    }}
                                  >
                                    Email
                                  </span>
                                </a>
                                <a
                                  className="people-chat"
                                  href={`https://teams.microsoft.com/l/chat/0/0?users=${this.getChatLink(
                                    item.emplid,
                                  )}`}
                                  rel="noopener noreferrer"
                                  target="_blank"
                                >
                                  <img
                                    style={{
                                      marginLeft: '25px',
                                      marginTop: '-6px',
                                    }}
                                    alt="chat"
                                    src="/chat.svg"
                                  />
                                  <span
                                    style={{
                                      paddingLeft: '4px',
                                      position: 'absolute',
                                      marginTop: '-2px',
                                    }}
                                  >
                                    Chat
                                  </span>
                                </a>
                              </div>
                            </div>
                          ) : (
                            <div
                              style={
                                isMobile
                                  ? { marginLeft: '25px', display: 'flex' }
                                  : { display: 'flex' }
                              }
                            >
                              <a
                                className="people-email"
                                style={{ marginTop: '20px' }}
                                href={`mailto:${item.workEmail}`}
                              >
                                <img
                                  style={{
                                    marginLeft: '25px',
                                    marginTop: '-6px',
                                  }}
                                  alt="email"
                                  src="/email.svg"
                                />
                                <span
                                  style={{
                                    paddingLeft: '4px',
                                    position: 'absolute',
                                    marginTop: '-2px',
                                  }}
                                >
                                  Email
                                </span>
                              </a>
                              <a
                                className="people-chat"
                                href={`https://teams.microsoft.com/l/chat/0/0?users=${item.workEmail}`}
                                rel="noopener noreferrer"
                                target="_blank"
                                style={{ marginTop: '20px' }}
                              >
                                <img
                                  style={{
                                    marginLeft: '25px',
                                    marginTop: '-6px',
                                  }}
                                  alt="chat"
                                  src="/chat.svg"
                                />
                                <span
                                  style={{
                                    paddingLeft: '4px',
                                    position: 'absolute',
                                    marginTop: '-2px',
                                  }}
                                >
                                  Chat
                                </span>
                              </a>
                            </div>
                          )}
                        </div>
                      </div>
                      {this.state.expandemplid.includes(item.emplid) ? (
                        <div
                          className="peopleexpandLine"
                          style={isMobile ? {} : { display: 'flex' }}
                        >
                          <div
                            style={
                              isMobile
                                ? {}
                                : {
                                    minWidth: '440px',
                                    maxWidth: '440px',
                                    marginLeft: '60px',
                                    paddingTop: '10px',
                                  }
                            }
                          >
                            <div>
                              <span className="people-phone">SHORTNAME: </span>
                              <span className="people-subtitle">{item.shortName}</span>
                            </div>
                            <div>
                              <span className="people-phone">WORK GROUP: </span>
                              <span className="people-subtitle">{item.department}</span>
                            </div>
                            <div>
                              <span className="people-phone">REPORTS TO: </span>
                              <span
                                className="people-subtitle"
                                onClick={() => this.renderManager(item.managerShortName)}
                                style={{
                                  textDecoration: 'underline',
                                  color: '#1C57A5',
                                  cursor: 'pointer',
                                }}
                              >
                                {/* {item.managerLastName}, {item.managerFirstName} */}
                                {item.managerPrefName}
                              </span>
                            </div>
                            {item.adminID ? (
                              <div>
                                <span className="people-phone">ADMINISTRATIVE ASSISTANT: </span>
                                <span
                                  className="people-subtitle"
                                  style={{
                                    textDecoration: 'underline',
                                    color: '#1C57A5',
                                    cursor: 'pointer',
                                  }}
                                  onClick={() => this.renderAssistant(item.adminID)}
                                >
                                  {this.getCurrentAdmins(item)}
                                </span>
                              </div>
                            ) : (
                              <div> </div>
                            )}
                            <div
                              style={
                                isMobile
                                  ? { marginLeft: '25px', display: 'flex' }
                                  : { display: 'flex' }
                              }
                            >
                              <a
                                className="people-workGroup"
                                // href={"/orgchart"}
                                rel="noopener noreferrer"
                                target="_blank"
                                title="Open Work Group"
                                style={{ marginTop: '20px' }}
                                onClick={() => this.getWorkGroup(item)}
                              >
                                <img
                                  style={{
                                    marginLeft: '12px',
                                    marginTop: '-6px',
                                  }}
                                  alt="Work Group"
                                  src="/work-group-icon.svg"
                                />

                                <span
                                  style={{
                                    paddingLeft: '6px',
                                    position: 'absolute',
                                    marginTop: '-2px',
                                  }}
                                >
                                  Work Group
                                </span>
                              </a>
                              <a
                                className="people-org"
                                // href={'/orgchart'}
                                rel="noopener noreferrer"
                                target="_blank"
                                style={{ marginTop: '20px' }}
                                title="Open OrgChart"
                                onClick={() => this.getOrgChart(item)}
                              >
                                <img
                                  style={{
                                    marginLeft: '12px',
                                    marginTop: '-6px',
                                  }}
                                  alt="Org Chart"
                                  src="/org-chart-icon.svg"
                                />
                                <span
                                  style={{
                                    color: '#1c57a5',
                                    paddingLeft: '6px',
                                    position: 'absolute',
                                    marginTop: '-2px',
                                  }}
                                >
                                  Org Chart
                                </span>
                              </a>
                            </div>
                          </div>
                          <div style={isMobile ? {} : { paddingTop: '10px' }}>
                            <div>
                              <span className="people-phone">MAIL CODE: </span>
                              <span className="people-subtitle">{item.mailDrop}</span>
                            </div>
                            <div>
                              <span className="people-phone">DISBURSEMENT CODE: </span>
                              <span className="people-subtitle">{item.disbCode}</span>
                            </div>
                            <div>
                              <span className="people-phone">
                                HR BUSINESS LOCATION (LOCATION ID):{' '}
                              </span>
                            </div>
                            <div className="people-subtitle">{item.locName}</div>
                            <div className="people-subtitle">
                              {item.state}
                              {', '}
                              {item.postal}
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div></div>
                      )}
                    </div>
                  </div>
                ))}
                <div className="row">
                  <div className="col col-sm-1" style={isMobile ? { display: 'none' } : {}}></div>
                  <div className="col" style={{ marginTop: '20px', marginBottom: '20px' }}>
                    {this.renderCallupBottom(callUpSNData.length)}
                  </div>
                </div>
              </div>
            </div>
          );
        }
      } else {
        return <div>{this.renderCallupCount(0)}</div>;
      }
    }
  }

  render() {
    const {
      searchData,
      searchQuery,
      searching,
      callUpSNData,
      searchingCallup,
      Callup_Image,
      onSearchQueryUpdate,
      onSearchQueryUpdateCallup,
    } = this.props;

    // For every new search
    if (!(this.state.searchQuery === searchQuery) && !(searchQuery === '')) {
      this.setState({
        showPeople: true,
        showHR: true,
        searchQuery,
        expandemplid: [],
        Callup_Image: [],
        restrictPeople: 10,
      });
    }

    if (
      (this.props.Callup_Image.length > 0 && this.state.Callup_Image.length === 0) ||
      this.props.Callup_Image.length > this.state.Callup_Image.length
    ) {
      this.setState({ Callup_Image });
    }

    // console.log(
    //   "ServiceNowSearch.render APS Image_Array in Search",
    //   this.props.Callup_Image
    // );
    // console.log("ServiceNowSearch.render HRK_Error", HRK_Error);
    // console.log("ServiceNowSearch.render People_Error", People_Error);
    // console.log(
    //   "ServiceNowSearch.render queryselection unfilteredNumber searchQuery",
    //   searchQuery
    // );
    // console.log("ServiceNowSearch.render queryselection searching", searching);
    // console.log(
    //   "ServiceNowSearch.render queryselection searchingCallup",
    //   searchingCallup
    // );
    const hrLength = searchData ? searchData.length : 0;
    const pLength = callUpSNData ? callUpSNData.length : 0;
    // console.log("ServiceNowSearch.render APS callUpSNData ", callUpSNData);
    // console.log("ServiceNowSearch.render hrlength ", hrLength);
    // console.log("ServiceNowSearch.render plength ", pLength);
    if (searching || searchingCallup) {
      return <Spinner />;
    } else {
      return (
        <div className="announcements" style={{ height: 'auto' }}>
          <div className="container">
            <div className="row">
              <div
                className={isMobile ? 'col' : 'announcementContainer col'}
                style={isMobile ? { height: 'auto', marginLeft: '-12px' } : { height: 'auto' }}
              >
                <div>
                  {this.renderSearch(
                    searchQuery,
                    searching,
                    searchingCallup,
                    onSearchQueryUpdate,
                    onSearchQueryUpdateCallup,
                    callUpSNData,
                  )}
                </div>
                <div style={isMobile ? {} : { display: 'flex' }}>
                  <div>{this.resultFilter(searching, searchingCallup, hrLength, pLength)}</div>
                  {hrLength === 0 &&
                  pLength === 0 &&
                  !this.props.HRK_Error &&
                  !this.props.People_Error ? (
                    <div style={{ marginLeft: '50px' }}>
                      {this.renderEmptyResults(searching, searchingCallup)}
                    </div>
                  ) : (
                    <div>
                      {this.state.showHR === true && this.props.HRK_Display === true ? (
                        <div>
                          <div style={{ marginLeft: '50px' }}>
                            {this.renderResults(searchData, searching)}
                          </div>
                          <div
                            className="searchTitleLine"
                            style={isMobile ? { minWidth: '110%' } : { display: 'none' }}
                          ></div>
                        </div>
                      ) : (
                        <div></div>
                      )}
                      {this.state.showPeople === true && this.props.People_Display === true ? (
                        <div style={{ marginLeft: '50px' }}>
                          {this.renderCallupResults(callUpSNData, searchingCallup, searchQuery)}
                        </div>
                      ) : (
                        <div></div>
                      )}
                    </div>
                  )}
                </div>
              </div>
              <div
                style={{
                  width: '100%',
                  textAlign: 'left',
                  marginBottom: '8px',
                }}
              >
                {searchData.length >= 0 &&
                !searching &&
                !searchingCallup &&
                searchQuery.length >= 2 ? (
                  <React.Fragment>{}</React.Fragment>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      );
    }
  }
}

export default ServiceNowSearch;
