import React, { Component } from "react";
// import { PortletInfo } from "../common/portletInfo";
// import { isMobile } from "react-device-detect";

class HPL extends Component {
  render() {
    return <React.Fragment></React.Fragment>;
    // return (
    //   <div className="mobileCalendarContainer" style={{ maxWidth: "540px" }}>
    //     <div className="actionsContent">
    //       <div className="tile-header">
    //         High Performing Leaders with Kirt
    //         <div className="noScrollingActions">
    //           <img
    //             src="/HPL_Capture.jpg"
    //             alt="Sttic POS"
    //             style={{ width: "100%", maxWidth: "498px" }}
    //           />
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    // );
  }
}

export default HPL;
