import React, { Component } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify';
import { replace } from 'lodash';
// import keydown, { Keys } from 'react-keydown';

class SearchBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeSuggestion: 0,
      filteredSuggestions: [],
      showSuggestions: false,
      searchQuery: '',
      queryselection: [],
      keyDownQuery: '',
    };
  }
  state = {
    wellbeing: false,
    money: false,
    benefits: false,
    career: false,
    administrator: false,
  };
  static propTypes = {};
  static defaultProperty = {
    suggestions: [],
  };

  // searchQuery = React.createRef();

  handleSubmit = e => {
    e.preventDefault();
    let searchText = '';
    if (this.state.searchQuery) {
      searchText = this.state.searchQuery
        .replace(' in <b>People</b>', '')
        .replace('in <b>HR Knowledge</b>', '');
      searchText = searchText.trim();
    }
    if (searchText.length === 0 || (searchText.length > 0 && searchText.length < 2)) {
      toast.error(`At least 2 characters must be entered to search`);
    } else {
      this.props.onSearchQueryUpdate(this.state.searchQuery);
      // BC 1/14/2021, this is the same functin as above (onSearchQueryUpdate), there is
      // no reason to call it twice
      // this.props.onSearchQueryUpdateCallup(this.state.searchQuery);
    }
    this.setState({
      activeSuggestion: 0,
      filteredSuggestions: [],
      showSuggestions: false,
    });
  };

  handleChange = e => {
    this.setState({ value: e.target.value.trim() });
  };

  onChange = e => {
    // const { suggestions } = this.props;
    const searchQuery = e.currentTarget.value;
    const suggestions = [
      `${searchQuery} in <b>HR Knowledge</b>`,
      `${searchQuery} in <b>People</b>`,
    ];

    // console.log("searchBar.onChange suggestions", suggestions);
    // console.log("searchBar.onChange searchQuery", searchQuery);

    const filteredSuggestions = suggestions;

    this.setState({
      activeSuggestion: 0,
      filteredSuggestions,
      showSuggestions: true,
      searchQuery: e.currentTarget.value,
    });
  };

  onClick = e => {
    // console.log(
    //   "searchBar.onClick queryselection int",
    //   e.currentTarget.innerText
    // );
    // console.log("searchBar.onClick queryselection int", e.target.value);
    let searchQuery = e.currentTarget.innerText;
    if (searchQuery.includes('in People')) {
      searchQuery = searchQuery.replace('in People', 'in <b>People</b>');
    }
    if (searchQuery.includes('in HR Knowledge')) {
      searchQuery = searchQuery.replace('in HR Knowledge', 'in <b>HR Knowledge</b>');
    }
    this.setState(
      {
        activeSuggestion: 0,
        filteredSuggestions: [],
        showSuggestions: false,
        // searchQuery: e.currentTarget.innerText,
        searchQuery,
      },
      () => {
        let searchText = '';
        searchText = this.state.searchQuery
          .replace('in <b>HR Knowledge</b>', '')
          .replace('in <b>People</b>', '');
        if (searchText) {
          searchText = searchText.trim();
        }
        // console.log("searchBar.onClick searchText", searchText);
        if (searchText.length === 0 || (searchText.length > 0 && searchText.length < 2)) {
          toast.error(`At least 2 characters must be entered to search`);
        } else {
          this.props.onSearchQueryUpdate(this.state.searchQuery);
          this.props.onSearchQueryUpdateCallup(this.state.searchQuery);
        }
      },
    );
  };

  // @keydown( '38' )
  onKeyDown = e => {
    const keyDowntest = this.state.keyDownQuery
      .replace(' in <b>People</b>', '')
      .replace(' in <b>HR Knowledge</b>', '');
    if (e.currentTarget.value !== keyDowntest) {
      this.setState({ keyDownQuery: '' });
    }
    // console.log("searchBar.onKeyDown e", e.currentTarget.value);

    if (e.keyCode === 38) {
      // console.log("searchBar.onKeyDown 38 before", this.state.keyDownQuery);
      if (this.state.keyDownQuery.includes(' in <b>People</b>')) {
        const keyDownQuery = e.currentTarget.value + ' in <b>HR Knowledge</b>';
        this.setState({ keyDownQuery, searchQuery: keyDownQuery }, () => {
          console.log('searchBar.onKeyDown 38 after', this.state.keyDownQuery);
        });
      }
    } else if (e.keyCode === 40) {
      // down arrow
      // console.log("searchBar.onKeyDown 40 before", this.state.keyDownQuery);
      if (this.state.keyDownQuery.includes(' in <b>HR Knowledge</b>')) {
        const keyDownQuery = e.currentTarget.value + ' in <b>People</b>';
        this.setState({ keyDownQuery, searchQuery: keyDownQuery }, () => {
          console.log('searchBar.onKeyDown 40 after', this.state.keyDownQuery);
        });
      } else {
        if (this.state.keyDownQuery === '') {
          const keyDownQuery = e.currentTarget.value + ' in <b>HR Knowledge</b>';
          this.setState({ keyDownQuery, searchQuery: keyDownQuery }, () => {
            console.log('searchBar.onKeyDown 40 after', this.state.keyDownQuery);
          });
        }
      }
    }
    // if (this.state.keyDownQuery !== "") {
    //   console.log("searchBar.onKeyDown exit", this.state.searchQuery);
    // }
  };

  render() {
    // const { searchQuery } = this.props;
    const {
      onChange,
      onClick,
      onKeyDown,
      state: {
        filteredSuggestions,
        showSuggestions,
        searchQuery,
        // queryselection
      },
    } = this;

    let suggestionsListComponent;

    let querytry = replace(replace(this.props.searchQuery, '<b>', ''), '</b>', '');

    querytry = querytry.includes('<i>') ? '' : querytry;

    if (showSuggestions && searchQuery && searchQuery.trim().length > 0) {
      const CNSugg =
        this.props.classname === true ? 'search-suggestionMsearch' : 'search-suggestion';
      suggestionsListComponent = (
        <div className={CNSugg}>
          {filteredSuggestions.map((suggestion, index) => {
            return (
              <div
                className="suggestiontitle"
                key={suggestion}
                style={
                  suggestion === this.state.keyDownQuery ? { backgroundColor: 'lightgray' } : {}
                }
                onClick={onClick}
              >
                <div dangerouslySetInnerHTML={{ __html: suggestion }}></div>
              </div>
            );
          })}
        </div>
      );
      // console.log(
      //   "searchBar.render  suggestionsListComponent in render",
      //   suggestionsListComponent
      // );
    }

    // console.log("searchBar.render searchQuery", searchQuery);
    const CN =
      this.props.classname === true ? 'snComponentTextInputMSearchPage' : 'snComponentTextInput';
    return (
      <form className="snComponentSearchForm" onSubmit={this.handleSubmit}>
        <React.Fragment>
          <input
            id="searchQuery"
            ref={this.state.value}
            className={CN}
            placeholder="HR Knowledge and People Search"
            type="text"
            onChange={onChange}
            onKeyDown={onKeyDown}
            defaultValue={querytry}
            maxLength="400"
          />
          <button
            id="btn_search"
            className="snComponentButton bolt-color-brand-muted-blue-accent"
            aria-label="Click to search HR Knowledge and People"
          >
            <img src="/search.png" alt=""></img>
          </button>
          {suggestionsListComponent}
        </React.Fragment>
      </form>
    );
  }
}

export default SearchBar;
