import React, { Component } from 'react';
import { isMobile } from 'react-device-detect';
// import MyTeam from "../components/myTeam.jsx";
// import { isBrowser } from "react-device-detect";
import leaderChecklistLinks from '../leaderChecklistLinks.json';
import '../leaderTransitionChecklist.css';

/* 
When testing the leader transtion checklist and you are not a sepm user. Go to app.jsx and find the 
checkSepm function.  Set the else to this.setState({isSepm: true}); in order to have the checklist show up
otherwise the checklist will not show up in the menu. Be sure to change the value back to this.setState({isSepm: false});
before making any updates to production.
*/
class LeaderTransitionChecklist extends Component {
  state = {
    displayDefaultPage: 'block',
    displaySearchResults: 'block',
    displayAdvancedPeopleSearch: 'block',
    displayAnnounceList: 'none',
    displayAnnounceEdit: 'none',
    displayAdminUserList: 'none',
    forceAnnounceSearchReload: 'Y',
    forceAdminUserSearchReload: 'N',
    displayAdminEffectiveLd: 'none',
    displayOrgChart: 'none',
    displayWorkGroup: 'none',
    orgChartDisplay: '',
    workGroupDisplay: '',

    style:false,
    linkChecklist:'',
    accessMgtOn: false,
    irmOn: false,
    FinanceOn: false,
    APIOn: false,
    otherLdOn: false,
    changeOn: false,

    AssetConfig: true,
    //displayChecklist: false,
  };
  focusChecklist = () => {
    // trying to use this to return focus back to the top of the checklist
    if(this.class == "return to top of list"){
      window.setTimeout(function () {
        document.getElementById("checklistTitle").focus();
      }, 0)
      console.log("the focusChecklist was called")
    };
  }

  handleAssetClick = () => {
    let assetOn = !this.state.assetOn;
    this.setState({ assetOn });
  };

  handleFinanceClick = () => {
    let financeOn = !this.state.financeOn;
    this.setState({ financeOn });
  };

  handleAccessMgtClick = () => {
    let accessMgtOn = !this.state.accessMgtOn;
    this.setState({ accessMgtOn });
  };

  handleIRMClick = () => {
    let irmOn = !this.state.irmOn;
    this.setState({ irmOn });
  };
  
  handleServiceNowClick = () => {
    let serviceNowOn = !this.state.serviceNowOn;
    this.setState({ serviceNowOn });
  };
  
  handlePCServiceClick = () => {
    let pcServiceOn = !this.state.pcServiceOn;
    this.setState({ pcServiceOn });
  };
  
  handleWorkforceClick = () => {
    let workforceOn = !this.state.workforceOn;
    this.setState({ workforceOn });
  };
  
  handleAPIsClick = () => {
    let apiOn = !this.state.apiOn;
    this.setState({ apiOn });
  };

  handleWorkMgtClick = () => {
    let workMgtOn = !this.state.workMgtOn;
    this.setState({ workMgtOn });
  };

  handleDigitalClick = () => {
    let digitalOn = !this.state.digitalOn;
    this.setState({ digitalOn });
  };

  handleCookies = click => {};

  handleChecklistDisplay = () => {
    const { closeChecklist } = this.props;
    closeChecklist();
  };
 
  loadLinks(linkStyle, linkChecklist)
  {
    return(
      <div
      className={isMobile ? 'ldOpenRefContainerMobile' : 'ldOpenRefContainer'}
      style={linkStyle ? { display: 'block' } : { display: 'none' }}
    >
      <hr class="smallSeparator" />
      {linkChecklist.items.map(item => (
      <div
          id={`leaderChecklistLinks_${item.id}`}
          as="b"
          key={item.id}
          href={item.url}
          target="_blank"
        >
          <table style={{width: "95%"}}>
            <tr>
              <th>{item.display}</th>
              <td>{<a className='checkListLinks' href={item.url} target='_blank'>{/*<div className = {isMobile ? 'clImageMobile': 'clLinkImage' }></div>*/}{item.urlText }</a>}</td>
            </tr>
          </table>
          <hr class="smallSeparator" />
        
        </div>
  ))}
    </div>)
  }

  loadChecklistContent()
  {
    const { assetOn, financeOn, accessMgtOn, irmOn, serviceNowOn, pcServiceOn, workforceOn,
      apiOn, digitalOn, workMgtOn } = this.state;
    const assetConfigLinks = leaderChecklistLinks.AssetConfig;
    const financeLinks = leaderChecklistLinks.Finance;
    const identityLinks = leaderChecklistLinks.Identity;
    const irmLinks = leaderChecklistLinks.IRM;
    const serviceNowLinks = leaderChecklistLinks.ServiceNow;
    const pcLinks = leaderChecklistLinks.PC;
    const workForceLinks = leaderChecklistLinks.WorkForce;
    const apiLinks = leaderChecklistLinks.API;
    const digitalLinks = leaderChecklistLinks.DigitalWork;
    const workMgmtLinks = leaderChecklistLinks.WorkMgmt;

    return(
        <div className={isMobile ? 'mobileJobChangeContainer' : 'jobChangeContainer'}>
        <hr class="sectionSeparator" />
        <h3 className='jobChangeSubTitle'>
        Use this checklist to ensure actions are completed for every role change, choosing only the items that are relevant to your specific scenario.  
        </h3>
        <hr class="sectionSeparator" />
          <div className="row">
            <button
              className='Check'
              aria-expanded ={this.state.assetOn ? "true" : "false"}
              tabIndex="0"
              onClick={e => this.handleAssetClick(e)}
              id={'Check'}
              style={{
                border: 'none',
                margin: "5px",
                backgroundColor: 'white',
              }}
            >
              <div className="clChevronContainer">
                <img
                  src={assetOn ? '/icon-chevron-up-dark.svg' : '/icon-chevron-down-dark.svg'}
                  alt={this.state.assetOn ? "Click to Collapse Asset & Config" : "Click to expand Asset & Config"}
                ></img>
              </div>
            </button>
            <div className='linkHeaders'>
            Asset & Config
            </div>
          </div>
          <div>
          {this.loadLinks(assetOn, assetConfigLinks)}
        </div>
        <hr class="sectionSeparator" />

            <div className="row">
              <button
                className='Check'
                tabIndex="0"
                aria-expanded ={this.state.financeOn ? "true" : "false"}
                onClick={e => this.handleFinanceClick(e)}
                id={'Check'}
                style={{
                  border: 'none',
                  margin: "5px",
                  backgroundColor: 'white',
                }}
              >
                <div className="clChevronContainer">
                  <img
                    src={financeOn ? '/icon-chevron-up-dark.svg' : '/icon-chevron-down-dark.svg'}
                    alt={this.state.financeOn ? "Click to Collapse Finance & Sourcing" : "Click to expand Finance & Sourcing"}
                  ></img>
                </div>
              </button>
              Finance & Sourcing - Contractors & Contracts
            </div>
          <div>
            {this.loadLinks(financeOn, financeLinks)}
          </div>

        <hr class="sectionSeparator" />

            <div className="row">
              <button
                className='Check'
                tabIndex="0"
                aria-expanded ={this.state.accessMgtOn? "true" : "false"}
                onClick={e => this.handleAccessMgtClick(e)}
                id={'Check'}
                style={{
                  border: 'none',
                  margin: "5px",
                  backgroundColor: 'white',
                }}
              >
                <div className="clChevronContainer">
                  <img
                    src={accessMgtOn ? '/icon-chevron-up-dark.svg' : '/icon-chevron-down-dark.svg'}
                    alt={this.state.accessMgtOn ? "Click to Collapse IRM" : "Click to expand IRM"}
                  ></img>
                </div>
              </button>
              Identity & Access Management
            </div>
            <div>
            {this.loadLinks(accessMgtOn, identityLinks)}
        </div>
        <hr class="sectionSeparator" />

            <div className="row">
              <button
                className='Check'
                aria-expanded ={this.state.irmOn ? "true" : "false"}
                tabIndex="0"
                onClick={e => this.handleIRMClick(e)}
                id={'Check'}
                style={{
                  border: 'none',
                  margin: "5px",
                  backgroundColor: 'white',
                }}
              >
                <div className="clChevronContainer">
                  <img
                    src={irmOn ? '/icon-chevron-up-dark.svg' : '/icon-chevron-down-dark.svg'}
                    alt={this.state.irmOn ? "Click to Collapse IRM" : "Click to expand IRM"}
                  ></img>
                </div>
              </button>
              Business Information Security Office (BISO) 
            </div>
            <div>
            {this.loadLinks(irmOn, irmLinks)}
            </div>
            <hr class="sectionSeparator" />

            <div className="row">
              <button
                className='Check'
                tabIndex="0"
                aria-expanded ={this.state.serviceNowOn ? "true" : "false"}
                onClick={e => this.handleServiceNowClick(e)}
                id={'Check'}
                style={{
                  border: 'none',
                  margin: "5px",
                  backgroundColor: 'white',
                }}
              >
                <div className="clChevronContainer">
                  <img
                    src={serviceNowOn ? '/icon-chevron-up-dark.svg' : '/icon-chevron-down-dark.svg'}
                    alt={this.state.serviceNowOn ? "Click to Collapse ServiceNow Groups and access" : "Click to expand ServiceNow Groups and access"}
                  ></img>
                </div>
              </button>
              ServiceNow Groups - Access & Alerting
            </div>
            <div>
              {this.loadLinks(serviceNowOn, serviceNowLinks)}
            </div>
            <hr class="sectionSeparator" />

            <div className="row">
              <button
                className='Check'
                tabIndex="0"
                aria-expanded ={this.state.pcServiceOn ? "true" : "false"}
                onClick={e => this.handlePCServiceClick(e)}
                id={'Check'}
                style={{
                  border: 'none',
                  margin: "5px",
                  backgroundColor: 'white',
                }}
              >
                <div className="clChevronContainer">
                  <img
                    src={pcServiceOn ? '/icon-chevron-up-dark.svg' : '/icon-chevron-down-dark.svg'}
                    alt={this.state.pcServiceOn ? "Click to Collapse PC Service" : "Click to expand PC service"}
                  ></img>
                </div>
              </button>
              PC Services
            </div>
            <div>
              {this.loadLinks(pcServiceOn, pcLinks)}
            </div>
            <hr class="sectionSeparator" />


            <div className="row">
              <button
                className='Check'
                tabIndex="0"
                aria-expanded ={this.state.workforceOn ? "true" : "false"}
                onClick={e => this.handleWorkforceClick(e)}
                id={'Check'}
                style={{
                  border: 'none',
                  margin: "5px",
                  backgroundColor: 'white',
                }}
              >
                <div className="clChevronContainer">
                  <img
                    src={workforceOn ? '/icon-chevron-up-dark.svg' : '/icon-chevron-down-dark.svg'}
                    alt={this.state.workforceOn ? "Click to Collapse Workforce" : "Click to expand Workforce"}
                  ></img>
                </div>
              </button>
              Workforce
            </div>
            <div>
              {this.loadLinks(workforceOn, workForceLinks)}
            </div>
            <hr class="sectionSeparator" />


            <div className="row">
              <button
                className='Check'
                tabIndex="0"
                aria-expanded ={this.state.apiOn ? "true" : "false"}
                onClick={e => this.handleAPIsClick(e)}
                id={'Check'}
                style={{
                  border: 'none',
                  margin: "5px",
                  backgroundColor: 'white',
                }}
              >
                <div className="clChevronContainer">
                  <img
                    src={apiOn ? '/icon-chevron-up-dark.svg' : '/icon-chevron-down-dark.svg'}
                    alt={this.state.apiOn ? "Click to Collapse APIs" : "Click to expand APIs"}
                  ></img>
                </div>
              </button>
              APIs
            </div>
            <div>
              {this.loadLinks(apiOn, apiLinks)}
            </div>
            <hr class="sectionSeparator" />


            <div className="row">
              <button
                className='Check'
                tabIndex="0"
                aria-expanded ={this.state.digitalOn ? "true" : "false"}
                onClick={e => this.handleDigitalClick(e)}
                id={'Check'}
                style={{
                  border: 'none',
                  margin: "5px",
                  backgroundColor: 'white',
                }}
              >
                <div className="clChevronContainer">
                  <img
                    src={digitalOn ? '/icon-chevron-up-dark.svg' : '/icon-chevron-down-dark.svg'}
                    alt={this.state.digitalOn ? "Click to Collapse Digital Workplace" : "Click to expand Digital Workplace"}
                  ></img>
                </div>
              </button>
              Digital Workplace
            </div>
            <div>
              {this.loadLinks(digitalOn, digitalLinks)}
            </div>
            <hr class="sectionSeparator" />


            <div className="row">
              <button
                className='Check'
                tabIndex="0"
                aria-expanded ={this.state.workMgtOn ? "true" : "false"}
                onClick={e => this.handleWorkMgtClick(e)}
                id={'Check'}
                style={{
                  border: 'none',
                  margin: "5px",
                  backgroundColor: 'white',
                }}
              >
                <div className="clChevronContainer">
                  <img
                    src={workMgtOn ? '/icon-chevron-up-dark.svg' : '/icon-chevron-down-dark.svg'}
                    alt={this.state.workMgtOn ? "Click to Collapse Work Management" : "Click to expand Work Management"}
                  ></img>
                </div>
              </button>
              Work Management
            </div>
            <div>
              {this.loadLinks(workMgtOn, workMgmtLinks)}
            </div>
            <hr class="sectionSeparator" />
            {// goal is to set when element is active to return to top of checklist
            /*<h3 class = "return to top of list"
                tabIndex = "0" 
                alt = "returning to top of checklist"
                blur = {this.focusChecklist()} >
              </h3>*/}

          </div>
    )
  }

  render() {
    const {displayChecklist} = this.props;
    //console.log("inside leadertransitionchecklist render. displaychecklist: ", this.props);
    //Adjusting display checklist to true and false seems to render with current build.
    return (
      //<div style={{ backgroundColor: '#DEE6F2' }}>
      <React.Fragment>
      <div className={displayChecklist ? 'noOverlay' : 'overlay'} >

      {/*console.log("inside leadertransitionchecklist return. displaychecklist: ", displayChecklist)*/}
      <div
        className={
          isMobile ? "checklistMobilePopupContainer" : "checklistPopupContainer"
        }
        style={
          displayChecklist
            ? {
                display: "block",
                position: "relative",
                
                ///overflow: "-moz-hidden-unscrollable"
              }
            : { display: "none", position: "relative", overflow: "hidden" }
        }/*aria-live="polite" //code will overwrite the expanded or collapsed annoucment */
      >
      <div className="checklist" style={isMobile ? {} : { height: "100%" }}>
          <div className="checklistBar ">
            <h2 className="checklistTitle" id= 'checklistTitle' tabIndex= '0'>
            Role Change Checklist
            </h2>
      <div
              style={{
                textAlign: "right",
                display: "inline-block"
              }}
            >
              <button
                className="btn checklistCloseButton"
                onClick={() => this.handleChecklistDisplay()}
                style={{ fontSize: "20px" }}
                title="Close Checklist display"
              ></button>
            </div>
        <div>
          {this.loadChecklistContent()}
          {/*this.focusChecklist()*/}
        </div>
        </div>
      </div>
    </div>
    </div>
      </React.Fragment>
      );
    }
}

export default LeaderTransitionChecklist;
