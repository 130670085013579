import config from "../config.json";
export function getCallbackURI() {
  const LOCAL = config.LOCAL;
  const DEV = config.DEV;
  const EXTDEV = config.EXTDEV;
  const EXTDEV2 = config.EXTDEV2;
  const TEST = config.TEST;
  const PT = config.PT;
  const TESTPROXY = config.TESTPROXY;
  const PROD = config.PROD;
  const loc = window.location.hostname;

  switch (loc) {
    case LOCAL:
      //console.log("EnvironmentVariables.getCallbackURI you are in LOCAL");
      return config.local_redirect_uri;

    case DEV:
      //console.log("EnvironmentVariables.getCallbackURI you are in DEV");
      return config.dev_redirect_uri;

    case EXTDEV:
      //console.log("EnvironmentVariables.getCallbackURI you are in EXTDEV");
      return config.extdev_redirect_uri;

    case EXTDEV2:
      //console.log("EnvironmentVariables.getCallbackURI you are in EXTDEV2");
      return config.extdev2_redirect_uri;

    case TEST:
      //console.log("EnvironmentVariables.getCallbackURI you are in TEST");
      return config.test_redirect_uri;

    case PT:
      //console.log("EnvironmentVariables.getCallbackURI you are in PT");
      return config.pt_redirect_uri;
    case TESTPROXY:
      //console.log("EnvironmentVariables.getCallbackURI you are in Test Proxy");
      return config.testproxy_redirect_uri;
    case PROD:
      return config.prod_redirect_uri;
    default:
      return config.redirect_uri;
  }
}
export function getEnvironment() {
  const LOCAL = config.LOCAL;
  const DEV = config.DEV;
  const TEST = config.TEST;
  const PT = config.PT;
  const EXTDEV = config.EXTDEV;
  const EXTDEV2 = config.EXTDEV2;
  const PROD = config.PROD;
  const loc = window.location.hostname;
  //console.log("EnvironmentVariables.getEnvironment loc", loc);
  switch (loc) {
    case LOCAL:
      return "LOCAL";
    case DEV:
      return "DEV";
    case EXTDEV:
      return "DEV";
    case EXTDEV2:
      return "DEV";
    case TEST:
      return "TEST";
    case PT:
      return "PT";
    default:
      return "DEFAULT";
  }
}
