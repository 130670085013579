import React, { Component } from 'react';
import MyTeam from '../components/myTeam';
import MyApprovals from '../components/myApprovals';
import MyCalendar from '../components/calendar';
import HPL from '../components/highPerformingLeaders';
import TeamRecognition from '../components/TeamRecognition';
import EffectiveLeader from '../components/EffectiveLeader';

class TILES extends Component {
  render() {
    const {
      team,
      teamMax,
      approvals,
      approvalsLoaded,
      approvalMax,
      calendarData,
      timeoffMax,
      birthdayMax,
      holidayMax,
      anniversaryMax,
      calendarRange,
      bravoData,
      retrieveBravos,
      bravosLoaded,
      leaderLinks,
      leaderLinksLoaded,
      retrieveEffectiveLeaderData,
      isAdmin,
      showAdminEffectiveLd,
      fetchOTApprovalData,
      fetchIIQApprovalData,
      fetchAribaApprovalData,
      fetchSnowApprovalData,
      snowApprovals,
      aribaApprovals,
      iiqApprovals,
      otApprovals,
      aribaContractApprovals,
      fetchAribaContractApprovalData,
    } = this.props;
    return (
      <div className="container contentContainer">
        <div className="row">
          <div className="col">
            <MyTeam
              team={team}
              teamMax={teamMax}
              handleChangeMaxTeam={teamMax => this.props.handleChangeMaxTeam(teamMax)}
              retrieveFOWData={refreshing => this.props.retrieveFOWData(refreshing)}
              changeTeamDisplay={memberIdx => this.props.changeTeamDisplay(memberIdx)}
              changeWholeTeamDisplay={display => this.props.changeWholeTeamDisplay(display)}
              retrieveTeam={() => this.props.retrieveTeam()}
            />
            <HPL />
            <TeamRecognition
              bravoData={bravoData}
              retrieveBravos={retrieveBravos}
              team={team}
              bravosLoaded={bravosLoaded}
            />
            <EffectiveLeader
              data={leaderLinks}
              loaded={leaderLinksLoaded}
              retrieveData={retrieveEffectiveLeaderData}
              isAdmin={isAdmin}
              showAdminEffectiveLd={showAdminEffectiveLd}
            />
          </div>

          <div className="col">
            <MyApprovals
              aribaContractApprovals={aribaContractApprovals}
              fetchAribaContractApprovalData={fetchAribaContractApprovalData}
              snowApprovals={snowApprovals}
              aribaApprovals={aribaApprovals}
              iiqApprovals={iiqApprovals}
              otApprovals={otApprovals}
              team={team}
              approvals={approvals}
              approvalsLoaded={approvalsLoaded}
              approvalMax={approvalMax}
              retrieveApprovals={this.props.retrieveApprovals}
              handleChangeMaxApprovals={teamMax => this.props.handleChangeMaxApprovals(teamMax)}
              handleRemoveApproval={idx => this.props.handleRemoveApproval(idx)}
              fetchOTApprovalData={fetchOTApprovalData}
              fetchIIQApprovalData={fetchIIQApprovalData}
              fetchAribaApprovalData={fetchAribaApprovalData}
              fetchSnowApprovalData={fetchSnowApprovalData}
              showAdminEffectiveLd={showAdminEffectiveLd}
            />
            <MyCalendar
              calendarData={calendarData}
              retrieveCalendarData={refreshing => this.props.retrieveCalendarData(refreshing)}
              timeoffMax={timeoffMax}
              handleChangeMaxTimeOff={timeoffMax => this.props.handleChangeMaxTimeOff(timeoffMax)}
              calendarRange={calendarRange}
              handleChangeCalendarRange={calendarRange =>
                this.props.handleChangeCalendarRange(calendarRange)
              }
              birthdayMax={birthdayMax}
              handleChangeMaxBirthdays={birthdayMax =>
                this.props.handleChangeMaxBirthdays(birthdayMax)
              }
              holidayMax={holidayMax}
              handleChangeMaxHolidays={holidayMax => this.props.handleChangeMaxHolidays(holidayMax)}
              anniversaryMax={anniversaryMax}
              handleChangeMaxAnniversary={anniversaryMax =>
                this.props.handleChangeMaxAnniversary(anniversaryMax)
              }
            />
          </div>
        </div>
      </div>
    );
  }
}

export default TILES;
