import config from '../config.json';
import { getRandomString } from '../services/AuthService';
import { getEnvironment } from './EnvironmentVariable';

export const getOrgChart = async Person => {
  let team = [];
  let subordinates = [];
  let teamFull = [];
  let superiors = [];

  if (Person.managerFlag === '1') {
    team = await getManagerTeam(Person.shortName);
    // person. might be able to be used.
    // Get all superiors up to CEO
    superiors = await getSuperiors(Person);
    // Manager first
    let lead = Person;
    subordinates[0] = {
      TeamMate: lead,
      Reports: [], // Currently not used, but leaving in case business decides they want to show reports of other managers
      Superior: true, // This value determines if there is an indentation or not
    };
    let temp = [];
    // Check team for other managers
    for (let i = 0; i < team.contacts.length; i++) {
      // Get direct report's teams
      if (team.contacts[i].managerFlag === '1') {
        // temp = await getManagerTeam(team.contacts[i].shortName);
        subordinates[i + 1] = {
          TeamMate: team.contacts[i],
          Reports: temp.contacts,
          Superior: false,
        };
      } else {
        subordinates[i + 1] = {
          TeamMate: team.contacts[i],
          Reports: [],
          Superior: false,
        };
      }
    }
    Array.prototype.push.apply(superiors, subordinates);
    subordinates = superiors;
    console.log('Person', superiors);
  } else {
    team = await getManagerTeam(Person.managerShortName);
    let temp = [];
    superiors = await getSuperiors(Person);
    // Check team for other managers
    for (let i = 0; i < team.contacts.length; i++) {
      // Get direct report's teams
      if (team.contacts[i].managerFlag === '1') {
        // temp = await getManagerTeam(team.contacts[i].shortName);
        subordinates[i] = {
          TeamMate: team.contacts[i],
          Reports: temp.contacts,
          Superior: false,
        };
      } else {
        subordinates[i] = {
          TeamMate: team.contacts[i],
          Reports: [],
          Superior: false,
        };
      }
    }
    Array.prototype.push.apply(superiors, subordinates);
    subordinates = superiors;
    console.log('Person is', superiors);
  }

  return subordinates;
};

export const getSuperiors = async Person => {
  let output = [];
  let count = 0;

  if (Person.level02FromTopShortName) {
    let node = await getCallupShortName(Person.level02FromTopShortName);
    output[count++] = {
      TeamMate: node.contacts[0],
      Reports: [],
      Superior: true,
    };
  }
  if (Person.level03FromTopShortName) {
    let node = await getCallupShortName(Person.level03FromTopShortName);
    output[count++] = {
      TeamMate: node.contacts[0],
      Reports: [],
      Superior: true,
    };
  }
  if (Person.level04FromTopShortName) {
    let node = await getCallupShortName(Person.level04FromTopShortName);
    output[count++] = {
      TeamMate: node.contacts[0],
      Reports: [],
      Superior: true,
    };
  }
  if (Person.level05FromTopShortName) {
    let node = await getCallupShortName(Person.level05FromTopShortName);
    output[count++] = {
      TeamMate: node.contacts[0],
      Reports: [],
      Superior: true,
    };
  }
  if (Person.level06FromTopShortName) {
    let node = await getCallupShortName(Person.level06FromTopShortName);
    output[count++] = {
      TeamMate: node.contacts[0],
      Reports: [],
      Superior: true,
    };
  }
  if (Person.level07FromTopShortName) {
    let node = await getCallupShortName(Person.level07FromTopShortName);
    output[count++] = {
      TeamMate: node.contacts[0],
      Reports: [],
      Superior: true,
    };
  }
  if (Person.level08FromTopShortName) {
    let node = await getCallupShortName(Person.level08FromTopShortName);
    output[count++] = {
      TeamMate: node.contacts[0],
      Reports: [],
      Superior: true,
    };
  }
  if (Person.level09FromTopShortName) {
    let node = await getCallupShortName(Person.level09FromTopShortName);
    output[count++] = {
      TeamMate: node.contacts[0],
      Reports: [],
      Superior: true,
    };
  }
  if (Person.level10FromTopShortName) {
    let node = await getCallupShortName(Person.level10FromTopShortName);
    output[count++] = {
      TeamMate: node.contacts[0],
      Reports: [],
      Superior: true,
    };
  }
  if (Person.level11FromTopShortName) {
    let node = await getCallupShortName(Person.level11FromTopShortName);
    output[count++] = {
      TeamMate: node.contacts[0],
      Reports: [],
      Superior: true,
    };
  }
  return output;
};

export const getWorkGroup = async shortName => {
  let output = [];
  let manager = await getCallupShortName(shortName);

  if (!manager) {
    return {
      error: 'Error retrieving Work Group data on callup shortname call',
    };
  }
  let team = await getManagerTeam(shortName);
  if (!team) {
    return {
      error: 'Error retrieving Work Group data on callup manager reports call',
    };
  }
  manager = manager.contacts[0];
  output[0] = manager;
  // Combine results
  Array.prototype.push.apply(output, team.contacts);
  console.log('GetWorkGroup', output);
  return output;
};

export const getManagerTeam = async shortName => {
  console.log('teamService.getManagerTeam started');
  const message = getRandomString(25);
  const environ = getEnvironment();
  let callupAPI = config.callupReportsAPI;
  let callupKey = config.callupKey;
  if (environ !== 'DEFAULT') {
    // if (isMobile) {
    //   callupAPI = config.OLDMobile_test_callupReportsAPI;
    //   //   callupKey = config.test_callupKey;
    // } else {
    // console.log("jobService.getState environ", environ);
    callupAPI = config.test_callupReportsAPI;
    callupKey = config.test_callupKey;
    // }
  }
  const contact = JSON.parse(sessionStorage.getItem('contact'));
  // let shortName = shortName;

  const body = {
    shortName: encodeURIComponent(shortName),
  };
  console.log();
  let headers = new Headers();
  headers.append('Content-Type', 'application/json');
  headers.append('X-NW-Message-ID', message);

  const token = sessionStorage.getItem('access_token');
  console.log('Token ', token);
  //const token = config.token;
  headers.append('Authorization', 'Bearer ' + token);
  headers.set('client_id', callupKey);

  const response = await fetch(callupAPI, {
    method: 'POST',
    headers: headers,
    body: JSON.stringify(body),
  });

  if (response.ok) {
    try {
      const jsondata = await response.json();
      // console.log("teamService.getManagerTeam completed");
      return jsondata;
    } catch (e) {
      console.error('teamService.getManagerTeam Callup API error', e);
      return null;
    }
  }
  return null;
};

export const getCallupShortName = async searchText => {
  const message = getRandomString(25);
  const environ = getEnvironment();
  let callupAPI = config.callupAPI;
  let callupKey = config.callupKey;
  if (environ !== 'DEFAULT') {
    // if (isMobile) {
    //   callupAPI = config.Mobile_test_callupAPI;
    // } else {
    //   console.log('jobService.getState environ', environ);
    callupAPI = config.test_callupAPI;
    callupKey = config.test_callupKey;
    // }
  }

  const body = {
    shortName: encodeURIComponent(searchText),
  };

  let headers = new Headers();
  headers.append('Content-Type', 'application/json');
  headers.append('X-NW-Message-ID', message);

  const token = sessionStorage.getItem('access_token');
  //const token = config.token;
  headers.append('Authorization', 'Bearer ' + token);
  headers.set('client_id', callupKey);

  const response = await fetch(callupAPI, {
    method: 'POST',
    headers: headers,
    body: JSON.stringify(body),
  });

  if (response.ok) {
    try {
      const jsondata = await response.json();
      return jsondata;
    } catch (e) {
      console.log('jobService.getCallupShortName Callup API error', e);
      return null;
    }
  }
  return null;
};
