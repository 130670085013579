import React, { Component } from 'react';
// import { getAnnouncements } from "../services/fakeAnnouncementService";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import config from '../config.json';
import { getAnnouncements } from '../services/announcementService';

class Announcements extends Component {
  state = {
    data: [],
    current: 0,
    announcement: null,
    intervalId: 0,
    showFull: false,
    error: false,
    errorMessage: '',
  };

  async componentDidMount() {
    const jsondata = await getAnnouncements();
    if (jsondata) {
      //jsondata object exists
      const messages = await jsondata.messages;
      const data = await jsondata.data;

      if (messages) {
        //jsondata object contains a messages object
        let errorMessage = '';
        if (messages.length > 0) {
          //the messages array is not empty
          for (let i = 0; i < messages.length; i++) {
            const currentMessage = messages[i];
            const displayMessage =
              'There was an error while retrieving announcements.  (' +
              currentMessage.userMessage +
              ' ' +
              currentMessage.messageId +
              ')';
            toast.error(displayMessage);
            errorMessage += displayMessage + '<hr/>';
          }
          this.setState({ error: true, errorMessage });
        } else {
          //the messages array is empty
          this.setState({ error: false });
        }
      }
      if (data) {
        //jsondata object contains a data object
        const announcement = data[0];
        this.setState({ data, announcement });
        if (data.length > 1) {
          //the data array contains more than one object
          this.setState({ error: false });
          const intervalId = setInterval(this.autoUpdateAnnouncement, config.announcementTime);
          this.setState({ intervalId });
        }
      }
    } else {
      //The jsondataobject does not exist
      this.setState({
        error: true,
        errorMessage: 'Unable to retrieve announcements',
      });
      toast.error('Unable to retrieve announcements');
    }
  }

  autoUpdateAnnouncement = () => {
    this.handleChangeClick(1);
  };

  handleChangeClick = changeValue => {
    const { current, data, intervalId } = this.state;
    clearInterval(intervalId);
    const change = current + changeValue;
    let changeTo = 0;
    if (changeValue < 0) {
      //Navigate to the previous data object
      changeTo = change < 0 ? data.length - 1 : change;
    } else {
      //Navigate to the next data object
      changeTo = change >= data.length ? 0 : change;
    }
    this.changeArticle(changeTo);
  };

  handleDotClick = announceId => {
    // console.log("announcements.handleDotClick announceId", announceId);
    const { data } = this.state;
    for (let i = 0; i < data.length; i++) {
      if (data[i].id === announceId) {
        //This data array object id matches the id of the announcement that was clicked
        const announcement = data[i];
        const current = i;
        const intervalId = setInterval(this.autoUpdateAnnouncement, config.announcementTime);
        this.setState({ announcement, current, intervalId });
        break;
      }
    }
  };

  handleButtonClick = link => {
    const t = window.open(link);
    console.log('window open', t);
  };
  changeArticle = current => {
    const { data } = this.state;
    const announcement = data[current];
    const intervalId = setInterval(this.autoUpdateAnnouncement, config.announcementTime);
    this.setState({ announcement, current, intervalId });
  };

  renderImage() {
    const { announcement, error, errorMessage } = this.state;
    if (announcement && !error) {
      //the announcement object exits and there is no error
      const base64Image = 'data:' + announcement.contentType + ';base64,' + announcement.img;
      return (
        <React.Fragment>
          <div className=" announcementDesktopDisplay">
            <div className="announcementColumn">
              <div className="announcementHeadline">{announcement.headline}</div>
              <div className="announcementSummaryText">{announcement.summaryText}</div>
              <div className="announcementBtnBox">
                {this.renderButton(announcement.button1Link, announcement.button1Text, true)}
                {this.renderButton(announcement.button2Link, announcement.button2Text, false)}
              </div>
            </div>
            <div className="announcementColumn announcementColumnTwo">
              <img
                className="announcementImage"
                src={base64Image}
                alt={
                  announcement.link && announcement.link !== ''
                    ? announcement.link
                    : announcement.title
                }
              />
            </div>
          </div>
          <div className="announcement announcementMobileDisplay">
            <div>
              <img
                className="announcementImage"
                src={base64Image}
                alt={
                  announcement.link && announcement.link !== ''
                    ? announcement.link
                    : announcement.title
                }
              />
            </div>
            <div>
              <div className="announcementHeadline">{announcement.headline}</div>
              <div className="announcementSummaryText">{announcement.summaryText}</div>
              <div className="announcementBtnBox">
                {this.renderButton(announcement.button1Link, announcement.button1Text, true)}
                {this.renderButton(announcement.button2Link, announcement.button2Text, false)}
              </div>
            </div>
          </div>
        </React.Fragment>
      );
    } else {
      //The announcement object does not exist or there is an error
      if (!error) {
        //There is not an error
        return <div className="progress-spinner"></div>;
      } else {
        //there is an error
        return (
          <div
            className="announcements-error"
            dangerouslySetInnerHTML={{ __html: errorMessage }}
          ></div>
        );
      }
    }
  }

  renderButton = (link, text, primary) => {
    // console.log("announcements.renderbutton", link, text, primary);
    if (text && text !== '') {
      //The text object exists and is not empty
      let gotoUrl = link;
      if (!link.startsWith('http://') && !link.startsWith('https://')) {
        //the link does not start with http or https
        console.error('announcements.renderButton link is missing protocol');
        gotoUrl = 'http://' + link;
      }
      return (
        <button
          className={
            primary
              ? 'btn btn-primary announcementButton'
              : 'btn btn-outline-primary btn-light announcementButtonSecondary'
          }
          onClick={() => window.open(gotoUrl)}
        >
          {text}
        </button>
      );
    } else {
      //the text object does not exist or is empty
      return <div></div>;
    }
  };

  render() {
    const { data, announcement } = this.state;
    const multipleAnnouncements = data.length > 1;
    if (data && data.length > 0) {
      //the data object exists and has at least 1 item
      return (
        <div className="announcements">
          <div className="container">
            <div className="row">
              <div className="announcementContainer col">
                {multipleAnnouncements ? (
                  <button
                    id="btn_previousannouncement"
                    className="announcementBtn fl btn btn-link"
                    aria-label="Previous Article"
                    tabIndex="0"
                    onClick={() => this.handleChangeClick(-1)}
                  >
                    <img src="/announce-chevron-left.png" alt=""></img>
                  </button>
                ) : (
                  <div></div>
                )}

                <div className="announcement">{this.renderImage()}</div>
                {multipleAnnouncements ? (
                  <div className="announcementCircleContainer">
                    {data.map(item => (
                      <div
                        key={item.id}
                        onClick={() => this.handleDotClick(item.id)}
                        aria-label={
                          item.id === announcement.id
                            ? 'Active Announcement'
                            : 'Inactive Announcement link'
                        }
                        className={
                          item.id === announcement.id ? 'activeAnnounce' : 'inactiveAnnounce'
                        }
                      ></div>
                    ))}
                  </div>
                ) : (
                  <div></div>
                )}

                {multipleAnnouncements ? (
                  <button
                    id="btn_nextsannouncement"
                    className="announcementBtn fr btn btn-link"
                    aria-label="Next Article"
                    tabIndex="0"
                    onClick={() => this.handleChangeClick(1)}
                  >
                    <img src="/announce-chevron-right.png" alt=""></img>
                  </button>
                ) : (
                  <div></div>
                )}
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      //the data object does not exist or is empty
      return <div></div>;
    }
  }
}

export default Announcements;
