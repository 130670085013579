import React, { Component } from "react";
import { search } from "../../services/announcementService";
import dateFormat from "dateformat";
import DatePicker from "react-datepicker";
import Pagination from "../../common/pagination";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-datepicker/dist/react-datepicker.css";

const pageSize = 10;
class AnnouncementList extends Component {
  state = {
    data: [],
    searchTitle: "",
    searchResults: [],
    currentPage: 1,
    sortBy: { field: "startOnDate", direction: "a" },
    filter: {
      filterId: "",
      filterUpdatedOnDate: "",
      filterUpdatedAfterDate: "",
      filterUpdatedBeforeDate: "",

      filterStartOnDate: "",
      filterStartAfterDate: "",
      filterStartBeforeDate: "",

      filterEndOnDate: "",
      filterEndAfterDate: "",
      filterEndBeforeDate: "",

      filterApproved: "",
      filterActive: "",
      filterTitle: ""
    },
    showDateFilterEntry: false,
    updatedDateText: "",
    showStartDateFilterEntry: false,
    startDateText: "",
    showEndDateFilterEntry: false,
    endDateText: "",
    searching: false,
    totalCount: 0,
    error: false,
    errorMessage: ""
  };

  async componentDidMount() {
    this.searchAnnouncements(
      0,
      "",
      "",
      "",
      "",
      "",
      "",
      {
        field: "id",
        direction: "d"
      },
      1
    );
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.forceAnnounceSearchReload !==
        this.props.forceAnnounceSearchReload &&
      this.props.forceAnnounceSearchReload === "Y"
    ) {
      this.handleSearch();
    }
  }

  sortByParam = sortBy => {
    const result = sortBy.direction === "d" ? "-" + sortBy.field : sortBy.field;
    return result;
  };

  changeSort = sortField => {
    const { sortBy, filter } = this.state;
    let dir = sortBy.direction;
    if (sortBy.field === sortField) {
      dir = dir === "d" ? "a" : "d";
    } else {
      dir = "a";
    }
    const newSortBy = { field: sortField, direction: dir };
    this.setState({ sortBy: newSortBy, currentPage: 1 });

    this.searchAnnouncements(
      filter.filterId,
      filter.filterStartAfterDate,
      filter.filterStartBeforeDate,
      filter.filterStartOnDate,
      filter.filterEndAfterDate,
      filter.filterEndBeforeDate,
      filter.filterEndOnDate,
      filter.filterUpdatedAfterDate,
      filter.filterUpdatedBeforeDate,
      filter.filterUpdatedOnDate,
      filter.filterApproved,
      filter.filterActive,
      filter.filterTitle,
      newSortBy,
      1
    );
  };

  renderSortIcon = fieldName => {
    const { sortBy } = this.state;
    if (fieldName !== sortBy.field) return null;
    if (sortBy.direction === "a") return <i className="fa fa-sort-asc"></i>;
    return <i className="fa fa-sort-desc"></i>;
  };

  handleSearch = async () => {
    const { sortBy, filter } = this.state;
    this.setState({ currentPage: 1 });
    this.searchAnnouncements(
      filter.filterId,
      filter.filterStartAfterDate,
      filter.filterStartBeforeDate,
      filter.filterStartOnDate,
      filter.filterEndAfterDate,
      filter.filterEndBeforeDate,
      filter.filterEndOnDate,
      filter.filterUpdatedAfterDate,
      filter.filterUpdatedBeforeDate,
      filter.filterUpdatedOnDate,
      filter.filterApproved,
      filter.filterActive,
      filter.filterTitle,
      sortBy,
      1
    );
    this.props.showAnnounceList(0, "N");
  };

  handlePageChange = page => {
    const { filter, sortBy } = this.state;
    this.setState({ currentPage: page });
    this.searchAnnouncements(
      filter.filterId,
      filter.filterStartAfterDate,
      filter.filterStartBeforeDate,
      filter.filterStartOnDate,
      filter.filterEndAfterDate,
      filter.filterEndBeforeDate,
      filter.filterEndOnDate,
      filter.filterUpdatedAfterDate,
      filter.filterUpdatedBeforeDate,
      filter.filterUpdatedOnDate,
      filter.filterApproved,
      filter.filterActive,
      filter.filterTitle,
      sortBy,
      page
    );
  };

  searchAnnouncements = async (
    filterId,
    filterStartAfterDate,
    filterStartBeforeDate,
    filterStartOnDate,
    filterEndAfterDate,
    filterEndBeforeDate,
    filterEndOnDate,
    filterUpdatedAfterDate,
    filterUpdatedBeforeDate,
    filterUpdatedOnDate,
    filterApproved,
    filterActive,
    filterTitle,
    sortBy,
    pageNumber
  ) => {
    // const { isAdmin } = this.props;
    const { displayAnnounceList } = this.props;
    if (displayAnnounceList === "none") {
      return false;
    }
    this.setState({ searching: true });

    // if (isAdmin) {
    let params = new Map();
    const paramStartAfterDate =
      filterStartAfterDate === ""
        ? filterStartAfterDate
        : filterStartAfterDate.toLocaleDateString();
    const paramStartBeforeDate =
      filterStartBeforeDate === ""
        ? filterStartBeforeDate
        : filterStartBeforeDate.toLocaleDateString();
    const paramStartOnDate =
      filterStartOnDate === ""
        ? filterStartOnDate
        : filterStartOnDate.toLocaleDateString();
    const paramEndAfterDate =
      filterEndAfterDate === ""
        ? filterEndAfterDate
        : filterEndAfterDate.toLocaleDateString();
    const paramEndBeforeDate =
      filterEndBeforeDate === ""
        ? filterEndBeforeDate
        : filterEndBeforeDate.toLocaleDateString();
    const paramEndOnDate =
      filterEndOnDate === ""
        ? filterEndOnDate
        : filterEndOnDate.toLocaleDateString();
    const paramAfterDate =
      filterUpdatedAfterDate === ""
        ? filterUpdatedAfterDate
        : filterUpdatedAfterDate.toLocaleDateString();
    const paramBeforeDate =
      filterUpdatedBeforeDate === ""
        ? filterUpdatedBeforeDate
        : filterUpdatedBeforeDate.toLocaleDateString();
    const paramOnDate =
      filterUpdatedOnDate === ""
        ? filterUpdatedOnDate
        : filterUpdatedOnDate.toLocaleDateString();

    params.set("filterId", filterId);
    params.set("filterStartAfterDate", paramStartAfterDate);
    params.set("filterStartBeforeDate", paramStartBeforeDate);
    params.set("filterStartOnDate", paramStartOnDate);
    params.set("filterEndAfterDate", paramEndAfterDate);
    params.set("filterEndBeforeDate", paramEndBeforeDate);
    params.set("filterEndOnDate", paramEndOnDate);
    params.set("filterUpdatedAfterDate", paramAfterDate);
    params.set("filterUpdatedBeforeDate", paramBeforeDate);
    params.set("filterUpdatedOnDate", paramOnDate);
    params.set("filterApproved", filterApproved);
    params.set("filterActive", filterActive);
    params.set("filterTitle", filterTitle);
    params.set("sort", this.sortByParam(sortBy));
    params.set("limit", pageSize);
    const page = pageNumber < 1 ? 0 : pageNumber - 1;
    params.set("offset", page);
    const result = await search(params);

    const data = result.data;
    const messages = result.messages;
    let error = false;
    let errorMessage = "";
    if (messages && messages.length > 0) {
      for (let i = 0; i < messages.length; i++) {
        const currentMessage = messages[i];
        const displayMessage =
          "There was an error while retrieving announcements.  (" +
          currentMessage.userMessage +
          " " +
          currentMessage.messageId +
          ")";
        toast.error(displayMessage);
        errorMessage += displayMessage + "<hr/>";
      }
      error = true;
      this.setState({
        searching: false,
        error,
        errorMessage
      });
    } else {
      const totalCount = parseInt(result.totalCount);
      this.setState({
        data,
        isAdmin: true,
        searching: false,
        totalCount,
        error,
        errorMessage
      });
    }
  };

  handleSubmit = e => {
    e.preventDefault();
  };

  onIdSelect = id => {
    this.props.onAnnounceIdUpdate(id);
    this.props.showAnnounceEdit();
  };

  handleUpdateDateFilterChange = date => {
    let { filter } = this.state;
    filter.filterUpdatedOnDate = date;
    this.setState({
      filter
    });
  };
  handleUpdateAfterDateFilterChange = date => {
    let { filter } = this.state;
    filter.filterUpdatedAfterDate = date;
    this.setState({
      filter
    });
  };
  handleUpdateBeforeDateFilterChange = date => {
    let { filter } = this.state;
    filter.filterUpdatedBeforeDate = date;
    this.setState({
      filter
    });
  };
  handleStartDateFilterChange = date => {
    // console.log("AnnouncementList.handleStartDateFilterChange", date);
    let { filter } = this.state;
    filter.filterStartOnDate = date;
    this.setState({
      filter
    });
  };
  handleStartAfterDateFilterChange = date => {
    // console.log("AnnouncementList.handleStartAfterDateFilterChange", date);
    let { filter } = this.state;
    filter.filterStartAfterDate = date;
    this.setState({
      filter
    });
  };
  handleStartBeforeDateFilterChange = date => {
    // console.log("AnnouncementList.handleStartBeforeDateFilterChange", date);
    let { filter } = this.state;
    filter.filterStartBeforeDate = date;
    this.setState({
      filter
    });
  };
  handleEndDateFilterChange = date => {
    let { filter } = this.state;
    filter.filterEndOnDate = date;
    this.setState({
      filter
    });
  };
  handleEndAfterDateFilterChange = date => {
    let { filter } = this.state;
    filter.filterEndAfterDate = date;
    this.setState({
      filter
    });
  };
  handleEndBeforeDateFilterChange = date => {
    let { filter } = this.state;
    filter.filterEndBeforeDate = date;
    this.setState({
      filter
    });
  };

  resetDateEntry() {
    let { filter } = this.state;
    filter.filterUpdatedOnDate = "";
    filter.filterUpdatedAfterDate = "";
    filter.filterUpdatedBeforeDate = "";
    this.setState({
      filter
    });
  }

  resetStartDateEntry() {
    let { filter } = this.state;
    filter.filterStartOnDate = "";
    filter.filterStartAfterDate = "";
    filter.filterStartBeforeDate = "";
    this.setState({
      filter
    });
  }

  resetEndDateEntry() {
    let { filter } = this.state;
    filter.filterEndOnDate = "";
    filter.filterEndAfterDate = "";
    filter.filterEndBeforeDate = "";
    this.setState({
      filter
    });
  }

  handleFilterChange = ({ currentTarget: input }) => {
    const { filter } = this.state;
    filter[input.name] = input.value;
    this.setState({ filter });
  };

  handleLastUpdateClick = e => {
    this.handleShow(true);
  };

  handleShow(display) {
    const showDateFilterEntry = display;
    this.setState({ showDateFilterEntry });
    if (!display) {
      this.getUpdatedDateText();
    }
  }

  getUpdatedDateText() {
    const { filter } = this.state;
    let updatedDateText = "";

    if (
      filter.filterUpdatedAfterDate !== "" ||
      filter.filterUpdatedBeforeDate !== ""
    ) {
      if (filter.filterUpdatedAfterDate !== "") {
        if (filter.filterUpdatedBeforeDate !== "") {
          if (filter.filterUpdatedAfterDate > filter.filterUpdatedBeforeDate) {
            const temp = filter.filterUpdatedAfterDate;
            filter.filterUpdatedAfterDate = filter.filterUpdatedBeforeDate;
            filter.filterUpdatedBeforeDate = temp;
            this.setState({ filter });
          }
          //filter will be between
          updatedDateText =
            "between " +
            filter.filterUpdatedAfterDate.toLocaleDateString() +
            " and " +
            filter.filterUpdatedBeforeDate.toLocaleDateString();
        } else {
          //filter is only after
          updatedDateText =
            "after " + filter.filterUpdatedAfterDate.toLocaleDateString();
        }
      } else {
        //only has updated before filter
        updatedDateText =
          "before " + filter.filterUpdatedBeforeDate.toLocaleDateString();
      }
    } else {
      //Both Filter After and Filter Before are emtpy, so check Filter ON date
      if (filter.filterUpdatedOnDate !== "") {
        updatedDateText =
          "on " + filter.filterUpdatedOnDate.toLocaleDateString();
      }
    }
    this.setState({ updatedDateText });
  }

  handleStartDateClick = e => {
    this.handleShowStartDate(true);
  };

  handleShowStartDate(display) {
    const showStartDateFilterEntry = display;
    this.setState({ showStartDateFilterEntry });
    if (!display) {
      this.getStartDateText();
    }
  }

  getStartDateText() {
    const { filter } = this.state;
    let startDateText = "";

    if (
      filter.filterStartAfterDate !== "" ||
      filter.filterStartBeforeDate !== ""
    ) {
      if (filter.filterStartAfterDate !== "") {
        if (filter.filterStartBeforeDate !== "") {
          if (filter.filterStartAfterDate > filter.filterStartBeforeDate) {
            const temp = filter.filterStartAfterDate;
            filter.filterStartAfterDate = filter.filterStartBeforeDate;
            filter.filterStartBeforeDate = temp;
            this.setState({ filter });
          }
          //filter will be between
          startDateText =
            "between " +
            filter.filterStartAfterDate.toLocaleDateString() +
            " and " +
            filter.filterStartBeforeDate.toLocaleDateString();
        } else {
          //filter is only after
          startDateText =
            "after " + filter.filterStartAfterDate.toLocaleDateString();
        }
      } else {
        //only has updated before filter
        startDateText =
          "before " + filter.filterStartBeforeDate.toLocaleDateString();
      }
    } else {
      //Both Filter After and Filter Before are emtpy, so check Filter ON date
      if (filter.filterStartOnDate !== "") {
        startDateText = "on " + filter.filterStartOnDate.toLocaleDateString();
      }
    }
    this.setState({ startDateText });
  }

  handleEndDateClick = e => {
    this.handleShowEndDate(true);
  };

  handleShowEndDate(display) {
    const showEndDateFilterEntry = display;
    this.setState({ showEndDateFilterEntry });
    if (!display) {
      this.getEndDateText();
    }
  }

  getEndDateText() {
    const { filter } = this.state;
    let endDateText = "";

    if (filter.filterEndAfterDate !== "" || filter.filterEndBeforeDate !== "") {
      if (filter.filterEndAfterDate !== "") {
        if (filter.filterEndBeforeDate !== "") {
          if (filter.filterEndAfterDate > filter.filterEndBeforeDate) {
            const temp = filter.filterEndAfterDate;
            filter.filterEndAfterDate = filter.filterEndBeforeDate;
            filter.filterEndBeforeDate = temp;
            this.setState({ filter });
          }
          //filter will be between
          endDateText =
            "between " +
            filter.filterEndAfterDate.toLocaleDateString() +
            " and " +
            filter.filterEndBeforeDate.toLocaleDateString();
        } else {
          //filter is only after
          endDateText =
            "after " + filter.filterEndAfterDate.toLocaleDateString();
        }
      } else {
        //only has updated before filter
        endDateText =
          "before " + filter.filterEndBeforeDate.toLocaleDateString();
      }
    } else {
      //Both Filter After and Filter Before are emtpy, so check Filter ON date
      if (filter.filterEndOnDate !== "") {
        endDateText = "on " + filter.filterEndOnDate.toLocaleDateString();
      }
    }
    this.setState({ endDateText });
  }

  renderSearchFields() {
    const { isAdmin } = this.props;
    const { filter, updatedDateText, startDateText, endDateText } = this.state;
    if (isAdmin) {
      return (
        <React.Fragment>
          <thead>
            <tr>
              <th
                style={{
                  maxWidth: "100px",
                  minWidth: "93px",
                  borderBottomColor: "#666",
                  borderBottomWidth: "0px"
                }}
                className="clickable "
                scope="col"
                onClick={() => this.changeSort("id")}
              >
                ID{this.renderSortIcon("id")}
              </th>
              <th
                style={{ borderBottomColor: "#666", borderBottomWidth: "0px" }}
                className="clickable"
                scope="col"
                colSpan="2"
                onClick={() => this.changeSort("title")}
              >
                Title{this.renderSortIcon("title")}
              </th>
              <th
                style={{ borderBottomColor: "#666", borderBottomWidth: "0px" }}
                className="clickable"
                scope="col"
                onClick={() => this.changeSort("startOnDate")}
              >
                Start Date{this.renderSortIcon("startOnDate")}
              </th>
              <th
                style={{ borderBottomColor: "#666", borderBottomWidth: "0px" }}
                className="clickable"
                scope="col"
                onClick={() => this.changeSort("endOnDate")}
              >
                End Date{this.renderSortIcon("endOnDate")}
              </th>
              <th
                style={{ borderBottomColor: "#666", borderBottomWidth: "0px" }}
                className="clickable"
                scope="col"
                onClick={() => this.changeSort("updatedDate")}
              >
                Last Updated{this.renderSortIcon("updatedDate")}
              </th>
              <th
                className="clickable"
                scope="col"
                onClick={() => this.changeSort("announcementType")}
                style={{
                  borderRightColor: "#0f0f0f",
                  borderBottomColor: "#666",
                  borderBottomWidth: "0px"
                }}
              >
                Status{this.renderSortIcon("announcementType")}
              </th>
              <th
                rowSpan="2"
                style={{
                  maxWidth: "36px",
                  backgroundColor: "white",
                  borderColor: "white",
                  padding: "0px",
                  borderTopWidth: "0px"
                }}
              >
                <button
                  onClick={this.handleSearch}
                  className="snComponentButton bolt-color-brand-muted-blue-accent"
                  aria-label="Click to search Announcements"
                  title="Click to search Announcements"
                  style={{ height: "97px" }}
                >
                  <img src="/search.png" alt="search"></img>
                </button>
              </th>
            </tr>
            <tr>
              <th style={{ maxWidth: "70px", borderTopWidth: "0px" }}>
                <input
                  className="form-control"
                  id="filterId"
                  name="filterId"
                  value={filter.filterId}
                  type="number"
                  style={{ maxWidth: "100px", minWidth: "80px" }}
                  onChange={this.handleFilterChange}
                />
              </th>
              <th colSpan="2" style={{ borderTopWidth: "0px" }}>
                <input
                  className="form-control"
                  id="filterTitle"
                  name="filterTitle"
                  value={filter.filterTitle}
                  onChange={this.handleFilterChange}
                />
              </th>
              <th style={{ borderTopWidth: "0px" }}>
                <input
                  className="form-control"
                  id="filterStartDate"
                  name="filterStartDate"
                  value={startDateText}
                  readOnly={true}
                  onClick={this.handleStartDateClick}
                />
              </th>
              <th style={{ borderTopWidth: "0px" }}>
                <input
                  className="form-control"
                  id="filterEndDate"
                  name="filterEndDate"
                  value={endDateText}
                  readOnly={true}
                  onClick={this.handleEndDateClick}
                />
              </th>
              <th style={{ borderTopWidth: "0px" }}>
                <input
                  className="form-control"
                  id="filterUpdateDate"
                  name="filterUpdateDate"
                  value={updatedDateText}
                  readOnly={true}
                  onClick={this.handleLastUpdateClick}
                />
              </th>
              <th
                style={{
                  borderRightColor: "#0f0f0f",
                  borderTopWidth: "0px"
                }}
              >
                <select
                  className="form-control"
                  id="filterActive"
                  value={filter.filterActive}
                  name="filterActive"
                  onChange={this.handleFilterChange}
                >
                  <option value="">All</option>
                  <option value="Current">Current</option>
                  <option value="Scheduled">Scheduled</option>
                  <option value="Expired">Expired</option>
                </select>
              </th>
            </tr>
          </thead>
        </React.Fragment>
      );
    }
  }

  renderData() {
    const { isAdmin } = this.props;
    const { data, error, errorMessage } = this.state;
    if (!error && isAdmin && data && data.length > 0) {
      return (
        <React.Fragment>
          <tbody>
            {data.map(item => (
              <tr key={item.id}>
                <td>
                  <div className="">
                    <button
                      className="link"
                      onClick={() => this.onIdSelect(item.id)}
                    >
                      {item.id}
                    </button>
                  </div>
                </td>
                <td style={{ borderRightColor: "transparent" }}>
                  {item.title}
                </td>
                <td style={{ width: "40px" }}>
                  <span className="tooltip">
                    <svg
                      id="icon-info"
                      width="32"
                      height="32"
                      viewBox="0 0 32 32"
                    >
                      <path
                        fill="#6d6e71"
                        d="M18.756 22.951v-10.667h-8.284v2.693h2.88v7.973h-2.88v2.871h10.996v-2.871z"
                      ></path>
                      <path
                        fill="#6d6e71"
                        d="M18.844 8.302c0 1.64-1.329 2.969-2.969 2.969s-2.969-1.329-2.969-2.969c0-1.64 1.329-2.969 2.969-2.969s2.969 1.329 2.969 2.969z"
                      ></path>
                      <path
                        fill="#6d6e71"
                        d="M15.973 1.529c3.822 0 7.413 1.484 10.116 4.196 2.702 2.702 4.196 6.293 4.196 10.116s-1.484 7.413-4.196 10.116c-2.702 2.702-6.293 4.196-10.116 4.196s-7.413-1.484-10.116-4.196c-2.702-2.702-4.196-6.293-4.196-10.116s1.484-7.413 4.187-10.116 6.293-4.196 10.124-4.196zM15.973 0.729c-8.347 0-15.111 6.764-15.111 15.111s6.764 15.111 15.111 15.111 15.111-6.764 15.111-15.111-6.773-15.111-15.111-15.111v0z"
                      ></path>
                    </svg>
                    <span className="tooltiptext">
                      <img
                        style={{ height: "125px" }}
                        src={"data:" + item.contentType + ";base64," + item.img}
                        alt={item.title}
                      />
                      <br />
                      <span style={{ whiteSpace: "nowrap" }}>
                        height: {item.height}px | width: {item.width}px | type:{" "}
                        {item.contentType}
                      </span>
                    </span>
                  </span>
                </td>
                <td>{dateFormat(item.startOnDate, "mm/dd/yyyy")}</td>
                <td>{dateFormat(item.endOnDate, "mm/dd/yyyy")}</td>
                <td>{dateFormat(item.updatedDate, "mm/dd/yyyy h:MM TT")}</td>

                <td>{item.announcementType}</td>
                <td
                  style={{
                    backgroundColor: "white",
                    borderRightColor: "white",
                    borderBottomColor: "white",
                    borderCollapse: "collapse"
                  }}
                ></td>
              </tr>
            ))}
          </tbody>
        </React.Fragment>
      );
    } else {
      if (error) {
        return (
          <tbody>
            <tr>
              <td colSpan="5">
                <div dangerouslySetInnerHTML={{ __html: errorMessage }}></div>
              </td>
              <td
                style={{
                  backgroundColor: "white",
                  borderRightColor: "white",
                  borderBottomColor: "white",
                  borderCollapse: "collapse"
                }}
              ></td>
            </tr>
          </tbody>
        );
      }
    }
  }

  renderDateFilterEdit() {
    const { showDateFilterEntry, filter } = this.state;
    if (showDateFilterEntry) {
      return (
        <React.Fragment>
          <div id="pageCover" className="pageCover">
            <div
              id="dateEntryContainer"
              className="dateSelectContainer overflow-hidden"
            >
              <div className="modalTitleBar" id="editHeaderDateEntryContainer">
                Last Updated Date Filter
                <button
                  className="close"
                  aria-label="Close"
                  id="closeDateEntryContainer"
                  onClick={() => this.handleShow(false)}
                  type="button"
                >
                  x
                </button>
              </div>
              <div id="editPane" className="modalEditPane">
                <div className="col-xs-12 form-group row">
                  <div className="col-sm-3">
                    <label
                      htmlFor="filterUpdatedOnDate"
                      title="If entered, then only Announcements Updated on this date will be included in the search results.  Entering an After or a Before date will cause this entry to be ignored."
                    >
                      On
                    </label>
                  </div>
                  <div className="col-sm-9">
                    <DatePicker
                      id="filterUpdatedOnDate"
                      name="filterUpdatedOnDate"
                      fixedHeight
                      withPortal
                      title="If entered, then only Announcements Updated on this date will be included in the search results.  Entering an After or a Before date will cause this entry to be ignored."
                      selected={filter.filterUpdatedOnDate}
                      onChange={this.handleUpdateDateFilterChange}
                    />
                  </div>
                </div>
                <div
                  className="col-xs-12 form-group row"
                  style={{
                    borderBottomStyle: "dotted",
                    borderBottomColor: "grey",
                    borderBottomWith: "1px"
                  }}
                >
                  <div className="col-sm-12 ml-3 mr-3">
                    <i>
                      Any dates entered in After or Before will over-ride any On
                      date
                    </i>
                  </div>
                </div>
                <div className="col-xs-12 form-group row">
                  <div className="col-sm-3">
                    <label
                      htmlFor="startDateAfter"
                      title="If entered, then only Audits Closed on or after this date will be included in the search results.  Entering an After date will cause any 'On' date entry to be ignored."
                    >
                      After
                    </label>
                  </div>
                  <div className="col-sm-9">
                    <DatePicker
                      id="filterUpdatedAfterDate"
                      name="filterUpdatedAfterDate"
                      fixedHeight
                      withPortal
                      title="If entered, then only Announcements Updated on or after this date will be included in the search results.  Entering an After date will cause any 'On' date entry to be ignored."
                      selected={filter.filterUpdatedAfterDate}
                      onChange={this.handleUpdateAfterDateFilterChange}
                    />
                  </div>
                </div>
                <div className="col-xs-12 form-group row">
                  <div className="col-sm-3">
                    <label
                      htmlFor="startDateBefore"
                      title="If entered, then only Announcements Updated on or before this date will be included in the search results.  Entering a Before date will cause any 'On' date entry to be ignored."
                    >
                      Before
                    </label>
                  </div>
                  <div className="col-sm-9">
                    <DatePicker
                      id="filterUpdatedBeforeDate"
                      name="filterUpdatedBeforeDate"
                      fixedHeight
                      withPortal
                      title="If entered, then only Announcements Updated on or before this date will be included in the search results.  Entering a Before date will cause any 'On' date entry to be ignored."
                      selected={filter.filterUpdatedBeforeDate}
                      onChange={this.handleUpdateBeforeDateFilterChange}
                    />
                  </div>
                </div>
                <div
                  className="col-xs-12 form-group row"
                  style={{ position: "relative", bottom: "0px" }}
                >
                  <button
                    className="btn btn-primary demoLink float-right ml-5"
                    id="resetDateButton"
                    type="button"
                    title="Click to clear the Date Filter values"
                    onClick={() => this.resetDateEntry()}
                  >
                    Reset
                  </button>
                  <button
                    className="btn btn-primary demoLink float-right ml-5"
                    id="closeDateFilterButton"
                    title="Click when you are done changing the date filters"
                    type="button"
                    onClick={() => this.handleShow(false)}
                  >
                    OK
                  </button>
                </div>
              </div>
            </div>
          </div>
        </React.Fragment>
      );
    }
  }

  render() {
    const { totalCount, currentPage } = this.state;
    return (
      <div className="announcements" style={{ height: "auto" }}>
        <div className="container" style={{ overflow: "hidden" }}>
          <div className="row">
            <div
              className="announcementEditContainer col"
              style={{ height: "auto" }}
            >
              <div className="pageHeader">
                Announcements{" "}
                {totalCount
                  ? totalCount + (totalCount !== 1 ? " results" : " result")
                  : ""}
              </div>

              {/* <h3 style={{ width: "100%", textAlign: "center" }}>
                Announcements{" "}
                {totalCount
                  ? totalCount + (totalCount !== 1 ? " results" : " result")
                  : ""}
              </h3> */}
              {this.renderDateFilterEdit()}
              {this.renderStartDateFilterEdit()}
              {this.renderEndDateFilterEdit()}
              {this.renderSpinner()}
            </div>
          </div>

          <div className="row ">
            <div className="col-md-12">
              <div
                className="table-responsive"
                style={{ overflowX: "hidden", borderRadius: "10px" }}
              >
                <table className="table table-bordered table-striped">
                  {this.renderSearchFields()}
                  {this.renderData()}
                </table>
              </div>
              <button className="link" onClick={() => this.onIdSelect(0)}>
                Add New
              </button>
              <Pagination
                itemsCount={totalCount}
                pageSize={pageSize}
                currentPage={currentPage}
                onPageChange={this.handlePageChange}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderStartDateFilterEdit() {
    const { showStartDateFilterEntry, filter } = this.state;
    if (showStartDateFilterEntry) {
      return (
        <React.Fragment>
          <div id="pageCover" className="pageCover">
            <div
              id="startDateEntryContainer"
              className="dateSelectContainer overflow-hidden"
            >
              <div
                className="modalTitleBar"
                id="editHeaderStartDateEntryContainer"
              >
                Start Date Filter
                <button
                  className="close"
                  aria-label="Close"
                  id="closeStartDateEntryContainer"
                  onClick={() => this.handleShowStartDate(false)}
                  type="button"
                >
                  x
                </button>
              </div>
              <div id="editStartDatePane" className="modalEditPane">
                <div className="col-xs-12 form-group row">
                  <div className="col-sm-3">
                    <label
                      htmlFor="filterStartOnDate"
                      title="If entered, then only Announcements starting on this date will be included in the search results.  Entering an After or a Before date will cause this entry to be ignored."
                    >
                      On
                    </label>
                  </div>
                  <div className="col-sm-9">
                    <DatePicker
                      id="filterStartOnDate"
                      name="filterStartOnDate"
                      fixedHeight
                      withPortal
                      title="If entered, then only Announcements starting on this date will be included in the search results.  Entering an After or a Before date will cause this entry to be ignored."
                      selected={filter.filterStartOnDate}
                      onChange={this.handleStartDateFilterChange}
                    />
                  </div>
                </div>
                <div
                  className="col-xs-12 form-group row"
                  style={{
                    borderBottomStyle: "dotted",
                    borderBottomColor: "grey",
                    borderBottomWith: "1px"
                  }}
                >
                  <div className="col-sm-12 ml-3 mr-3">
                    <i>
                      Any dates entered in After or Before will over-ride any On
                      date
                    </i>
                  </div>
                </div>
                <div className="col-xs-12 form-group row">
                  <div className="col-sm-3">
                    <label
                      htmlFor="filterStartAfterDate"
                      title="If entered, then only Announcements starting on or after this date will be included in the search results.  Entering an After date will cause any 'On' date entry to be ignored."
                    >
                      After
                    </label>
                  </div>
                  <div className="col-sm-9">
                    <DatePicker
                      id="filterStartAfterDate"
                      name="filterStartAfterDate"
                      fixedHeight
                      withPortal
                      title="If entered, then only Announcements starting on or after this date will be included in the search results.  Entering an After date will cause any 'On' date entry to be ignored."
                      selected={filter.filterStartAfterDate}
                      onChange={this.handleStartAfterDateFilterChange}
                    />
                  </div>
                </div>
                <div className="col-xs-12 form-group row">
                  <div className="col-sm-3">
                    <label
                      htmlFor="filterStartBeforeDate"
                      title="If entered, then only Announcements starting on or before this date will be included in the search results.  Entering a Before date will cause any 'On' date entry to be ignored."
                    >
                      Before
                    </label>
                  </div>
                  <div className="col-sm-9">
                    <DatePicker
                      id="filterStartBeforeDate"
                      name="filterStartBeforeDate"
                      fixedHeight
                      withPortal
                      title="If entered, then only Announcements starting on or before this date will be included in the search results.  Entering a Before date will cause any 'On' date entry to be ignored."
                      selected={filter.filterStartBeforeDate}
                      onChange={this.handleStartBeforeDateFilterChange}
                    />
                  </div>
                </div>
                <div
                  className="col-xs-12 form-group row"
                  style={{ position: "relative", bottom: "0px" }}
                >
                  <button
                    className="btn btn-primary demoLink float-right ml-5"
                    id="resetDateButton"
                    type="button"
                    title="Click to clear the Date Filter values"
                    onClick={() => this.resetStartDateEntry()}
                  >
                    Reset
                  </button>
                  <button
                    className="btn btn-primary demoLink float-right ml-5"
                    id="closeStartDateFilterButton"
                    title="Click when you are done changing the date filters"
                    type="button"
                    onClick={() => this.handleShowStartDate(false)}
                  >
                    OK
                  </button>
                </div>
              </div>
            </div>
          </div>
        </React.Fragment>
      );
    }
  }

  renderEndDateFilterEdit() {
    const { showEndDateFilterEntry, filter } = this.state;
    if (showEndDateFilterEntry) {
      return (
        <React.Fragment>
          <div id="pageCover" className="pageCover">
            <div
              id="EndDateEntryContainer"
              className="dateSelectContainer overflow-hidden"
            >
              <div
                className="modalTitleBar"
                id="editHeaderEndDateEntryContainer"
              >
                End Date Filter
                <button
                  className="close"
                  aria-label="Close"
                  id="closeEndDateEntryContainer"
                  onClick={() => this.handleShowEndDate(false)}
                  type="button"
                >
                  x
                </button>
              </div>
              <div id="editEndDatePane" className="modalEditPane">
                <div className="col-xs-12 form-group row">
                  <div className="col-sm-3">
                    <label
                      htmlFor="filterEndOnDate"
                      title="If entered, then only Announcements Ending on this date will be included in the search results.  Entering an After or a Before date will cause this entry to be ignored."
                    >
                      On
                    </label>
                  </div>
                  <div className="col-sm-9">
                    <DatePicker
                      id="filterEndOnDate"
                      name="filterEndOnDate"
                      fixedHeight
                      withPortal
                      title="If entered, then only Announcements Ending on this date will be included in the search results.  Entering an After or a Before date will cause this entry to be ignored."
                      selected={filter.filterEndOnDate}
                      onChange={this.handleEndDateFilterChange}
                    />
                  </div>
                </div>
                <div
                  className="col-xs-12 form-group row"
                  style={{
                    borderBottomStyle: "dotted",
                    borderBottomColor: "grey",
                    borderBottomWith: "1px"
                  }}
                >
                  <div className="col-sm-12 ml-3 mr-3">
                    <i>
                      Any dates entered in After or Before will over-ride any On
                      date
                    </i>
                  </div>
                </div>
                <div className="col-xs-12 form-group row">
                  <div className="col-sm-3">
                    <label
                      htmlFor="filterEndAfterDate"
                      title="If entered, then only Announcements Ending on or after this date will be included in the search results.  Entering an After date will cause any 'On' date entry to be ignored."
                    >
                      After
                    </label>
                  </div>
                  <div className="col-sm-9">
                    <DatePicker
                      id="filterEndAfterDate"
                      name="filterEndAfterDate"
                      fixedHeight
                      withPortal
                      title="If entered, then only Announcements Ending on or after this date will be included in the search results.  Entering an After date will cause any 'On' date entry to be ignored."
                      selected={filter.filterEndAfterDate}
                      onChange={this.handleEndAfterDateFilterChange}
                    />
                  </div>
                </div>
                <div className="col-xs-12 form-group row">
                  <div className="col-sm-3">
                    <label
                      htmlFor="filterEndBeforeDate"
                      title="If entered, then only Announcements Ending on or before this date will be included in the search results.  Entering a Before date will cause any 'On' date entry to be ignored."
                    >
                      Before
                    </label>
                  </div>
                  <div className="col-sm-9">
                    <DatePicker
                      id="filterEndBeforeDate"
                      name="filterEndBeforeDate"
                      fixedHeight
                      withPortal
                      title="If entered, then only Announcements Ending on or before this date will be included in the search results.  Entering a Before date will cause any 'On' date entry to be ignored."
                      selected={filter.filterEndBeforeDate}
                      onChange={this.handleEndBeforeDateFilterChange}
                    />
                  </div>
                </div>
                <div
                  className="col-xs-12 form-group row"
                  style={{ position: "relative", bottom: "0px" }}
                >
                  <button
                    className="btn btn-primary demoLink float-right ml-5"
                    id="resetDateButton"
                    type="button"
                    title="Click to clear the Date Filter values"
                    onClick={() => this.resetEndDateEntry()}
                  >
                    Reset
                  </button>
                  <button
                    className="btn btn-primary demoLink float-right ml-5"
                    id="closeEndDateFilterButton"
                    title="Click when you are done changing the date filters"
                    type="button"
                    onClick={() => this.handleShowEndDate(false)}
                  >
                    OK
                  </button>
                </div>
              </div>
            </div>
          </div>
        </React.Fragment>
      );
    }
  }

  renderSpinner() {
    const { searching, error } = this.state;
    if (!error && searching) {
      return (
        <div
          id="pageCover"
          style={{
            position: "fixed",
            top: "0%",
            left: "0%",
            height: "100%",
            width: "100%",
            zIndex: "100",
            backgroundColor: "white",
            opacity: "0.5",
            overflow: "hidden"
          }}
        >
          <div
            className="progress-spinner"
            style={{ position: "fixed", left: "50%", top: "50%" }}
          ></div>
        </div>
      );
    }
  }
}

export default AnnouncementList;
