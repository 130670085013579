import React, { Component } from 'react';
import { isMobile } from 'react-device-detect';
import config from '../config.json';

class EffectiveLeader extends Component {
  state = {
    StrongerOn: false,
    RetentionOn: false,
    oneOn: false,
    OnboardOn: false,
    otherLdOn: false,
    changeOn: false,
  };

  getLinks = async () => {
    const { retrieveData } = this.props;
    retrieveData();
  };

  handleStrongerClick = () => {
    // console.log("calendar.handleTimeOffClick");
    let StrongerOn = !this.state.StrongerOn;
    this.setState({ StrongerOn });
  };

  handleRetentionClick = () => {
    // console.log("calendar.handleTimeOffClick");
    let RetentionOn = !this.state.RetentionOn;
    this.setState({ RetentionOn });
  };

  handleOneClick = () => {
    // console.log("calendar.handleTimeOffClick");
    let oneOn = !this.state.oneOn;
    this.setState({ oneOn });
  };

  handleOnboardClick = () => {
    // console.log("calendar.handleTimeOffClick");
    let OnboardOn = !this.state.OnboardOn;
    this.setState({ OnboardOn });
  };

  handleOtherLdClick = () => {
    // console.log("calendar.handleTimeOffClick");
    let otherLdOn = !this.state.otherLdOn;
    this.setState({ otherLdOn });
  };

  handleChangeClick = () => {
    // console.log("calendar.handleTimeOffClick");
    let changeOn = !this.state.changeOn;
    this.setState({ changeOn });
  };

  handleCookies = click => {};

  handleAdminPage = () => {
    this.props.retrieveData(true);
    //window.location.reload();
    if (this.props.loaded) {
      this.props.showAdminEffectiveLd();
    }
  };

  returnContent(data) {
    let { isAdmin } = this.props;
    // isAdmin = false;
    // console.log('Alt data test', isAdmin);
    return (
      <div>
        <div className="container-fluid">
          <div className="row">
            <div className="col ldTitle">Being a More Effective Leader</div>
            <div
              className="adminButton"
              onClick={() => this.handleAdminPage()}
              style={isAdmin && !isMobile ? {} : { display: 'none' }}
            >
              Admin
            </div>
          </div>
          <div
            className="noScrollingActions"
            style={{
              color: '#bcbdbc',
              marginBottom: '8px',
            }}
          ></div>
          <div className={isMobile ? 'ldBlueBannerMobile' : 'ldBlueBanner'}>
            <div className="row">
              <div className={isMobile ? '' : 'ldTopBannerImage'}></div>
              <div
                style={
                  isMobile ? { paddingLeft: '18px', paddingTop: '10px', marginRight: '10px' } : {}
                }
              >
                <div className={isMobile ? 'ldWhiteTextSmall' : 'ldWhiteTextBig'}>
                  What You Need to Know Now
                </div>
                <div className="ldWhiteTextSmall">
                  Stay up to date on the latest leadership news.
                </div>
              </div>
            </div>
          </div>
          {this.renderTop(data)}
          <div className={isMobile ? 'ldBlueBannerMobile' : 'ldBlueBanner'}>
            <div className="row">
              <div className={isMobile ? '' : 'ldBottomBannerImage'}></div>
              <div
                style={
                  isMobile ? { paddingLeft: '18px', paddingTop: '10px', marginRight: '10px' } : {}
                }
              >
                <div className={isMobile ? 'ldWhiteTextSmall' : 'ldWhiteTextBig'}>
                  Leader Quick Reference
                </div>
                <div className="ldWhiteTextSmall">
                  Links to help guide you through key leadership areas.
                </div>
              </div>
            </div>
          </div>
          {this.renderBottom(data)}
        </div>
      </div>
    );
  }

  goToLink = link => {
    window.open(link);
  };

  renderTop(data) {
    data = this.parseNeedToKnow(data);

    return (
      <div className={isMobile ? 'newsLinksContainerMobile' : 'newsLinksContainer'}>
        {data.map((item, index) => (
          <div>
            <div className="row">
              <button
                className="ldNewsLink"
                onClick={() => this.goToLink(item.Link)}
                title={'Click "' + item.Display + '" Link - ' + item.Link}
              >
                {item.Display}
              </button>
              {/* <div className={item.New ? 'ldNewLogo' : ''}></div> */}
            </div>
            <div className="dotted"></div>
          </div>
        ))}
      </div>
    );
  }

  renderBottom(data) {
    data = this.parseReferences(data);
    let stronger = data.strongerTeam;
    let retention = data.leaderRetention;
    let better1s = data.better1on1s;
    let onboard = data.onboard;
    let otherLeaders = data.otherLeaders;
    let change = data.change;
    const { StrongerOn, RetentionOn, oneOn, OnboardOn, otherLdOn, changeOn } = this.state;
    return (
      <div className={isMobile ? 'refContainerMobile' : 'refContainer'}>
        <div className="row">
          <button
            tabIndex="0"
            onClick={e => this.handleStrongerClick(e)}
            id={'Bravo'}
            style={{
              //   width: "100%",
              textAlign: 'left',
              border: 'none',
              backgroundColor: 'white',
            }}
          >
            <div className="ldChevronContainer">
              <img
                src={StrongerOn ? '/icon-chevron-up-dark.svg' : '/icon-chevron-down-dark.svg'}
                alt={this.state.StrongerOn ? "Click to Collapse Building a Strong Team" : "Click to expand Building a Strong Team Vision"}
              ></img>
            </div>
          </button>
          <div className={isMobile ? 'refTitleMobile' : 'refTitle'}>
            Building a Strong Team
          </div>
        </div>
        <div
          className={isMobile ? 'ldOpenRefContainerMobile' : 'ldOpenRefContainer'}
          style={StrongerOn ? { display: 'block' } : { display: 'none' }}
        >
          {/* <div className="ldDescText">{stronger[0].desc.Summary}</div> */}
          {stronger.map((item, index) => (
            <div className="row" style={{ paddingLeft: '15px' }}>
              <div className="ldLinkImage" onClick={() => this.goToLink(item.Link)}></div>
              <button
                className="linkbutton ldRefLink"
                onClick={() => this.goToLink(item.Link)}
                title={'Click "' + item.Display + '" Link - ' + item.Link}
              >
                {item.Display}
              </button>
            </div>
          ))}
        </div>
        {/* Insert mapping */}
        <div className="dotted"></div>
        <div className="row" style={{ paddingTop: '15px' }}>
          <button
            tabIndex="0"
            onClick={e => this.handleRetentionClick(e)}
            style={{
              //   width: "100%",
              textAlign: 'left',
              border: 'none',
              backgroundColor: 'white',
            }}
          >
            <div className="ldChevronContainer">
              <img
                src={RetentionOn ? '/icon-chevron-up-dark.svg' : '/icon-chevron-down-dark.svg'}
                alt={this.state.RetentionOn ? "Click to Collapse Leader Retention Conversation Guide" : "Click to expand Leader Retention Conversation Guide"}
              ></img>
            </div>
          </button>
          <div className={isMobile ? 'refTitleMobile' : 'refTitle'}>
            Leader Retention Conversation Guide
          </div>
        </div>
        <div
          className={isMobile ? 'ldOpenRefContainerMobile' : 'ldOpenRefContainer'}
          style={RetentionOn ? { display: 'block' } : { display: 'none' }}
        >
          {/* <div className="ldDescText">{retention[0].desc.Summary}</div> */}
          {retention.map((item, index) => (
            <div className="row" style={{ paddingLeft: '15px' }}>
              <div className="ldLinkImage" onClick={() => this.goToLink(item.Link)}></div>
              <button
                className="linkbutton ldRefLink"
                onClick={() => this.goToLink(item.Link)}
                title={'Click "' + item.Display + '" Link - ' + item.Link}
              >
                {item.Display}
              </button>
            </div>
          ))}
        </div>
        {/* Insert mapping */}
        <div className="dotted"></div>
        <div className="row" style={{ paddingTop: '15px' }}>
          <button
            tabIndex="0"
            onClick={e => this.handleOneClick(e)}
            id={'Bravo'}
            style={{
              //   width: "100%",
              textAlign: 'left',
              border: 'none',
              backgroundColor: 'white',
            }}
          >
            <div className="ldChevronContainer">
              <img
                src={oneOn ? '/icon-chevron-up-dark.svg' : '/icon-chevron-down-dark.svg'}
                alt={this.state.oneOn ? "Click to Collapse Conduct Better 1-on-1's" : "Click to expand Conduct Better 1-on-1's"}
              ></img>
            </div>
          </button>
          <div className={isMobile ? 'refTitleMobile' : 'refTitle'}>Conduct Better 1-on-1’s</div>
        </div>
        <div
          className={isMobile ? 'ldOpenRefContainerMobile' : 'ldOpenRefContainer'}
          style={oneOn ? { display: 'block' } : { display: 'none' }}
        >
          {/* <div className="ldDescText">{better1s[0].desc.Summary}</div> */}
          {better1s.map((item, index) => (
            <div className="row" style={{ paddingLeft: '15px' }}>
              <div className="ldLinkImage" onClick={() => this.goToLink(item.Link)}></div>
              <button
                className="linkbutton ldRefLink"
                onClick={() => this.goToLink(item.Link)}
                title={'Click "' + item.Display + '" Link - ' + item.Link}
              >
                {item.Display}
              </button>
            </div>
          ))}
        </div>
        {/* Insert mapping */}
        <div className="dotted"></div>
        <div className="row" style={{ paddingTop: '15px' }}>
          <button
            tabIndex="0"
            onClick={e => this.handleOnboardClick(e)}
            id={'Bravo'}
            style={{
              //   width: "100%",
              textAlign: 'left',
              border: 'none',
              backgroundColor: 'white',
            }}
          >
            <div className="ldChevronContainer">
              <img
                src={OnboardOn ? '/icon-chevron-up-dark.svg' : '/icon-chevron-down-dark.svg'}
                alt={this.state.OnboardOn ? "Click to Collapse Onboard a new Team Member" : "Click to expand Onboard a new Team Member"}
              ></img>
            </div>
          </button>
          <div className={isMobile ? 'refTitleMobile' : 'refTitle'}>Onboard a New Team Member</div>
        </div>
        <div
          className={isMobile ? 'ldOpenRefContainerMobile' : 'ldOpenRefContainer'}
          style={OnboardOn ? { display: 'block' } : { display: 'none' }}
        >
          {/* <div className="ldDescText">{onboard[0].desc.Summary}</div> */}
          {onboard.map((item, index) => (
            <div className="row" style={{ paddingLeft: '15px' }}>
              <div className="ldLinkImage" onClick={() => this.goToLink(item.Link)}></div>
              <button
                className="linkbutton ldRefLink"
                onClick={() => this.goToLink(item.Link)}
                title={'Click "' + item.Display + '" Link - ' + item.Link}
              >
                {item.Display}
              </button>
            </div>
          ))}
        </div>
        {/* Insert mapping */}
        <div className="dotted"></div>
        <div className="row" style={{ paddingTop: '15px' }}>
          <button
            tabIndex="0"
            onClick={e => this.handleOtherLdClick(e)}
            id={'Bravo'}
            style={{
              //   width: "100%",
              textAlign: 'left',
              border: 'none',
              backgroundColor: 'white',
            }}
          >
            <div className="ldChevronContainer">
              <img
                src={otherLdOn ? '/icon-chevron-up-dark.svg' : '/icon-chevron-down-dark.svg'}
                alt={this.state.otherLdOn ? "Click to Collapse Connect with other Leaders" : "Click to expand Connect with Other Leaders"}
              ></img>
            </div>
          </button>
          <div className={isMobile ? 'refTitleMobile' : 'refTitle'}>Connect with Other Leaders</div>
        </div>
        <div
          className={isMobile ? 'ldOpenRefContainerMobile' : 'ldOpenRefContainer'}
          style={otherLdOn ? { display: 'block' } : { display: 'none' }}
        >
          {/* <div className="ldDescText">{otherLeaders[0].desc.Summary}</div> */}
          {otherLeaders.map((item, index) => (
            <div className="row" style={{ paddingLeft: '15px' }}>
              <div className="ldLinkImage" onClick={() => this.goToLink(item.Link)}></div>
              <button
                className="linkbutton ldRefLink"
                onClick={() => this.goToLink(item.Link)}
                title={'Click "' + item.Display + '" Link - ' + item.Link}
              >
                {item.Display}
              </button>
            </div>
          ))}
        </div>
        {/* Insert mapping */}
        <div className="dotted"></div>
        <div className="row" style={{ paddingTop: '15px' }}>
          <button
            tabIndex="0"
            onClick={e => this.handleChangeClick(e)}
            id={'Bravo'}
            style={{
              //   width: "100%",
              textAlign: 'left',
              border: 'none',
              backgroundColor: 'white',
            }}
          >
            <div className="ldChevronContainer">
              <img
                src={changeOn ? '/icon-chevron-up-dark.svg' : '/icon-chevron-down-dark.svg'}
                alt={this.state.changeOn ? "Click to Collapse Lead through Change" : "Click to expand Lead through Change"}
              ></img>
            </div>
          </button>
          <div className={isMobile ? 'refTitleMobile' : 'refTitle'}>Lead through Change</div>
        </div>
        <div
          className={isMobile ? 'ldOpenRefContainerMobile' : 'ldOpenRefContainer'}
          style={changeOn ? { display: 'block' } : { display: 'none' }}
        >
          {/* <div className="ldDescText">{change[0].desc.Summary}</div> */}
          {change.map((item, index) => (
            <div className="row" style={{ paddingLeft: '15px' }}>
              <div className="ldLinkImage" onClick={() => this.goToLink(item.Link)}></div>
              <button
                className="linkbutton ldRefLink"
                onClick={() => this.goToLink(item.Link)}
                title={'Click "' + item.Display + '" Link - ' + item.Link}
              >
                {item.Display}
              </button>
            </div>
          ))}
        </div>
      </div>
    );
  }

  isActive(startDate, endDate) {
    let start = Date.parse(startDate);
    let end = Date.parse(endDate);
    let now = Date.now();

    if (start <= now && now <= end) {
      return true;
    } else {
      return false;
    }
  }

  parseReferences(data) {
    let strongerTeam = [],
      leaderRetention = [],
      better1on1s = [],
      onboard = [],
      otherLeaders = [],
      change = [],
      strongerCount = 0,
      retentionCount = 0,
      count = 0;
    try {
      for (let a = 0; a < data.strongerTeam.Links.length; a++) {
        if (data.strongerTeam.Links[a].startDate && data.strongerTeam.Links[a].endDate) {
          if (
            this.isActive(data.strongerTeam.Links[a].startDate, data.strongerTeam.Links[a].endDate)
          ) {
            strongerTeam[strongerCount++] = {
              Display: data.strongerTeam.Links[a].Display,
              Link: data.strongerTeam.Links[a].Link,
            };
          }
        } else {
          strongerTeam[strongerCount++] = {
            Display: data.strongerTeam.Links[a].Display,
            Link: data.strongerTeam.Links[a].Link,
          };
        }
      }
    } catch {
      strongerTeam = [];
    }
    try {
      if (data.LeaderRetention.Links.length > 0) {
        for (let b = 0; b < data.LeaderRetention.Links.length; b++) {
          let node = data.LeaderRetention;
          if (node.Links[b].startDate && node.Links[b].endDate) {
            // console.log('Test Rentent Start end', node.Links[b].startDate, node.Links[b].startDate);
            if (this.isActive(node.Links[b].startDate, node.Links[b].endDate)) {
              leaderRetention[retentionCount++] = {
                Display: node.Links[b].Display,
                Link: node.Links[b].Link,
              };
            }
          } else {
            leaderRetention[retentionCount++] = {
              Display: node.Links[b].Display,
              Link: node.Links[b].Link,
            };
          }
        }
      }
    } catch {
      leaderRetention = [];
    }
    try {
      if (data.Better1on1s.Links.length > 0) {
        for (let c = 0; c < data.Better1on1s.Links.length; c++) {
          let node = data.Better1on1s;
          if (node.Links[c].startDate && node.Links[c].endDate) {
            // console.log('Test Rentent Start end', node.Links[b].startDate, node.Links[b].startDate);
            if (this.isActive(node.Links[c].startDate, node.Links[c].endDate)) {
              better1on1s[count++] = {
                Display: node.Links[c].Display,
                Link: node.Links[c].Link,
              };
            }
          } else {
            better1on1s[count++] = {
              Display: node.Links[c].Display,
              Link: node.Links[c].Link,
            };
          }
        }
      }
    } catch {
      better1on1s = [];
    }
    count = 0;
    try {
      if (data.Onboard.Links.length > 0) {
        for (let c = 0; c < data.Onboard.Links.length; c++) {
          let node = data.Onboard;
          if (node.Links[c].startDate && node.Links[c].endDate) {
            // console.log('Test Rentent Start end', node.Links[b].startDate, node.Links[b].startDate);
            if (this.isActive(node.Links[c].startDate, node.Links[c].endDate)) {
              onboard[count++] = {
                Display: node.Links[c].Display,
                Link: node.Links[c].Link,
              };
            }
          } else {
            onboard[count++] = {
              Display: node.Links[c].Display,
              Link: node.Links[c].Link,
            };
          }
        }
      }
    } catch {
      onboard = [];
    }
    count = 0;
    try {
      if (data.OtherLeaders.Links.length > 0) {
        for (let c = 0; c < data.OtherLeaders.Links.length; c++) {
          let node = data.OtherLeaders;
          if (node.Links[c].startDate && node.Links[c].endDate) {
            // console.log('Test Rentent Start end', node.Links[b].startDate, node.Links[b].startDate);
            if (this.isActive(node.Links[c].startDate, node.Links[c].endDate)) {
              otherLeaders[count++] = {
                Display: node.Links[c].Display,
                Link: node.Links[c].Link,
              };
            }
          } else {
            otherLeaders[count++] = {
              Display: node.Links[c].Display,
              Link: node.Links[c].Link,
            };
          }
        }
      }
    } catch {
      otherLeaders = [];
    }
    count = 0;
    try {
      if (data.Change.Links.length > 0) {
        for (let c = 0; c < data.Change.Links.length; c++) {
          let node = data.Change;
          if (node.Links[c].startDate && node.Links[c].endDate) {
            // console.log('Test Rentent Start end', node.Links[b].startDate, node.Links[b].startDate);
            if (this.isActive(node.Links[c].startDate, node.Links[c].endDate)) {
              change[count++] = {
                Display: node.Links[c].Display,
                Link: node.Links[c].Link,
              };
            }
          } else {
            change[count++] = {
              Display: node.Links[c].Display,
              Link: node.Links[c].Link,
            };
          }
        }
      }
    } catch {
      change = [];
    }
    return { strongerTeam, leaderRetention, better1on1s, onboard, otherLeaders, change };
  }

  parseNeedToKnow(data) {
    let out = [];
    let newLink = false;
    let count = 0;
    if (data.news.length > 0) {
      for (let i = 0; i < data.news.length; i++) {
        newLink = false;
        if (data.news[i].Added) {
          let node = data.news;
          if (node[i].startDate && node[i].endDate) {
            if (this.isActive(node[i].startDate, node[i].endDate)) {
              out[count++] = {
                Display: data.news[i].Display,
                Link: data.news[i].Link,
                New: newLink,
              };
            }
          } else {
            out[count++] = {
              Display: data.news[i].Display,
              Link: data.news[i].Link,
              New: newLink,
            };
          }
        }
      }
    }

    return out;
  }

  render() {
    let { data, loaded, retrieveData } = this.props;

    if (data === null) {
      retrieveData();
    }

    if (data) {
      if (loaded) {
        const content = this.returnContent(data);
        return (
          <div className="leaderContainer">
            <div className="leaderContent">
              {content}
              <div style={{ paddingBottom: '15px' }}></div>
            </div>
          </div>
        );
      } else {
        // console.log("Call populate Fake Data from ", title)
        return (
          <div className="leaderContainer">
            <div className="leaderContent">
              <div
                className="progress-spinner "
                style={{
                  left: '50%',
                  top: '50%',
                  paddingTop: '15px',
                }}
              />
            </div>
          </div>
        );
      }
    } else {
      if (loaded) {
        return (
          <div className="leaderContainer" style={{ height: '501px' }}>
            <div className="portletError">
              <div className="portletErrorAlert">!</div>
              <div
                style={{
                  fontFamily: 'nw-primary',
                  fontSize: '20px',
                  lineHeight: '24px',
                  color: '#4D4F53',
                }}
              >
                Oops, this is unexpected.
              </div>
              <div
                style={{
                  fontFamily: 'nw-primary',
                  fontSize: '12px',
                  lineHeight: '15px',
                  color: '#4D4F53',
                }}
              >
                Error code: 500
                <br />
                An error has occurred and we're working to fix the issue.
                <br />
                If the issue persists please{' '}
                <a
                  style={{ color: '#4D4F53', textDecoration: 'underline' }}
                  href={config.contactUsUrl}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  contact us.
                </a>
              </div>
              <div
                className="tile-apierror-refresh"
                // onMouseDown={() => this.getFow(index)}
                // onClick={}
                title="Refresh"
              >
                Refresh
              </div>
            </div>
          </div>
        );
      } else {
        return (
          <div className="leaderContainer">
            <div className="leaderContent">
              <div
                className="progress-spinner "
                style={{
                  left: '50%',
                  top: '50%',
                  paddingTop: '15px',
                }}
              />
            </div>
          </div>
        );
      }
    }
  }
}

export default EffectiveLeader;
