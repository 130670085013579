import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
// import * as serviceWorker from "./serviceWorker";
import './index.css';
import 'bootstrap/dist/css/bootstrap.css';
import '@nwcode/bolt-bootstrap-theme/dist/css/bolt-bootstrap.css';
import 'bootstrap/dist/css/bootstrap.css';
import 'font-awesome/css/font-awesome.css';
import '@nationwide-bolt/css-core/dist/css/bolt-core.css';
import '@nationwide-bolt/bundle-core/dist/bolt-core.js';
import {CookiesProvider} from "react-cookie";

ReactDOM.render(
  <CookiesProvider>
  <BrowserRouter>
    <App />
  </BrowserRouter>
  </CookiesProvider>,
  document.getElementById('root'),
);
