import React, { Component } from 'react';
// import DropdownButton from "react-bootstrap/DropdownButton";
import Dropdown from 'react-bootstrap/Dropdown';
// import config from "../config.json";
//import LeaderTransitionChecklist from '../components/LeaderTransitionChecklist';
import DropdownItem from 'react-bootstrap/esm/DropdownItem';
import menu from '../menu.json';

class MenuBar extends Component {
  state = {
    leaderresources: false,
    peopleandculture: false,
    career: false
  };

  /*loadChecklistContent(){
    return(
      <LeaderTransitionChecklist/>
    )
  };*/

  // Will be used to determine if user is able to see the MOVE link (replaced with a style for if the item will show)

  /*showSepmMenu() {
    const {isSepm} = this.state;
    console.log('is the user a sepm', isSepm)
  if(isSepm/* == true){
      return(
      <DropdownItem
          tabIndex="0"
          class="changeChecklist"
          onClick={() => this.launchChecklistContent()}
          title="Manage Onboarding & Verify Departures Efficiently"
          >
                <div>
                  MOVE
                </div>
          </DropdownItem>  
      )            
    }else{
      return null;
    }
  };*/

  launchChecklistContent(){
    const { showDefault, loadChecklistContent } = this.props;
    console.log("did the checklist launch");
    showDefault();
    loadChecklistContent();
    window.setTimeout(function () {
      document.getElementById("checklistTitle").focus();
    }, 0)
   /* return(
      <div id='alert' aria-live='Leader Checklist has launched'>
      </div>
    )*/
  };

  clickMenu = stateVariable => {
    // console.log("MenuBar.clickMenu stateVariable", stateVariable);
    try {
      switch (stateVariable) {
        case 'leader resources':
          const { leaderresources } = this.state;
          this.setState({
            leaderresources: !leaderresources,
            peopleandculture: false,
            career: false,
          });
          break;
        case 'people & culture':
          const { peopleandculture } = this.state;
          this.setState({
            peopleandculture: !peopleandculture,
            leaderresources: false,
            career: false,
          });
          break;
        case 'career':
          const { career } = this.state;
          this.setState({
            career: !career,
            leaderresources: false,
            peopleandculture: false,
          });
          break;
        default:
          break;
      }
    } catch (e) {
      console.error('menubar>clickMenu Error', e);
    }
  };

  onMouseEnter = event => {
    try {
      const stateVariable = event.currentTarget.children[0].textContent.toLowerCase();
      switch (stateVariable) {
        case 'leader resources':
          this.setState({ leaderresources: true });
          break;
        case 'people & culture':
          this.setState({ peopleandculture: true });
          break;
        case 'career':
          this.setState({ career: true });
          break;
        default:
          break;
      }
    } catch (e) {
      console.error('menubar>onMouseEnter Error', e);
    }
  };

  onMouseLeave = event => {
    try {
      const stateVariable = event.currentTarget.children[0].textContent.toLowerCase();
      switch (stateVariable) {
        case 'leader resources':
          this.setState({ leaderresources: false });
          break;
        case 'people & culture':
          this.setState({ peopleandculture: false });
          break;
        case 'career':
          this.setState({ career: false });
          break;
        default:
          break;
      }
    } catch (e) {
      console.error('menubar>onMouseLeave Error', e);
    }
  };

  logout() {
    // console.log("LOGOUT");
    sessionStorage.clear();
    localStorage.clear();
    document.location = 'https://nationwide.com';
  }

  goToHRConnect() {
    window.location.href = 'https://hrconnect.nationwide.com';
  }

  goToHome() {
    const { showDefault, changeMenuExpanded } = this.props;
    showDefault();
    changeMenuExpanded();
  }

  render() {
    const { leaderresources, peopleandculture, career } = this.state;
    const leaderresourcesMnu = menu.leaderresources;
    const careerMnu = menu.career;
    const peopleandcultureMnu = menu.peopleandculture;
    const { menuExpanded, isSepm,  } = this.props;

    return (
      <React.Fragment>
        <div
          id="mobileMenu"
          className={
            menuExpanded
              ? 'header-dropdowns menuMobileDisplay menuMobileExpanded'
              : 'header-dropdowns menuMobileDisplay'
          }
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
            // flexBasis: "100%"
          }}
        >
          <div id="mobileMenuSub" style={menuExpanded ? { display: 'block' } : { display: 'none' }}>
            <div className="showWhenMobile mobileMenu">
              <button className="btn btn-link mnuLogoutButton" onClick={() => this.goToHome()}>
                Home
              </button>
            </div>
            <div className="showWhenMobile donotshowfocus">
              <div
                style={
                  leaderresources
                    ? { backgroundColor: '#3FCDD7', width: '100%' }
                    : { backgroundColor: '#007d8a', width: '100%' }
                }
              >
                <button
                  tabIndex="0"
                  className={
                    leaderresources
                      ? 'btn bntLink activeMobileMenu mobileMenu'
                      : 'btn bntLink inactiveMobileMenu mobileMenu'
                  }
                  onClick={() => this.clickMenu('leader resources')}
                  // onMouseEnter={this.onMouseEnter}
                  // onMouseLeave={this.onMouseLeave}
                >
                  {leaderresourcesMnu.text}{' '}
                  <div
                    className={
                      leaderresources ? 'activeMobileMenuImage' : 'inactiveMobileMenuImage'
                    }
                    id="payAndBenefitsMenu"
                  >
                    <img
                      src={leaderresources ? 'icon-chevron-up-dark.svg' : '/icon-chevron-down.svg'}
                      alt=""
                    ></img>
                  </div>
                </button>
              </div>
              <div
                className={leaderresources ? 'mobileExpandedContainer' : 'mobileCollapsedContainer'}
              >
                <div
                  className={
                    leaderresources ? 'mobileExpandedItemsContainer' : 'mobileCollapsedContainer'
                  }
                >
                  {leaderresourcesMnu.items.map(item => (
                    <div key={item.id} className="menuLinkContainer">
                      <a
                        id={`mobile_menu_${item.id}`}
                        href={item.url}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="mobileMenuLink"
                      >
                        {item.text}
                      </a>
                    </div>
                  ))}
                  <div className="menuLinkContainer" >
                    <a
                    onClick={() => this.launchChecklistContent()}
                  >
                      Role Change Checklist 
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="showWhenMobile donotshowfocus">
              <div
                style={
                  career
                    ? { backgroundColor: '#3FCDD7', width: '100%' }
                    : { backgroundColor: '#007d8a', width: '100%' }
                }
              >
                <button
                  tabIndex="0"
                  className={
                    career
                      ? 'btn bntLink activeMobileMenu mobileMenu'
                      : 'btn bntLink inactiveMobileMenu mobileMenu'
                  }
                  onClick={() => this.clickMenu('career')}
                  // onMouseEnter={this.onMouseEnter}
                  // onMouseLeave={this.onMouseLeave}
                  id="careerMenu"
                >
                  {careerMnu.text}{' '}
                  <div
                    className={career ? 'activeMobileMenuImage' : 'inactiveMobileMenuImage'}
                    id="careerMenu"
                  >
                    <img
                      src={career ? 'icon-chevron-up-dark.svg' : '/icon-chevron-down.svg'}
                      alt=""
                    ></img>
                  </div>
                </button>
              </div>

              <div className={career ? 'mobileExpandedContainer' : 'mobileCollapsedContainer'}>
                <div
                  className={career ? 'mobileExpandedItemsContainer' : 'mobileCollapsedContainer'}
                >
                  {careerMnu.items.map(item => (
                    <div key={item.id} className="menuLinkContainer">
                      <a
                        id={`mobile_menu_${item.id}`}
                        href={item.url}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="mobileMenuLink"
                      >
                        {item.text}
                      </a>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div className="showWhenMobile donotshowfocus">
              <div
                style={
                  peopleandculture
                    ? { backgroundColor: '#3FCDD7', width: '100%' }
                    : { backgroundColor: '#007d8a', width: '100%' }
                }
              >
                <button
                  tabIndex="0"
                  className={
                    peopleandculture
                      ? 'btn bntLink activeMobileMenu mobileMenu'
                      : 'btn bntLink inactiveMobileMenu mobileMenu'
                  }
                  onClick={() => this.clickMenu('people & culture')}
                  id="peopleandcultureMenu"
                >
                  {peopleandcultureMnu.text}{' '}
                  <div
                    className={
                      peopleandculture ? 'activeMobileMenuImage' : 'inactiveMobileMenuImage'
                    }
                    id="peopleandcultureMenu"
                  >
                    <img
                      src={peopleandculture ? 'icon-chevron-up-dark.svg' : '/icon-chevron-down.svg'}
                      alt=""
                    ></img>
                  </div>
                </button>
              </div>
              <div
                className={
                  peopleandculture ? 'mobileExpandedContainer' : 'mobileCollapsedContainer'
                }
              >
                <div
                  className={
                    peopleandculture ? 'mobileExpandedItemsContainer' : 'mobileCollapsedContainer'
                  }
                >
                  {peopleandcultureMnu.items.map(item => (
                    <div key={item.id} className="menuLinkContainer">
                      <a
                        id={`mobile_menu_${item.id}`}
                        href={item.url}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="mobileMenuLink"
                      >
                        {item.text}
                      </a>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div className="showWhenMobile mobileMenu">
              <button className="btn btn-link mnuLogoutButton" onClick={() => this.goToHRConnect()}>
                HRConnect
              </button>
            </div>
            <div className="showWhenMobile">
              <button className="btn btn-link mnuLogoutButton" onClick={() => this.logout()}>
                Log Out
              </button>
            </div>
          </div>
        </div>

        <div className="header-dropdowns menuDesktopDisplay xcol-md-6">
          <Dropdown
            tabIndex="0"
            show={leaderresources}
            onFocus={this.onMouseEnter}
            onBlur={this.onMouseLeave}
            onMouseEnter={this.onMouseEnter}
            onMouseLeave={this.onMouseLeave}
            id="dropdown-item-button"
            style={{
              backgroundColor: 'transparent',
              position: 'relative',
            }}
            className={leaderresources ? 'menu active-menu' : 'menu inactive-menu'}
          >
            <Dropdown.Toggle
              className={leaderresources ? 'xno-chevron active-menu-item' : 'xno-chevron'}
            >
              {leaderresourcesMnu.text}
            </Dropdown.Toggle>
            <Dropdown.Menu className="dropdown-menu-custom-style" style={{ minWidth: '265px' }}>
              {leaderresourcesMnu.items.map(item => (
                <Dropdown.Item
                  id={`menu_${item.id}`}
                  as="a"
                  key={item.id}
                  href={item.url}
                  target="_blank"
                >
                  {item.text}
                </Dropdown.Item>
              ))}
              {/*console.log("the value of isSepm ", isSepm)*/}
              <DropdownItem
              tabIndex="0"
              class="changeChecklist" style={isSepm ? {display: "block"} : {display: "none"}}
              onClick={() => this.launchChecklistContent()}
              title=""
              >
                    <div>
                    Role Change Checklist
                    </div>
            </DropdownItem>

            </Dropdown.Menu>
          </Dropdown>

          <Dropdown
            tabIndex="0"
            show={career}
            onFocus={this.onMouseEnter}
            onBlur={this.onMouseLeave}
            onMouseEnter={this.onMouseEnter}
            onMouseLeave={this.onMouseLeave}
            id="dropdown-item-button2"
            style={{
              backgroundColor: 'transparent',
              // flexBasis: flexAmount,
              position: 'relative',
            }}
            // className="menu dropdown-toggle"
            className={career ? 'menu active-menu' : 'menu inactive-menu'}
          >
            <Dropdown.Toggle className={career ? 'xno-chevron active-menu-item' : 'xno-chevron'}>
              {careerMnu.text}
            </Dropdown.Toggle>
            <Dropdown.Menu
              className="dropdown-menu-custom-style"
              style={{ width: '100%', boxShadow: 'none', minWidth: '265px' }}
            >
              {careerMnu.items.map(item => (
                <Dropdown.Item
                  as="a"
                  id={`menu_${item.id}`}
                  key={item.id}
                  href={item.url}
                  target="_blank"
                  // onClick={() => showMessage("This is just a demo link")}
                >
                  {item.text}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
          <Dropdown
            tabIndex="0"
            show={peopleandculture}
            onFocus={this.onMouseEnter}
            onBlur={this.onMouseLeave}
            onMouseEnter={this.onMouseEnter}
            onMouseLeave={this.onMouseLeave}
            id="dropdown-item-button3"
            style={{
              backgroundColor: 'transparent',
              // flexBasis: flexAmount,
              position: 'relative',
            }}
            // className="menu dropdown-toggle"
            className={peopleandculture ? 'menu active-menu' : 'menu inactive-menu'}
          >
            <Dropdown.Toggle
              className={peopleandculture ? 'xno-chevron active-menu-item' : 'xno-chevron'}
            >
              {peopleandcultureMnu.text}
            </Dropdown.Toggle>
            <Dropdown.Menu
              className="dropdown-menu-custom-style"
              style={{ width: '100%', boxShadow: 'none', minWidth: '265px' }}
            >
              {peopleandcultureMnu.items.map(item => (
                <Dropdown.Item
                  id={`menu_${item.id}`}
                  as="a"
                  key={item.id}
                  href={item.url}
                  target="_blank"
                  // onClick={() => showMessage("This is just a demo link")}
                >
                  {item.text}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>

          <div
            tabIndex="0"
            id="dropdown-item-button4"
            className="menu inactive-menu "
            style={{ backgroundColor: 'transparent', position: 'relative' }}
          >
            <button
              aria-haspopup="true"
              aria-expanded="false"
              type="button"
              className=" btn btn-primary"
              style={{ border: 'none' }}
              onClick={() => this.logout()}
            >
              Log Out
            </button>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default MenuBar;
