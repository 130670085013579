import React, { Component } from 'react';
// import { PortletInfo } from "../common/portletInfo";
import { isMobile } from 'react-device-detect';
// import Overlay from "react-bootstrap/Overlay";
import Overlay from 'react-bootstrap/Overlay';
import Popover from 'react-bootstrap/Popover';
import config from '../config.json';
// import { toast } from "react-toastify";
import '../TeamRecognition.css';
import links from '../links.json';

class TeamRecognition extends Component {
  state = {
    displayInfo: false,
    bravoResults: null,
    precent: 0,
    lastBravo: null,
    lastBravoDate: null,
    noBravos: true,
  };

  showInformation = event => {
    const { displayInfo } = this.state;
    const target = event.target;
    // console.log("MyCalendar.displayInfo", displayInfo);
    this.setState({ displayInfo: !displayInfo, target });
  };

  goToBravo = () => {
    window.open(links.bravo);
  };

  handleBravoClick = () => {
    let BravoOn = !this.state.BravoOn;
    this.setState({ BravoOn });
  };

  handleLearnMore = () => {
    window.open(links.learnRec);
  };

  getBravos = async () => {
    const { retrieveBravos } = this.props;
    retrieveBravos();
  };

  parseBravos() {
    const { team, bravoData } = this.props;

    let results = [];
    let count = 0;
    let teamMap = new Map();

    for (let x = 0; x < team.members.length; x++) {
      teamMap.set(team.members[x].shortName, false); // Allocate Default: Value = false "does not have a Bravo assoicated with team member"
    }
    for (let i = 0; i < bravoData.entries.length; i++) {
      let date = Date.parse(bravoData.entries[i].Date);
      let before_date = new Date(Date.now() - 12096e5); // 12096e5 = 14 days in milli-secs
      before_date = Date.parse(before_date);
      let shortname = bravoData.entries[i].shortName;

      let onTeam = false;
      // Checks callup data to see if team member is still on manager's team
      let x = 0;
      for (x = 0; x < team.members.length; x++) {
        if (team.members[x].shortName == shortname) {
          onTeam = true;
          teamMap.set(team.members[x].shortName, true);
          break;
        }
      }
      // Checks if the Bravo entry is within two weeks.
      if (before_date.valueOf() <= date.valueOf() && onTeam) {
        results[count] = {
          bravo: bravoData.entries[i],
          image: team.members[x].image,
          name: team.members[x].associateName,
        };
        count++;
      }
    }
    let precent_temp = this.calculatePrecent(teamMap);

    return {
      results,
      precent_temp,
    };
  }

  // Helper method that calculates the precent of workers awarded a Bravo in the past year.
  calculatePrecent(teamMap) {
    let count = 0;
    let total = teamMap.size;
    for (let e of teamMap.values()) {
      if (e == true) count++;
    }
    return Math.round((count / total) * 100);
  }

  managerBravos() {
    const { bravoData } = this.props;
    let output = '';
    if (bravoData.managerBravos) {
      if (bravoData.managerBravos.length > 0) {
        let date = new Date(Date.parse(bravoData.managerBravos[0].Date));
        date = this.formatDateText(date);
        output =
          'You last gave a Bravo! ecard to ' + bravoData.managerBravos[0].name + ' on ' + date + '.';
        return output;
      } else {
        return "You haven't given any Bravo! ecards recently.";
      }
    }
    else {
      return "You haven't given any Bravo! ecards recently.";
    }
  }

  formatDateText(date) {
    let output = '';
    let date_temp = new Date(Date.parse(date));
    date_temp =
      date_temp.toLocaleString('default', { month: 'long' }) +
      ' ' +
      date_temp.getDate() +
      ', ' +
      date_temp.getFullYear();
    return date_temp;
  }

  render() {
    const { bravoData, bravosLoaded, retrieveBravos } = this.props;
    let informationMessge =
      "Currently, only your team's latest Bravo! ecards for the past 2 weeks are displayed. Monetary awards, private and systems generated eCards (e.g., phishing) are not included.";
    let percent = 0; // Add function
    let noBravos = true;

    const { displayInfo, target, BravoOn, bravoResults } = this.state;

    if (bravoData === null) {
      retrieveBravos(false);
    }
    //const token = sessionStorage.getItem('access_token');
    // console.log('Bravo from tile', token);
    if (bravoData && bravosLoaded) {
      if (bravoData && bravoData.error) {
        return (
          <div className="recognitionContainer">
            <div className="recognitionContent" style={{ height: '388px' }}>
              <h2 className="tile-header">
                <div style={{ height: '39px' }}>
                  Team Recognition
                  <button onClick={e => this.showInformation(e)} className="infoLink">
                    {displayInfo ? 'X' : '?'}
                  </button>
                  <Overlay show={displayInfo} target={target} placement="bottom">
                    <Popover id="timeOffPopOver">
                      <Popover.Title as="div">Team Recognition</Popover.Title>
                      <Popover.Content>{informationMessge}</Popover.Content>
                    </Popover>
                  </Overlay>
                  <div
                    className="noScrollingActions"
                    style={{
                      color: '#bcbdbc',
                    }}
                  ></div>
                  <div className="portletError">
                    <div className="portletErrorAlert">!</div>
                    <div
                      style={{
                        fontFamily: 'nw-primary',
                        fontSize: '20px',
                        lineHeight: '24px',
                        color: '#4D4F53',
                      }}
                    >
                      Oops, this is unexpected.
                    </div>
                    <div
                      style={{
                        fontFamily: 'nw-primary',
                        fontSize: '12px',
                        lineHeight: '15px',
                        color: '#4D4F53',
                      }}
                    >
                      Error code: 500
                      <br />
                      An error has occurred and we're working to fix the issue.
                      <br />
                      If the issue persists please{' '}
                      <a
                        style={{
                          color: '#4D4F53',
                          textDecoration: 'underline',
                        }}
                        href={config.contactUsUrl}
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        contact us.
                      </a>
                    </div>
                    <div
                      className="tile-apierror-refresh"
                      // onMouseDown={() => this.getFow(index)}
                      onClick={() => this.getBravos()}
                      title="Refresh"
                    >
                      Refresh
                    </div>
                  </div>
                </div>
              </h2>
            </div>
          </div>
        );
      } else {
        if (true) {
          let manBravos = this.managerBravos();
          let bravoParse = this.parseBravos();
          let precent_out = bravoParse.precent_temp;
          let bravos_out = bravoParse.results;
          let num_bravos = bravos_out.length;
          return (
            <div className="recognitionContainer">
              <div className="recognitionContent" style={{ minHeight: '265px' }}>
                <h2 className="tile-header">
                  <div style={{ height: '39px' }}>
                    Team Recognition
                    <button onClick={e => this.showInformation(e)} className="infoLink">
                      {displayInfo ? 'X' : '?'}
                    </button>
                    <Overlay show={displayInfo} target={target} placement="bottom">
                      <Popover id="timeOffPopOver">
                        <Popover.Title as="div">Team Recognition</Popover.Title>
                        <Popover.Content>{informationMessge}</Popover.Content>
                      </Popover>
                    </Overlay>
                  </div>
                  <div
                    className="noScrollingActions"
                    style={{
                      color: '#bcbdbc',
                      marginBottom: '8px',
                    }}
                  ></div>
                  <div className="row">
                    <div className="BravoImageContainer" style={{ paddingLeft: '15px' }}>
                      <img alt="Bravo! icon" src="/BravoIcon.png" />
                    </div>
                    <div className="col RecognitionData1">
                      {precent_out}% of my team has been awarded a Bravo! in the last 90 days.
                    </div>
                    <br></br>
                  </div>
                  <button
                    className="learnMoreLink"
                    style={
                      precent_out == 0
                        ? { paddingLeft: '60px', paddingBottom: '15px', display: 'block' }
                        : { display: 'none' }
                    }
                    onClick={e => this.handleLearnMore(e)}
                  >
                    Learn about the importance of recognizing your team’s contributions.
                  </button>

                  <div
                    className="RecPlainText"
                    style={{ paddingLeft: '60px', paddingRight: '40px' }}
                  >
                    {manBravos}
                  </div>
                  <div
                    className="noScrollingActions"
                    style={{
                      color: '#bcbdbc',
                      marginTop: '12px',
                    }}
                  ></div>
                  <button
                    tabIndex="0"
                    onClick={e => this.handleBravoClick(e)}
                    id={'Bravo'}
                    style={{
                      //   width: "100%",
                      textAlign: 'left',
                      border: 'none',
                      backgroundColor: 'white',
                    }}
                  >
                    <div className="teamRecChevronContainer">
                      <img
                        src={BravoOn ? '/icon-chevron-up-dark.svg' : '/icon-chevron-down-dark.svg'}
                        alt={this.state.BravoOn ? "Click to Collapse My Team's Latest Bravo! Recipients" : "Click to expand My Team's Bravo! Recipients"}
                      ></img>
                    </div>
                  </button>
                  <div className="RecognitionData" style={{ paddingLeft: '15px' }}>
                    My Team's Latest Bravo! Recipients ({num_bravos})
                  </div>
                  <div style={BravoOn ? { display: 'block' } : { display: 'none' }}>
                    <div
                      className="RecPlainText"
                      style={
                        num_bravos == 0
                          ? { paddingLeft: '55px', display: 'block', marginRight: '66px' }
                          : { display: 'none' }
                      }
                    >
                      There have not been any Bravo!s awarded to your team within the past 2 weeks.
                    </div>
                    {bravos_out.map((item, index) => (
                      <div
                        style={
                          num_bravos > 0
                            ? { paddingLeft: '55px', paddingBottom: '20px', display: 'block' }
                            : { display: 'none' }
                        }
                      >
                        <div>
                          <div className="row">
                            <div
                              className="BravoImageContainer"
                              key={item.name + 'immagecontainer'}
                            >
                              <img
                                key={item.name + 'image'}
                                className="bravoImage"
                                alt={item.image ? 'Associate Image' : 'no-image'}
                                src={
                                  item.image
                                    ? 'data:image/jpeg;base64,' + item.image
                                    : '/no-image.svg'
                                }
                              />
                            </div>
                            <div className="col">
                              <div
                                className="row RecognitionDataDisplay"
                                style={{ paddingLeft: '10px' }}
                              >
                                {item.name}
                                {/* <span style={{ minWidth: '175px' }}>{item.name}</span> */}
                              </div>
                              <div className="row RecPlainText" style={{ paddingLeft: '10px' }}>
                                <span>
                                  received a Bravo! ecard on {this.formatDateText(item.bravo.Date)}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <button
                          className="learnMoreLink"
                          style={{ paddingLeft: '70px' }}
                          onClick={() => this.goToBravo()}
                          >
                          <div aria-label={"View the ecard for " + item.name}><p aria-hidden="true">View the ecard</p></div>
                        </button>
                        {/* grey line */}
                        <div
                          className="noScrollingActions"
                          style={{
                            color: '#bcbdbc',
                          }}
                        ></div>
                      </div>
                    ))}
                  </div>
                  <div className="blueLinkContainer">
                    <button
                      className="tileButton recBravoButton"
                      style={isMobile ? { marginLeft: '40%' } : { marginLeft: '68%' }}
                      onClick={() => this.goToBravo()}
                    >
                      Send a Bravo!
                    </button>
                  </div>
                </h2>
              </div>
            </div>
          );
        }
      }
    } else {
      return (
        <div className="recognitionContainer">
          <div className="recognitionContent" style={{ minHeight: '400px' }}>
            <h2 className="tile-header">
              <div style={{ height: '39px' }}>
                Team Recognition
                <div className="scrollingActions">
                  <div
                    className="progress-spinner "
                    style={{ left: '50%', top: '50%', paddingTop: '15px' }}
                  />
                </div>
              </div>
            </h2>
          </div>
        </div>
      );
    }
  }
}

export default TeamRecognition;
