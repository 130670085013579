import config from '../config.json';
import testers from '../testers.json';
import { getRandomString } from './AuthService';
import { getEnvironment } from './EnvironmentVariable';
import { isMobile } from 'react-device-detect';

export const getAribaContractApprovals = async () => {
  const environ = getEnvironment();

  let aribaAuthAPI = config.aribaAuthAPI;
  let aribaApprovalAPI = config.aribaApprovalAPI;
  let aribaKey = config.aribaKey;

  if (environ !== 'DEFAULT') {
    //We are not in production environment
    if (isMobile) {
      //However we are testing on a mobile device so use the Mobile api
      aribaAuthAPI = config.Mobile_aribaAuthAPI;
      aribaApprovalAPI = config.Mobile_aribaApprovalAPI;
      aribaKey = config.test_aribaKey;
    } else {
      //We are not testing on a mobile device, so we will use the test API
      aribaAuthAPI = config.test_aribaAuthAPI;
      aribaApprovalAPI = config.test_aribaApprovalAPI;
      aribaKey = config.test_aribaKey;
    }
  }
  const userInfo = JSON.parse(sessionStorage.getItem('userinfo'));
  if (!userInfo || !userInfo.shortName) {
    return { error: 'No user information found.' };
  }
  let shortName = userInfo.shortName
    ? userInfo.shortName.toUpperCase()
    : userInfo.username.toUpperCase();

  const testUsers = testers.users;
  for (let x = 0; x < testUsers.length; x++) {
    const tester = testUsers[x];
    if (tester.shortName.toUpperCase() === shortName.toUpperCase()) {
      //User shortname matches to a tester
      if (environ === 'DEFAULT') {
        //This is the production environment
        if (tester.prodAllowed) {
          //This tester is allowed to mimic in production
          shortName = tester.mimicManager.toUpperCase();
        }
      } else {
        //This is not a production environment
        shortName = tester.mimicManager.toUpperCase();
      }
      break;
    }
  }
  sessionStorage.removeItem('aribaAccessToken');
  const message = getRandomString(25);
  const token = sessionStorage.getItem('access_token');
  let headers = new Headers();
  headers.append('Content-Type', 'application/x-www-form-urlencoded');
  headers.append('Authorization', 'Bearer ' + token);
  headers.append('X-NW-Message-ID', message);
  headers.set('client_id', aribaKey);
  const body = encodeKeyValuePair('grant_type', 'openapi_2lo');

  let data = null;
  try {
    const response = await fetch(aribaAuthAPI, {
      method: 'post',
      headers: headers,
      body: body,
    });
    if (response.ok) {
      try {
        data = await response.json();
        // console.log("calendarService.getcalendar  data", data);
      } catch (e) {
        //Response status was OK, but we got an error getting the json data
        console.error('aribaService.getAribaApprovals  error', e);
        return { error: 'There was a problem with the JSON data.' };
      }
    } else {
      console.error('aribaService.getAribaApprovals  error calling API', response.statusText);
      return {
        error: 'There was a problem retrieving the ARIBA approvals data.',
      };
    }
  } catch (E) {
    //There was an error calling the API
    console.error('aribaService.getAribaApprovals  error', E);
    return {
      error: 'There was a problem retrieving the ARIBA approvals data.',
    };
  }

  if (data && data.userMessage) {
    //There is an error message in the data that was returned
    console.error('aribaService.getAribaApprovals  error', data.userMessage);
    return { error: data.userMessage };
  }

  if (!data.access_token) {
    console.error('aribaService.getAribaApprovals  error no data.access_token');
    return { error: data.userMessage };
  }
  const aribaAccessToken = data.access_token;
  sessionStorage.setItem('aribaAccessToken', aribaAccessToken);
  let approvalHeaders = new Headers();
  approvalHeaders.append('Content-Type', 'application/x-www-form-urlencoded');
  approvalHeaders.append('Authorization', 'Bearer ' + aribaAccessToken);
  approvalHeaders.append('X-NW-Message-ID', message);
  approvalHeaders.set('client_id', aribaKey);

  let approvalData = null;
  // const params =
  //   "?realm=nationwide-T&passwordAdapter=PasswordAdapter1&documentType=ContractWorkspace&user=" +
  //   encodeURIComponent(shortName + "@nationwide.com");
  let params = '';
  if (environ !== 'DEFAULT') {
    params =
      '?realm=nationwide-T&passwordAdapter=PasswordAdapter1&user=' +
      encodeURIComponent(shortName.toLowerCase() + '@nationwide.com');
  } else {
    params =
      '?realm=nationwide&passwordAdapter=PasswordAdapter1&user=' +
      encodeURIComponent(shortName.toLowerCase() + '@nationwide.com');
  }

  // const params =
  //   "?realm=nationwide-T&passwordAdapter=PasswordAdapter1&user=" +
  //   encodeURIComponent("paolon@nationwide.com");
  // console.log(
  //   "aribaService.getAribaApprovals  URL",
  //   aribaApprovalAPI + params
  // );
  try {
    const response = await fetch(aribaApprovalAPI + params, {
      method: 'get',
      headers: approvalHeaders,
    });
    if (response.ok) {
      try {
        approvalData = await response.json();
        // console.log("calendarService.getcalendar  data", data);
      } catch (e) {
        //Response status was OK, but we got an error getting the json data
        console.error('aribaService.getAribaApprovals  error', e);
        return { error: 'There was a problem with the JSON data.' };
      }
    } else {
      return {
        error: 'There was a problem retrieving the ARIBA approvals data.',
      };
    }
  } catch (E) {
    //There was an error calling the API
    console.error('aribaService.getAribaApprovals  error', E);
    return {
      error: 'There was a problem retrieving the ARIBA approvals data.',
    };
  }

  if (approvalData && approvalData.userMessage) {
    //There is an error message in the data that was returned
    return { error: approvalData.userMessage };
  }
  // console.log("aribaService.getAribaApprovals  approvalData", approvalData);
  let approvals = [];
  if (approvalData && approvalData.length > 0) {
    //data was retrieved and has approval entries
    for (let x = 0; x < approvalData.length; x++) {
      //loop through the approvals
      const entry = approvalData[x];
      // console.log("aribaService.getAribaApprovals  entry", x, entry);
      let url = entry.fullURL;
      if (url.indexOf('https://') < 0) {
        // console.log("serviceNow.getApprovals 1 url", url);
        url = url.substring(7);
        // console.log("serviceNow.getApprovals 2 url", url);
        url = 'https://' + url;
        // console.log("serviceNow.getApprovals 3 url", url);
      }
      approvals[x] = {
        id: entry.uniqueName,
        actionType: 'Ariba',
        title: entry.description,
        url: url,
      };
    }
  }
  // console.log("aribaService.getAribaApprovals  approvals", approvals);

  // //DEBUG START
  // approvals[0] = {
  //   id: 'ariba1',
  //   actionType: 'Ariba',
  //   title: 'Look a fake Ariba Contract',
  //   url: 'https:www.google.com',
  // };
  // //DEBUG END
  const error = null;
  return {
    entries: approvals,
    error,
  };
};

// function searchBody(params) {
//   let out = "";
//   for (let [key, value] of params) {
//     if (out !== "") {
//       out += "&";
//     }
//     out += encodeKeyValuePair(key, value);
//   }
//   return out;
// }

function encodeKeyValuePair(key, value) {
  return `${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
}
