import config from '../config.json';
import { getRandomString } from './AuthService';
import { getEnvironment } from './EnvironmentVariable';
import { isMobile } from 'react-device-detect';
import testers from '../testers.json';
import { getHolidays } from './holidayService';

const getDisplayDate = someDate => {
  const monthName = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];
  try {
    const dtAry = someDate.split('-');
    let dt = new Date();
    dt.setMonth(parseInt(dtAry[1]) - 1);
    let mon = parseInt(dtAry[1]) - 1;
    dt.setDate(parseInt(dtAry[2]));
    dt.setFullYear(parseInt(dtAry[0]));
    // dt.setDate(dt.getDate() + 1);
    const displayDate = monthName[mon] + ' ' + dt.getDate() + ', ' + dt.getFullYear();
    return displayDate;
  } catch (e) {
    return '';
  }
};

export const getCalendar = async calendarRange => {
  const environ = getEnvironment();

  let calendarAPI = config.calendarAPI;
  let calendarKey = config.calendarKey;
  if (environ !== 'DEFAULT') {
    //We are not in production environment
    if (isMobile) {
      //However we are testing on a mobile device so use the Mobile api
      calendarAPI = config.Mobile_test_calendarAPI;
      calendarKey = config.test_calendarKey;
    } else {
      //We are not testing on a mobile device, so we will use the test API
      calendarAPI = config.test_calendarAPI;
      calendarKey = config.test_calendarKey;
    }
  }

  // THIS WILL ALL DATE RANGE TO BE SENT TO API.
  let endDate = new Date();
  const DAYS = calendarRange * 7;
  // const DAYS = 2 * 7;
  endDate.setDate(endDate.getDate() + DAYS);
  const monthNum = endDate.getMonth() + 1;
  const MONTH = monthNum < 10 ? '0' + monthNum : '' + monthNum;
  const DAY = endDate.getDate() < 10 ? '0' + endDate.getDate() : '' + endDate.getDate();
  const toDate = endDate.getFullYear() + '-' + MONTH + '-' + DAY;

  const userInfo = JSON.parse(sessionStorage.getItem('userinfo'));
  if (!userInfo || !userInfo.shortName) {
    return { error: 'No user information found.' };
  }
  let shortName = userInfo.shortName
    ? userInfo.shortName.toUpperCase()
    : userInfo.username.toUpperCase();

  const testUsers = testers.users;
  for (let x = 0; x < testUsers.length; x++) {
    const tester = testUsers[x];
    if (tester.shortName.toUpperCase() === shortName.toUpperCase()) {
      //User shortname matches to a tester
      if (environ === 'DEFAULT') {
        //This is the production environment
        if (tester.prodAllowed) {
          //This tester is allowed to mimic in production
          shortName = tester.mimicManager.toUpperCase();
        }
      } else {
        //This is not a production environment
        shortName = tester.mimicManager.toUpperCase();
      }
      break;
    }
  }

  const message = getRandomString(25);
  const token = sessionStorage.getItem('access_token');
  let headers = new Headers();
  headers.append('Content-Type', 'application/json');
  headers.append('Authorization', 'Bearer ' + token);

  headers.append('X-NW-Message-ID', message);
  headers.set('client_id', calendarKey);
  // console.log("calendarService.getcalendar client_id headers", calendarKey);
  // console.log("calendarService.getcalendar  calendarAPI", calendarAPI);
  const body = {
    shortName: shortName,
    toDate: toDate,
  };
  let data = null;
  try {
    const response = await fetch(calendarAPI, {
      method: 'post',
      headers: headers,
      body: JSON.stringify(body),
    });
    if (response.ok) {
      try {
        data = await response.json();
        // console.log("calendarService.getcalendar  data", data);
      } catch (e) {
        //Response status was OK, but we got an error getting the json data
        console.error('calendarService.getcalendar  error', e);
        return { error: 'There was a problem with the JSON data.' };
      }
    } else {
      console.error('calendarService.getcalendar  error', response.statusText);
      return { error: 'There was a problem retrieving the calendar data.' };
    }
  } catch (E) {
    //There was an error calling the API
    console.error('calendarService.getcalendar  error', E);
    return { error: 'There was a problem retrieving the calendar data.' };
  }

  let rawData = [];

  if (data && data.userMessage) {
    //There is an error message in the data that was returned
    return { error: data.userMessage };
  }

  if (data && data.Employee && data.Employee.length > 0) {
    //data was retrieved and has Employee entries
    let num = 0;
    for (let x = 0; x < data.Employee.length; x++) {
      //loop through the employees
      const entry = data.Employee[x];
      if (entry.TimeOff) {
        //There are TimeOff entries for the employee
        const entryDates = entry.TimeOff;
        // console.log("calendarService.getCalendar entryDates", entryDates);
        if (Array.isArray(entryDates)) {
          for (let e = 0; e < entryDates.length; e++) {
            //Loop through the timeoff entries and create a rawData entry for each date
            rawData[num++] = {
              type: 'Time Off',
              date: entryDates[e],
              name: entry.Name,
            };
          }
        } else {
          rawData[num++] = {
            type: 'Time Off',
            date: entryDates,
            name: entry.Name,
          };
        }
      }
      if (entry.Birthday) {
        //There is a Birthday entry for the employee, create a rawData entry for the date
        rawData[num++] = {
          type: 'Birthday',
          date: entry.Birthday,
          name: entry.Name,
        };
      }
      if (entry.Anniversary) {
        //There is an Anniversary entry for the employee, create a rawData entry for the date
        let serviceYears = null;
        if (entry && entry.ServiceYears) {
          serviceYears =
            entry.ServiceYears > 1 ? `${entry.ServiceYears} years of service` : '1 year of service';
        }

        rawData[num++] = {
          type: 'Anniversary',
          date: entry.Anniversary,
          name: entry.Name,
          serviceYears,
        };
      }
    }
  }

  // console.log("calendarService.getCalendar unsorted result", rawData);
  //Sort by name and then date
  rawData.sort(function sortByNameDate(a, b) {
    return a.date === b.date ? (a.name > b.name ? 1 : -1) : a.date > b.date ? 1 : -1;
  });
  // console.log("calendarService.getCalendar sorted result", rawData);
  let timeoff = [];
  let t = 0;
  let holiday = getHolidays(DAYS);
  let birthday = [];
  let b = 0;
  let anniversary = [];
  let a = 0;
  let lastTimeOffDate = '1900-01-01';
  for (let i = 0; i < rawData.length; i++) {
    // console.log("calendarService rawData[i]", i, rawData[i]);
    //Loop through the rawData
    if (rawData[i].type === 'Time Off') {
      //This is a time off entry
      if (lastTimeOffDate === rawData[i].date) {
        //This is the same date as the last timeoff date,
        //so create a timeoff array entry without a display date value
        timeoff[t++] = {
          type: 'Time Off',
          date: rawData[i].date,
          displayDate: '',
          name: rawData[i].name,
        };
        // console.log("calendarService timeoff[t]", t, timeoff[t]);
      } else {
        //This is a different date from the last timeoff date.
        //so create a timeoff array entry with a display date value
        const displayDate = getDisplayDate(rawData[i].date);

        timeoff[t++] = {
          type: 'Time Off',
          date: rawData[i].date,
          displayDate,
          name: rawData[i].name,
        };
        // console.log("calendarService timeoff[t]", t, timeoff[t]);
        lastTimeOffDate = rawData[i].date;
      }
    }
    if (rawData[i].type === 'Birthday') {
      //This is a Birthday entry so create a new birthday array entry
      const displayDate = getDisplayDate(rawData[i].date);

      birthday[b++] = { displayDate, ...rawData[i] };
    }
    if (rawData[i].type === 'Anniversary') {
      //This is a Birthday entry so create a new anniversary array entry
      const displayDate = getDisplayDate(rawData[i].date);
      anniversary[a++] = { displayDate, ...rawData[i] };
    }
  }
  const result = {
    error: '',
    timeoff,
    holiday,
    birthday,
    anniversary,
  };
  return result;
};
