import React, { Component } from 'react';
import 'react-toastify/dist/ReactToastify.css';

class EffectiveLdAdmin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      StrongerOn: false,
      RetentionOn: false,
      oneOn: false,
      OnboardOn: false,
      otherLdOn: false,
      changeOn: false,
      inputUpdates: [],
      addNewOnNews: false,
      addNewRet: false,
      addNewOne: false,
      addNewOnboard: false,
      addOtherLd: false,
      addChange: false,
      addStronger: false,
      // New entry display
      newsEntry_Display: '',
      newsEntry_Count: 50,
      strongerEntry_Display: '',
      strongerEntry_Count: 52,
      retentionEntry_Display: '',
      retentionEntry_Count: 52,
      oneEntry_Display: '',
      oneEntry_Count: 52,
      onboardEntry_Display: '',
      onboardEntry_Count: 52,
      otherEntry_Display: '',
      otherEntry_Count: 52,
      changeEntry_Display: '',
      changeEntry_Count: 52,
      // New entry url
      newsEntry_url: '',
      strongerEntry_url: '',
      retentionEntry_url: '',
      oneEntry_url: '',
      onboardEntry_url: '',
      otherEntry_url: '',
      changeEntry_url: '',
      existingUpdates: [],
      validationError: false,
      // New Entry startDate
      newsEntry_startDate: '',
      strongerEntry_startDate: '',
      retentionEntry_startDate: '',
      oneEntry_startDate: '',
      onboardEntry_startDate: '',
      otherEntry_startDate: '',
      changeEntry_startDate: '',
      // New Entry endDate
      newsEntry_endDate: '',
      strongerEntry_endDate: '',
      retentionEntry_endDate: '',
      oneEntry_endDate: '',
      onboardEntry_endDate: '',
      otherEntry_endDate: '',
      changeEntry_endDate: '',
    };

    this.deleteEntry = this.deleteEntry.bind(this);
    this.handleUpdateEntry = this.handleUpdateEntry.bind(this);
    this.newsEntryUpdate_Display = this.newsEntryUpdate_Display.bind(this);
    this.newsEntryUpdate_url = this.newsEntryUpdate_url.bind(this);
    this.strongerEntryUpdate_Display = this.strongerEntryUpdate_Display.bind(this);
    this.strongerEntryUpdate_url = this.strongerEntryUpdate_url.bind(this);
    this.retentionEntryUpdate_Display = this.retentionEntryUpdate_Display.bind(this);
    this.retentionEntryUpdate_url = this.retentionEntryUpdate_url.bind(this);
    this.oneEntryUpdate_Display = this.oneEntryUpdate_Display.bind(this);
    this.oneEntryUpdate_url = this.oneEntryUpdate_url.bind(this);
    this.onboardEntryUpdate_Display = this.onboardEntryUpdate_Display.bind(this);
    this.onboardEntryUpdate_url = this.onboardEntryUpdate_url.bind(this);
    this.otherEntryUpdate_Display = this.otherEntryUpdate_Display.bind(this);
    this.otherEntryUpdate_url = this.otherEntryUpdate_url.bind(this);
    this.changeEntryUpdate_Display = this.changeEntryUpdate_Display.bind(this);
    this.changeEntryUpdate_url = this.changeEntryUpdate_url.bind(this);
    this.handleUpdateEntry = this.handleUpdateEntry.bind(this);
    this.handleUpdateEntryBottom = this.handleUpdateEntryBottom.bind(this);
    this.handleUpdateEntryUrl = this.handleUpdateEntryUrl.bind(this);
    this.newsEntryUpdate_startDate = this.newsEntryUpdate_startDate.bind(this);
    this.newsEntryUpdate_endDate = this.newsEntryUpdate_endDate.bind(this);
    this.strongerEntryUpdate_startDate = this.strongerEntryUpdate_startDate.bind(this);
    this.strongerEntryUpdate_endDate = this.strongerEntryUpdate_endDate.bind(this);
    this.retentionEntryUpdate_startDate = this.retentionEntryUpdate_startDate.bind(this);
    this.retentionEntryUpdate_endDate = this.retentionEntryUpdate_endDate.bind(this);
    this.oneEntryUpdate_startDate = this.oneEntryUpdate_startDate.bind(this);
    this.oneEntryUpdate_endDate = this.oneEntryUpdate_endDate.bind(this);
    this.onboardEntryUpdate_startDate = this.onboardEntryUpdate_startDate.bind(this);
    this.onboardEntryUpdate_endDate = this.onboardEntryUpdate_endDate.bind(this);
    this.otherEntryUpdate_startDate = this.otherEntryUpdate_startDate.bind(this);
    this.otherEntryUpdate_endDate = this.otherEntryUpdate_endDate.bind(this);
    this.changeEntryUpdate_startDate = this.changeEntryUpdate_startDate.bind(this);
    this.changeEntryUpdate_endDate = this.changeEntryUpdate_endDate.bind(this);
    this.handleUpdateEntryDates = this.handleUpdateEntryDates.bind(this);
  }

  changeEntryUpdate_Display(event) {
    let curr = event.target.value,
      remainingChars = 52 - curr.length;
    if (remainingChars > -1) {
      this.setState({ changeEntry_Display: event.target.value });
      this.setState({ changeEntry_Count: remainingChars });
    }
  }

  changeEntryUpdate_url(event) {
    let curr = event.target.value;
    this.setState({ changeEntry_url: event.target.value });
  }

  otherEntryUpdate_Display(event) {
    let curr = event.target.value,
      remainingChars = 52 - curr.length;
    if (remainingChars > -1) {
      this.setState({ otherEntry_Display: event.target.value });
      this.setState({ otherEntry_Count: remainingChars });
    }
  }

  otherEntryUpdate_url(event) {
    let curr = event.target.value;
    this.setState({ otherEntry_url: event.target.value });
  }

  onboardEntryUpdate_Display(event) {
    let curr = event.target.value,
      remainingChars = 52 - curr.length;
    if (remainingChars > -1) {
      this.setState({ onboardEntry_Display: event.target.value });
      this.setState({ onboardEntry_Count: remainingChars });
    }
  }

  onboardEntryUpdate_url(event) {
    let curr = event.target.value;
    this.setState({ onboardEntry_url: event.target.value });
  }

  oneEntryUpdate_Display(event) {
    let curr = event.target.value,
      remainingChars = 52 - curr.length;
    if (remainingChars > -1) {
      this.setState({ oneEntry_Display: event.target.value });
      this.setState({ oneEntry_Count: remainingChars });
    }
  }

  oneEntryUpdate_url(event) {
    let curr = event.target.value;
    this.setState({ oneEntry_url: event.target.value });
  }

  newsEntryUpdate_Display(event) {
    let curr = event.target.value,
      remainingChars = 50 - curr.length;
    if (remainingChars > -1) {
      this.setState({ newsEntry_Display: event.target.value });
      this.setState({ newsEntry_Count: remainingChars });
    }
  }

  newsEntryUpdate_startDate(event) {
    let curr = event.target.value;
    this.setState({ newsEntry_startDate: event.target.value });
  }

  newsEntryUpdate_endDate(event) {
    let curr = event.target.value;
    this.setState({ newsEntry_endDate: event.target.value });
  }

  strongerEntryUpdate_startDate(event) {
    let curr = event.target.value;
    this.setState({ strongerEntry_startDate: event.target.value });
  }

  strongerEntryUpdate_endDate(event) {
    let curr = event.target.value;
    this.setState({ strongerEntry_endDate: event.target.value });
  }

  retentionEntryUpdate_startDate(event) {
    let curr = event.target.value;
    this.setState({ retentionEntry_startDate: event.target.value });
  }

  retentionEntryUpdate_endDate(event) {
    let curr = event.target.value;
    this.setState({ retentionEntry_endDate: event.target.value });
  }

  oneEntryUpdate_startDate(event) {
    let curr = event.target.value;
    this.setState({ oneEntry_startDate: event.target.value });
  }

  oneEntryUpdate_endDate(event) {
    let curr = event.target.value;
    this.setState({ oneEntry_endDate: event.target.value });
  }

  onboardEntryUpdate_startDate(event) {
    let curr = event.target.value;
    this.setState({ onboardEntry_startDate: event.target.value });
  }

  onboardEntryUpdate_endDate(event) {
    let curr = event.target.value;
    this.setState({ onboardEntry_endDate: event.target.value });
  }

  otherEntryUpdate_startDate(event) {
    let curr = event.target.value;
    this.setState({ otherEntry_startDate: event.target.value });
  }

  otherEntryUpdate_endDate(event) {
    let curr = event.target.value;
    this.setState({ otherEntry_endDate: event.target.value });
  }

  changeEntryUpdate_startDate(event) {
    let curr = event.target.value;
    this.setState({ changeEntry_startDate: event.target.value });
  }

  changeEntryUpdate_endDate(event) {
    let curr = event.target.value;
    this.setState({ changeEntry_endDate: event.target.value });
  }

  newsEntryUpdate_url(event) {
    let curr = event.target.value;
    this.setState({ newsEntry_url: event.target.value });
  }

  strongerEntryUpdate_Display(event) {
    let curr = event.target.value,
      remainingChars = 52 - curr.length;
    if (remainingChars > -1) {
      this.setState({ strongerEntry_Display: event.target.value });
      this.setState({ strongerEntry_Count: remainingChars });
    }
  }

  strongerEntryUpdate_url(event) {
    let curr = event.target.value;
    this.setState({ strongerEntry_url: event.target.value });
  }

  retentionEntryUpdate_Display(event) {
    let curr = event.target.value,
      remainingChars = 52 - curr.length;
    if (remainingChars > -1) {
      this.setState({ retentionEntry_Display: event.target.value });
      this.setState({ retentionEntry_Count: remainingChars });
    }
  }

  retentionEntryUpdate_url(event) {
    let curr = event.target.value;
    this.setState({ retentionEntry_url: event.target.value });
  }

  handleStrongerClick = () => {
    // console.log("calendar.handleTimeOffClick");
    let StrongerOn = !this.state.StrongerOn;
    this.setState({ StrongerOn });
  };

  handleRetentionClick = () => {
    // console.log("calendar.handleTimeOffClick");
    let RetentionOn = !this.state.RetentionOn;
    this.setState({ RetentionOn });
  };

  handleOneOnClick = () => {
    // console.log("calendar.handleTimeOffClick");
    let oneOn = !this.state.oneOn;
    this.setState({ oneOn });
  };

  handleOnboardClick = () => {
    // console.log("calendar.handleTimeOffClick");
    let OnboardOn = !this.state.OnboardOn;
    this.setState({ OnboardOn });
  };

  handleOtherLdClick = () => {
    // console.log("calendar.handleTimeOffClick");
    let otherLdOn = !this.state.otherLdOn;
    this.setState({ otherLdOn });
  };

  handleChangeClick = () => {
    // console.log("calendar.handleTimeOffClick");
    let changeOn = !this.state.changeOn;
    this.setState({ changeOn });
  };

  handleNewLinkNewsClick = () => {
    // console.log("calendar.handleTimeOffClick");
    let addNewOnNews = !this.state.addNewOnNews;
    this.setState({ addNewOnNews });
  };

  handleNewLinkRetClick = () => {
    // console.log("calendar.handleTimeOffClick");
    let addNewRet = !this.state.addNewRet;
    this.setState({ addNewRet });
  };

  handleNewLinkOneClick = () => {
    // console.log("calendar.handleTimeOffClick");
    let addNewOne = !this.state.addNewOne;
    this.setState({ addNewOne });
  };

  handleNewLinkOnboardClick = () => {
    // console.log("calendar.handleTimeOffClick");
    let addNewOnboard = !this.state.addNewOnboard;
    this.setState({ addNewOnboard });
  };

  handleNewLinkOtherClick = () => {
    // console.log("calendar.handleTimeOffClick");
    let addOtherLd = !this.state.addOtherLd;
    this.setState({ addOtherLd });
  };

  handleNewLinkChangeClick = () => {
    // console.log("calendar.handleTimeOffClick");
    let addChange = !this.state.addChange;
    this.setState({ addChange });
  };

  handleNewLinkStrongClick = () => {
    // console.log("calendar.handleTimeOffClick");
    let addStronger = !this.state.addStronger;
    this.setState({ addStronger });
  };

  // Deletes an entry from the current state
  deleteEntry = (id, category, display, url) => {
    const { deleteLeaderLinks } = this.props;
    const { inputUpdates } = this.state;
    let currentUpdates = inputUpdates;
    let action = 'DELETE';
    currentUpdates.push({ action, id, category, display, url });
    console.log('Delete entry ', currentUpdates);
    deleteLeaderLinks(id, category, display, url);
    this.setState({ inputUpdates: currentUpdates });
  };

  newEntries() {
    const {
      inputUpdates,
      newsEntry_url,
      newsEntry_Display,
      strongerEntry_url,
      strongerEntry_Display,
      retentionEntry_url,
      retentionEntry_Display,
      oneEntry_url,
      oneEntry_Display,
      onboardEntry_url,
      onboardEntry_Display,
      otherEntry_url,
      otherEntry_Display,
      changeEntry_url,
      changeEntry_Display,
      validationError,
    } = this.state;
    let currentUpdates = inputUpdates;
    let action = 'ADD';
    if (newsEntry_url.length > 0 && newsEntry_Display.length > 0) {
      let category = 'news',
        id = 'NA';
      // let urlHttp = newsEntry_url.substring(0,7) == 'https://' ? newsEntry_Display :
      // console.log('URL TEST', newsEntry_url.substring(0, 2));
      if (newsEntry_url.substring(0, 2) == 'ht') {
        currentUpdates.push({
          action,
          id,
          category,
          display: newsEntry_Display,
          url: newsEntry_url,
          startDate: this.state.newsEntry_startDate,
          endDate: this.state.newsEntry_endDate,
        });
      } else {
        alert('Invalid URL: ' + newsEntry_url + ' entry not added.');
      }
    } else if (
      (!newsEntry_url.length > 0 && newsEntry_Display.length > 0) ||
      (newsEntry_url.length > 0 && !newsEntry_Display.length > 0)
    ) {
      alert('You must have a Display and URL on new entries!');
      let validationError = !this.state.validationError;
      this.setState({ validationError });
      console.log('Validation switch ', this.state.validationError);
    }
    if (strongerEntry_url.length > 0 && strongerEntry_Display.length > 0) {
      let category = 'strongerTeam',
        id = 'NA';
      this.setState({ validationError: false });
      if (strongerEntry_url.substring(0, 2) == 'ht') {
        currentUpdates.push({
          action,
          id,
          category,
          display: strongerEntry_Display,
          url: strongerEntry_url,
          startDate: this.state.strongerEntry_startDate,
          endDate: this.state.strongerEntry_endDate,
        });
      } else {
        alert('Invalid URL: ' + strongerEntry_url + ' entry not added.');
      }
    } else if (
      (!strongerEntry_url.length > 0 && strongerEntry_Display.length > 0) ||
      (strongerEntry_url.length > 0 && !strongerEntry_Display.length > 0)
    ) {
      this.setState({ validationError: true });
      alert('You must have a Display and URL on new entries!');
    }
    if (retentionEntry_url.length > 0 && retentionEntry_Display.length > 0) {
      let category = 'LeaderRetention',
        id = 'NA';
      if (retentionEntry_url.substring(0, 2) == 'ht') {
        this.setState({ validationError: false });
        currentUpdates.push({
          action,
          id,
          category,
          display: retentionEntry_Display,
          url: retentionEntry_url,
          startDate: this.state.retentionEntry_startDate,
          endDate: this.state.retentionEntry_endDate,
        });
      } else {
        alert('Invalid URL: ' + retentionEntry_url + ' entry not added.');
      }
    } else if (
      (!retentionEntry_url.length > 0 && retentionEntry_Display.length > 0) ||
      (retentionEntry_url.length > 0 && !retentionEntry_Display.length > 0)
    ) {
      this.setState({ validationError: true });
      alert('You must have a Display and URL on new entries!');
    }
    if (oneEntry_url.length > 0 && oneEntry_Display.length > 0) {
      let category = 'Better1on1s',
        id = 'NA';
      this.setState({ validationError: false });
      if (oneEntry_url.substring(0, 2) == 'ht') {
        currentUpdates.push({
          action,
          id,
          category,
          display: oneEntry_Display,
          url: oneEntry_url,
          startDate: this.state.oneEntry_startDate,
          endDate: this.state.oneEntry_endDate,
        });
      } else {
        alert('Invalid URL: ' + oneEntry_url + ' entry not added.');
      }
    } else if (
      (!oneEntry_url.length > 0 && oneEntry_Display.length > 0) ||
      (oneEntry_url.length > 0 && !oneEntry_Display.length > 0)
    ) {
      this.setState({ validationError: true });
      alert('You must have a Display and URL on new entries!');
    }
    if (onboardEntry_url.length > 0 && onboardEntry_Display.length > 0) {
      let category = 'Onboard',
        id = 'NA';
      this.setState({ validationError: false });
      if (onboardEntry_url.substring(0, 2) == 'ht') {
        currentUpdates.push({
          action,
          id,
          category,
          display: onboardEntry_Display,
          url: onboardEntry_url,
          startDate: this.state.onboardEntry_startDate,
          endDate: this.state.onboardEntry_endDate,
        });
      } else {
        alert('Invalid URL: ' + onboardEntry_url + ' entry not added.');
      }
    } else if (
      (!onboardEntry_url.length > 0 && onboardEntry_Display.length > 0) ||
      (onboardEntry_url.length > 0 && !onboardEntry_Display.length > 0)
    ) {
      this.setState({ validationError: true });
      alert('You must have a Display and URL on new entries!');
    }
    if (otherEntry_url.length > 0 && otherEntry_Display.length > 0) {
      let category = 'OtherLeaders',
        id = 'NA';
      this.setState({ validationError: false });
      if (otherEntry_url.substring(0, 2) == 'ht') {
        currentUpdates.push({
          action,
          id,
          category,
          display: otherEntry_Display,
          url: otherEntry_url,
          startDate: this.state.otherEntry_startDate,
          endDate: this.state.otherEntry_endDate,
        });
      } else {
        alert('Invalid URL: ' + otherEntry_url + ' entry not added.');
      }
    } else if (
      (!otherEntry_url.length > 0 && otherEntry_Display.length > 0) ||
      (otherEntry_url.length > 0 && !otherEntry_Display.length > 0)
    ) {
      this.setState({ validationError: true });
      alert('You must have a Display and URL on new entries!');
    }
    if (changeEntry_url.length > 0 && changeEntry_Display.length > 0) {
      let category = 'Change',
        id = 'NA';
      this.setState({ validationError: false });
      if (changeEntry_url.substring(0, 2) == 'ht') {
        currentUpdates.push({
          action,
          id,
          category,
          display: changeEntry_Display,
          url: changeEntry_url,
          startDate: this.state.changeEntry_startDate,
          endDate: this.state.changeEntry_endDate,
        });
      } else {
        alert('Invalid URL: ' + changeEntry_url + ' entry not added.');
      }
    } else if (
      (!changeEntry_url.length > 0 && changeEntry_Display.length > 0) ||
      (changeEntry_url.length > 0 && !changeEntry_Display.length > 0)
    ) {
      this.setState({ validationError: true });
      alert('You must have a Display and URL on new entries!');
    }
    this.setState({ inputUpdates: currentUpdates });
    console.log('NewEntries', inputUpdates);
  }

  // Updates existing entries
  handleUpdateEntry(event) {
    const target = event.target;
    const value = target.value;
    const id = target.id;
    if (value.length < 51) {
      this.setState({
        [id]: value,
      });
    }
    // console.log('Value length ', value.length);
  }

  // Updates existing entries
  handleUpdateEntryBottom(event) {
    const target = event.target;
    const value = target.value;
    const id = target.id;
    if (value.length < 53) {
      this.setState({
        [id]: value,
      });
    }
    // console.log('Value length ', value.length);
  }

  handleUpdateEntryUrl(event) {
    const target = event.target;
    const value = target.value;
    const id = target.id;

    this.setState({
      [id]: value,
    });

    // console.log('Value length ', value.length);
  }

  handleUpdateEntryDates(event) {
    const target = event.target;
    const value = target.value;
    const id = target.id;
    this.setState({
      [id]: value,
    });
  }

  async handleUpdates(event) {}

  // Collect updates from state
  updates() {
    const { data } = this.props;
    let output = [];
    for (let i = 0; i < data.idList.length; i++) {
      let id_display = data.idList[i] + '_Display';
      let kind = 'Display';
      if (this.state[id_display]) {
        output.push({ id: data.idList[i], Update: this.state[id_display], Type: kind });
      }
      let id_url = data.idList[i] + '_URL';
      kind = 'URL';
      if (this.state[id_url]) {
        output.push({ id: data.idList[i], Update: this.state[id_url], Type: kind });
      }
      let id_startDate = data.idList[i] + '_startDate';
      kind = 'startDate';
      if (this.state[id_startDate]) {
        console.log('Start date change detected', {
          id: data.idList[i],
          Update: this.state[id_startDate],
          Type: kind,
        });
        output.push({ id: data.idList[i], Update: this.state[id_startDate], Type: kind });
      }
      let id_endDate = data.idList[i] + '_endDate';
      kind = 'endDate';
      if (this.state[id_endDate]) {
        output.push({ id: data.idList[i], Update: this.state[id_endDate], Type: kind });
      }
    }
    console.log('Updates allocation ', output);
    return output;
  }

  resetStates() {
    this.setState({
      StrongerOn: false,
      RetentionOn: false,
      oneOn: false,
      OnboardOn: false,
      otherLdOn: false,
      changeOn: false,
      inputUpdates: [],
      addNewOnNews: false,
      addNewRet: false,
      addNewOne: false,
      addNewOnboard: false,
      addOtherLd: false,
      addChange: false,
      addStronger: false,
      // New entry display
      newsEntry_Display: '',
      newsEntry_Count: 50,
      strongerEntry_Display: '',
      strongerEntry_Count: 52,
      retentionEntry_Display: '',
      retentionEntry_Count: 52,
      oneEntry_Display: '',
      oneEntry_Count: 52,
      onboardEntry_Display: '',
      onboardEntry_Count: 52,
      otherEntry_Display: '',
      otherEntry_Count: 52,
      changeEntry_Display: '',
      changeEntry_Count: 52,
      // New entry url
      newsEntry_url: '',
      strongerEntry_url: '',
      retentionEntry_url: '',
      oneEntry_url: '',
      onboardEntry_url: '',
      otherEntry_url: '',
      changeEntry_url: '',
      existingUpdates: [],
      validationError: false,
      // New Entry startDate
      newsEntry_startDate: '',
      strongerEntry_startDate: '',
      retentionEntry_startDate: '',
      oneEntry_startDate: '',
      onboardEntry_startDate: '',
      otherEntry_startDate: '',
      changeEntry_startDate: '',
      // New Entry endDate
      newsEntry_endDate: '',
      strongerEntry_endDate: '',
      retentionEntry_endDate: '',
      oneEntry_endDate: '',
      onboardEntry_endDate: '',
      otherEntry_endDate: '',
      changeEntry_endDate: '',
    });
  }

  mergeUpdates(updates) {
    const { data } = this.props;
    let output = [];
    for (let i = 0; i < data.data.length; i++) {
      let updateID = data.data[i].id,
        category = data.data[i].category,
        display = data.data[i].display,
        url = data.data[i].url,
        startDate = '',
        endDate = '',
        flag = false;
      if (data.data[i].startDate) startDate = data.data[i].startDate;
      if (data.data[i].endDate) endDate = data.data[i].endDate;
      for (let x = 0; x < updates.length; x++) {
        let type = updates[x].Type;
        if (updateID === updates[x].id) {
          if (type === 'Display') display = updates[x].Update;
          if (type === 'URL') url = updates[x].Update;
          if (type === 'startDate') startDate = updates[x].Update + 'T00:00:00';
          if (type === 'endDate') endDate = updates[x].Update + 'T00:00:00';
          flag = true;
        }
      }
      if (flag) {
        if (startDate != '' && endDate != '') {
          output.push({
            id: updateID,
            category,
            display: display,
            url: url,
            startDate: startDate,
            endDate: endDate,
          });
        } else {
          output.push({
            id: updateID,
            category,
            display: display,
            url: url,
          });
        }
      }
    }
    return output;
  }

  validateDateRange(input) {
    if (input.startDate) {
      if (input.startDate < input.endDate) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  }

  handleUpdatesBackend = async () => {
    let { inputUpdates } = this.state;
    let validDates = true;
    const {
      deleteEffLdLink,
      addEffLdLink,
      updateLeaderLinks,
      showDefault,
      retrieveData,
    } = this.props;
    this.newEntries(); // Allocates new entries to inputUpdates list
    let updates = this.mergeUpdates(this.updates());
    console.log('Updates merge', this.mergeUpdates(this.updates()));
    // Test updates and new links
    for (let i = 0; i < updates.length; i++) {
      if (this.validateDateRange(updates[i])) {
        validDates = true;
      } else {
        validDates = false;
        this.setState({ inputUpdates: [] });
        alert('Invalid date range input. Please review database entries and resubmit form.');
        return -1;
      }
    }
    console.log('On update ', inputUpdates);
    for (let i = 0; i < inputUpdates.length; i++) {
      //console.log('On update ', inputUpdates[i]);
      if (inputUpdates[i].action === 'ADD') {
        if (this.validateDateRange(inputUpdates[i])) {
          validDates = true;
        } else {
          validDates = false;
          // Clear input updates
          this.setState({ inputUpdates: [] });
          alert('Invalid date range input. Please review database entries and resubmit form.');
          return -1;
        }
      }
    }

    if (!this.state.validationError && validDates) {
      for (let i = 0; i < updates.length; i++) {
        let res = null;
        res = await updateLeaderLinks(updates[i]);
        validDates = true;
      }
      if (validDates) {
        for (let i = 0; i < inputUpdates.length; i++) {
          console.log('On update ', inputUpdates[i]);
          switch (inputUpdates[i].action) {
            case 'DELETE':
              let res2 = await deleteEffLdLink(inputUpdates[i].id); // Calls API to update database
              break;
            case 'ADD':
              let res3 = await addEffLdLink(
                inputUpdates[i].category,
                inputUpdates[i].display,
                inputUpdates[i].url,
                inputUpdates[i].startDate,
                inputUpdates[i].endDate,
              );
          }
        }

        if (validDates === false) {
          console.log('invalid date inputs');
        } else {
          this.resetStates();
          //retrieveData(true);
          alert(
            'Updates saved. Please click "Ok" then refresh the page to view new data in the tile.',
          );
          showDefault();
          //this.refreshPage();
        }
      }
    }
  };

  returnConent(data) {
    const { retrieveData } = this.props;
    return (
      <div className="container-fluid">
        <div className="row">
          <div className="adminTile">
            <b>Being a More Effective Leader</b>
          </div>
          <div className="adminTile2">{'>'} Edit</div>
        </div>
        <div
          className="noScrollingActions"
          style={{
            color: '#bcbdbc',
            marginBottom: '12px',
          }}
        ></div>
        <div className="adTopHelpText">
          Use these fields to edit the Being a More Effective Leader tile on Leader Connect.
          <br></br>
          <b>
            <u>Note:</u>
          </b>{' '}
          Links without a <b>Start Date</b> and <b>End Date</b> will always be active. Links with
          date range must contain both a <b>Start Date</b> and <b>End Date</b>.
        </div>
        <div
          className="noScrollingActions"
          style={{
            color: '#bcbdbc',
            marginTop: '12px',
            marginBottom: '17px',
          }}
        ></div>
        <div>
          {this.renderNeedToKnow(data)}
          <div
            className="noScrollingActions"
            style={{
              color: '#bcbdbc',
              marginTop: '18px',
              marginBottom: '17px',
            }}
          ></div>
          {this.renderStrongerRegion(data)}
          <div
            className="noScrollingActions"
            style={{
              color: '#bcbdbc',
              marginTop: '18px',
              marginBottom: '17px',
            }}
          ></div>
          {this.renderRetentionRegion(data)}
          <div
            className="noScrollingActions"
            style={{
              color: '#bcbdbc',
              marginTop: '18px',
              marginBottom: '17px',
            }}
          ></div>
          {this.renderOneRegion(data)}
          <div
            className="noScrollingActions"
            style={{
              color: '#bcbdbc',
              marginTop: '18px',
              marginBottom: '17px',
            }}
          ></div>
          {this.renderOnboardRegion(data)}
          <div
            className="noScrollingActions"
            style={{
              color: '#bcbdbc',
              marginTop: '18px',
              marginBottom: '17px',
            }}
          ></div>
          {this.renderOtherLdRegion(data)}
          <div
            className="noScrollingActions"
            style={{
              color: '#bcbdbc',
              marginTop: '18px',
              marginBottom: '17px',
            }}
          ></div>
          {this.renderChangeRegion(data)}
          <div
            className="noScrollingActions"
            style={{
              color: '#bcbdbc',
              marginTop: '18px',
              marginBottom: '17px',
            }}
          ></div>
          <div className="row" style={{ paddingLeft: '75%' }}>
            <div className="cancelButton" onClick={() => this.performCancel()}>
              <div className="cancelButtonText">Cancel</div>
            </div>
            <button className="saveButton">
              <div onClick={() => this.handleUpdatesBackend()} className="saveButtonText">
                {' '}
                Save and Update
              </div>
            </button>
          </div>
          <div style={{ paddingBottom: '10px' }}></div>
        </div>
      </div>
    );
  }

  performCancel = () => {
    this.refreshPage();
  };

  addNewLinkForm(type) {
    if (type.toLowerCase() === 'news') {
      return (
        <div>
          <div>
            <div className="row" style={{ paddingBottom: '15px' }}>
              <div className="col" style={{ maxWidth: '44%' }}>
                <div style={{ display: 'flex', overflow: 'hidden' }}>
                  <div className="infoText">Display Text:</div>
                  <input
                    type="text"
                    id="new"
                    value={this.state.newsEntry_Display}
                    onChange={this.newsEntryUpdate_Display}
                    className="inputBox inputBoxText"
                  />
                </div>
                <div className="remainingCharacters2">
                  Characters remaining: {this.state.newsEntry_Count}
                </div>
                <div className="datebox">
                  <div
                    className={
                      this.isActive(this.state.newsEntry_startDate, this.state.newsEntry_endDate)
                        ? 'activeBox'
                        : 'inactiveBox'
                    }
                  ></div>
                  <div>
                    <div className="infoText" id="New">
                      <b>Start Date</b> (Current: {this.state.newsEntry_startDate}):{' '}
                    </div>
                    <input
                      className="inputBox inputBoxText"
                      id="New"
                      type="date"
                      value={this.state.newsEntry_startDate}
                      onChange={this.newsEntryUpdate_startDate}
                      //placeholder={item.startDate}
                    ></input>
                  </div>
                  <div>
                    <div className="infoText" id="New">
                      <b>End Date</b> (Current: {this.state.newsEntry_endDate}):{' '}
                    </div>
                    <input
                      className="inputBox inputBoxText"
                      id="New"
                      type="date"
                      value={this.state.newsEntry_endDate}
                      onChange={this.newsEntryUpdate_endDate}
                    ></input>
                  </div>
                </div>
              </div>
              <div className="col">
                <div style={{ display: 'flex', overflow: 'hidden' }}>
                  <div className="infoText">Link URL:</div>
                  <input
                    type="text"
                    id="new"
                    value={this.state.newsEntry_url}
                    onChange={this.newsEntryUpdate_url}
                    className="inputBox inputBoxText"
                  />

                  <div
                    onClick={() => this.handleDeleteNew(type)}
                    className="deleteButton deleteButtonText"
                  >
                    <b>Delete</b>
                  </div>
                </div>
                <div className="remainingCharacters3">Paste the exact URL</div>
              </div>
            </div>
            <div className="dottedLine"></div>
          </div>
        </div>
      );
    }
    if (type.toLowerCase() === 'stronger') {
      return (
        <div>
          <div>
            <div className="row" style={{ paddingBottom: '15px' }}>
              <div className="col" style={{ maxWidth: '44%' }}>
                <div style={{ display: 'flex', overflow: 'hidden' }}>
                  <div className="infoText">Display Text:</div>
                  <input
                    type="text"
                    id="new"
                    value={this.state.strongerEntry_Display}
                    onChange={this.strongerEntryUpdate_Display}
                    className="inputBox inputBoxText"
                  />
                </div>
                <div className="remainingCharacters2">
                  Characters remaining: {this.state.strongerEntry_Count}
                </div>
                <div className="datebox">
                  <div
                    className={
                      this.isActive(
                        this.state.strongerEntry_startDate,
                        this.state.strongerEntry_endDate,
                      )
                        ? 'activeBox'
                        : 'inactiveBox'
                    }
                  ></div>
                  <div>
                    <div className="infoText" id="New">
                      <b>Start Date</b> (Current: {this.state.strongerEntry_startDate}):{' '}
                    </div>
                    <input
                      className="inputBox inputBoxText"
                      id="New"
                      type="date"
                      value={this.state.strongerEntry_startDate}
                      onChange={this.strongerEntryUpdate_startDate}
                      //placeholder={item.startDate}
                    ></input>
                  </div>
                  <div>
                    <div className="infoText" id="New">
                      <b>End Date</b> (Current: {this.state.strongerEntry_endDate}):{' '}
                    </div>
                    <input
                      className="inputBox inputBoxText"
                      id="New"
                      type="date"
                      value={this.state.strongerEntry_endDate}
                      onChange={this.strongerEntryUpdate_endDate}
                    ></input>
                  </div>
                </div>
              </div>
              <div className="col">
                <div style={{ display: 'flex', overflow: 'hidden' }}>
                  <div className="infoText">Link URL:</div>
                  <input
                    type="text"
                    id="new"
                    value={this.state.strongerEntry_url}
                    onChange={this.strongerEntryUpdate_url}
                    className="inputBox inputBoxText"
                  />

                  <div
                    onClick={() => this.handleDeleteNew(type)}
                    className="deleteButton deleteButtonText"
                  >
                    <b>Delete</b>
                  </div>
                </div>
                <div className="remainingCharacters3">Paste the exact URL</div>
              </div>
            </div>
            <div className="dottedLine"></div>
          </div>
        </div>
      );
    }
    if (type.toLowerCase() === 'retention') {
      return (
        <div>
          <div>
            <div className="row" style={{ paddingBottom: '15px' }}>
              <div className="col" style={{ maxWidth: '44%' }}>
                <div style={{ display: 'flex', overflow: 'hidden' }}>
                  <div className="infoText">Display Text:</div>
                  <input
                    type="text"
                    id="new"
                    value={this.state.retentionEntry_Display}
                    onChange={this.retentionEntryUpdate_Display}
                    className="inputBox inputBoxText"
                  />
                </div>
                <div className="remainingCharacters2">
                  Characters remaining: {this.state.retentionEntry_Count}
                </div>
                <div className="datebox">
                  <div
                    className={
                      this.isActive(
                        this.state.retentionEntry_startDate,
                        this.state.retentionEntry_endDate,
                      )
                        ? 'activeBox'
                        : 'inactiveBox'
                    }
                  ></div>
                  <div>
                    <div className="infoText" id="New">
                      <b>Start Date</b> (Current: {this.state.retentionEntry_startDate}):{' '}
                    </div>
                    <input
                      className="inputBox inputBoxText"
                      id="New"
                      type="date"
                      value={this.state.retentionEntry_startDate}
                      onChange={this.retentionEntryUpdate_startDate}
                      //placeholder={item.startDate}
                    ></input>
                  </div>
                  <div>
                    <div className="infoText" id="New">
                      <b>End Date</b> (Current: {this.state.retentionEntry_endDate}):{' '}
                    </div>
                    <input
                      className="inputBox inputBoxText"
                      id="New"
                      type="date"
                      value={this.state.retentionEntry_endDate}
                      onChange={this.retentionEntryUpdate_endDate}
                    ></input>
                  </div>
                </div>
              </div>
              <div className="col">
                <div style={{ display: 'flex', overflow: 'hidden' }}>
                  <div className="infoText">Link URL:</div>
                  <input
                    type="text"
                    id="new"
                    value={this.state.retentionEntry_url}
                    onChange={this.retentionEntryUpdate_url}
                    className="inputBox inputBoxText"
                  />

                  <div
                    onClick={() => this.handleDeleteNew(type)}
                    className="deleteButton deleteButtonText"
                  >
                    <b>Delete</b>
                  </div>
                </div>
                <div className="remainingCharacters3">Paste the exact URL</div>
              </div>
            </div>
            <div className="dottedLine"></div>
          </div>
        </div>
      );
    }
    if (type.toLowerCase() === 'one') {
      return (
        <div>
          <div>
            <div className="row" style={{ paddingBottom: '15px' }}>
              <div className="col" style={{ maxWidth: '44%' }}>
                <div style={{ display: 'flex', overflow: 'hidden' }}>
                  <div className="infoText">Display Text:</div>
                  <input
                    type="text"
                    id="new"
                    value={this.state.oneEntry_Display}
                    onChange={this.oneEntryUpdate_Display}
                    className="inputBox inputBoxText"
                  />
                </div>
                <div className="remainingCharacters2">
                  Characters remaining: {this.state.oneEntry_Count}
                </div>
                <div className="datebox">
                  <div
                    className={
                      this.isActive(this.state.oneEntry_startDate, this.state.oneEntry_endDate)
                        ? 'activeBox'
                        : 'inactiveBox'
                    }
                  ></div>
                  <div>
                    <div className="infoText" id="New">
                      <b>Start Date</b> (Current: {this.state.oneEntry_startDate}):{' '}
                    </div>
                    <input
                      className="inputBox inputBoxText"
                      id="New"
                      type="date"
                      value={this.state.oneEntry_startDate}
                      onChange={this.oneEntryUpdate_startDate}
                      //placeholder={item.startDate}
                    ></input>
                  </div>
                  <div>
                    <div className="infoText" id="New">
                      <b>End Date</b> (Current: {this.state.oneEntry_endDate}):{' '}
                    </div>
                    <input
                      className="inputBox inputBoxText"
                      id="New"
                      type="date"
                      value={this.state.oneEntry_endDate}
                      onChange={this.oneEntryUpdate_endDate}
                    ></input>
                  </div>
                </div>
              </div>
              <div className="col">
                <div style={{ display: 'flex', overflow: 'hidden' }}>
                  <div className="infoText">Link URL:</div>
                  <input
                    type="text"
                    id="new"
                    value={this.state.oneEntry_url}
                    onChange={this.oneEntryUpdate_url}
                    className="inputBox inputBoxText"
                  />

                  <div
                    onClick={() => this.handleDeleteNew(type)}
                    className="deleteButton deleteButtonText"
                  >
                    <b>Delete</b>
                  </div>
                </div>
                <div className="remainingCharacters3">Paste the exact URL</div>
              </div>
            </div>
            <div className="dottedLine"></div>
          </div>
        </div>
      );
    }
    if (type.toLowerCase() === 'onboard') {
      return (
        <div>
          <div>
            <div className="row" style={{ paddingBottom: '15px' }}>
              <div className="col" style={{ maxWidth: '44%' }}>
                <div style={{ display: 'flex', overflow: 'hidden' }}>
                  <div className="infoText">Display Text:</div>
                  <input
                    type="text"
                    id="new"
                    value={this.state.onboardEntry_Display}
                    onChange={this.onboardEntryUpdate_Display}
                    className="inputBox inputBoxText"
                  />
                </div>
                <div className="remainingCharacters2">
                  Characters remaining: {this.state.onboardEntry_Count}
                </div>
                <div className="datebox">
                  <div
                    className={
                      this.isActive(
                        this.state.onboardEntry_startDate,
                        this.state.onboardEntry_endDate,
                      )
                        ? 'activeBox'
                        : 'inactiveBox'
                    }
                  ></div>
                  <div>
                    <div className="infoText" id="New">
                      <b>Start Date</b> (Current: {this.state.onboardEntry_startDate}):{' '}
                    </div>
                    <input
                      className="inputBox inputBoxText"
                      id="New"
                      type="date"
                      value={this.state.onboardEntry_startDate}
                      onChange={this.onboardEntryUpdate_startDate}
                      //placeholder={item.startDate}
                    ></input>
                  </div>
                  <div>
                    <div className="infoText" id="New">
                      <b>End Date</b> (Current: {this.state.onboardEntry_endDate}):{' '}
                    </div>
                    <input
                      className="inputBox inputBoxText"
                      id="New"
                      type="date"
                      value={this.state.onboardEntry_endDate}
                      onChange={this.onboardEntryUpdate_endDate}
                    ></input>
                  </div>
                </div>
              </div>
              <div className="col">
                <div style={{ display: 'flex', overflow: 'hidden' }}>
                  <div className="infoText">Link URL:</div>
                  <input
                    type="text"
                    id="new"
                    value={this.state.onboardEntry_url}
                    onChange={this.onboardEntryUpdate_url}
                    className="inputBox inputBoxText"
                  />

                  <div
                    onClick={() => this.handleDeleteNew(type)}
                    className="deleteButton deleteButtonText"
                  >
                    <b>Delete</b>
                  </div>
                </div>
                <div className="remainingCharacters3">Paste the exact URL</div>
              </div>
            </div>
            <div className="dottedLine"></div>
          </div>
        </div>
      );
    }
    if (type.toLowerCase() === 'other') {
      return (
        <div>
          <div>
            <div className="row" style={{ paddingBottom: '15px' }}>
              <div className="col" style={{ maxWidth: '44%' }}>
                <div style={{ display: 'flex', overflow: 'hidden' }}>
                  <div className="infoText">Display Text:</div>
                  <input
                    type="text"
                    id="new"
                    value={this.state.otherEntry_Display}
                    onChange={this.otherEntryUpdate_Display}
                    className="inputBox inputBoxText"
                  />
                </div>
                <div className="remainingCharacters2">
                  Characters remaining: {this.state.otherEntry_Count}
                </div>
                <div className="datebox">
                  <div
                    className={
                      this.isActive(this.state.otherEntry_startDate, this.state.otherEntry_endDate)
                        ? 'activeBox'
                        : 'inactiveBox'
                    }
                  ></div>
                  <div>
                    <div className="infoText" id="New">
                      <b>Start Date</b> (Current: {this.state.otherEntry_startDate}):{' '}
                    </div>
                    <input
                      className="inputBox inputBoxText"
                      id="New"
                      type="date"
                      value={this.state.otherEntry_startDate}
                      onChange={this.otherEntryUpdate_startDate}
                      //placeholder={item.startDate}
                    ></input>
                  </div>
                  <div>
                    <div className="infoText" id="New">
                      <b>End Date</b> (Current: {this.state.otherEntry_endDate}):{' '}
                    </div>
                    <input
                      className="inputBox inputBoxText"
                      id="New"
                      type="date"
                      value={this.state.otherEntry_endDate}
                      onChange={this.otherEntryUpdate_endDate}
                    ></input>
                  </div>
                </div>
              </div>
              <div className="col">
                <div style={{ display: 'flex', overflow: 'hidden' }}>
                  <div className="infoText">Link URL:</div>
                  <input
                    type="text"
                    id="new"
                    value={this.state.otherEntry_url}
                    onChange={this.otherEntryUpdate_url}
                    className="inputBox inputBoxText"
                  />

                  <div
                    onClick={() => this.handleDeleteNew(type)}
                    className="deleteButton deleteButtonText"
                  >
                    <b>Delete</b>
                  </div>
                </div>
                <div className="remainingCharacters3">Paste the exact URL</div>
              </div>
            </div>
            <div className="dottedLine"></div>
          </div>
        </div>
      );
    }
    if (type.toLowerCase() === 'change') {
      return (
        <div>
          <div>
            <div className="row" style={{ paddingBottom: '15px' }}>
              <div className="col" style={{ maxWidth: '44%' }}>
                <div style={{ display: 'flex', overflow: 'hidden' }}>
                  <div className="infoText">Display Text:</div>
                  <input
                    type="text"
                    id="new"
                    value={this.state.changeEntry_Display}
                    onChange={this.changeEntryUpdate_Display}
                    className="inputBox inputBoxText"
                  />
                </div>
                <div className="remainingCharacters2">
                  Characters remaining: {this.state.changeEntry_Count}
                </div>
                <div className="datebox">
                  <div
                    className={
                      this.isActive(
                        this.state.changeEntry_startDate,
                        this.state.changeEntry_endDate,
                      )
                        ? 'activeBox'
                        : 'inactiveBox'
                    }
                  ></div>
                  <div>
                    <div className="infoText" id="New">
                      <b>Start Date</b> (Current: {this.state.changeEntry_startDate}):{' '}
                    </div>
                    <input
                      className="inputBox inputBoxText"
                      id="New"
                      type="date"
                      value={this.state.changeEntry_startDate}
                      onChange={this.changeEntryUpdate_startDate}
                      //placeholder={item.startDate}
                    ></input>
                  </div>
                  <div>
                    <div className="infoText" id="New">
                      <b>End Date</b> (Current: {this.state.changeEntry_endDate}):{' '}
                    </div>
                    <input
                      className="inputBox inputBoxText"
                      id="New"
                      type="date"
                      value={this.state.changeEntry_endDate}
                      onChange={this.changeEntryUpdate_endDate}
                    ></input>
                  </div>
                </div>
              </div>
              <div className="col">
                <div style={{ display: 'flex', overflow: 'hidden' }}>
                  <div className="infoText">Link URL:</div>
                  <input
                    type="text"
                    id="new"
                    value={this.state.changeEntry_url}
                    onChange={this.changeEntryUpdate_url}
                    className="inputBox inputBoxText"
                  />

                  <div
                    onClick={() => this.handleDeleteNew(type)}
                    className="deleteButton deleteButtonText"
                  >
                    <b>Delete</b>
                  </div>
                </div>
                <div className="remainingCharacters3">Paste the exact URL</div>
              </div>
            </div>
            <div className="dottedLine"></div>
          </div>
        </div>
      );
    }
  }

  handleDeleteNew = type => {
    switch (type) {
      case 'Stronger':
        let addStronger = !this.state.addStronger;
        this.setState({
          addStronger,
          strongerEntry_url: '',
          strongerEntry_Display: '',
          strongerEntry_Count: 52,
          strongerEntry_startDate: '',
          strongerEntry_endDate: '',
        });
        break;
      case 'News':
        let addNewOnNews = !this.state.addNewOnNews;
        this.setState({
          addNewOnNews,
          newsEntry_url: '',
          newsEntry_Display: '',
          newsEntry_Count: 50,
          newsEntry_startDate: '',
          newsEntry_endDate: '',
        });
        break;
      case 'Retention':
        let addNewRet = !this.state.addNewRet;
        this.setState({
          addNewRet,
          retentionEntry_url: '',
          retentionEntry_Display: '',
          retentionEntry_Count: 52,
          retentionEntry_startDate: '',
          retentionEntry_endDate: '',
        });
        break;
      case 'One':
        let addNewOne = !this.state.addNewOne;
        this.setState({
          addNewOne,
          oneEntry_url: '',
          oneEntry_Display: '',
          oneEntry_Count: 52,
          oneEntry_startDate: '',
          oneEntry_endDate: '',
        });
        break;
      case 'Onboard':
        let addNewOnboard = !this.state.addNewOnboard;
        this.setState({
          addNewOnboard,
          onboardEntry_url: '',
          onboardEntry_Display: '',
          onboardEntry_Count: 52,
          onboardEntry_startDate: '',
          onboardEntry_endDate: '',
        });
        break;
      case 'Other':
        let addOtherLd = !this.state.addOtherLd;
        this.setState({
          addOtherLd,
          otherEntry_url: '',
          otherEntry_Display: '',
          otherEntry_Count: 52,
          otherEntry_startDate: '',
          otherEntry_endDate: '',
        });
        break;
      case 'Change':
        let addChange = !this.state.addChange;
        this.setState({
          addChange,
          changeEntry_url: '',
          changeEntry_Display: '',
          changeEntry_Count: 52,
          changeEntry_startDate: '',
          changeEntry_endDate: '',
        });
        break;
    }
  };

  renderStrongerRegion(data) {
    const { StrongerOn } = this.state;
    // let strongerSummary = data.strongerTeam.Summary.Summary;
    // let strongerID = data.strongerTeam.Summary.ID;
    let strongerLinks = this.parseStronger(data);
    return (
      <div>
        <div className="row" style={{ paddingLeft: '55px' }}>
          <button
            tabIndex="0"
            onClick={e => this.handleStrongerClick(e)}
            id={'StongerClick'}
            style={{
              //   width: "100%",
              textAlign: 'left',
              border: 'none',
              backgroundColor: 'white',
            }}
          >
            <div className="ldChevronContainer">
              <img
                src={StrongerOn ? '/icon-chevron-up-dark.svg' : '/icon-chevron-down-dark.svg'}
                alt={this.state.StrongerOn ? "Click to collapse Building a Strong Team" : "Click to expand Building a Strong Team"}
              ></img>
            </div>
          </button>
          <div className="collapseHeader">
            <b>Building a Strong Team</b>
          </div>
        </div>
        <div className="needToKnowCont" style={StrongerOn ? {} : { display: 'none' }}>
          {/* <div className="linkSubTitle" style={{ paddingBottom: '5px' }}>
            SUMMARY TEXT:
          </div>
          <input
            id={strongerID}
            value={strongerSummary}
            className="summaryTextInputBox inputBoxText"
          />
          <div className="remainingCharacters">Characters remaining: 23</div> */}
          <div className="linkSubTitle" style={{ paddingTop: '10px' }}>
            LINKS:
          </div>
          {strongerLinks.map((item, index) => (
            <div>
              <div className="row" style={{ paddingBottom: '15px' }}>
                <div className="col" style={{ maxWidth: '44%' }}>
                  <div style={{ display: 'flex', overflow: 'hidden' }}>
                    <div className="infoText">Display Text:</div>
                    <input
                      type="text"
                      id={item.ID + '_Display'}
                      value={this.currState(item.ID)}
                      onChange={this.handleUpdateEntryBottom}
                      placeholder={item.Display}
                      className="inputBox inputBoxText"
                    />
                  </div>
                  <div className="remainingCharacters2">
                    Characters remaining: {this.lengthOfState(item.ID, 52)}
                  </div>
                  <div style={{ paddingTop: '10px' }}></div>
                  <div className="datebox">
                    <div
                      className={
                        this.isActive(item.startDate, item.endDate) ? 'activeBox' : 'inactiveBox'
                      }
                    ></div>
                    <div>
                      <div className="infoText" id={item.ID + '_startDate'}>
                        <b>Start Date</b> (Current: {this.removeTime(item.startDate)}):{' '}
                      </div>
                      <input
                        className="inputBox inputBoxText"
                        id={item.ID + '_startDate'}
                        type="date"
                        placeholder={item.startDate}
                        onChange={this.handleUpdateEntryDates}
                      ></input>
                    </div>
                    <div>
                      <div className="infoText" id={item.ID + '_endDate'}>
                        <b>End Date</b> (Current: {this.removeTime(item.endDate)}):{' '}
                      </div>
                      <input
                        className="inputBox inputBoxText"
                        id={item.ID + '_endDate'}
                        type="date"
                        placeholder={item.endDate}
                        onChange={this.handleUpdateEntryDates}
                      ></input>
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div style={{ display: 'flex', overflow: 'hidden' }}>
                    <div className="infoText">Link URL:</div>
                    <input
                      type="text"
                      id={item.ID + '_URL'}
                      // value={item.Link}
                      placeholder={item.Link}
                      onChange={this.handleUpdateEntryUrl}
                      className="inputBox inputBoxText"
                    />

                    <div
                      style={strongerLinks.length > 1 ? {} : { display: 'none' }}
                      onClick={() =>
                        this.deleteEntry(item.ID, 'strongerTeam', item.Display, item.Link)
                      }
                      className="deleteButton deleteButtonText"
                    >
                      <b>Delete</b>
                    </div>
                  </div>
                  <div className="remainingCharacters3">Paste the exact URL</div>
                </div>
              </div>
              <div className="dottedLine"></div>
            </div>
          ))}
          <div style={StrongerOn && this.state.addStronger ? {} : { display: 'none' }}>
            {this.addNewLinkForm('Stronger')}
          </div>
        </div>

        <div
          className="newLinkButton deleteButtonText"
          style={StrongerOn && !this.state.addStronger ? {} : { display: 'none' }}
          onClick={e => this.handleNewLinkStrongClick(e)}
        >
          <b>Add New Link</b>
        </div>
      </div>
    );
  }

  renderRetentionRegion(data) {
    const { StrongerOn, RetentionOn, oneOn, OnboardOn, otherLdOn, changeOn } = this.state;
    // let retentionSummary = data.LeaderRetention.Summary.Summary;
    // let retentionId = data.LeaderRetention.Summary.ID;
    let retentionLinks = this.parseRentention(data);
    return (
      <div>
        <div className="row" style={{ paddingLeft: '55px' }}>
          <button
            tabIndex="0"
            onClick={e => this.handleRetentionClick(e)}
            id={'retentionClick'}
            style={{
              //   width: "100%",
              textAlign: 'left',
              border: 'none',
              backgroundColor: 'white',
            }}
          >
            <div className="ldChevronContainer">
              <img
                src={RetentionOn ? '/icon-chevron-up-dark.svg' : '/icon-chevron-down-dark.svg'}
                alt={this.state.RetentionOn ? "Click to collapse Leader Retention Conversation Guide" : "Click to expand Leader Retention Conversation Guide"}
              ></img>
            </div>
          </button>
          <div className="collapseHeader">
            <b>Leader Retention Conversation Guide</b>
          </div>
        </div>
        <div className="needToKnowCont" style={RetentionOn ? {} : { display: 'none' }}>
          {/* <div className="linkSubTitle" style={{ paddingBottom: '5px' }}>
            SUMMARY TEXT:
          </div>
          <input
            id={retentionId}
            value={retentionSummary}
            className="summaryTextInputBox inputBoxText"
          />
          <div className="remainingCharacters">Characters remaining: 23</div> */}
          <div className="linkSubTitle" style={{ paddingTop: '10px' }}>
            LINKS:
          </div>
          {retentionLinks.map((item, index) => (
            <div>
              <div className="row" style={{ paddingBottom: '15px' }}>
                <div className="col" style={{ maxWidth: '44%' }}>
                  <div style={{ display: 'flex', overflow: 'hidden' }}>
                    <div className="infoText">Display Text:</div>
                    <input
                      type="text"
                      id={item.ID + '_Display'}
                      value={this.currState(item.ID)}
                      onChange={this.handleUpdateEntryBottom}
                      placeholder={item.Display}
                      className="inputBox inputBoxText"
                    />
                  </div>
                  <div className="remainingCharacters2">
                    Characters remaining: {this.lengthOfState(item.ID, 52)}
                  </div>
                  <div style={{ paddingTop: '10px' }}></div>
                  <div className="datebox">
                    <div
                      className={
                        this.isActive(item.startDate, item.endDate) ? 'activeBox' : 'inactiveBox'
                      }
                    ></div>
                    <div>
                      <div className="infoText" id={item.ID + '_startDate'}>
                        <b>Start Date</b> (Current: {this.removeTime(item.startDate)}):{' '}
                      </div>
                      <input
                        className="inputBox inputBoxText"
                        id={item.ID + '_startDate'}
                        type="date"
                        placeholder={item.startDate}
                        onChange={this.handleUpdateEntryDates}
                      ></input>
                    </div>
                    <div>
                      <div className="infoText" id={item.ID + '_endDate'}>
                        <b>End Date</b> (Current: {this.removeTime(item.endDate)}):{' '}
                      </div>
                      <input
                        className="inputBox inputBoxText"
                        id={item.ID + '_endDate'}
                        type="date"
                        placeholder={item.endDate}
                        onChange={this.handleUpdateEntryDates}
                      ></input>
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div style={{ display: 'flex', overflow: 'hidden' }}>
                    <div className="infoText">Link URL:</div>
                    <input
                      type="text"
                      id={item.ID + '_URL'}
                      // value={item.Link}
                      placeholder={item.Link}
                      onChange={this.handleUpdateEntryUrl}
                      className="inputBox inputBoxText"
                    />

                    <div
                      style={retentionLinks.length > 1 ? {} : { display: 'none' }}
                      id={item.ID}
                      onClick={() =>
                        this.deleteEntry(item.ID, 'LeaderRetention', item.Display, item.Link)
                      }
                      className="deleteButton deleteButtonText"
                    >
                      <b>Delete</b>
                    </div>
                  </div>
                  <div className="remainingCharacters3">Paste the exact URL</div>
                </div>
              </div>
              <div className="dottedLine"></div>
            </div>
          ))}
          <div style={RetentionOn && this.state.addNewRet ? {} : { display: 'none' }}>
            {this.addNewLinkForm('Retention')}
          </div>
        </div>

        <div
          className="newLinkButton deleteButtonText"
          style={RetentionOn && !this.state.addNewRet ? {} : { display: 'none' }}
          onClick={e => this.handleNewLinkRetClick(e)}
        >
          <b>Add New Link</b>
        </div>
      </div>
    );
  }

  renderOneRegion(data) {
    const { StrongerOn, RetentionOn, oneOn, OnboardOn, otherLdOn, changeOn } = this.state;
    // let summary = data.Better1on1s.Summary.Summary;
    // let strongerID = data.Better1on1s.Summary.ID;
    let oneOnLinks = this.parseOneOn(data);
    return (
      <div>
        <div className="row" style={{ paddingLeft: '55px' }}>
          <button
            tabIndex="0"
            onClick={e => this.handleOneOnClick(e)}
            id={'StongerClick'}
            style={{
              //   width: "100%",
              textAlign: 'left',
              border: 'none',
              backgroundColor: 'white',
            }}
          >
            <div className="ldChevronContainer">
              <img
                src={oneOn ? '/icon-chevron-up-dark.svg' : '/icon-chevron-down-dark.svg'}
                alt={this.state.oneOn ? "Click to Collapse Conduct Better 1-on-1's" : "Click to expand Conduct Better 1-on-1's"}
              ></img>
            </div>
          </button>
          <div className="collapseHeader">
            <b>Conduct Better 1-on-1’s</b>
          </div>
        </div>
        <div className="needToKnowCont" style={oneOn ? {} : { display: 'none' }}>
          {/* <div className="linkSubTitle" style={{ paddingBottom: '5px' }}>
            SUMMARY TEXT:
          </div>
          <input id={strongerID} value={summary} className="summaryTextInputBox inputBoxText" />
          <div className="remainingCharacters">Characters remaining: 23</div> */}
          <div className="linkSubTitle" style={{ paddingTop: '10px' }}>
            LINKS:
          </div>
          {oneOnLinks.map((item, index) => (
            <div>
              <div className="row" style={{ paddingBottom: '15px' }}>
                <div className="col" style={{ maxWidth: '44%' }}>
                  <div style={{ display: 'flex', overflow: 'hidden' }}>
                    <div className="infoText">Display Text:</div>
                    <input
                      type="text"
                      id={item.ID + '_Display'}
                      value={this.currState(item.ID)}
                      onChange={this.handleUpdateEntryBottom}
                      placeholder={item.Display}
                      className="inputBox inputBoxText"
                    />
                  </div>
                  <div className="remainingCharacters2">
                    Characters remaining: {this.lengthOfState(item.ID, 52)}
                  </div>
                  <div style={{ paddingTop: '10px' }}></div>
                  <div className="datebox">
                    <div
                      className={
                        this.isActive(item.startDate, item.endDate) ? 'activeBox' : 'inactiveBox'
                      }
                    ></div>
                    <div>
                      <div className="infoText" id={item.ID + '_startDate'}>
                        <b>Start Date</b> (Current: {this.removeTime(item.startDate)}):{' '}
                      </div>
                      <input
                        className="inputBox inputBoxText"
                        id={item.ID + '_startDate'}
                        type="date"
                        placeholder={item.startDate}
                        onChange={this.handleUpdateEntryDates}
                      ></input>
                    </div>
                    <div>
                      <div className="infoText" id={item.ID + '_endDate'}>
                        <b>End Date</b> (Current: {this.removeTime(item.endDate)}):{' '}
                      </div>
                      <input
                        className="inputBox inputBoxText"
                        id={item.ID + '_endDate'}
                        type="date"
                        placeholder={item.endDate}
                        onChange={this.handleUpdateEntryDates}
                      ></input>
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div style={{ display: 'flex', overflow: 'hidden' }}>
                    <div className="infoText">Link URL:</div>
                    <input
                      type="text"
                      id={item.ID + '_URL'}
                      // value={item.Link}
                      placeholder={item.Link}
                      onChange={this.handleUpdateEntryUrl}
                      className="inputBox inputBoxText"
                    />

                    <div
                      style={oneOnLinks.length > 1 ? {} : { display: 'none' }}
                      id={item.ID}
                      onClick={() =>
                        this.deleteEntry(item.ID, 'Better1on1s', item.Display, item.Link)
                      }
                      className="deleteButton deleteButtonText"
                    >
                      <b>Delete</b>
                    </div>
                  </div>
                  <div className="remainingCharacters3">Paste the exact URL</div>
                </div>
              </div>
              <div className="dottedLine"></div>
            </div>
          ))}
          <div style={oneOn && this.state.addNewOne ? {} : { display: 'none' }}>
            {this.addNewLinkForm('One')}
          </div>
        </div>

        <div
          className="newLinkButton deleteButtonText"
          style={oneOn && !this.state.addNewOne ? {} : { display: 'none' }}
          onClick={e => this.handleNewLinkOneClick(e)}
        >
          <b>Add New Link</b>
        </div>
      </div>
    );
  }

  renderOnboardRegion(data) {
    const { StrongerOn, RetentionOn, oneOn, OnboardOn, otherLdOn, changeOn } = this.state;
    // let summary = data.Onboard.Summary.Summary;
    // let strongerID = data.Onboard.Summary.ID;
    let links = this.parseOnboard(data);
    return (
      <div>
        <div className="row" style={{ paddingLeft: '55px' }}>
          <button
            tabIndex="0"
            onClick={e => this.handleOnboardClick(e)}
            id={'OnboardClick'}
            style={{
              //   width: "100%",
              textAlign: 'left',
              border: 'none',
              backgroundColor: 'white',
            }}
          >
            <div className="ldChevronContainer">
              <img
                src={OnboardOn ? '/icon-chevron-up-dark.svg' : '/icon-chevron-down-dark.svg'}
                alt={this.state.OnboardOn ? "Click to Collapse Onboard a new Team Member" : "Click to expand On board a new Team Member"}
              ></img>
            </div>
          </button>
          <div className="collapseHeader">
            <b>Onboard a New Team Member</b>
          </div>
        </div>
        <div className="needToKnowCont" style={OnboardOn ? {} : { display: 'none' }}>
          {/* <div className="linkSubTitle" style={{ paddingBottom: '5px' }}>
            SUMMARY TEXT:
          </div>
          <input id={strongerID} value={summary} className="summaryTextInputBox inputBoxText" />
          <div className="remainingCharacters">Characters remaining: 23</div> */}
          <div className="linkSubTitle" style={{ paddingTop: '10px' }}>
            LINKS:
          </div>
          {links.map((item, index) => (
            <div>
              <div className="row" style={{ paddingBottom: '15px' }}>
                <div className="col" style={{ maxWidth: '44%' }}>
                  <div style={{ display: 'flex', overflow: 'hidden' }}>
                    <div className="infoText">Display Text:</div>
                    <input
                      type="text"
                      id={item.ID + '_Display'}
                      value={this.currState(item.ID)}
                      onChange={this.handleUpdateEntryBottom}
                      placeholder={item.Display}
                      className="inputBox inputBoxText"
                    />
                  </div>
                  <div className="remainingCharacters2">
                    Characters remaining: {this.lengthOfState(item.ID, 52)}
                  </div>
                  <div style={{ paddingTop: '10px' }}></div>
                  <div className="datebox">
                    <div
                      className={
                        this.isActive(item.startDate, item.endDate) ? 'activeBox' : 'inactiveBox'
                      }
                    ></div>
                    <div>
                      <div className="infoText" id={item.ID + '_startDate'}>
                        <b>Start Date</b> (Current: {this.removeTime(item.startDate)}):{' '}
                      </div>
                      <input
                        className="inputBox inputBoxText"
                        id={item.ID + '_startDate'}
                        type="date"
                        placeholder={item.startDate}
                        onChange={this.handleUpdateEntryDates}
                      ></input>
                    </div>
                    <div>
                      <div className="infoText" id={item.ID + '_endDate'}>
                        <b>End Date</b> (Current: {this.removeTime(item.endDate)}):{' '}
                      </div>
                      <input
                        className="inputBox inputBoxText"
                        id={item.ID + '_endDate'}
                        type="date"
                        placeholder={item.endDate}
                        onChange={this.handleUpdateEntryDates}
                      ></input>
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div style={{ display: 'flex', overflow: 'hidden' }}>
                    <div className="infoText">Link URL:</div>
                    <input
                      type="text"
                      id={item.ID + '_URL'}
                      // value={item.Link}
                      placeholder={item.Link}
                      onChange={this.handleUpdateEntryUrl}
                      className="inputBox inputBoxText"
                    />

                    <div
                      style={links.length > 1 ? {} : { display: 'none' }}
                      id={item.ID}
                      onClick={() => this.deleteEntry(item.ID, 'Onboard', item.Display, item.Link)}
                      className="deleteButton deleteButtonText"
                    >
                      <b>Delete</b>
                    </div>
                  </div>
                  <div className="remainingCharacters3">Paste the exact URL</div>
                </div>
              </div>
              <div className="dottedLine"></div>
            </div>
          ))}
          <div style={OnboardOn && this.state.addNewOnboard ? {} : { display: 'none' }}>
            {this.addNewLinkForm('Onboard')}
          </div>
        </div>

        <div
          className="newLinkButton deleteButtonText"
          style={OnboardOn && !this.state.addNewOnboard ? {} : { display: 'none' }}
          onClick={e => this.handleNewLinkOnboardClick(e)}
        >
          <b>Add New Link</b>
        </div>
      </div>
    );
  }

  renderOtherLdRegion(data) {
    const { StrongerOn, RetentionOn, oneOn, OnboardOn, otherLdOn, changeOn } = this.state;
    // let summary = data.OtherLeaders.Summary.Summary;
    // let strongerID = data.OtherLeaders.Summary.ID;
    let links = this.parseOtherLeaders(data);
    return (
      <div>
        <div className="row" style={{ paddingLeft: '55px' }}>
          <button
            tabIndex="0"
            onClick={e => this.handleOtherLdClick(e)}
            id={'OtherLdClick'}
            style={{
              //   width: "100%",
              textAlign: 'left',
              border: 'none',
              backgroundColor: 'white',
            }}
          >
            <div className="ldChevronContainer">
              <img
                src={otherLdOn ? '/icon-chevron-up-dark.svg' : '/icon-chevron-down-dark.svg'}
                alt={this.state.otherLdOn ? "Click to Collapse Connect with other Leaders" : "Click to expand Connect with other leaders"}
              ></img>
            </div>
          </button>
          <div className="collapseHeader">
            <b>Connect with Other Leaders</b>
          </div>
        </div>
        <div className="needToKnowCont" style={otherLdOn ? {} : { display: 'none' }}>
          {/* <div className="linkSubTitle" style={{ paddingBottom: '5px' }}>
            SUMMARY TEXT:
          </div>
          <input id={strongerID} value={summary} className="summaryTextInputBox inputBoxText" />
          <div className="remainingCharacters">Characters remaining: 23</div> */}
          <div className="linkSubTitle" style={{ paddingTop: '10px' }}>
            LINKS:
          </div>
          {links.map((item, index) => (
            <form>
              <div className="row" style={{ paddingBottom: '15px' }}>
                <div className="col" style={{ maxWidth: '44%' }}>
                  <div style={{ display: 'flex', overflow: 'hidden' }}>
                    <div className="infoText">Display Text:</div>
                    <input
                      type="text"
                      id={item.ID + '_Display'}
                      value={this.currState(item.ID)}
                      onChange={this.handleUpdateEntryBottom}
                      placeholder={item.Display}
                      className="inputBox inputBoxText"
                    />
                  </div>
                  <div className="remainingCharacters2">
                    Characters remaining: {this.lengthOfState(item.ID, 52)}
                  </div>
                  <div style={{ paddingTop: '10px' }}></div>
                  <div className="datebox">
                    <div
                      className={
                        this.isActive(item.startDate, item.endDate) ? 'activeBox' : 'inactiveBox'
                      }
                    ></div>
                    <div>
                      <div className="infoText" id={item.ID + '_startDate'}>
                        <b>Start Date</b> (Current: {this.removeTime(item.startDate)}):{' '}
                      </div>
                      <input
                        className="inputBox inputBoxText"
                        id={item.ID + '_startDate'}
                        type="date"
                        placeholder={item.startDate}
                        onChange={this.handleUpdateEntryDates}
                      ></input>
                    </div>
                    <div>
                      <div className="infoText" id={item.ID + '_endDate'}>
                        <b>End Date</b> (Current: {this.removeTime(item.endDate)}):{' '}
                      </div>
                      <input
                        className="inputBox inputBoxText"
                        id={item.ID + '_endDate'}
                        type="date"
                        placeholder={item.endDate}
                        onChange={this.handleUpdateEntryDates}
                      ></input>
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div style={{ display: 'flex', overflow: 'hidden' }}>
                    <div className="infoText">Link URL:</div>
                    <input
                      type="text"
                      id={item.ID + '_URL'}
                      // value={item.Link}
                      placeholder={item.Link}
                      onChange={this.handleUpdateEntryUrl}
                      className="inputBox inputBoxText"
                    />

                    <div
                      style={links.length > 1 ? {} : { display: 'none' }}
                      id={item.ID}
                      onClick={() =>
                        this.deleteEntry(item.ID, 'OtherLeaders', item.Display, item.Link)
                      }
                      className="deleteButton deleteButtonText"
                    >
                      <b>Delete</b>
                    </div>
                  </div>
                  <div className="remainingCharacters3">Paste the exact URL</div>
                </div>
              </div>
              <div className="dottedLine"></div>
            </form>
          ))}
          <div style={otherLdOn && this.state.addOtherLd ? {} : { display: 'none' }}>
            {this.addNewLinkForm('Other')}
          </div>
        </div>

        <div
          className="newLinkButton deleteButtonText"
          style={otherLdOn && !this.state.addOtherLd ? {} : { display: 'none' }}
          onClick={e => this.handleNewLinkOtherClick(e)}
        >
          <b>Add New Link</b>
        </div>
      </div>
    );
  }

  renderChangeRegion(data) {
    const { StrongerOn, RetentionOn, oneOn, OnboardOn, otherLdOn, changeOn } = this.state;
    // let summary = data.Change.Summary.Summary;
    // let strongerID = data.Change.Summary.ID;
    let links = this.parseChange(data);
    return (
      <div>
        <div className="row" style={{ paddingLeft: '55px' }}>
          <button
            tabIndex="0"
            onClick={e => this.handleChangeClick(e)}
            id={'ChangeClick'}
            style={{
              //   width: "100%",
              textAlign: 'left',
              border: 'none',
              backgroundColor: 'white',
            }}
          >
            <div className="ldChevronContainer">
              <img
                src={changeOn ? '/icon-chevron-up-dark.svg' : '/icon-chevron-down-dark.svg'}
                alt={this.state.changeOn ? "Click to Collapse Lead through Change" : "Click to expand Lead through Change"}
              ></img>
            </div>
          </button>
          <div className="collapseHeader">
            <b>Lead through Change</b>
          </div>
        </div>
        <div className="needToKnowCont" style={changeOn ? {} : { display: 'none' }}>
          {/* <div className="linkSubTitle" style={{ paddingBottom: '5px' }}>
            SUMMARY TEXT:
          </div>
          <input id={strongerID} value={summary} className="summaryTextInputBox inputBoxText" />
          <div className="remainingCharacters">Characters remaining: 23</div> */}
          <div className="linkSubTitle" style={{ paddingTop: '10px' }}>
            LINKS:
          </div>
          {links.map((item, index) => (
            <div>
              <div className="row" style={{ paddingBottom: '15px' }}>
                <div className="col" style={{ maxWidth: '44%' }}>
                  <div style={{ display: 'flex', overflow: 'hidden' }}>
                    <div className="infoText">Display Text:</div>
                    <input
                      type="text"
                      id={item.ID + '_Display'}
                      value={this.currState(item.ID)}
                      onChange={this.handleUpdateEntryBottom}
                      placeholder={item.Display}
                      className="inputBox inputBoxText"
                    />
                  </div>
                  <div className="remainingCharacters2">
                    Characters remaining: {this.lengthOfState(item.ID, 52)}
                  </div>
                  <div className="datebox">
                    <div
                      className={
                        this.isActive(item.startDate, item.endDate) ? 'activeBox' : 'inactiveBox'
                      }
                    ></div>
                    <div>
                      <div className="infoText" id={item.ID + '_startDate'}>
                        <b>Start Date</b> (Current: {this.removeTime(item.startDate)}):{' '}
                      </div>
                      <input
                        className="inputBox inputBoxText"
                        id={item.ID + '_startDate'}
                        type="date"
                        placeholder={item.startDate}
                        onChange={this.handleUpdateEntryDates}
                      ></input>
                    </div>
                    <div>
                      <div className="infoText" id={item.ID + '_endDate'}>
                        <b>End Date</b> (Current: {this.removeTime(item.endDate)}):{' '}
                      </div>
                      <input
                        className="inputBox inputBoxText"
                        id={item.ID + '_endDate'}
                        type="date"
                        placeholder={item.endDate}
                        onChange={this.handleUpdateEntryDates}
                      ></input>
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div style={{ display: 'flex', overflow: 'hidden' }}>
                    <div className="infoText">Link URL:</div>
                    <input
                      type="text"
                      id={item.ID + '_URL'}
                      // value={item.Link}
                      placeholder={item.Link}
                      onChange={this.handleUpdateEntryUrl}
                      className="inputBox inputBoxText"
                    />

                    <div
                      style={links.length > 1 ? {} : { display: 'none' }}
                      id={item.ID}
                      onClick={() => this.deleteEntry(item.ID, 'Change', item.Display, item.Link)}
                      className="deleteButton deleteButtonText"
                    >
                      <b>Delete</b>
                    </div>
                  </div>
                  <div className="remainingCharacters3">Paste the exact URL</div>
                </div>
              </div>
              <div className="dottedLine"></div>
            </div>
          ))}
          <div style={changeOn && this.state.addChange ? {} : { display: 'none' }}>
            {this.addNewLinkForm('Change')}
          </div>
        </div>

        <div
          className="newLinkButton deleteButtonText"
          style={changeOn && !this.state.addChange ? {} : { display: 'none' }}
          onClick={e => this.handleNewLinkChangeClick(e)}
        >
          <b>Add New Link</b>
        </div>
      </div>
    );
  }

  lengthOfState(id, len) {
    if (this.state[id + '_Display']) {
      return len - this.state[id + '_Display'].length;
    } else {
      return len;
    }
  }

  currState(id) {
    if (this.state[id + '_Display']) {
      return this.state[id + '_Display'];
    } else {
      return '';
    }
  }

  isActive(startDate, endDate) {
    if (startDate === 'NA' || endDate === 'NA') return true;
    let start = Date.parse(startDate);
    let end = Date.parse(endDate);
    let now = Date.now();
    // console.log('isActive', start, end, now);
    if (start <= now && now <= end) {
      // console.log('isActive', true);
      return true;
    } else {
      //console.log('isActive', false);
      return false;
    }
  }

  renderNeedToKnow(data) {
    data = this.parseNeedToKnow(data);
    //console.log('Need to know', data);
    return (
      <div>
        <div className="needToKnowCont">
          <div className="needToKnowTitle">
            <b>What You Need to Know Now</b>
          </div>
          <div className="linkSubTitle">LINKS:</div>

          {data.map((item, index) => (
            <div>
              <div className="row" style={{ paddingBottom: '15px' }}>
                <div className="col" style={{ maxWidth: '44%' }}>
                  <div style={{ display: 'flex', overflow: 'hidden' }}>
                    <div className="infoText">Display Text:</div>
                    <input
                      type="text"
                      id={item.ID + '_Display'}
                      value={this.currState(item.ID)}
                      onChange={this.handleUpdateEntry}
                      placeholder={item.Display}
                      className="inputBox inputBoxText"
                    />
                  </div>
                  <div className="remainingCharacters2">
                    Characters remaining: {this.lengthOfState(item.ID, 50)}
                  </div>
                  <div style={{ paddingTop: '10px' }}></div>
                  <div className="datebox">
                    <div
                      className={
                        this.isActive(item.startDate, item.endDate) ? 'activeBox' : 'inactiveBox'
                      }
                    ></div>
                    <div>
                      <div className="infoText" id={item.ID + '_startDate'}>
                        <b>Start Date</b> (Current: {this.removeTime(item.startDate)}):{' '}
                      </div>
                      <input
                        className="inputBox inputBoxText"
                        id={item.ID + '_startDate'}
                        type="date"
                        placeholder={item.startDate}
                        onChange={this.handleUpdateEntryDates}
                      ></input>
                    </div>
                    <div>
                      <div className="infoText" id={item.ID + '_endDate'}>
                        <b>End Date</b> (Current: {this.removeTime(item.endDate)}):{' '}
                      </div>
                      <input
                        className="inputBox inputBoxText"
                        id={item.ID + '_endDate'}
                        type="date"
                        placeholder={item.endDate}
                        onChange={this.handleUpdateEntryDates}
                      ></input>
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div style={{ display: 'flex', overflow: 'hidden' }}>
                    <div className="infoText">Link URL:</div>
                    <input
                      type="text"
                      id={item.ID + '_URL'}
                      //value={item.Link}
                      placeholder={item.Link}
                      onChange={this.handleUpdateEntryUrl}
                      className="inputBox inputBoxText"
                    />

                    <div
                      style={data.length > 1 ? {} : { display: 'none' }}
                      onClick={() => this.deleteEntry(item.ID, 'news', item.Display, item.Link)}
                      className="deleteButton deleteButtonText"
                    >
                      <b>Delete</b>
                    </div>
                  </div>
                  <div className="remainingCharacters3">Paste the exact URL</div>
                </div>
              </div>
              <div className="dottedLine"></div>
            </div>
          ))}
          <div style={this.state.addNewOnNews ? {} : { display: 'none' }}>
            {this.addNewLinkForm('News')}
          </div>
        </div>
        <div
          className="newLinkButton deleteButtonText"
          style={!this.state.addNewOnNews ? {} : { display: 'none' }}
          onClick={e => this.handleNewLinkNewsClick(e)}
        >
          <b>Add New Link</b>
        </div>
      </div>
    );
  }

  removeTime(input) {
    let out = input.substring(0, 10);
    return out;
  }

  parseNeedToKnow(data) {
    let out = [];
    for (let i = 0; i < data.news.length; i++) {
      if (data.news[i].Added) {
        let start = 'NA';
        let end = 'NA';
        if (data.news[i].startDate) start = data.news[i].startDate;
        if (data.news[i].endDate) end = data.news[i].endDate;
        out[i] = {
          Display: data.news[i].Display,
          Link: data.news[i].Link,
          ID: data.news[i].ID,
          endDate: end,
          startDate: start,
        };
      }
    }
    return out;
  }

  parseStronger(data) {
    let out = [];
    for (let i = 0; i < data.strongerTeam.Links.length; i++) {
      if (data.strongerTeam.Links[i]) {
        let start = 'NA';
        let end = 'NA';
        if (data.strongerTeam.Links[i].startDate) start = data.strongerTeam.Links[i].startDate;
        if (data.strongerTeam.Links[i].endDate) end = data.strongerTeam.Links[i].endDate;
        out[i] = {
          Display: data.strongerTeam.Links[i].Display,
          Link: data.strongerTeam.Links[i].Link,
          ID: data.strongerTeam.Links[i].ID,
          startDate: start,
          endDate: end,
        };
      }
    }
    return out;
  }

  parseRentention(data) {
    let out = [];
    for (let i = 0; i < data.LeaderRetention.Links.length; i++) {
      if (data.LeaderRetention.Links[i]) {
        let start = 'NA';
        let end = 'NA';
        if (data.strongerTeam.Links[i].startDate) start = data.LeaderRetention.Links[i].startDate;
        if (data.strongerTeam.Links[i].endDate) end = data.LeaderRetention.Links[i].endDate;
        out[i] = {
          Display: data.LeaderRetention.Links[i].Display,
          Link: data.LeaderRetention.Links[i].Link,
          ID: data.LeaderRetention.Links[i].ID,
          startDate: start,
          endDate: end,
        };
      }
    }
    return out;
  }

  parseOneOn(data) {
    let out = [];
    for (let i = 0; i < data.Better1on1s.Links.length; i++) {
      if (data.Better1on1s.Links[i]) {
        let start = 'NA';
        let end = 'NA';
        if (data.Better1on1s.Links[i].startDate) start = data.Better1on1s.Links[i].startDate;
        if (data.Better1on1s.Links[i].endDate) end = data.Better1on1s.Links[i].endDate;
        out[i] = {
          Display: data.Better1on1s.Links[i].Display,
          Link: data.Better1on1s.Links[i].Link,
          ID: data.Better1on1s.Links[i].ID,
          startDate: start,
          endDate: end,
        };
      }
    }
    return out;
  }

  parseOnboard(data) {
    let out = [];
    for (let i = 0; i < data.Onboard.Links.length; i++) {
      if (data.Onboard.Links[i]) {
        let start = 'NA';
        let end = 'NA';
        if (data.Onboard.Links[i].startDate) start = data.Onboard.Links[i].startDate;
        if (data.Onboard.Links[i].endDate) end = data.Onboard.Links[i].endDate;
        out[i] = {
          Display: data.Onboard.Links[i].Display,
          Link: data.Onboard.Links[i].Link,
          ID: data.Onboard.Links[i].ID,
          startDate: start,
          endDate: end,
        };
      }
    }
    return out;
  }

  parseOtherLeaders(data) {
    let out = [];
    for (let i = 0; i < data.OtherLeaders.Links.length; i++) {
      if (data.OtherLeaders.Links[i]) {
        let start = 'NA';
        let end = 'NA';
        if (data.OtherLeaders.Links[i].startDate) start = data.OtherLeaders.Links[i].startDate;
        if (data.OtherLeaders.Links[i].endDate) end = data.OtherLeaders.Links[i].endDate;
        out[i] = {
          Display: data.OtherLeaders.Links[i].Display,
          Link: data.OtherLeaders.Links[i].Link,
          ID: data.OtherLeaders.Links[i].ID,
          startDate: start,
          endDate: end,
        };
      }
    }
    return out;
  }

  parseChange(data) {
    let out = [];
    for (let i = 0; i < data.Change.Links.length; i++) {
      if (data.Change.Links[i]) {
        let start = 'NA';
        let end = 'NA';
        if (data.Change.Links[i].startDate) start = data.Change.Links[i].startDate;
        if (data.Change.Links[i].endDate) end = data.Change.Links[i].endDate;
        out[i] = {
          Display: data.Change.Links[i].Display,
          Link: data.Change.Links[i].Link,
          ID: data.Change.Links[i].ID,
          startDate: start,
          endDate: end,
        };
      }
    }
    return out;
  }

  refreshPage = () => {
    window.location.reload();
  };

  render() {
    let { data, retrieveData, loaded } = this.props;
    // data = effectiveLd;
    // loaded = true;
    if (data === null) {
      retrieveData();
    }

    if (data) {
      if (loaded) {
        const content = this.returnConent(data);
        return <div className="adminBackground">{content}</div>;
      } else {
        return (
          <div className="adminBackground">
            <div
              className="progress-spinner "
              style={{
                left: '50%',
                top: '50%',
                paddingTop: '15px',
              }}
            />
          </div>
        );
      }
    } else {
      return null;
    }
  }
}

export default EffectiveLdAdmin;
