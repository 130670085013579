import jwt_decode from "jwt-decode";
import React, { Component } from "react";
import {
  getAccessToken,
  // getAzureAuthUrl,
  // isLoggedIn,
  getNwRefreshMS,
  getUserInformation,
  refreshAzureToken
} from "../services/AuthService";
// import config from "../config.json";
import { toast } from "react-toastify";

class Authorization extends Component {
  state = { intervalId: 0, refreshIntervalId: 0, user: null, loggedIn: true };
  _isMounted = false;

  componentDidMount() {
    this.badCount = 0;
    this._isMounted = true;
    let intervalId = setInterval(this.checkUser, 1000);

    if (this._isMounted) {
      this.setState({ intervalId });
    }

    const intervalTime = getNwRefreshMS();
    // console.log("Authorization.componentDidMount intervalTime", intervalTime);
    setInterval(this.refreshAccessToken, intervalTime);
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  refreshAccessToken = async () => {
    // console.log("Authorization.refreshAccessToken", new Date());

    const azureRefreshDate = new Date(
      sessionStorage.getItem("Azure_Expiration_date")
    );
    // console.log(
    //   "Authorization.refreshAccessToken azureRefreshDate",
    //   azureRefreshDate
    // );
    const now = new Date();
    const diff = azureRefreshDate - now;
    const intervalTime = getNwRefreshMS() + 1;
    if (diff < intervalTime) {
      console.log(
        "Authorization.refreshAccessToken Time to refresh you Azure Token!"
      );
      if (!refreshAzureToken()) {
        toast.error(
          "Popup blocked.  In order to remain logged in, please disable the popup blocker for this site."
        );
      }
      this.badCount = 0;
      setTimeout(this.updateFromLocalStorage, 5000);
    } else {
      const idToken = sessionStorage.getItem("idToken");
      const jsondata = await getAccessToken(idToken);
      const err = jsondata.error;
      if (err) {
        if (this.badCount < 5) {
          console.error(
            "Authorization.refreshAccessToken  has failed.  Try to refreshAzureToken",
            this.badCount
          );
          this.badCount++;
          if (!refreshAzureToken()) {
            toast.error(
              "Popup blocked.  In order to remain logged in, please disable the popup blocker for this site."
            );
          }
          setTimeout(this.refreshAccessToken, 5000 * this.badCount);
        } else {
          console.error(
            "Authorization.refreshAccessToken  has failed 5 times in a row--stop calling for now"
          );
        }
      } else {
        this.badCount = 0;
        localStorage.setItem("refreshing", "true");
        sessionStorage.setItem("refreshing", "true");
        const access_token = jsondata.access_token;
        this.requestUserInformation(access_token);
      }
    }
  };

  requestUserInformation = async access_token => {
    // console.log("Authorization.requestUserInformation()", new Date());
    // const jsondata = await getUserInformation(access_token);
    await getUserInformation(access_token);
  };

  onUnload = () => {
    localStorage.clear();
  };

  updateFromLocalStorage = () => {
    // console.log("authorization.updateFromLocalStorage start");
    const localToken = localStorage.getItem("idToken");
    // console.log("updateFromLocalStorage " + new Date(), localToken);
    if (localToken) {
      sessionStorage.setItem("idToken", localStorage.getItem("idToken"));
      sessionStorage.setItem(
        "Azure_Expiration_date",
        localStorage.getItem("Azure_Expiration_date")
      );
      sessionStorage.setItem("userinfo", localStorage.getItem("userinfo"));
      sessionStorage.setItem(
        "access_token",
        localStorage.getItem("access_token")
      );
      sessionStorage.setItem("contact", localStorage.getItem("contact"));
      sessionStorage.removeItem("refreshing");
      localStorage.clear();
    } else {
      if (this.badCount < 5) {
        this.badCount++;
        setTimeout(this.updateFromLocalStorage, 5000 * this.badCount);
      }
    }
  };

  checkUser = () => {
    console.log("authorization.checkUser", new Date());
    const idToken = sessionStorage.getItem("idToken");
    //  const idToken = localStorage.getItem("idToken");
    if (idToken) {
      const decoded = jwt_decode(idToken);
      if (decoded) {
        const family_name = decoded.family_name;
        const given_name = decoded.given_name;
        const unique_name = decoded.unique_name.toUpperCase();
        const userId = unique_name.replace("@NATIONWIDE.COM", "");
        // attempting to get values of job title and grade to set state of checklist 
        // values come back as null 
        const user = {
          firstName: given_name,
          lastName: family_name,
          userId: userId
        };
        this.props.onUserUpate(user);
        if (this._isMounted) {
          this.setState({ user });
        }
        clearInterval(this.state.intervalId);
      }
    }
  };

  renderPage() {
    return <div></div>;
  }

  render() {
    return <React.Fragment>{this.renderPage()}</React.Fragment>;
  }
}

export default Authorization;
