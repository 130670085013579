// import React, { Component } from "react";
import config from '../config.json';
import { getCallbackURI } from '../services/EnvironmentVariable';
import { getEnvironment } from './EnvironmentVariable';
import { isMobile } from 'react-device-detect';
const AZURE_CLIENT_ID = config.azureClientId;
const AZURE_TENANT_ID = config.azureTenantId;

export function CanViewReports() {
  // const environ = getEnvironment();
  // console.log("AuthService.CanViewReports start");
  try {
    const contact = JSON.parse(sessionStorage.getItem('contact'));
    //2/8/2021 updating the API.  If the reportAccess property exists then use it.
    if (contact.reportsAccess) {
      //The contact object contains the reportsAccess property
      // console.log(
      //   "AuthService.CanViewReports contact.reportsAccess",
      //   contact.reportsAccess
      // );
      return contact.reportsAccess === 'Y';
    }

    return false;
  } catch (e) {
    console.error('AuthService.CanViewReports Error2', e);
  }

  return false;
}
export function UserIsAdmin() {
  try {
    const contact = JSON.parse(sessionStorage.getItem('contact'));
    //2/8/2021 updating the API.  If the isPortalAdmin property exists then use it.
    if (contact.isPortalAdmin) {
      //This contact object contains the isPortalAdmin property
      // console.log(
      //   "AuthService.UserIsAdmin contact.isPortalAdmin",
      //   contact.isPortalAdmin
      // );
      return contact.isPortalAdmin === 'Y';
    }
    return false;
  } catch (e) {
    try {
      console.error('AuthService.UserIsAdmin Error', e);
      const userinfo = JSON.parse(sessionStorage.getItem('userinfo'));

      //2/8/2021 updating the API.  If the reportsAllowed property exists then use it.
      if (userinfo.isAdmin) {
        //The userinfo object contains the isAdmin property
        // console.log(
        //   "AuthService.UserIsAdmin userinfo.isAdmin",
        //   userinfo.isAdmin
        // );
        return userinfo.isAdmin;
      }

      return false;
    } catch (e2) {
      console.error('AuthService.UserIsAdmin Error2', e);
    }

    return false;
  }
}

export function getRandomString(length) {
  let text = '';
  const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  for (var i = 0; i < length; i++) {
    text += possible.charAt(Math.floor(Math.random() * possible.length));
  }
  return text;
}

export function getNwRefreshMS() {
  const nwTokenRefreshMinutes = config.nwTokenRefreshMinutes;
  const result = nwTokenRefreshMinutes === 0 ? 15 * 60 * 1000 : nwTokenRefreshMinutes * 60 * 1000;
  return result;
}

export function getAzureAuthUrl() {
  const REDIRECT_URL = encodeURIComponent(getCallbackURI());
  const nonce = getRandomString(12);
  const state = getRandomString(12);
  return `https://login.microsoftonline.com/${AZURE_TENANT_ID}/oauth2/authorize?client_id=${AZURE_CLIENT_ID}&response_type=id_token&redirect_uri=${REDIRECT_URL}&scope=openid&response_mode=fragment&state=${state}&nonce=${nonce}`;
}

export function refreshAzureToken() {
  // console.log("AuthService.refreshAzureToken");
  sessionStorage.setItem('refreshing', 'true');
  localStorage.setItem('refreshing', 'true');
  const nonce = getRandomString(12);
  const state = getRandomString(12);
  const url = getAzureAuthUrl(nonce, state);
  // console.log("AuthService.refreshAzureToken", url);
  const newWin = window.open(
    url,
    '_blank',
    'height=100,width=100,location=yes,menubar=no,resizable=no,titlebar=yes',
  );
  if (!newWin || newWin.closed || typeof newWin.closed == 'undefined') {
    //The window did not open
    return false;
  } else {
    //The window did open
    return true;
  }
}

export function isLoggedIn() {
  const expires_at_sec = sessionStorage.getItem('expires_at_sec');
  const user = sessionStorage.getItem('user');
  if (expires_at_sec && user) {
    //Found the expires_at_sec and user objects in sessionStorage
    const expDate = new Date(expires_at_sec * 1000);
    const now = new Date();
    if (expDate - now < 3000) {
      //The expiration date is within 3 seconds
      return false;
    } else {
      //The expiration date is more than 3 seconds away
      return true;
    }
  } else {
    //DId not find the expires_at_sec and user objects in sessionStorage
    return false;
  }
}

export function refreshInterval() {
  const sec = sessionStorage.getItem('expires_at_sec');
  const user = sessionStorage.getItem('user');
  if (sec && user) {
    //Found the expires_at_sec and user objects in sessionStorage
    const expires_at_sec = parseInt(sec);
    const expDate = new Date(expires_at_sec * 1000);
    const now = new Date();
    const refreshWhen = expDate - now;
    if (refreshWhen < 3000) return 3000;
    return refreshWhen;
  } else {
    //Did not find the expires_at_sec and user objects in sessionStorage
    // console.log(
    //   "AuthService.refreshInterval did not find expires_at_sec && user",
    //   sec && user
    // );
    return 0;
  }
}

export const getAccessToken = async idToken => {
  const environ = getEnvironment();
  console.log('AuthService.getAccessToken environ', environ);
  // let APIGEE_AUTHORITY = config.authority;
  // let APIGEE_CLIENT_ID = config.client_id;
  let APIGEE_AUTHORITY = config.portalApi;
  let APIGEE_CLIENT_ID = config.client_id;
  if (environ !== 'DEFAULT') {
    // We are not in a production environment
    if (isMobile) {
      //The user is in viewing on a phone or tablet
      APIGEE_AUTHORITY = config.Mobile_test_portalApi;
      APIGEE_CLIENT_ID = config.test_client_id;
    } else {
      //The user is viewing on a laptop
      APIGEE_AUTHORITY = config.test_portalApi;
      APIGEE_CLIENT_ID = config.test_client_id;
    }
  }

  const body = `azureToken=${encodeURIComponent(idToken)}`;
  let headers = new Headers();
  headers.append('client_id', APIGEE_CLIENT_ID);
  headers.append('Content-Type', 'application/x-www-form-urlencoded');
  const message = getRandomString(25);
  headers.append('X-NW-Message-ID', message);

  const response = await fetch(APIGEE_AUTHORITY + 'auth', {
    method: 'post',
    headers: headers,
    body: body,
  });
  if (response.ok) {
    //The API responded with a success message
    const fulljson = await response.json();
    const jsondata = fulljson.data;
    // console.log("requestAccessToken jsondata", jsondata);
    const access_token = jsondata.access_token;
    const refresh_token = jsondata.refresh_token;
    sessionStorage.setItem('access_token', access_token);
    sessionStorage.setItem('refresh_token', refresh_token);
    let d = new Date();
    d.setSeconds(d.getSeconds() + parseInt(jsondata.expires_in));
    // console.log("AuthService.getAccessToken store expires_at", d);
    const sec = Math.round(d.getTime() / 1000);
    sessionStorage.setItem('expires_at_sec', sec);

    // const now = new Date();
    // const diff = d - now;
    // console.log("AuthService.getAccessToken setTimeout diff", diff);
    return jsondata;
  } else {
    //The API did not respond successfully
    return { error: 'Unable to retrieve' };
  }
};

export const getUserInformation = async access_token => {
  // const APIGEE_USERINFO = config.user_info;

  const environ = getEnvironment();
  // console.log("AuthService.getAccessToken environ", environ);
  // console.log("AuthService.getUserInformation environ", environ);
  let APIGEE_USERINFO = config.user_info;
  // let APIGEE_CLIENT_ID = config.client_id;
  if (environ !== 'DEFAULT') {
    // We are not in a production environment
    if (isMobile) {
      //The user is in viewing on a phone or tablet
      APIGEE_USERINFO = config.Mobile_test_user_info;
    } else {
      //The user is in viewing on a laptop
      APIGEE_USERINFO = config.test_user_info;
      // APIGEE_CLIENT_ID = config.test_client_id;
    }
  }
  // console.log(
  //   "AuthService.getUserInformation  APIGEE_USERINFO=" + APIGEE_USERINFO
  // );
  // console.log(
  //   "AuthService.getUserInformation  APIGEE_CLIENT_ID=" + APIGEE_CLIENT_ID
  // );

  // APIGEE_USERINFO = "https://thisisnotreal.nwie.net";
  try {
    let headers = new Headers();
    headers.append('Authorization', 'Bearer ' + access_token);
    const response = await fetch(APIGEE_USERINFO, {
      method: 'get',
      headers: headers,
    });
    const jsondata = await response.json();
    try {
      const emplid = jsondata.employeeNumber;
      const contractor = emplid.startsWith('P');
      if (!contractor) {
        //The users emplid does not begin with P, so they are not a contractor
        sessionStorage.setItem('userinfo', JSON.stringify(jsondata));
      }
    } catch (e) {
      console.error('AuthService.getUserInformation error=' + e);
    }

    return jsondata;
  } catch (e1) {
    console.error('AuthService.getUserInformation error=' + e1);
  }
  return 'Error fetching from ' + APIGEE_USERINFO;
};
