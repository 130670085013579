import config from "../config.json";
import { getRandomString } from "./AuthService";
import { getEnvironment } from "./EnvironmentVariable";
import { isMobile } from "react-device-detect";
import testers from "../testers.json";

export const getFOW = async () => {
  //fowAPI
  const environ = getEnvironment();
  let fowAPI = config.fowAPI;
  let fowKey = config.fowKey;
  // console.log("fowService.getFOW environ", environ);
  if (environ !== "DEFAULT") {
    //We are not in production environment
    if (isMobile) {
      //However we are testing on a mobile device so use the Mobile api
      fowAPI = config.Mobile_test_fowAPI;
      fowKey = config.test_fowKey;
    } else {
      //We are not testing on a mobile device, so we will use the test API
      fowAPI = config.test_fowAPI;
      fowKey = config.test_fowKey;
    }
  }

  const userInfo = JSON.parse(sessionStorage.getItem("userinfo"));
  if (!userInfo || !userInfo.shortName) {
    return { error: "No user information found." };
  }
  let shortName = userInfo.shortName
    ? userInfo.shortName.toUpperCase()
    : userInfo.username.toUpperCase();

  const testUsers = testers.users;
  for (let x = 0; x < testUsers.length; x++) {
    const tester = testUsers[x];
    if (tester.shortName.toUpperCase() === shortName.toUpperCase()) {
      //User shortname matches to a tester
      if (environ === "DEFAULT") {
        //This is the production environment
        if (tester.prodAllowed) {
          //This tester is allowed to mimic in production
          shortName = tester.mimicManager.toUpperCase();
        }
      } else {
        //This is not a production environment
        shortName = tester.mimicManager.toUpperCase();
      }
      break;
    }
  }

  const message = getRandomString(25);
  const token = sessionStorage.getItem("access_token");
  let headers = new Headers();
  headers.append("Content-Type", "application/json");
  headers.append("Authorization", "Bearer " + token);
  // console.log("fowService.getFOW Authorization headers", "Bearer " + token);
  headers.append("X-NW-Message-ID", message);
  headers.set("client_id", fowKey);
  // console.log("fowService.getFOW client_id headers", fowKey);
  // console.log("fowService.getFOW  fowAPI", fowAPI);
  const body = {
    shortName: shortName
  };
  let data = null;
  let today = new Date();
  //Default the fowYear value to the year from the current date.
  let fowYear = today.getFullYear();
  // console.log("fowService.getFOW default year", fowYear);
  // console.log("fowService.getFOW default body", body);

  try {
    const response = await fetch(fowAPI, {
      method: "post",
      headers: headers,
      body: JSON.stringify(body)
    });
    if (response.ok) {
      try {
        data = await response.json();
        // console.log("fowService.getFOW  data", data);
      } catch (e) {
        //Response status was OK, but we got an error getting the json data
        console.error("fowService.getFOW  error", e);
        return {
          fowYear,
          error: "There was a problem getting the FOW JSON data"
        };
      }
    }
  } catch (E) {
    //There was an error calling the API
    console.error("fowService.getFOW  error", E);
    return { fowYear, error: "There was a problem retrieving the FOW data." };
  }

  if (!data) {
    //No data was returned
    // console.log("fowService.getFOW - no data returned");
    return { fowYear, error: "No data returned" };
  }
  if (data.userMessage) {
    //There is an error message in the data that was returned
    return { fowYear, error: data.userMessage };
  }
  // return { fowYear, error: "TEST" };
  let returnData = [];
  if (data && data.TeamResults && data.TeamResults.length > 0) {
    for (let x = 0; x < data.TeamResults.length; x++) {
      const hDate = data.TeamResults[x].hireDate;
      const arydt = hDate.split("-");
      const fowYear = data.TeamResults[x].fowYear;
      //create the hireDate for the associatefrom the string date (yyyy-mm=dd).
      const hireDate = new Date(
        parseInt(arydt[0]),
        parseInt(arydt[1]) - 1,
        parseInt(arydt[2])
      );
      //Create the newHireDate -- used to determine if the user is considered a new hire.
      //New hire date is June 1st of the fowYear
      let newHireDate = new Date();
      newHireDate.setMonth(6);
      newHireDate.setDate(1);
      newHireDate.setFullYear(fowYear);

      //Create the notNeededHireDate -- used to determine if the user doesn't have to compelte FOW this fowYear
      let notNeededHireDate = new Date();
      notNeededHireDate.setMonth(10);
      notNeededHireDate.setDate(2);
      //create newHire flag.  True if the user hireDate is on or after the new hired cuttoff date.
      const newHire = hireDate >= newHireDate;
      // create required flag.  True if the user hireDate is prior to the notNeededHireDate
      const required = hireDate < notNeededHireDate;
      //create noFowRequired flag.  True if the user hireDate is on or after the notNeededHireDate
      const noFowRequired = hireDate >= notNeededHireDate;

      //Get the digital completetion value.   Comes to us a string like ".####"
      let digitalCompletion = 0;
      if (data.TeamResults[x].digitalCompleted) {
        digitalCompletion =
          parseInt(data.TeamResults[x].digitalCompleted * 10000) / 100;
        // digitalCompletion =
        //   parseFloat(data.TeamResults[x].digitalCompleted) * 100;
      }
      if (digitalCompletion > 100) {
        digitalCompletion = 100;
      }

      //Get the Lead completetion value.  Comes to us a string like ".####"
      let leadCompletion = 0;
      if (data.TeamResults[x].LIACompleted) {
        leadCompletion =
          parseInt(data.TeamResults[x].LIACompleted * 10000) / 100;
        // leadCompletion = parseFloat(data.TeamResults[x].LIACompleted) * 100;
      }
      if (leadCompletion > 100) {
        leadCompletion = 100;
      }

      //Get the Overall completetion value.  Comes to us a string like ".####"
      let overall = 0;
      if (data.TeamResults[x].FoWCompleted) {
        overall = parseInt(data.TeamResults[x].FoWCompleted * 10000) / 100;
        // overall = parseFloat(data.TeamResults[x].FoWCompleted) * 100;
      }
      if (overall > 100) {
        overall = 100;
      }
      const fowShortName = data.TeamResults[x].shortname;
      //REMOVE THIS AFTER DEMO -- START
      // digitalCompletion = parseInt(Math.random() * 10000) / 100;
      // leadCompletion = parseInt(Math.random() * 10000) / 100;
      // overall =
      //   parseInt(((digitalCompletion + leadCompletion) / 2) * 100) / 100;

      //REMOVE THIS AFTER DEMO -- STOP

      returnData[x] = {
        shortName: fowShortName,
        fowYear,
        noFowRequired,
        hireDate,
        required,
        newHire,
        overall,
        digitalCompletion,
        leadCompletion,
        error: null
      };
    }
  }
  // console.log("fowService.getFOW completed");
  return returnData;
};
