import { isMobile } from "react-device-detect";
import config from "../config.json";
import testers from "../testers.json";
import { getRandomString } from "./AuthService";
import { getEnvironment } from "./EnvironmentVariable";

export const getTraining = async () => {
  const environ = getEnvironment();
  let trainingAPI = config.trainingAPI;
  let trainingKey = config.trainingKey;
  if (environ !== "DEFAULT") {
    //We are not in production environment
    if (isMobile) {
      //However we are testing on a mobile device so use the Mobile api
      trainingAPI = config.Mobile_test_trainingAPI;
      trainingKey = config.test_trainingKey;
    } else {
      //We are not testing on a mobile device, so we will use the test API
      trainingAPI = config.test_trainingAPI;
      trainingKey = config.test_trainingKey;
    }
  }

  const userInfo = JSON.parse(sessionStorage.getItem("userinfo"));
  if (!userInfo || !userInfo.shortName) {
    return { error: "No user information found." };
  }
  let shortName = userInfo.shortName
    ? userInfo.shortName.toUpperCase()
    : userInfo.username.toUpperCase();

  const testUsers = testers.users;
  for (let x = 0; x < testUsers.length; x++) {
    const tester = testUsers[x];
    if (tester.shortName.toUpperCase() === shortName.toUpperCase()) {
      //User shortname matches to a tester
      if (environ === "DEFAULT") {
        //This is the production environment
        if (tester.prodAllowed) {
          //This tester is allowed to mimic in production
          shortName = tester.mimicManager.toUpperCase();
        }
      } else {
        //This is not a production environment
        shortName = tester.mimicManager.toUpperCase();
      }
      break;
    }
  }

  const message = getRandomString(25);
  const token = sessionStorage.getItem("access_token");
  let headers = new Headers();
  headers.append("Content-Type", "application/json");
  headers.append("Authorization", "Bearer " + token);
  // console.log(
  //   "trainingService.getTraining Authorization headers",
  //   "Bearer " + token
  // );
  headers.append("X-NW-Message-ID", message);
  headers.set("client_id", trainingKey);
  // console.log("trainingService.getTraining client_id headers", trainingKey);
  // console.log("trainingService.getTraining  trainingAPI", trainingAPI);
  const body = {
    shortName: shortName
  };
  let data = null;

  // console.log("trainingService.getTraining default body", body);

  try {
    const response = await fetch(trainingAPI, {
      method: "post",
      headers: headers,
      body: JSON.stringify(body)
    });
    if (response.ok) {
      //The API returned a valid response
      try {
        data = await response.json();
        // console.log("trainingService.getTraining  data retrieved");
      } catch (e) {
        //Response status was OK, but we got an error getting the json data
        console.error("trainingService.getTraining  error", e);
        return { error: "There was an error getting the JSON training data." };
      }
    }
  } catch (E) {
    //There was an error calling the API
    console.error("trainingService.getTraining  error", E);
    return { error: "Unable to retrieve training data." };
  }
  const today = new Date();
  let result = [];
  if (data && data.userMessage) {
    //There is an error message in the data that was returned
    return { error: data.userMessage };
  }
  if (data && data.Employee && data.Employee.length > 0) {
    //Data was received and contains employees
    for (let x = 0; x < data.Employee.length; x++) {
      //Loop through the employees
      let learning = [];
      const employee = data.Employee[x];
      const shortName = employee.ShortName.toUpperCase();
      if (employee.CourseDetails && employee.CourseDetails.length > 0) {
        //The employee has at least 1 course assigned
        const courseDetails = employee.CourseDetails;
        for (let c = 0; c < courseDetails.length; c++) {
          // Loop through the employee course details
          const course = courseDetails[c];
          const title = course.Course_Name;
          let completed = course.Status;
          //Create a JavaScript date from the Due_Date property
          let dueDate = new Date();
          try {
            let aryDueDate = course.Due_Date.split("-");

            dueDate.setFullYear(aryDueDate[0]);
            dueDate.setDate(aryDueDate[2]);
            dueDate.setMonth(aryDueDate[1] - 1);
          } catch (e) {}

          let classDisplay = "learningComplete";
          if (completed === "Open") {
            //The Course is not completed
            if (today > dueDate) {
              //It is past the due date
              classDisplay = "learningPastDue";
              completed = "Past Due";
            } else {
              //It is not past the due date
              classDisplay = "learningIncomplete";
              completed = "Incomplete";
            }
          } else {
            completed = "Complete";
          }
          //console.log(title, " course status shows as ", completed, " with a due date of ", dueDate)

          const assignedDate = course.Assigned_Date;
          const completedDate = course.Completed_Date;
          //Create a learning object
          learning[c] = {
            shortName,
            title,
            completed,
            classDisplay,
            assignedDate,
            completedDate,
            dueDate: course.Due_Date
          };
        }
      }
      //SOrt the learning object array by assignedDate descending
      learning.sort((a, b) => (a.assignedDate > b.assignedDate ? -1 : 1));
      //add the object to the result array
      result[x] = { shortName, learning };
    }
  }

  //return the result array
  // console.log("trainingService.getTraining completed");
  return result;
};
